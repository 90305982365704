export const speakingClubType = {
  SPEAKING_CLUB_SLOT_SUCCESS: "SPEAKING_CLUB_SLOT_SUCCESS",
  SPEAKING_CLUB_SLOT_FETCH_FAIL: "SPEAKING_CLUB_SLOT_FETCH_FAIL",
  SPEAKING_CLUB_SLOT_FETCH_REQUESTED: "SPEAKING_CLUB_SLOT_FETCH_REQUESTED",
  SPEAKING_WORKSHOP_POST_REQUESTED: "SPEAKING_WORKSHOP_POST_REQUESTED",
  SPEAKING_WORKSHOP_POST_SUCCESS: "SPEAKING_WORKSHOP_POST_SUCCESS",
  SPEAKING_WORKSHOP_POST_FAIL: "SPEAKING_WORKSHOP_POST_FAIL",
  UPCOMING_SPEAKING_CLUB_REQUEST: "UPCOMING_SPEAKING_CLUB_REQUEST",
  UPCOMING_SPEAKING_CLUB_SUCCESS: "UPCOMING_SPEAKING_CLUB_SUCCESS",
  UPCOMING_SPEAKING_CLUB_FAIL: "UPCOMING_SPEAKING_CLUB_FAIL",
  UPDATE_MODAL_TEXT: "UPDATE_MODAL_TEXT",
  CLOSE_MODAL: "CLOSE_MODAL",
  SET_MODAL_TEXT: "SET_MODAL_TEXT,",
  FETCH_SPEAKING_CLUB_CREDITS: "FETCH_SPEAKING_CLUB_CREDITS",
  FETCH_SPEAKING_CLUB_CREDITS_SUCCESS: "FETCH_SPEAKING_CLUB_CREDITS_SUCCESS",
  FETCH_SPEAKING_CLUB_CREDITS_FAIL: "FETCH_SPEAKING_CLUB_CREDITS_FAIL",
  FETCH_SPEAKING_BUNDLE_PRICE_REQ: "FETCH_SPEAKING_CLUB_BUNDLE_PRICE_REQ",
  FETCH_SPEAKING_BUNDLE_PRICE_SUCCESS: "FETCH_SPEAKING_BUNDLE_PRICE_SUCCESS",
  FETCH_SPEAKING_BUNDLE_PRICE_FAIL: "FETCH_SPEAKING_BUNDLE_PRICE_FAIL",
  TOOGLE_SPEAKING_CLUB_RESCHEDULE_MODAL:
    "TOOGLE_SPEAKING_CLUB_RESCHEDULE_MODAL",
  TOGGLE_SPEAKING_CLUB_CANCELLED_MODAL: "TOGGLE_SPEAKING_CLUB_CANCELLED_MODAL",
  CANCEL_SPEAKING_CLUB_BATCH: "CANCEL_SPEAKING_CLUB_BATCH",
  UPDATE_SPEAKING_CLUB_ERROR: "UPDATE_SPEAKING_CLUB_ERROR",
  RESCHEDULE_SPEAKING_CLUB_BATCH: "RESCHEDULE_SPEAKING_CLUB_BATCH",
  TOGGLE_SPEAKING_CLUB_IS_RESCHEDULING: "TOGGLE_SPEAKING_CLUB_IS_RESCHEDULING",
}

const fetchSpeakingClubSlotData = () => ({
  type: speakingClubType.SPEAKING_CLUB_SLOT_FETCH_REQUESTED,
})

const fetchSpeakingClubSlotSuccess = (payload: any) => ({
  type: speakingClubType.SPEAKING_CLUB_SLOT_SUCCESS,
  payload,
})

const fetchSpeakingClubSlotFail = (message: string) => ({
  type: speakingClubType.SPEAKING_CLUB_SLOT_FETCH_FAIL,
  payload: message,
})

const postSpeakingWorkShop = (payload: number) => ({
  type: speakingClubType.SPEAKING_WORKSHOP_POST_REQUESTED,
  payload: payload,
})

const postSpeakingWorkShopSuccess = (payload: boolean) => ({
  type: speakingClubType.SPEAKING_WORKSHOP_POST_SUCCESS,
  payload: payload,
})

const postSpeakingWorkShopFail = (payload: string) => ({
  type: speakingClubType.SPEAKING_WORKSHOP_POST_FAIL,
  payload: payload,
})

// upcoming speaking classes action

const fetchUpcomingSpeakingClubData = () => ({
  type: speakingClubType.UPCOMING_SPEAKING_CLUB_REQUEST,
})

const fetchUpcomingSpeakingClubSuccess = (payload: any) => ({
  type: speakingClubType.UPCOMING_SPEAKING_CLUB_SUCCESS,
  payload,
})

const fetchUpcomingSpeakingClubFail = (message: string) => ({
  type: speakingClubType.UPCOMING_SPEAKING_CLUB_FAIL,
  payload: message,
})

// modal actions
const closeModal = () => ({
  type: speakingClubType.CLOSE_MODAL,
})

const setModalText = (message: string) => ({
  type: speakingClubType.SET_MODAL_TEXT,
  payload: message,
})

const fetchSpeakingClubCredits = () => ({
  type: speakingClubType.FETCH_SPEAKING_CLUB_CREDITS,
})

const fetchSpeakingClubCreditsSuccess = (payload: any) => ({
  type: speakingClubType.FETCH_SPEAKING_CLUB_CREDITS_SUCCESS,
  payload,
})

const fetchSpeakingClubCreditsFail = (message: string) => ({
  type: speakingClubType.FETCH_SPEAKING_CLUB_CREDITS_FAIL,
  payload: message,
})

const fetchSpeakingPriceBundleReq = () => ({
  type: speakingClubType.FETCH_SPEAKING_BUNDLE_PRICE_REQ,
})

const fetchSpeakingPriceBundleSuccess = (payload: any) => ({
  type: speakingClubType.FETCH_SPEAKING_BUNDLE_PRICE_SUCCESS,
  payload,
})
const fetchSpeakingPriceBundleFail = (payload: string) => ({
  type: speakingClubType.FETCH_SPEAKING_BUNDLE_PRICE_FAIL,
  payload,
})

const toogleSpeakingClubRescheduleModal = (payload: boolean) => ({
  type: speakingClubType.TOOGLE_SPEAKING_CLUB_RESCHEDULE_MODAL,
  payload,
})

const toggleSpeakingClubCancelledModal = (payload: boolean) => ({
  type: speakingClubType.TOGGLE_SPEAKING_CLUB_CANCELLED_MODAL,
  payload,
})

const cancelSpeakingClubSlot = (payload: number) => ({
  type: speakingClubType.CANCEL_SPEAKING_CLUB_BATCH,
  payload,
})

const updateError = (payload: string) => ({
  type: speakingClubType.UPDATE_SPEAKING_CLUB_ERROR,
  payload,
})

const rescheduleSlot = (payload: any) => ({
  type: speakingClubType.RESCHEDULE_SPEAKING_CLUB_BATCH,
  payload,
})

const toggleIsRescheduling = (payload: boolean) => ({
  type: speakingClubType.TOGGLE_SPEAKING_CLUB_IS_RESCHEDULING,
  payload,
})

export const speakingClubActions = {
  fetchSpeakingClubSlotData,
  fetchSpeakingClubSlotSuccess,
  fetchSpeakingClubSlotFail,
  postSpeakingWorkShop,
  postSpeakingWorkShopSuccess,
  postSpeakingWorkShopFail,
  fetchUpcomingSpeakingClubData,
  fetchUpcomingSpeakingClubSuccess,
  fetchUpcomingSpeakingClubFail,
  closeModal,
  setModalText,
  fetchSpeakingClubCredits,
  fetchSpeakingClubCreditsSuccess,
  fetchSpeakingClubCreditsFail,
  fetchSpeakingPriceBundleReq,
  fetchSpeakingPriceBundleSuccess,
  fetchSpeakingPriceBundleFail,
  toogleSpeakingClubRescheduleModal,
  toggleSpeakingClubCancelledModal,
  cancelSpeakingClubSlot,
  updateError,
  rescheduleSlot,
  toggleIsRescheduling,
}
