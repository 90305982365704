import api from "service/api"

const fetchPostPaymentData = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=student/post-payment`)
    return res
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const fetchBookmarksData = async () => {
  try {
    const res = await api.post(
      "/api/scholarRoute?path=scholarship-finder/get-bookmarks",
      {
        bookmarkType: "SCHOLARSHIP",
      },
    )
    return res
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page")
  }
}

export const counsellingApi = {
  fetchPostPaymentData,
  fetchBookmarksData,
}
