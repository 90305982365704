import { navRoutes } from "page_routes"
import { trackClick, trackIeltsClick } from "scripts/segment"
import { ieltsPageName } from "utils/events-utils"
import { TimeRangeInterface } from "components/Ielts/IeltsDemo/DemoCalendar/Filters/redux/FiltersSlice"
export enum CalendarView {
  LOADING = "LOADING",
  LIVE_VIEW = "LIVE_VIEW",
  FULL_CALENDAR_VIEW = "FULL_CALENDAR_VIEW",
}

interface HeaderFilterProps {
  heading: string
  subHeading: string
  showDateFilters: boolean
  showTimeFilters: boolean
}

export const getHeaderFilterProps = ({
  isLiveView,
  isDisqualifiedUser,
  isMasterclassAlias,
  isLiyFlow,
}: {
  isLiveView: boolean
  isDisqualifiedUser: boolean | undefined
  isMasterclassAlias: boolean
  isLiyFlow: boolean
}): HeaderFilterProps => {
  if (isLiyFlow)
    return {
      heading: "Schedule Your Discussion",
      subHeading: "Get expert feedback on your test performance",
      showDateFilters: !isLiveView,
      showTimeFilters: !isLiveView,
    }
  else
    return {
      heading: "1:1 Masterclass with IELTS trainer",
      subHeading: "Start your 8 band journey today!",
      showDateFilters: !isLiveView,
      showTimeFilters: !isLiveView,
    }
}

export interface TimeRange {
  startHour: number
  endHour: number
  disabled: boolean
}

export const defaultTimeRangeArray: Array<TimeRange> = [
  {
    startHour: 10,
    endHour: 14,
    disabled: false,
  },
  {
    startHour: 14,
    endHour: 19,
    disabled: false,
  },
  {
    startHour: 19,
    endHour: 23,
    disabled: false,
  },
]

export const allowedTimeRangeArray: Array<TimeRangeInterface> = [
  {
    startHour: 0,
    endHour: 14,
  },
  {
    startHour: 14,
    endHour: 19,
  },
  {
    startHour: 19,
    endHour: 24,
  },
]

export const noSlotsModalConten = {
  title: "Thank you for signing up with LeapScholar!",
  subtitle:
    "All MasterClass slots are currently booked. We will let you know once the slots become available",
  ctaAction: () => {
    trackIeltsClick(ieltsPageName.NO_DEMO_SLOTS, {
      contentName: "Go to dashboard",
    })
    window.open(navRoutes.IELTS_STUDENT_DASHBOARD, "_self")
  },
  description: "Meanwhile, you can try out our FREE mock tests!",
  imageURL: `${process.env.CDN_URL}/assets/images/profile/retry-modal.svg`,
  ctaText: "Go to Dashboard",
}

export enum IeltsDemoStages {
  TEACHER_MODAL,
  FORM_STAGE,
  TECAHER_SCREEN,
  JOIN_NOW,
}

export const congratsConfetticoData = {
  image: `${process.env.CDN_URL}/assets/images/congrats_confetti.svg`,
  heading: "Your IELTS Masterclass is Booked",
}

export interface ZoomCardData {
  image: string
  heading: string
  subHeading: string
  isAutoDownload: boolean
  manualDownload?: {
    downloadCta: string
    downloadCtaClick: () => void
  }
  autoDownload?: {
    redirectText: string
    redirectTimeInSec: number
    downloadCta: string
    downloadCtaClick: () => void
  }
}

export const getZoomCardData = (downloadLink: string): ZoomCardData => {
  return {
    image: `${process.env.CDN_URL}/assets/images/zoom_image.png`,
    heading: "Install Zoom App",
    subHeading: "MasterClass would be on Zoom",
    isAutoDownload: true,
    manualDownload: {
      downloadCta: "Download Zoom",
      downloadCtaClick: () => {
        trackClick(ieltsPageName.DEMO_CONFIRMATION_SCREEN, {
          contentName: "Download Zoom",
        })
        window.open(downloadLink, "_self")
      },
    },
    autoDownload: {
      redirectText: "Redirecting to Play Store in",
      redirectTimeInSec: 5,
      downloadCta: "Download Zoom",
      downloadCtaClick: () => {
        trackClick("Demo Confirm Exp 2", {
          contentName: "Download Zoom",
        })
        window.open(downloadLink, "_self")
      },
    },
  }
}

export enum AbVariants {
  ORIGINAL,
  MANUAL_DOWNLOAD,
  AUTO_DOWNLOAD,
}

export enum DemoFormAbVariants {
  ORIGINAL,
  FORM_1_CALENDAR_FORM_2,
}

export enum DisqualifiedUserRedirectVariants {
  TO_MASTER_CLASS,
  TO_DISQUAILIFED_CALENDER,
}

export enum QeFormFlow {
  OLD_FORM,
  NEW_FORM,
}

export enum IeltsAppVariants {
  ZOOM_DOWNLOAD = "0",
  IELTS_APP = "1",
  IELTS_APP_SCRATCH_CARD = "2",
}

export interface IeltsAppCard {
  image: string
  heading: string
  subHeading?: string
  rewardPointers: Array<{
    img: string
    text: string
  }>
}

export const ieltsAppCardData: IeltsAppCard = {
  image: `${process.env.CDN_URL}/assets/icons/ielts_app.png`,
  heading: "Your classes would happen on our IELTS App",
  subHeading: "Download IELTS App to unlock:",
  rewardPointers: [
    {
      img: `${process.env.CDN_URL}/assets/icons/green_accept_icon.svg`,
      text: "Free Speaking Practice Sessions",
    },
    {
      img: `${process.env.CDN_URL}/assets/icons/green_accept_icon.svg`,
      text: "IELTS Band Predictor Test",
    },
    {
      img: `${process.env.CDN_URL}/assets/icons/green_accept_icon.svg`,
      text: "Exclusive Free Masterclasses",
    },
  ],
}

export const IELTS_APP_LINK = "https://leapielts.app.link/open/"

export interface MasterclassLimit {
  start: number
  end: number
  dataLimit?: number
}
