import api from "service/api"

const fetchMockTest = async (batchId?: number) => {
  try {
    const res = await api.get(`/api/scholarRoute?path=mocktest/${batchId}`)
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const fetchFreeMockTest = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=mocktest/event`)
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const fetchSampleMockTest = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=mocktest/freeMockTest`)
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const fetchEssayStatus = async (essayId: number) => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=workshop/essay/${essayId}/details`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const uploadWritingMockTest = async (payload: any) => {
  const { file, activeBatchId, activeMockTestIndex } = payload
  try {
    const formData = new FormData()
    formData.append("file", file)
    const res = await api.post(
      `/api/uploadFile?name=workshop/essay/upload/${activeBatchId}/mocktest/${activeMockTestIndex}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    )
    return res.data
  } catch (err: any) {
    return { success: false, payload: "res.data.message" }
  }
}

const fetchEssayList = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=mocktest/independent/essays`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

export const mockTestApi = {
  fetchMockTest,
  fetchEssayStatus,
  uploadWritingMockTest,
  fetchEssayList,
  fetchFreeMockTest,
  fetchSampleMockTest,
}
