import { AnyAction } from "redux";
import { QuizReduceSchema } from "Interfaces/reducerTypes/IeltsPlusQuizSchema";
import { QuizType } from "components/Profile/IeltsProfile/QuizSection/redux/QuizType";

const initialState: QuizReduceSchema = {
  error: "",
  isLoading: false,
  quizList: [],
  activeQuestionId: null,
  activeQuizId: null,
  completeQuizList: [],
  newQuizList: [],
  ieltsQuizQuestionList: [],
  quizStatus: null,
  submittedQuestionsList: [],
  attemptId: null,
  isSubmitModalOpen: false,
  isExitModalOpen: false,
  submitSuccessfully: false,
  quizStartTime: null,
  quizEndTime: null,
  quizTitle: "",
  quizModalType:null,
  QuizBundleList:null
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case QuizType.FETCH_QUIZ_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case QuizType.FETCH_QUIZ_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        quizList: action.payload,
      };
    case QuizType.UPDATE_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case QuizType.UPDATE_COMPLETE_QUIZ_LIST:
      return {
        ...state,
        completeQuizList: action.payload,
      };
    case QuizType.UPDATE_NEW_QUIZ_LIST:
      return {
        ...state,
        newQuizList: action.payload,
      };
    case QuizType.FETCH_QUIZ_QUESTION_SUCCESS:
      return {
        ...state,
        ieltsQuizQuestionList: action.payload,
      };
    case QuizType.UPDATE_ACTIVE_QUIZ_ID:
      return {
        ...state,
        activeQuizId: action.payload,
      };
    case QuizType.UPDATE_QUIZ_STATUS:
      return {
        ...state,
        quizStatus: action.payload,
      };
    case QuizType.UPDATE_SUBMITTED_ANS:
      return {
        ...state,
        submittedQuestionsList: action.payload,
      };
    case QuizType.UPDATE_QUIZ_ATTEMPT_ID:
      return {
        ...state,
        attemptId: action.payload,
      };
    case QuizType.UPDATE_SUBMIT_MODAL:
      return {
        ...state,
        isSubmitModalOpen: action.payload,
      };
    case QuizType.UPDATE_EXIT_MODAL:
      return {
        ...state,
        isExitModalOpen: action.payload,
      };
    case QuizType.QUIZ_UPDATE_START_TEST_DATE:
      return {
        ...state,
        quizStartTime: action.payload,
      };
    case QuizType.SUBMIT_SUCCESSFULLY_ACTION:
      return {
        ...state,
        submitSuccessfully: action.payload,
      };
    case QuizType.QUIZ_UPDATE_END_TEST_DATE:
      return {
        ...state,
        quizEndTime: action.payload,
      };
    case QuizType.UPDATE_QUIZ_TITLE:
      return {
        ...state,
        quizTitle: action.payload,
      };
    case QuizType.FETCH_QUIZ_MODAL_TYPE_SUCCESS:
      return {
        ...state,
        quizModalType: action.payload,
      }
    case QuizType.FETCH_QUIZ_BUNDLE_TYPE_SUCCESS:
      return {
        ...state,
        QuizBundleList: action.payload,
      }
    default:
      return state;
  }
};

export default reducer;
