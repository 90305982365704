import { AnyAction } from 'redux'
import { documentUploadActionConstants } from 'components/DocumentsUpload/redux/DocumentUploadActions'
const initialState = {
  highestEducation: null,
  initialData: {},
  viewUrl: '',
  highestEducationData: {},
  uploadRes: null,
  isDeleted: false,
  filterStatus: 'ALL_DOCUMENTS',
  englishExamType: '',
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case documentUploadActionConstants.FETCH_DOCUMENT_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case documentUploadActionConstants.FETCH_DOCUMENT_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        initialData: action.payload,
      }
    case documentUploadActionConstants.VIEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        viewUrl: action.payload,
      }
    case documentUploadActionConstants.ADD_HIGHEST_EDUCATION_SUCCESS:
      return {
        ...state,
        initialData: {
          ...state.initialData,
          educationDocuments: action.payload,
        },
      }
    case documentUploadActionConstants.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        uploadRes: action.payload,
      }
    case documentUploadActionConstants.FILTER_DOCUMENTS:
      return {
        ...state,
        filterStatus: action.payload,
      }
    case documentUploadActionConstants.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isDeleted: action.payload,
      }
    case documentUploadActionConstants.DELETE_DOCUMENT:
      return {
        ...state,
        isDeleted: false,
      }
    case documentUploadActionConstants.ADD_MISC_DOCUMENT_SUCCESS:
      return {
        ...state,
        initialData: action.payload,
      }
    case documentUploadActionConstants.ADD_FILE_LABEL:
      return {
        ...state,
        initialData: {
          ...state.initialData,
          //@ts-ignore
          miscellaneousDocuments: state?.initialData?.miscellaneousDocuments
            ? //@ts-ignore
              [...state.initialData.miscellaneousDocuments, action.payload]
            : [action.payload],
        },
      }
    case documentUploadActionConstants.EDIT_TITLE:
      const newArray = { ...state.initialData }
      const splitData = Object.entries(newArray)
      const filData: any = splitData.filter((item: any) => {
        if (item[0] === 'miscellaneousDocuments') {
          return item
        }
      })
      const UpdatedData: any = [...filData[0][1], action.payload]

      return {
        ...state,
        initialData: {
          ...state.initialData,
          miscellaneousDocuments: UpdatedData,
        },
      }
    case documentUploadActionConstants.SELECT_ENGLISH_EXAM_TYPE_SUCCESS:
      return {
        ...state,
        initialData: {
          ...state.initialData,
          englishProficiencyExamDocuments: action.payload,
        },
      }
    case documentUploadActionConstants.EDIT_MISC_TITLE:
      const savedData = { ...action.payload }
      const newArray2 = { ...state.initialData }
      const splitData2 = Object.entries(newArray2)
      const filData2: any = splitData2.filter((item: any) => {
        if (item[0] === 'miscellaneousDocuments') {
          return item
        }
      })
      const b = filData2[0][1]
      const UpdatedData2 = b.map((item: any) => {
        return item.fileLabel == savedData?.toBeEditedTitle
          ? { ...item, fileLabel: savedData?.updatedTitle }
          : item
      })

      return {
        ...state,
        initialData: {
          ...state.initialData,
          miscellaneousDocuments: UpdatedData2,
        },
      }
    default:
      return state
  }
}

export default reducer
