import {
  CourseType,
  SelfPrepPriceInterface,
} from "components/Ielts/SelfPrepPrePayment/DataMapper/intefaces"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
const reducerName = "selfPrep"
export interface SelfPrepReducerInterface {
  selfPrepPrice: Record<CourseType, SelfPrepPriceInterface> | null
}
const initialState: SelfPrepReducerInterface = {
  selfPrepPrice: null,
}

const selfPrepSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    setSelfPrepPrice(
      state,
      action: PayloadAction<Record<CourseType, SelfPrepPriceInterface> | null>,
    ) {
      return { ...state, selfPrepPrice: action.payload }
    },
  },
})

export const selfPrepAction = { ...selfPrepSlice.actions }
export default selfPrepSlice.reducer
