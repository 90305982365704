import documentDetailsConstants from "../constants/document-details-constants";

const fetchDocumentDetailsData = () => ({
  type: documentDetailsConstants.FETCH_DOCUMENT_DETAILS_DATA,
});

const fetchDocumentDetailsDataSuccess = (payload: string) => ({
  type: documentDetailsConstants.FETCH_DOCUMENT_DETAILS_DATA_SUCCESS,
  payload,
});

const fetchDocumentDetailsDataError = (message: string) => ({
  type: documentDetailsConstants.FETCH_DOCUMENT_DETAILS_DATA_ERROR,
  payload: message,
});

export const documentDetailsActions = {
  fetchDocumentDetailsData,
  fetchDocumentDetailsDataSuccess,
  fetchDocumentDetailsDataError,
};
