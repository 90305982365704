import React from 'react'

type Props = {
  device?: 'mobile' | 'desktop'
}

const LineBreak: React.FC<Props> = ({ device }) => {
  return (
    <br
      className={
        device === 'mobile'
          ? 'md:hidden'
          : device === 'desktop'
          ? 'hidden md:block'
          : ''
      }
    ></br>
  )
}
export default LineBreak
