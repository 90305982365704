import { call, put, takeLatest } from "redux-saga/effects";
import { financialCounsellingApi } from "components/Profile/CounsellingProfile/FinancialCounselling/redux/FinancialCounsellingApi";
import { financialCounsellingActions } from "components/Profile/CounsellingProfile/FinancialCounselling/redux/FinancialCounsellingActions";

function* fetchData(): Generator<any, any, any> {
  try {
    const financialCounsellingData = yield call(
      financialCounsellingApi.fetchFinancialCounsellingData
    );
    if (financialCounsellingData.isJoined) {
      yield put(
        financialCounsellingActions.updateJoinStatus(
          financialCounsellingData.isJoined
        )
      );
    }
    yield put(
      financialCounsellingActions.fetchSuccess(financialCounsellingData)
    );
  } catch (e:any) {
    yield put(financialCounsellingActions.fetchFail(e.message));
  }
}

function* fetchPageData(): Generator<any, any, any> {
  try {
    const res = yield call(
      financialCounsellingApi.fetchFinancialCounsellingPageData
    );
    if (res.data.success) {
      yield put(
        financialCounsellingActions.fetchFinancialCounsellingPageDataSuccess(
          res.data.data
        )
      );
    } else {
      yield put(
        financialCounsellingActions.fetchFinancialCounsellingPageDataFail(
          res.data.message
        )
      );
    }
  } catch (e:any) {
    yield put(
      financialCounsellingActions.fetchFinancialCounsellingPageDataFail(
        e.message
      )
    );
  }
}

function* financialCounsellingSaga() {
  yield takeLatest("FINANCIAL_COUNSELLING_FETCH_REQUESTED", fetchData);
  yield takeLatest("FINANCIAL_COUNSELLING_PAGE_FETCH_REQUESTED", fetchPageData);
}

export default financialCounsellingSaga;
