import differenceInSeconds from "date-fns/differenceInSeconds"
import differenceInDays from "date-fns/differenceInDays"
import format from "date-fns/format"
import { isValid } from "date-fns"
import { getExactTime } from "utils/ielts_date_utils"

export function secondsConverter(seconds: number) {
  const day = Math.floor(seconds / (24 * 3600))
  const h = Math.floor((seconds % (24 * 3600)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor((seconds % 3600) % 60)
  return {
    sec: s,
    min: m,
    hour: h,
    day: day,
  }
}

export function appendZeroForTiming(digit: number) {
  if (`${digit}`.length == 1) return `0${digit}`
  return digit
}

export const getTimeLeftForMeetingInSeconds = (
  meetingStartTime: string,
  startHour: number,
  startMinute: number,
) => {
  const timeDiff = differenceInSeconds(
    getExactTime(meetingStartTime, startHour, startMinute),
    new Date(),
  )
  return timeDiff > 0 ? timeDiff : 0
}

export function getClassTimingInWork(ms: number) {
  const seconds = Math.floor(ms / 1000)
  const date = secondsConverter(seconds)
  if (date.day) {
    if (date.day > 1) return `${date.day} Days`
    return "Tomorrow"
  } else {
    return "Today"
  }
}

export function getNextClassTiming(date: string) {
  const todayDay = new Date(new Date().toDateString()) //excluding the time because we need date only
  const classDay = new Date(date)
  const dayDiff = differenceInDays(classDay, todayDay)
  if (dayDiff < 0) return ""
  if (todayDay.getDate() == classDay.getDate()) return "Today"
  if (dayDiff == 1 || dayDiff == 0) return "Tomorrow"
  else return `${differenceInDays(classDay, todayDay)} Days`
}

export function formatDateForSpeakingClasses(date: Date) {
  if (!isValid(date)) return null
  const formattedDate = format(date, "do MMM, EEEE")
  const formattedTime = format(date, "h:mm a")
  return {
    date: formattedDate,
    time: formattedTime,
  }
}
