const fetchUserInfo = () => ({
  type: "USER_DETAILS_FETCH_REQUESTED",
});

const fetchSuccess = (payload: any) => ({
  type: "USER_DETAILS_FETCH_SUCCESS",
  payload,
});

const fetchFail = (message: string) => ({
  type: "USER_DETAILS_FETCH_FAIL",
  payload: message,
});



export const referAndEarnActions = {
  fetchUserInfo,
  fetchSuccess,
  fetchFail,
};
