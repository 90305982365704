import Image from "next/image"
import { FunctionComponent, ReactNode } from "react"

interface ButtonProps {
  px?: string
  py?: string
  ctaText: string | ReactNode
  onClick: (e?: any) => any
  disabled?: boolean
  iconDimension?: string
  iconSrc?: string
  className?: string
}

interface TertairyButtonProps {
  px?: string
  ctaText: string
  href: string
  onClick?: () => any
}

export const PrimaryButton: FunctionComponent<ButtonProps> = ({
  px,
  ctaText,
  onClick,
  disabled,
  className,
  py,
}) => {
  return (
    <button
      onClick={onClick}
      className={`font-bold text-center shadow-11 ${
        py ? py : "py-3"
      } rounded cursor-pointer ${px ? px : `px-6`} ${
        disabled
          ? "text-lightgray-600 bg-gray-1050 cursor-not-allowed"
          : "bg-primary text-white border-primary border"
      } ${className ? className : ""}`}
    >
      {ctaText}
    </button>
  )
}

export const DangerButton: FunctionComponent<ButtonProps> = ({
  px,
  ctaText,
  onClick,
  disabled,
  className,
  py,
}) => {
  return (
    <button
      onClick={onClick}
      className={`font-bold text-center shadow-11 ${
        py ? py : "py-3"
      } rounded cursor-pointer ${px ? px : `px-6`} ${
        disabled
          ? "text-lightgray-600 bg-gray-1050 cursor-not-allowed"
          : "bg-red-400 text-white border-red-400 border"
      } ${className ? className : ""}`}
    >
      {ctaText}
    </button>
  )
}

export const SecondaryButton: FunctionComponent<ButtonProps> = ({
  px,
  py,
  ctaText,
  onClick,
  className,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={`bg-white text-primary font-bold text-sm md:text-base text-center border border-primary rounded cursor-pointer ${
        py ? py : "py-3"
      } ${px ? px : `px-6`} ${disabled ? "opacity-50" : ""} ${
        className ? className : ""
      }`}
    >
      {ctaText}
    </button>
  )
}

export const SecondaryButtonWithIcon: FunctionComponent<ButtonProps> = ({
  px,
  py,
  ctaText,
  onClick,
  className,
  iconDimension,
  iconSrc,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={`bg-white text-primary border font-bold text-sm md:text-base text-center border-primary rounded cursor-pointer ${
        py ? py : "py-3"
      } ${px ? px : `px-6`} ${disabled ? "opacity-50" : ""} ${
        className ? className : ""
      }`}
    >
      <div className="flex items-center justify-center">
        {ctaText}
        <div className="ml-1 flex items-center">
          {iconSrc && (
            <Image
              src={iconSrc}
              width={iconDimension ?? 16}
              height={iconDimension ?? 16}
              alt="single_tick"
            />
          )}
        </div>
      </div>
    </button>
  )
}

export const TertiaryButton: FunctionComponent<TertairyButtonProps> = ({
  px,
  ctaText,
  href,
  onClick,
}) => {
  return (
    <a
      onClick={onClick}
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      className={`bg-primary text-white font-bold text-sm md:text-base text-center py-3 border border-primary rounded cursor-pointer ${
        px ? px : `px-6`
      }`}
    >
      {ctaText}
    </a>
  )
}

export const QuaternaryButton: FunctionComponent<TertairyButtonProps> = ({
  px,
  ctaText,
  href,
}) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      className={`bg-white text-primary font-bold text-sm md:text-base text-center py-3 border border-primary rounded cursor-pointer ${
        px ? px : `px-6`
      }`}
    >
      {ctaText}
    </a>
  )
}

export interface ImageButtonProps {
  image?: string
  size?: "x" | "m" | "l"
  ctaText: string
  ctaAction: () => any
  customCss?: string
}

export const ImageButton: FunctionComponent<ImageButtonProps> = ({
  image,
  size = "m",
  ctaText,
  ctaAction,
  customCss,
}) => {
  const imageSize = () => {
    switch (size) {
      case "x":
        return "w-4"
      case "m":
        return "w-6"
      case "l":
        return "w-8"
    }
  }
  return (
    <button className="my-2 md:my-4 w-full" onClick={ctaAction}>
      <div
        className={`flex justify-center items-center ${
          customCss || "text-primary font-bold"
        }`}
      >
        {image && <img src={image} className={imageSize()} />}
        <span className="pl-2">{ctaText}</span>
      </div>
    </button>
  )
}

interface FloatingButtonProps {
  isVisible: boolean
  ctaText: string
  ctaAction: () => any
  img?: string
  positionY?: "top" | "bottom"
  positionX?: "right" | "left"
  size: "x" | "m" | "l"
  buttonCss?: string
  customCss?: string
  zIndex?: number
}
export const FloatingButton: FunctionComponent<FloatingButtonProps> = ({
  isVisible,
  ctaAction,
  ctaText,
  img,
  positionX = "right",
  positionY = "bottom",
  buttonCss,
  customCss,
  zIndex = 2000,
}) => {
  const getPositionY = () => {
    if (positionY == "top") {
      return "top-0"
    } else {
      return "bottom-0"
    }
  }

  const getPositionX = () => {
    if (positionX === "left") {
      return "left-0"
    } else {
      return "right-0"
    }
  }

  if (isVisible) {
    return (
      <div
        className={[
          "fixed",
          getPositionX(),
          getPositionY(),
          "my-2 md:my-2",
        ].join(" ")}
        style={{ zIndex }}
      >
        <div className={customCss}>
          <button className="my-2  w-full" onClick={ctaAction}>
            <div
              className={`flex justify-center items-center ${
                buttonCss || "text-primary font-bold"
              }`}
            >
              {img && <img src={img} className="" />}
              <span className="pl-2">{ctaText}</span>
            </div>
          </button>
        </div>
      </div>
    )
  } else {
    return null
  }
}

interface StyledButtonProps {
  ctaText?: string | ReactNode
  onClick?: () => void
  disabled?: boolean
  className?: string
  bRadius?: string
  image?: string
  textAlignment?: string
  py?: string
  isCounNudge?: boolean
  iconDimension?: string
}

export const StyledButton: FunctionComponent<StyledButtonProps> = ({
  ctaText,
  onClick,
  className,
  disabled,
  bRadius,
  py,
  isCounNudge,
}) => {
  return (
    <button
      onClick={!disabled ? onClick : () => {}}
      style={{
        background: disabled
          ? "#CDCDCD"
          : !isCounNudge
          ? "transparent linear-gradient(166deg, #E2A531 0%, #E26D30 100%) 0% 0% no-repeat padding-box"
          : "",
        boxShadow: "0px 1px 5px #9C27B01F",
        borderRadius: bRadius ?? "3px",
        opacity: 1,
      }}
      className={`px-3 ${
        py ?? "py-1"
      } text-white font-bold text-sm md:text-base text-center  rounded cursor-pointer  ${
        disabled ? "text-[#8E8E8E]" : ""
      } ${className ? className : ""}`}
      disabled={disabled}
    >
      {ctaText}
    </button>
  )
}

export const StyledButtonWithArrow: FunctionComponent<StyledButtonProps> = ({
  ctaText,
  onClick,
  className,
  disabled,
  bRadius,
  iconDimension,
  py,
}) => {
  return (
    <button
      onClick={!disabled ? onClick : () => {}}
      style={{
        background:
          "transparent linear-gradient(166deg, #E2A531 0%, #E26D30 100%) 0% 0% no-repeat padding-box",
        boxShadow: "0px 1px 5px #9C27B01F",
        borderRadius: bRadius ?? "3px",
        opacity: 1,
      }}
      className={`px-3 ${
        py ?? "py-1"
      } text-white font-bold text-sm md:text-base text-center  rounded cursor-pointer  ${
        disabled ? "opacity-50" : ""
      } ${className ? className : ""}`}
      disabled={disabled}
    >
      <div className={`flex items-center`}>
        {ctaText}
        <div className="ml-1 flex items-center">
          <Image
            src= {`${process.env.CDN_URL}/assets/icons/arrow_right_long.svg`}
            width={iconDimension ?? 16}
            height={iconDimension ?? 16}
            alt="single_tick"
          />
        </div>
      </div>
    </button>
  )
}

export const StyledButtonWithIcon: FunctionComponent<StyledButtonProps> = ({
  ctaText,
  onClick,
  image,
  className,
  disabled,
  bRadius,
  iconDimension,
  py,
}) => {
  return (
    <button
      onClick={!disabled ? onClick : () => {}}
      style={{
        background: disabled
          ? "grey"
          : "transparent linear-gradient(166deg, #E2A531 0%, #E26D30 100%) 0% 0% no-repeat padding-box",
        boxShadow: "0px 1px 5px #9C27B01F",
        borderRadius: bRadius ?? "3px",
        opacity: 1,
      }}
      className={`px-3 ${
        py ?? "py-1"
      } text-white font-bold text-sm md:text-base text-center  rounded cursor-pointer  ${
        disabled ? "opacity-50" : ""
      } ${className ? className : ""}`}
      disabled={disabled}
    >
      <div className="flex items-center">
        <div className="mr-1 flex items-center">
          <Image
            src={image ?? `${process.env.CDN_URL}/assets/icons/arrow_right_long.svg`}
            width={iconDimension ?? 16}
            height={iconDimension ?? 16}
            alt="single_tick"
          />
        </div>
        {ctaText}
      </div>
    </button>
  )
}
