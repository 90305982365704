import {
  IeltsPlusDataSchema,
  ModalTextSchema,
} from 'Interfaces/reducerTypes/IeltsPlusSchema'
import { UpcomingClassSchema } from 'Interfaces/reducerTypes/MyClassSchema'

export const myClassActionType = {
  IELTS_FETCH_REQUESTED: 'IELTS_FETCH_REQUESTED',
  IElTS_FETCH_SUCCEEDED: 'IElTS_FETCH_SUCCEEDED',
  IELTS_FETCH_FAILED: 'IELTS_FETCH_FAILED',
  UPDATE_MODAL_TEXT: 'UPDATE_MODAL_TEXT',
  TOGGLE_CONFIRM_MODAL: 'TOGGLE_CONFIRM_MODAL',
  ENROLL_TO_IELTS_PLUS: 'ENROLL_TO_IELTS_PLUS',
  ENROLL_TO_IELTS_PLUS_SUCCESS: 'ENROLL_TO_IELTS_PLUS_SUCCESS',
  ENROLL_TO_IELTS_PLUS_FAILED: 'ENROLL_TO_IELTS_PLUS_FAILED',
  ENROLL_TO_MASTER_CLASS: 'ENROLL_TO_MASTER_CLASS',
  ENROLL_TO_MASTER_CLASS_SUCCESS: 'ENROLL_TO_MASTER_CLASS_SUCCESS',
  ENROLL_TO_MASTER_CLASS_FAIL: 'ENROLL_TO_MASTER_CLASS_FAIL',
  MASTER_FETCH_REQUESTED: 'MASTER_FETCH_REQUESTED',
  MASTER_FETCH_SUCCESS: 'MASTER_FETCH_SUCCESS',
  MASTER_FETCH_FAIL: 'MASTER_FETCH_FAIL',
  UPCOMING_CLASS_FETCH_REQUESTED: 'UPCOMING_CLASS_FETCH_REQUESTED',
  UPCOMING_CLASS_FETCH_SUCCESS: 'UPCOMING_CLASS_FETCH_SUCCESS',
  UPCOMING_CLASS_FETCH_FAIL: 'UPCOMING_CLASS_FETCH_FAIL',
  SET_IELTS_MODAL: 'SET_IELTS_MODAL',
  SET_MASTER_CLASS_MODAL: 'SET_MASTER_CLASS_MODAL',
  NOTIFY_USER_FOR_MASTERCLASS: 'NOTIFY_USER_FOR_MASTERCLASS',
  FETCH_MASTERCLASS_SUBSCRIPTION_STATUS:
    'FETCH_MASTERCLASS_SUBSCRIPTION_STATUS',
}

const fetchIeltsData = () => ({
  type: myClassActionType.IELTS_FETCH_REQUESTED,
})

const fetchSuccess = (payload: Array<IeltsPlusDataSchema>) => ({
  type: myClassActionType.IElTS_FETCH_SUCCEEDED,
  payload,
})

const fetchFail = (message: string) => ({
  type: myClassActionType.IELTS_FETCH_FAILED,
  payload: message,
})

const updateModalText = (payload: ModalTextSchema) => ({
  type: myClassActionType.UPDATE_MODAL_TEXT,
  payload,
})

const toggleConfirmModal = (payload: boolean) => ({
  type: myClassActionType.TOGGLE_CONFIRM_MODAL,
  payload,
})

const enrollToIeltsPlus = (batchId: number) => ({
  type: myClassActionType.ENROLL_TO_IELTS_PLUS,
  payload: batchId,
})

const enrollIeltsSuccess = (payload: boolean) => ({
  type: myClassActionType.ENROLL_TO_IELTS_PLUS_SUCCESS,
  payload,
})

const enrollIeltsFail = (message: string) => ({
  type: myClassActionType.ENROLL_TO_IELTS_PLUS_FAILED,
  payload: message,
})

// Master class Actions

const fetchMasterclassSubscriptionStatus = () => ({
  type: myClassActionType.FETCH_MASTERCLASS_SUBSCRIPTION_STATUS,
})

const fetchMasterclassSubscriptionStatusSuccess = (
  payload: boolean | null
) => ({
  type: 'FETCH_MASTERCLASS_SUBSCRIPTION_STATUS_SUCCESS',
  payload,
})

const fetchMasterclassSubscriptionStatusFail = (message: string) => ({
  type: 'FETCH_MASTERCLASS_SUBSCRIPTION_STATUS_FAIL',
  payload: message,
})

const fetchMasterClassData = () => ({
  type: myClassActionType.MASTER_FETCH_REQUESTED,
})

const fetchMasterClassSuccess = (payload: Array<UpcomingClassSchema>) => ({
  type: myClassActionType.MASTER_FETCH_SUCCESS,
  payload,
})

const fetchMasterClassFail = (message: string) => ({
  type: myClassActionType.MASTER_FETCH_FAIL,
  payload: message,
})

const enrollToMasterClass = () => ({
  type: myClassActionType.ENROLL_TO_MASTER_CLASS,
})

const enrollToMasterClassSuccess = (payload: boolean) => ({
  type: myClassActionType.ENROLL_TO_MASTER_CLASS_SUCCESS,
  payload,
})

const enrollToMasterClassFail = (message: string) => ({
  type: myClassActionType.ENROLL_TO_MASTER_CLASS_FAIL,
  payload: message,
})

const notifyUser = (batchId: number) => ({
  type: myClassActionType.NOTIFY_USER_FOR_MASTERCLASS,
  payload: batchId,
})

// upcoming classes

const fetchUpcomingClassData = () => ({
  type: myClassActionType.UPCOMING_CLASS_FETCH_REQUESTED,
})

const fetchUpcomingClassSuccess = (payload: UpcomingClassSchema) => ({
  type: myClassActionType.UPCOMING_CLASS_FETCH_SUCCESS,
  payload,
})

const fetchUpcomingClassFail = (message: string) => ({
  type: myClassActionType.UPCOMING_CLASS_FETCH_FAIL,
  payload: message,
})

const setIeltsModal = (modalStatus: boolean) => ({
  type: myClassActionType.SET_IELTS_MODAL,
  payload: modalStatus,
})

const setMasterClassModal = (modalStatus: boolean) => ({
  type: myClassActionType.SET_MASTER_CLASS_MODAL,
  payload: modalStatus,
})

export const myClassAction = {
  fetchIeltsData,
  fetchSuccess,
  fetchFail,
  updateModalText,
  toggleConfirmModal,
  enrollToIeltsPlus,
  enrollIeltsSuccess,
  enrollIeltsFail,
  fetchMasterclassSubscriptionStatus,
  fetchMasterclassSubscriptionStatusSuccess,
  fetchMasterclassSubscriptionStatusFail,
  fetchMasterClassData,
  fetchMasterClassSuccess,
  fetchMasterClassFail,
  fetchUpcomingClassData,
  fetchUpcomingClassSuccess,
  fetchUpcomingClassFail,
  setIeltsModal,
  enrollToMasterClass,
  enrollToMasterClassSuccess,
  enrollToMasterClassFail,
  setMasterClassModal,
  notifyUser,
}
