import { getUtmData } from "./utm_utils"

export const isFunction = (data: any) => {
  if (typeof data === "function") return true
  return false
}

export const localStorageSetItem = (key: string, value: any): boolean => {
  try {
    localStorage.setItem(key, JSON.stringify(value))
    return true
  } catch (error) {
    return false
  }
}

export const localStorageGetItem = (key: string): any => {
  try {
    const itemWeGet = localStorage.getItem(key)
    if (itemWeGet !== null) {
      return JSON.parse(itemWeGet)
    }
    return null
  } catch (error) {
    return null
  }
}

export const getQueryParamStringFromObject = (object: any) => {
  let valuesString: any = Object.keys(object).map((key) =>
    object[key]?.length > 0 ? `${key}=${object[key]}` : ``,
  ) as string[]

  valuesString = valuesString?.filter((n: any) => n)?.join("&")
  valuesString = valuesString.length > 0 ? `&${valuesString}` : ``
  return valuesString
}

export const redirectOutFromIframe = (url: string) => {
  try {
    if (window && window.top) {
      window.top.location.href = url
    }
  } catch (err: any) {
    console.log(err)
  }
}

export const checkIfWeAreInFrame = (): boolean => {
  try {
    if (window) {
      if (window.parent.location === window.location) {
        return false
      } else {
        return true
      }
    }
    return false
  } catch (error) {
    return false
  }
}

export function getFixedRandomNumberPerHour(
  startingNumber: number,
  endingNumber: number,
  salt?: number,
) {
  const randomNumber = salt || 5144
  const currentHour = new Date().getHours()
  const diff = endingNumber - startingNumber
  return Math.floor((randomNumber * currentHour) % diff) + startingNumber
}

export function getNumberFromEnd(str: string) {
  if (!str) return null
  const regex = /(\d+)$/

  const matches: RegExpExecArray | null = regex.exec(str)

  if (matches && matches.length > 1) {
    return parseInt(matches[1])
  } else {
    return null
  }
}

export const joinStringWithHyphen = (string: string) => {
  if (!string) return null
  string = string.replace(/[^a-zA-Z0-9\s]/g, "").toLowerCase()

  string = string.replace(/\s+/g, "-")

  return string
}

export const truncateString = (str: string, len: number) => {
  if (str && str !== "-" && str?.length > len) {
    if (len <= 3) {
      return str.slice(0, len - 3) + "..."
    } else {
      return str.slice(0, len) + "..."
    }
  } else {
    return str
  }
}

export const isReferrerSemLeapScholar = () => {
  try {
    const utmDetails = getUtmData(null)
    const isGoogleUTMSource = utmDetails?.utmSource
      ? utmDetails?.utmSource?.toLowerCase()?.includes("google_search")
      : false
  
    return isGoogleUTMSource
  } catch (error) {
    console.error(error)
    return false
  }
}
export const getRandomNumber = (min : number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
