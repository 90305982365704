import { AnyAction } from "redux";
import { essayType } from "components/Profile/IeltsProfile/EssayEvaluation/redux/EssayActions";
import { EssayEvaluationSchema } from "Interfaces/reducerTypes/EssaySchema";

const initialState: EssayEvaluationSchema = {
  essayData: null,
  loading: true,
  uploadSuccess: false,
  errorMessage: "",
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case essayType.ESSAY_FETCH_REQ:
      return {
        ...state,
        loading: true,
      };
    case essayType.ESSAY_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        essayData: action.payload,
      };
    case essayType.ESSAY_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case essayType.UPLOAD_ESSAY_REQ:
      return {
        ...state,
        loading: true,
      };
    case essayType.UPLOAD_ESSAY_SUCCESS:
      return {
        ...state,
        loading: false,
        essayData: action.payload,
      };
    case essayType.UPLOAD_ESSAY_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case essayType.REPLACE_ESSAY_REQ:
      return {
        ...state,
        loading: true,
      };
    case essayType.REPLACE_ESSAY_SUCCESS:
      return {
        ...state,
        loading: false,
        essayData: action.payload,
      };
    case essayType.REPLACE_ESSAY_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case essayType.CLEAR_ERROR_MSG:
      return {
        ...state,
        errorMessage: "",
      };
    default:
      return state;
  }
};

export default reducer;
