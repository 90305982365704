import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import {
  documentUploadActionConstants,
  documentUploadActions,
} from 'components/DocumentsUpload/redux/DocumentUploadActions'
import { documentUploadApi } from 'components/DocumentsUpload/redux/DocumentUploadApis'

function* fetchDocumentData(): Generator<any, any, any> {
  try {
    const res = yield call(documentUploadApi.fetchDocumentDataRequest)
    yield put(documentUploadActions.fetchDocumentDataSuccess(res))
  } catch (e: any) {
    console.error(e)
    yield put(documentUploadActions.fetchDocumentDataError(e.message))
  }
}

function* uploadDocument(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      documentUploadApi.uploadDocumentRequest,
      action.payload
    )
    yield put(documentUploadActions.uploadDocumentSuccess(res))
    yield put(documentUploadActions.fetchDocumentData())
  } catch (e: any) {
    console.error(e)
    yield put(documentUploadActions.uploadDocumentError(e.message))
  }
}

function* deleteDocument(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      documentUploadApi.deleteDocumentRequest,
      action.payload
    )
    yield put(documentUploadActions.deleteDocumentSuccess(res))
    yield put(documentUploadActions.fetchDocumentData())
  } catch (e: any) {
    console.error(e)
    yield put(documentUploadActions.deleteDocumentError(e.message))
  }
}

function* downloaDocument(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      documentUploadApi.downloadDocumentRequest,
      action.payload
    )
    yield put(documentUploadActions.downloadDocumentSuccess(res))
  } catch (e: any) {
    console.error(e)
    yield put(documentUploadActions.downloadDocumentError(e.message))
  }
}

function* viewDocument(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      documentUploadApi.viewDocumentRequest,
      action.payload
    )
    yield put(documentUploadActions.viewDocumentSuccess(res))
  } catch (e: any) {
    console.error(e)
    yield put(documentUploadActions.viewDocumentError(e.message))
  }
}

function* highestEducation(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      documentUploadApi.addHighestEducationRequest,
      action.payload
    )
    yield put(documentUploadActions.addHighestEducationSuccess(res))
    yield put(documentUploadActions.fetchDocumentData())
  } catch (e: any) {
    console.error(e)
    yield put(documentUploadActions.addHighestEducationError(e.message))
  }
}

function* addWorkEx(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(documentUploadApi.addWorkExRequest, action.payload)
    yield put(documentUploadActions.addWorkExSuccess(res))
    yield put(documentUploadActions.fetchDocumentData())
  } catch (e: any) {
    console.error(e)
    yield put(documentUploadActions.addWorkExError(e.message))
  }
}

function* editTitle(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(documentUploadApi.editTitleRequest, action.payload)
    yield put(documentUploadActions.editTitleSuccess(res))
    yield put(documentUploadActions.fetchDocumentData())
  } catch (e: any) {
    console.error(e)
    yield put(documentUploadActions.editTitleError(e.message))
  }
}
function* addMiscDocument(): Generator<any, any, any> {
  const getStep = (state: any) => state.documentUpload.initialData
  yield select(getStep)

  // try {
  //   const res = yield call(
  //     documentUploadApi.addFileLabelRequest,
  //     action.payload
  //   );
  //   yield put(documentUploadActions.addFileLabelSuccess(res));
  //   yield put(documentUploadActions.fetchDocumentData());
  // } catch (e:any) {
  //   console.error(e);
  //   yield put(documentUploadActions.addFileLabelError(e.message));
  // }
}

function* selectEnglishExamType(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      documentUploadApi.selectEnglishExamRequest,
      action.payload
    )
    yield put(documentUploadActions.selectEnglishExamTypeSuccess(res))
  } catch (e: any) {
    console.error(e)
    yield put(documentUploadActions.selectEnglishExamTypeError(e.message))
  }
}

function* documentsUploadSaga() {
  yield takeLatest(
    documentUploadActionConstants.FETCH_DOCUMENT_DATA,
    fetchDocumentData
  )
  yield takeLatest(
    documentUploadActionConstants.UPLOAD_DOCUMENT,
    uploadDocument
  )
  yield takeLatest(
    documentUploadActionConstants.DELETE_DOCUMENT,
    deleteDocument
  )
  yield takeLatest(
    documentUploadActionConstants.DOWNLOAD_DOCUMENT,
    downloaDocument
  )
  yield takeLatest(documentUploadActionConstants.VIEW_DOCUMENT, viewDocument)
  yield takeLatest(
    documentUploadActionConstants.ADD_HIGHEST_EDUCATION,
    highestEducation
  )
  yield takeLatest(documentUploadActionConstants.ADD_WORK_EX, addWorkEx)
  yield takeLatest(documentUploadActionConstants.EDIT_TITLE, editTitle)
  // yield takeLatest(documentUploadActionConstants.ADD_FILE_LABEL, addFileLabel);
  yield takeLatest(
    documentUploadActionConstants.ADD_MISC_DOCUMENT,
    addMiscDocument
  )
  yield takeLatest(
    documentUploadActionConstants.SELECT_ENGLISH_EXAM_TYPE,
    selectEnglishExamType
  )
}

export default documentsUploadSaga
