import isArray from "lodash/isArray";

export const createOptionsObject = (arr) => {
  if (arr.length) {
    let optionObject = [];
    arr.forEach((e) => {
      optionObject.push({ value: e, label: e });
    });
    return optionObject;
  }
};

export const getFilteredData = (status, data) => {
  let updatedData = {};
  if (isArray(data)) {
    if (status === "PENDING") {
      updatedData = {
        uploaded: [],
        pending: data && data.filter((k) => k.documentStatus === "PENDING"),
        rejected: [],
        accepted: [],
      };
    } else if (status === "UPLOADED") {
      updatedData = {
        uploaded: data && data.filter((k) => k.documentStatus === "UPLOADED"),
        pending: [],
        rejected: [],
        accepted: [],
      };
    } else if (status === "VERIFIED") {
      updatedData = {
        uploaded: [],
        pending: [],
        rejected: [],
        accepted: data && data.filter((k) => k.documentStatus === "ACCEPTED"),
      };
    } else if (status === "REJECTED") {
      updatedData = {
        uploaded: [],
        pending: [],
        rejected: data && data.filter((k) => k.documentStatus === "REJECTED"),
        accepted: [],
      };
    } else {
      updatedData = {
        uploaded: data && data.filter((k) => k.documentStatus === "UPLOADED"),
        pending: data && data.filter((k) => k.documentStatus === "PENDING"),
        rejected: data && data.filter((k) => k.documentStatus === "REJECTED"),
        accepted: data && data.filter((k) => k.documentStatus === "ACCEPTED"),
      };
    }
    return updatedData;
  }
};

export const getPercentage = (number, total) => {
  return Math.floor((number / total) * 100);
};

export const getYearRange = (backYearsRange = 10) => {
  const currentYear = new Date().getFullYear();
  let yearRange = [];
  const range = (start, stop, step) =>
    Array.from({ length: (stop - start) / step + 1 }, (_, i) => {
      yearRange.push({ label: (start + i * step).toString(), value: start + i * step });
    });

  range(currentYear + 1, currentYear - backYearsRange, -1);
  return yearRange;
};