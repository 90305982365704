import { AnyAction } from "redux";
import PaymentConstants from "components/Payment/redux/PaymentReduxConstants";

const initialState: any = {
  userDetails: null,
  paymentDetails: null,
  promoDetails: null,
  error: "",
  isLoading: false,
  isError: false,
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case PaymentConstants.FETCH_USER_DATA_FOR_PAYMENT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case PaymentConstants.FETCH_USER_DATA_FOR_PAYMENT_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
        isLoading: false,
        isError: false,
      };
    case PaymentConstants.FETCH_USER_DATA_FOR_PAYMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isError: true,
      };

    case PaymentConstants.FETCH_PAYMENT_DATA:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case PaymentConstants.FETCH_PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        paymentDetails: action.payload,
        isLoading: false,
        isError: false,
      };
    case PaymentConstants.FETCH_PAYMENT_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isError: true,
      };

    case PaymentConstants.VALIDATE_PROMO_CODE:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case PaymentConstants.VALIDATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoDetails: action.payload,
        isLoading: false,
        isError: false,
      };
    case PaymentConstants.VALIDATE_PROMO_CODE_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isError: true,
      };

    // case PaymentConstants.APPLY_PROMO_CODE:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     isError: false,
    //     paymentDetails: {
    //       ...state.paymentDetails,
    //       offer: Math.floor(
    //         state.paymentDetails.offer -
    //           (state.promoDetails.discount / 100) * state.paymentDetails.offer
    //       ),
    //     },
    //   };

    case PaymentConstants.INITIALIZE_PAYMENT:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case PaymentConstants.INITIALIZE_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
      };
    case PaymentConstants.INITIALIZE_PAYMENT_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};

export default reducer;
