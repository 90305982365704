export const cookiesConstant = {
  downloadAppModal: "downloadAppModal",
  IELTS_COURSE_REFFERER: "ieltsCourseReferer",
  USER_COUNTRY: "user_country",
  NEED_HELP_OPENED: "need_help_opened",
  USER_ID: "user_id",
  IP_COUNTRY_KEY: "IP_COUNTRY_KEY",
}

export const cookiesTtl = {
  downloadAppModal: 60 * 30, //30 min
}
