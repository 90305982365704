export const fastTrackActionType = {
  FETCH_FAST_TRACK_STATUS_REQ: "FETCH_FAST_TRACK_REQ",
  FETCH_FAST_TRACK_STATUS_SUCCESS: "FETCH_FAST_TRACK_SUCCESS",
  FETCH_FAST_TRACK_STATUS_FAIL: "FETCH_FAST_TRACK_FAIL",
  FETCH_FAST_TRACK_CARD_REQ: "FETCH_FAST_TRACK_CARD_REQ",
  FETCH_FAST_TRACK_CARD_SUCCESS: "FETCH_FAST_TRACK_CARD_SUCCESS",
  FETCH_FAST_TRACK_CARD_FAIL: "FETCH_FAST_TRACK_CARD_FAIL",
  FETCH_FAST_TRACK_VIDEO_CATEGORY:"FETCH_FAST_TRACK_VIDEO_CATEGORY",
};

const fetchFastTrackReq = () => ({
  type: fastTrackActionType.FETCH_FAST_TRACK_STATUS_REQ,
});
const fetchFastTrackSuccess = (payload: any) => ({
  type: fastTrackActionType.FETCH_FAST_TRACK_STATUS_SUCCESS,
  payload,
});
const fetchFastTrackFail = (payload: string) => ({
  type: fastTrackActionType.FETCH_FAST_TRACK_STATUS_FAIL,
  payload,
});

const fetchFastTrackCardReq = () => ({
  type: fastTrackActionType.FETCH_FAST_TRACK_CARD_REQ,
});

const fetchFastTrackCardSuccess = (payload: any) => ({
  type: fastTrackActionType.FETCH_FAST_TRACK_CARD_SUCCESS,
  payload,
});

const fetchFastTrackCardFail = (payload: string) => ({
  type: fastTrackActionType.FETCH_FAST_TRACK_CARD_FAIL,
  payload,
});

const fetchFastTrackCategoryList=() =>({
  type:fastTrackActionType.FETCH_FAST_TRACK_VIDEO_CATEGORY,
})

export const fastTrackAction = {
  fetchFastTrackFail,
  fetchFastTrackReq,
  fetchFastTrackSuccess,
  fetchFastTrackCardReq,
  fetchFastTrackCardSuccess,
  fetchFastTrackCardFail,
  fetchFastTrackCategoryList,
};
