import { AnyAction } from "redux";
import { fastTrackActionType } from "components/Profile/IeltsProfile/FastTrack/redux/FastTrackAction";
import { FastTrackReducerSchema } from "Interfaces/reducerTypes/FastTrackSchema";

const initialState: FastTrackReducerSchema = {
  fastTrackStatus: null,
  fastTrackCard: [],
  loading: false,
  error: "",
};

const reducer = (
  state: FastTrackReducerSchema = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case fastTrackActionType.FETCH_FAST_TRACK_STATUS_REQ:
      return {
        ...state,
        loading: true,
      };
    case fastTrackActionType.FETCH_FAST_TRACK_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        fastTrackStatus: action.payload,
      };
    case fastTrackActionType.FETCH_FAST_TRACK_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case fastTrackActionType.FETCH_FAST_TRACK_CARD_REQ:
      return {
        ...state,
        loading: true,
      };
    case fastTrackActionType.FETCH_FAST_TRACK_CARD_SUCCESS:
      return {
        ...state,
        loading: false,
        fastTrackCard: action.payload,
      };
    case fastTrackActionType.FETCH_FAST_TRACK_CARD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
