import { TcyBannerDto } from "components/Profile/IeltsProfile/MockTest/MockTestLandingPage/redux/MockTestPriceSlice"
import { ExamLiveStatus } from "components/Profile/IeltsProfile/TestPrep/DataMapper"
import { priceBundleName } from "DataMapper/Common/WorkshopData"
import { batchExamTypeDto } from "DataMapper/IeltsDateUploadData"

export interface TestPrepSchema {
  isLoading: boolean
  errorMessage: string
  MockTestData: MockTestDto | null
  examData: Array<ExamDashBoardDto>
  showModal: ModalTypes
  mocktestModalData: MocktestModalData | null
  initiatePayment: boolean
  unlockPriceObj: PriceDTO | null
  daignosticTests: null | DaignosticTests
  daignosticTestsError: null | boolean | string
  singleMockTest: ExamDashBoardCardDto | null
  examResult: IeltsScoreResponse | null
  selfPrepBaughtData: PriceDTO | null
  practiceTests: PracticeTest | null
  submittedExamPref: boolean
  showFLSEFeedback: boolean
  liyMocktestDashboard: LiyDashboard | null
}
export enum CreditType {
  SECTIONAL_TEST = "SECTIONAL_TEST",
  SPEAKING_PRACTICE = "SPEAKING_PRACTICE",
  IELTS_REPLICA_TEST = "IELTS_REPLICA_TEST",
}

export interface MockTestCreditDto {
  creditType: CreditType
  count: number
}
export interface PracticeTest {
  credits: Array<MockTestCreditDto>
  speakingSlot: SpeakingSlotDetailsDto
  selfPrepSpeakingSlot: SpeakingSlotDetailsDto
  tcyMockTestData: TcyBannerDto | null
}
export interface SpeakingSlotDetailsDto {
  batchId: number
  status: SpeakingSlotStatus
}
export enum SpeakingSlotStatus {
  SCHEDULED = "SCHEDULED",
  LIVE = "LIVE",
}

export interface IeltsScoreResponse {
  ieltsScoreCard: IeltsScoreCardV2
  examType: ExamFormatTypeDto
}
export interface IeltsScoreCardV2 {
  ieltsScoreCard: IeltsScoreCard
  bandAndUpsellBannerDto: BandAndUpsellBannerDto
  examName: string
}
export interface BandAndUpsellBannerDto {
  showBand: boolean
  showUpSellBanner: boolean
  showBlurredBandWidget: boolean
}
export enum ExamFormatTypeDto {
  SECTIONAL = "SECTIONAL",
  FULL_LENGTH = "FULL_LENGTH",
  SECTIONAL_TIME_BOUND = "SECTIONAL_TIME_BOUND",
  DIAGNOSTIC = "DIAGNOSTIC",
  MINI_BPT = "MINI_BPT",
}
export interface IeltsScoreCard {
  totalScore: string | number
  answeredQuestion: string | number
  skippedQuestions: string | number
  inCorrectQuestions: string | number
  band: string | number
  maxScore: number
  examType: ExamFormatTypeDto
}

export interface PriceDTO {
  mrp: number
  offerPrice: number
  alreadyPaid: boolean
  currency: string
  bundleCategory: string
  bundleName: priceBundleName
}

export enum ModalTypes {
  INITIAL = "",
  MOCKTEST_MODAL = "MOCKTEST_MODAL",
  REGISTRATION_MODAL = "REGISTRATION_MODAL",
  PREFERANCE_MODAL = "PREFERANCE_MODAL",
}

export interface ExamDashBoardDto {
  examCategories: string
  examCards: Array<ExamDashBoardCardDto>
  color?: string
}

export interface DaignosticTests {
  speaking: DaignosticTestExamDto | null
  reading: DaignosticTestExamDto | null
  listening: DaignosticTestExamDto | null
  writing: DaignosticTestExamDto | null
  summary: {
    averageScore: number
    predictedBand: string
  }
}

export interface speakingResponseDto {
  overAllScore: number
  qualitativeRatingText: string
  spokenTextInHtml: string
  correctPhonemeColor: string
  firstQuestionProcessed: boolean
  secondQuestionProcessed: boolean
}
export interface ExamDashBoardCardDto {
  examId: number
  examName: string
  metaInfo?: Record<string, any>
  result: string | null
  status: Status
  isFree: boolean
  liveStatus: ExamLiveStatus
  examAudience?: batchExamTypeDto
}
export interface DaignosticTestExamDto extends ExamDashBoardCardDto {
  ieltsBand: null | string
  ieltsBandRange: string
}

export const ExamFormatDto: Record<string, string> = {
  "Sectional Test": "Sectional Test",
  "Full Length Test": "Full Length Test",
  "Diagnostic Test": "Diagnostic Test",
}

export enum ExamFormats {
  SECTIONAL = "Sectional Test",
  FULL_LENGTH = "Full Length Test",
  DAIGNOSTIC = "Diagnostic Test",
}

export enum Status {
  LOCKED = "LOCKED",
  UNLOCKED = "UNLOCKED",
}
export interface MockTestDto {
  examId: number
  examName: string
  examType: ExamDto
  metaInfo?: Record<string, any>
  examTags: string
  sections: Array<SectionDto>
}

export interface SectionDto {
  sectionId: null | number
  sectionName: string
  metaInfo?: Array<string>
  sectionDurationInMinutes: number // int in minutes
  sectionTags: string
  sectionDescription: string
  questionPassages: Array<any>
}

export enum ExamDto {
  SAT = "SAT",
  IELTS = "IELTS",
}

export interface MocktestModalData {
  headerImage: CmsImage
  heading: string
  descriptionList: Array<DescriptionList>
  promoImage: CmsImage
  status: StatusDto
}

export enum StatusDto {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
}

export interface DescriptionList {
  img: CmsImage
  description: string
  subDescription: string
}

export interface CmsImage {
  url: string
}

export interface Exam {
  duration: number
  credits: number
  examFormat: ExamFormatTypeDto
}
export interface LiyMocktest {
  examCategory: ExamCategories
  exams: Array<Exam>
  examsCount: number
}
export enum ExamCategories {
  READING = "READING",
  LISTENING = "LISTENING",
  WRITING = "WRITING",
  AI_SPEAKING_MOCKTEST = "AI_SPEAKING_MOCKTEST",
}

export interface LiyDashboard {
  showDemoCard: boolean
  examDetails: Array<LiyMocktest>
}
