import ieltsReviewQuery from "apollo/queries/reviews";
import { apoloClient } from "apollo/apollo";

const fetchReviews = async () => {
  try {
    const data =  await apoloClient.query({query: ieltsReviewQuery, variables: []});
    return data;
  } catch (e:any) {
    console.log(e);
  }
};

export const reviewApis = {
  fetchReviews,
};
