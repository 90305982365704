import { batchDetailsActions } from "components/Ielts/BatchDetails/redux/BatchDetailsAction"
import { counsellingFormActions } from "components/Profile/CounsellingFormExperiment/redux/CounsellingFormActions"
import { ieltsRegistrationFormActions } from "components/Profile/IeltsRegistrationForm/redux/IeltsRegistrationFormActions"
import { ieltsRegistrationFormApi } from "components/Profile/IeltsRegistrationForm/redux/IeltsRegistrationFormApi"
import { IeltsToCounsellingEligibility } from "components/TofuOnbordingForms/SpotCounsellingProgressiveForm/IeltsToCounselling/IeltsToCounsellingApi"
import { eventCategory, eventName } from "DataMapper/EventTracker/EventData"
import { eventTracker } from "eventTracker"
import { fireTagEvent, logEvent } from "ga"
import { State } from "Interfaces/storeSchema"
import { AnyAction } from "redux"
import { call, put, select, takeLatest } from "redux-saga/effects"

function* saveData(action: AnyAction): Generator<any, any, any> {
  const freeTrailEligibility = yield select(
    (state: State) => state.ieltsRegistrationForm.ieltsFreeTrailEligiblity,
  )
  if (freeTrailEligibility) {
    logEvent("Ielts free trial", "start free trial", "start free trial cta")
  }
  try {
    yield put(ieltsRegistrationFormActions.toggleCtaText("Saving"))
    const res = yield call(ieltsRegistrationFormApi.saveForm, {
      ...action.payload.values,
      formId: "I2C_form",
    })
    yield call(eventTracker, eventName.INITIATE_CHECKOUT, {
      category: "Ielts",
      action: eventName.INITIATE_CHECKOUT,
      label: "",
      batchId: parseInt(action.payload.batchId, 10),
    })
    yield call(fireTagEvent, "ielts_initiate_checkout")
    if (res.success) {
      yield put(
        counsellingFormActions.updatePostFetchEligibilityAction(
          (eligibilityData) => {
            IeltsToCounsellingEligibility(eligibilityData)
          },
        ),
      )
      yield put(counsellingFormActions.fetchEligibility())
      yield put(batchDetailsActions.toggleIeltsPlusForm(false))
      yield put(ieltsRegistrationFormActions.toggleCtaText("Next"))
      yield put(ieltsRegistrationFormActions.toggleInitPayment(true))
    } else {
      yield put(ieltsRegistrationFormActions.updateError(res.message))
    }
  } catch (e: any) {
    yield put(
      ieltsRegistrationFormActions.updateError(
        "Something went wrong. Please try after sometime.",
      ),
    )
    console.error(e)
  } finally {
    yield put(ieltsRegistrationFormActions.toggleCtaText("Next"))
  }
}

function* enrollToIeltsPlus(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(ieltsRegistrationFormActions.toggleCtaText("Saving"))
    const res = yield call(
      ieltsRegistrationFormApi.enrollToIeltsPlus,
      action.payload,
    )
    if (res.success) {
      yield put(ieltsRegistrationFormActions.toggleCtaText("Submit"))
      yield put(ieltsRegistrationFormActions.toggleIeltsFormModal(true))
    } else {
      yield put(ieltsRegistrationFormActions.updateError(res.message))
      yield put(ieltsRegistrationFormActions.toggleCtaText("Submit"))
    }
  } catch (e: any) {
    yield put(
      ieltsRegistrationFormActions.updateError(
        "Something went wrong. Please try after sometime.",
      ),
    )
    console.error(e)
  }
}

function* ieltsSaga() {
  yield takeLatest("SAVE_IELTS_FORM", saveData)
  yield takeLatest("ENROLL_TO_IELTS_PLUS_FORM", enrollToIeltsPlus)
}

export default ieltsSaga
