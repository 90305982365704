import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { ieltsAttendanceApi } from "components/Profile/IeltsAttendanceScreen/redux/IeltsAttendanceApi";
import { ieltsAttendanceActions } from "components/Profile/IeltsAttendanceScreen/redux/IeltsAttendanceActions";
import { ieltsPlusApi } from "components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusApi";
import { ieltsPlusActions } from "components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusActions";
import { IeltsPlusDataSchema } from "Interfaces/reducerTypes/IeltsPlusSchema";
import { eventTracker } from "eventTracker";
import {
  eventCategory,
  eventName,
  WEeventDataAttributeNames,
} from "DataMapper/EventTracker/EventData";

function* addToCalendar(action: AnyAction): Generator<any, any, any> {
  try {

    const res = yield call(ieltsAttendanceApi.addToCalendar, action.payload);
    if (res) {
      const ieltsData: IeltsPlusDataSchema = yield call(
        ieltsPlusApi.fetchIeltsPlusData
      );
      if (ieltsData.workshop.length > 0) {
        yield call(eventTracker, eventName.ADDED_TO_CALENDAR, {
          [WEeventDataAttributeNames.CATEGORY]: eventCategory.REGISTRATION,
          [WEeventDataAttributeNames.ACTION]: eventName.ADDED_TO_CALENDAR,
          [WEeventDataAttributeNames.LABEL]: "",
          [WEeventDataAttributeNames.BATCH_ID]: ieltsData.workshop[0].batchId,
          [WEeventDataAttributeNames.BATCH_START_DATE]: new Date(
            ieltsData?.workshop[0].meetingStartTime || ""
          ),
          [WEeventDataAttributeNames.BATCH_END_DATE]: new Date(
            ieltsData?.workshop[0].meetingEndTime || ""
          ),
          [WEeventDataAttributeNames.TEACHER_NAME]: ieltsData.workshop[0].name,
        });
      }
      yield put(ieltsAttendanceActions.updateAddedToCalendar(true));
      yield put(ieltsPlusActions.updateAddToCalendar(action.payload.selectedBatch.batchId));
    }
  } catch (e:any) {
    yield put(
      ieltsAttendanceActions.updateError(
        "Something went wrong. Please try after sometime"
      )
    );
  }
}

function* ieltsSaga() {
  yield takeLatest("ADD_IELTS_CLASS_TO_CALENDAR", addToCalendar);
}

export default ieltsSaga;
