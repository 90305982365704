import { TestPrepApi } from "components/Profile/IeltsProfile/TestPrep/redux/TestPrepApi"
import {
  fetchGetDaignosticTests,
  fetchMockTest,
  fetchMockTestList,
  fetchMockTestModal,
  fetchMockTestPrice,
  fetchMocktestResult,
  fetchMockTestsCredits,
  fetchSelfPrepBaughtStatusAction,
  saveForm,
  setDaignosticTests,
  setDaignosticTestsError,
  setExamResult,
  setInitiatePayment,
  setMockTest,
  setMockTestError,
  setMockTestIsLoading,
  setMockTestList,
  setMocktestModalData,
  setSelfPrepBaughtData,
  setUnlockPrice,
  setPracticeTests,
  setSubmittedExamPref,
  postWritingExamPreference,
  setShowFLSEFeedback,
  fetchFLSEFeedbackData,
  fetchLiyMocktestDashboard,
  setLiyMocktestDashboard,
} from "components/Profile/IeltsProfile/TestPrep/redux/TestPrepSlice"
import { ieltsRegistrationFormApi } from "components/Profile/IeltsRegistrationForm/redux/IeltsRegistrationFormApi"
import { AnyAction } from "redux"
import { call, put, takeLatest } from "redux-saga/effects"
import { speakingClubApi } from "components/Profile/IeltsProfile/SpeakingClub/redux/SpeakingClubApi"

function* fetchMockTestListSaga(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(setMockTestIsLoading(true))
    const res = yield call(TestPrepApi.fetchMockTestList, action.payload)
    yield put(setMockTestIsLoading(false))
    if (res.data.success) {
      yield put(setMockTestList(res.data.data))
    } else {
      yield put(setMockTestError("something went wrong"))
    }
  } catch (e) {
    yield put(setMockTestIsLoading(false))
    yield put(setMockTestError("something went wrong"))
  }
}

function* fetchMockTestSaga(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(setMockTestIsLoading(true))
    const res = yield call(TestPrepApi.fetchMockTest, action.payload)
    yield put(setMockTestIsLoading(false))

    if (res.data.success && res.data.data) {
      yield put(setMockTest(res.data.data))
    } else {
      yield put(
        setMockTestError(
          "something went wrong while fetching  mock test data please try after some time",
        ),
      )
    }
    yield put(setMockTestIsLoading(false))
  } catch (e) {
    yield put(setMockTestIsLoading(false))
    yield put(
      setMockTestError(
        "something went wrong while fetching  mock test data please try after some time",
      ),
    )
  }
}

function* fetchMockTestModalSaga(): Generator<any, any, any> {
  try {
    yield put(setMockTestIsLoading(true))
    const res = yield call(TestPrepApi.fetchModalDataCms)
    yield put(setMockTestIsLoading(false))
    if (res?.data?.length > 0) {
      yield put(setMocktestModalData(res?.data[0]))
    } else {
      yield put(setMockTestError("something went wrong"))
    }
  } catch (e) {
    yield put(setMockTestIsLoading(false))
    yield put(setMockTestError("something went wrong"))
  }
}
function* fetchMockTestPriceSaga(): Generator<any, any, any> {
  try {
    yield put(setMockTestIsLoading(true))
    const res = yield call(TestPrepApi.fetchMocktestPrice)
    yield put(setMockTestIsLoading(false))
    if (res?.data?.success) {
      yield put(setUnlockPrice(res?.data?.data))
    } else {
      yield put(setMockTestError("something went wrong"))
    }
  } catch (e) {
    yield put(setMockTestIsLoading(false))
    yield put(setMockTestError("something went wrong"))
  }
}

function* saveIeltsForm(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(ieltsRegistrationFormApi.saveForm, action.payload)
    if (res?.success) {
      yield put(setInitiatePayment(true))
    } else {
      yield put(setMockTestError("something went wrong"))
    }
  } catch (e) {
    yield put(setMockTestError("something went wrong"))
  }
}

function* fetchGetDaignosticTestsSaga(): Generator<any, any, any> {
  try {
    const res = yield call(TestPrepApi.fetchGetDaignosticTests)
    if (res.data?.success) {
      yield put(setDaignosticTests(res.data?.data))
    } else {
      yield put(setDaignosticTestsError(true))
    }
  } catch (error) {
    yield put(setDaignosticTestsError(true))
  }
}
function* fetchMocktestResultSaga(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(TestPrepApi.fetchMockTestResult, action.payload)
    if (res.data?.success) {
      yield put(setExamResult(res.data.data))
    } else {
      yield put(setExamResult(null))
    }
  } catch (error) {
    yield put(setExamResult(null))
  }
}

function* fetchSelfPrepBaughtStatusSaga(): Generator<any, any, any> {
  try {
    const res = yield call(TestPrepApi.fetchSelfPrepBaughtStatus)
    if (res.data?.success) {
      yield put(setSelfPrepBaughtData(res.data.data))
    } else {
      yield put(setSelfPrepBaughtData(null))
    }
  } catch (error) {
    yield put(setSelfPrepBaughtData(null))
  }
}
function* fetchPracticeTestsSaga(): Generator<any, any, any> {
  try {
    const res = yield call(TestPrepApi.fetchMockTestsCredits)

    if (res?.data?.success) {
      yield put(setPracticeTests(res.data.data))
    } else {
      yield put(setMockTestError("something went wrong"))
    }
  } catch (e) {
    yield put(setMockTestError("something went wrong"))
  }
}

function* postWritingExamPreferenceSaga(
  action: AnyAction,
): Generator<any, any, any> {
  try {
    const res = yield call(
      TestPrepApi.postWritingExamPreferenceApi,
      action.payload,
    )
    if (res.data?.success) {
      yield put(setSubmittedExamPref(res.data.success))
    } else {
      yield put(setSubmittedExamPref(false))
    }
  } catch (error) {
    yield put(setSubmittedExamPref(false))
  }
}

function* fetchFLSEFeedbackSaga(): Generator<any, any, any> {
  try {
    const res = yield call(speakingClubApi.getUserFeedbackApi)
    if (res) {
      if (res.length > 0) {
        yield put(setShowFLSEFeedback(true))
      }
    } else {
      yield put(setMockTestError("something went wrong"))
    }
  } catch (e) {
    yield put(setMockTestError("something went wrong"))
  }
}

function* fetchLiyMocktestDashboardSaga(): Generator<any, any, any> {
  try {
    const res = yield call(TestPrepApi.fetchLiyMocktestDashboard)
    if (res.data.success) {
      yield put(setLiyMocktestDashboard(res.data.data))
    } else {
      yield put(setLiyMocktestDashboard(null))
    }
  } catch (error) {
    yield put(setLiyMocktestDashboard(null))
  }
}

function* testPrepSaga() {
  yield takeLatest(fetchMockTestList, fetchMockTestListSaga)
  yield takeLatest(fetchMockTest, fetchMockTestSaga)

  yield takeLatest(fetchMockTestModal, fetchMockTestModalSaga)
  yield takeLatest(fetchMockTestPrice, fetchMockTestPriceSaga)
  yield takeLatest(saveForm, saveIeltsForm)
  yield takeLatest(fetchGetDaignosticTests, fetchGetDaignosticTestsSaga)
  yield takeLatest(fetchMocktestResult, fetchMocktestResultSaga)
  yield takeLatest(
    fetchSelfPrepBaughtStatusAction,
    fetchSelfPrepBaughtStatusSaga,
  )
  yield takeLatest(fetchMockTestsCredits, fetchPracticeTestsSaga)
  yield takeLatest(postWritingExamPreference, postWritingExamPreferenceSaga)
  yield takeLatest(fetchFLSEFeedbackData, fetchFLSEFeedbackSaga)
  yield takeLatest(fetchLiyMocktestDashboard, fetchLiyMocktestDashboardSaga)
}

export default testPrepSaga
