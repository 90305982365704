import { spotCounsellingActions } from 'components/SpotCounselling/redux/SpotCounsellingActions'
import { spotCounsellingApi } from 'components/SpotCounselling/redux/SpotCounsellingApi'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

function* getExpertsData(): Generator<any, any, any> {
  try {
    const res = yield call(spotCounsellingApi.getExpertData)
    if (res.success) {
      yield put(spotCounsellingActions.fetchExpertsSuccess(res.data))
    } else {
      yield put(spotCounsellingActions.fetchExpertsFail(res.message))
    }
  } catch (e: any) {
    yield put(
      spotCounsellingActions.fetchExpertsFail(
        'Something went wrong. Please try after sometime.',
      ),
    )
    console.error(e)
  }
}

function* getStage(): Generator<any, any, any> {
  try {
    const res = yield call(spotCounsellingApi.getStage)
    if (res.success) {
      yield put(spotCounsellingActions.fetchStageSuccess(res.data))
    } else {
      yield put(spotCounsellingActions.fetchStageFail(res.message))
    }
  } catch (e: any) {
    yield put(
      spotCounsellingActions.fetchExpertsFail(
        'Something went wrong. Please try after sometime.',
      ),
    )
    console.error(e)
  }
}

function* getAppNudgeEligibility(): Generator<any, any, any> {
  try {
    const res = yield call(spotCounsellingApi.getAppNudgeEligibility)
    if (res.success) {
      yield put(spotCounsellingActions.fetchAppNudgeEligibilitySuccess(res.data))
      const appData = yield call(spotCounsellingApi.getAppNudgeData)
      yield put(spotCounsellingActions.fetchAppNudgeData(appData))
    } else {
      yield put(spotCounsellingActions.fetchAppNudgeEligibilityFail(res.message))
    }
  } catch (e: any) {
    yield put(
      spotCounsellingActions.fetchExpertsFail(
        'Something went wrong. Please try after sometime.',
      ),
    )
  }
}

function* getGoalPlanData(): Generator<any, any, any> {
  try {
    const res = yield call(spotCounsellingApi.getGoalPlanData)
    if (res.success) {
      yield put(spotCounsellingActions.fetchGoalPlanDataSuccess(res.data))
    } else {
      yield put(spotCounsellingActions.fetchGoalPlanDataFail(res.message))
    }
  } catch (e: any) {
    yield put(
      spotCounsellingActions.fetchGoalPlanDataFail(
        'Something went wrong. Please try after sometime.',
      ),
    )
    console.error(e)
  }
}

function* fetchSpotCounsellingSlot(): Generator<any, any, any> {
  try {
    const slots = yield call(spotCounsellingApi.fetchSpotCounsellingSlot)
    yield put(spotCounsellingActions.fetchSpotCounsellingSlotSuccess(slots))
  } catch (e: any) {
    yield put(spotCounsellingActions.fetchSpotCounsellingSlotFail(e.message))
  }
}

function* confirmSpotCounsellingSlot(
  action: AnyAction,
): Generator<any, any, any> {
  try {
    const res = yield call(
      spotCounsellingApi.confirmSpotCounsellingSlot,
      action.payload,
    )
    if (res.success) {
      yield put(spotCounsellingActions.confirmSpotCounsellingSlotSuccess(res))
    } else {
      yield put(spotCounsellingActions.confirmSpotCounsellingSlotFail(res))
    }
  } catch (e: any) {
    yield put(spotCounsellingActions.confirmSpotCounsellingSlotFail(e.message))
  }
}

function* confirmRescheduleSlot(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      spotCounsellingApi.confirmRescheduleSlot,
      action.payload,
    )

    if (res.success) {
      yield put(spotCounsellingActions.confirmRescheduleSlotSuccess(res))
    } else {
      yield put(spotCounsellingActions.confirmRescheduleSlotFail(res))
    }
  } catch (e: any) {
    yield put(spotCounsellingActions.confirmRescheduleSlotFail(e.message))
  }
}

function* fetchSpotProfile(): any {
  try {
    const res = yield call(spotCounsellingApi.fetchSpotProfile)
    if (res.success) {
      yield put(spotCounsellingActions.fetchSpotProfileSuccess(res.data))
    } else {
      yield put(spotCounsellingActions.fetchSpotProfileFail(res.message))
    }
  } catch (e: any) {
    yield put(
      spotCounsellingActions.fetchSpotProfileFail(
        'Something went wrong. Please try after sometime.',
      ),
    )
    console.error(e)
  }
}

function* fetchUpcomingEvents(): any {
  try {
    const res = yield call(spotCounsellingApi.fetchUpcommingEventsData)
    if (res) {
      yield put(spotCounsellingActions.fetchUpcomingEventsSuccess(res))
    } else {
      yield put(
        spotCounsellingActions.fetchUpcomingEventsFail('Something went wrong'),
      )
    }
  } catch (e: any) {
    console.error(e)
    yield put(spotCounsellingActions.fetchUpcomingEventsFail(e.message))
  }
}

function* submitSpotProfile(action: AnyAction): any {
  try {
    const res = yield call(spotCounsellingApi.submitSpotProfile, action.payload)

    if (res?.data?.success) {
      yield put(spotCounsellingActions.submitSpotProfileSuccess(res.data.data))
    } else {
      yield put(spotCounsellingActions.submitSpotProfileFail(res))
    }
  } catch (e: any) {
    yield put(spotCounsellingActions.submitSpotProfileFail(e.message))
  }
}

function* fetchEligibilitySaga(): any {
  try {
    const res = yield call(spotCounsellingApi.checkEligibilty)
    if (res.data?.success) {
      yield put(spotCounsellingActions.fetchEligibilitySuccess(res.data.data))
    } else {
      yield put(spotCounsellingActions.fetchEligibilityFail(res))
    }
  } catch (e: any) {
    console.log(e)
    yield put(spotCounsellingActions.fetchEligibilityFail(e.message))
  }
}

function* fetchReQualificationEligibilitySaga(): any {
  try {
    const res = yield call(spotCounsellingApi.checkReQualificationEligibility)
    if (res.success) {
      yield put(
        spotCounsellingActions.fetchReQualificationCheckSuccess(res.data),
      )
    } else {
      yield put(spotCounsellingActions.fetchReQualificationCheckFail(res))
    }
  } catch (e: any) {
    console.log(e)
    yield put(spotCounsellingActions.fetchReQualificationCheckFail(e.message))
  }
}

function* fetchProfileLayout(): any {
  try {
    const res = yield call(spotCounsellingApi.getProfileLayout)
    if (res?.success) {
      yield put(spotCounsellingActions.fetchProfileLayoutSuccess(res.data))
    } else yield put(spotCounsellingActions.fetchProfileLayoutFail(''))
  } catch (e: any) {
    console.log(e.message)
  }
}

function* spotCounsellingSaga() {
  yield takeLatest('ELIGIBILITY_FETCH_REQUESTED', fetchEligibilitySaga)
  yield takeLatest('FETCH_UPCOMING_EVENTS', fetchUpcomingEvents)
  yield takeLatest('FETCH_EXPERTS_DATA', getExpertsData)
  yield takeLatest('FETCH_STAGE', getStage)
  yield takeLatest('FETCH_SPOT_COUNSELLING_SLOT', fetchSpotCounsellingSlot)
  yield takeLatest('CONFIRM_SPOT_COUNSELLING_SLOT', confirmSpotCounsellingSlot)
  yield takeLatest('CONFIRM_SPOT_RESCHEDULE_SLOT', confirmRescheduleSlot)
  yield takeLatest('FETCH_SPOT_PROFILE', fetchSpotProfile)
  yield takeLatest('SUBMIT_SPOT_PROFILE', submitSpotProfile)
  yield takeLatest('FETCH_GOAL_PLAN_DATA', getGoalPlanData)
  yield takeLatest('FETCH_PROFILE_LAYOUT', fetchProfileLayout)
  yield takeLatest(
    'REQUALIFICATION_ELIGIBLITY_FETCH_REQUESTED',
    fetchReQualificationEligibilitySaga,
  )
  yield takeLatest('FETCH_APP_NUDGE_ELIGIBILITY', getAppNudgeEligibility)
}

export default spotCounsellingSaga
