import { paymentActions } from 'components/Payment/redux/PaymentActions'
import { paymentApi } from 'components/Payment/redux/PaymentApis'
import PaymentConstants from 'components/Payment/redux/PaymentReduxConstants'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchUserDataForPayment(): Generator<any, any, any> {
  try {
    const res = yield call(paymentApi.fetchUserDetailsForPayment)
    if (res.data.success) {
      yield put(paymentActions.fetchUserDataForPaymentSuccess(res.data.data))
    } else {
      yield put(paymentActions.fetchUserDataForPaymentError(res.data.message))
    }
  } catch (e: any) {
    console.error(e)
    yield put(paymentActions.fetchUserDataForPaymentError(e.message))
  }
}

function* fetchPaymentData(action: AnyAction): Generator<any, any, any> {
  console.log('saga', action)
  try {
    const res = yield call(
      paymentApi.fetchPaymentData,
      action.payload.paymentData
    )
    if (res.data.success) {
      if (res.data.data) {
        const { offer, alreadypaid, mrp, paymentCategory } = res.data.data
        if (!alreadypaid && offer !== 0 && paymentCategory === 'PGD_999') {
          if (action.payload.promoCode) {
            const res2 = yield call(
              paymentApi.validatePromoCode,
              action.payload.promoCode
            )
            if (res2.data.success) {
              yield put(paymentActions.validatePromoCodeSuccess(res2.data.data))
              if (mrp === 999) {
                const newOffer = Math.floor(
                  offer - (res2.data.data.discount / 100) * offer
                )
                yield put(
                  paymentActions.fetchPaymentDataSuccess({
                    ...res.data.data,
                    offer: newOffer,
                  })
                )
              } else {
                yield put(paymentActions.fetchPaymentDataSuccess(res.data.data))
              }
            } else {
              yield put(paymentActions.fetchPaymentDataSuccess(res.data.data))
            }
          } else {
            yield put(paymentActions.fetchPaymentDataSuccess(res.data.data))
          }
        } else {
          yield put(paymentActions.fetchPaymentDataSuccess(res.data.data))
        }
      }
    } else {
      yield put(paymentActions.fetchPaymentDataError(res.data.message))
    }
  } catch (e: any) {
    console.error(e)
    yield put(paymentActions.fetchPaymentDataError(e.message))
  }
}

// function* validatePromoCode(action: AnyAction): Generator<any, any, any> {
//   try {
//     const res = yield call(paymentApi.validatePromoCode, action.payload);
//     if (res.data.success) {
//       yield put(paymentActions.validatePromoCodeSuccess(res.data.data));
//     } else {
//       yield put(paymentActions.validatePromoCodeError(res.data.message));
//     }
//   } catch (e: any) {
//     console.error(e);
//     yield put(paymentActions.validatePromoCodeError(e.message));
//   }
// }

// function* initilizePayment(action: AnyAction): Generator<any, any, any> {
//   try {
//     const res = yield call(paymentApi. , action.payload);
//     if (res.data.success) {
//       yield put(paymentActions.initilizePaymentSuccess(res.data.data));
//     } else {
//       yield put(paymentActions.initilizePaymentError(res.data.message));
//     }
//   } catch (e:any) {
//     console.error(e);
//     yield put(paymentActions.initilizePaymentError(e.message));
//   }
// }

function* paymentSaga() {
  yield takeLatest(
    PaymentConstants.FETCH_USER_DATA_FOR_PAYMENT,
    fetchUserDataForPayment
  )
  yield takeLatest(PaymentConstants.FETCH_PAYMENT_DATA, fetchPaymentData)
  //yield takeLatest(PaymentConstants.VALIDATE_PROMO_CODE, validatePromoCode);

  // yield takeLatest(
  //   PaymentConstants.INITIALIZE_PAYMENT,
  //   initilizePayment
  // );
}

export default paymentSaga
