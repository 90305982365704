import { call, put, takeLatest } from 'redux-saga/effects'
import { DemoRewardsApi } from 'components/Profile/StudentDashbord/IeltsSection/IeltsDemoRewards/redux/DemoRewardsApi'
import { AnyAction } from 'redux'
import {
  fetchScratchCardStatusAction,
  fetchWhatsappTipsDataAction,
  setScratchCardStatus,
  setScratchSubscriptionData,
  setWhatsappTipsData,
  setWhatsappTipsStatus,
  updateScratchCardStatusAction,
  updateTipsTricksStatusAction,
} from 'components/Profile/StudentDashbord/IeltsSection/IeltsDemoRewards/redux/DemoRewardsSlice'

function* fetchScratchCardStatus(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      DemoRewardsApi.fetchScratchCardStatus,
      action.payload,
    )
    if (res.data.success) {
      yield put(setScratchSubscriptionData(res.data.data))
    } else {
      yield put(setScratchSubscriptionData(null))
    }
  } catch (e) {
    yield put(setScratchSubscriptionData(null))
  }
}

function* fetchWhatsappTipsData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      DemoRewardsApi.fetchScratchCardStatus,
      action.payload,
    )
    if (res.data.success) {
      yield put(setWhatsappTipsData(res.data.data))
    } else {
      yield put(setWhatsappTipsData(null))
    }
  } catch (e) {
    yield put(setWhatsappTipsData(null))
  }
}

function* updateScratchCardStatus(): Generator<any, any, any> {
  try {
    const res = yield call(DemoRewardsApi.updateScratchCardStatus)

    if (res.data.success) {
      yield put(setScratchCardStatus(res.data.data))
    } else {
      yield put(setScratchCardStatus(false))
    }
  } catch (e) {
    yield put(setScratchCardStatus(false))
  }
}
function* updateTipsTricksStatus(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(DemoRewardsApi.updateTipsTricksStatus)
    if (res.data.success) {
      yield put(setWhatsappTipsStatus(!action.payload))
    } else {
      yield put(setWhatsappTipsStatus(false))
    }
  } catch (e) {
    yield put(setWhatsappTipsStatus(false))
  }
}

function* DemoRewardsSaga() {
  yield takeLatest(fetchScratchCardStatusAction, fetchScratchCardStatus)
  yield takeLatest(fetchWhatsappTipsDataAction, fetchWhatsappTipsData)
  yield takeLatest(updateScratchCardStatusAction, updateScratchCardStatus)
  yield takeLatest(updateTipsTricksStatusAction, updateTipsTricksStatus)
}

export default DemoRewardsSaga
