const fetchSpotCounsellingFromData = () => ({
  type: "FETCH_SPOT_FORM_DATA",
});

const fetchSpotCounsellingFromDataSuccess = (payload: any) => ({
  type: "FETCH_SPOT_FORM_DATA_SUCCESS",
  payload,
});

const fetchSpotCounsellingFromDataFail = (message: string) => ({
  type: "FETCH_SPOT_FORM_DATA_FAIL",
  payload: message,
});

export const tofuObordingActions = {
  fetchSpotCounsellingFromData,
  fetchSpotCounsellingFromDataSuccess,
  fetchSpotCounsellingFromDataFail,
};
