import { FunctionComponent } from "react";

interface Props {
  options: Array<OptionType>;
  input: any;
  meta: any;
  containerCss?: string;
  activeCss?: string;
  inActiveCss?: string;
}

interface OptionType {
  label: string;
  value: string;
  icon?: string;
  subLabel?: string;
}

const RadioButton: FunctionComponent<Props> = ({
  options,
  input,
  meta,
  containerCss,
  inActiveCss,
  activeCss,
}) => {
  const hasError = meta && meta.touched && meta.error;
  return (
    <>
      <div className={`${containerCss ?? "flex justify-between gap-4"}`}>
        {options.map((option: OptionType) => {
          const { label, icon, value, subLabel } = option;
          return (
            <button
              key={`option-${value}`}
              type="button"
              onFocus={() => {
                if (input) {
                  input.onFocus();
                }
              }}
              onBlur={() => {
                if (input) {
                  input.onBlur();
                }
              }}
              onClick={() => {
                if (input) {
                  input.onChange(value);
                }
              }}
              className={`w-full border py-4 ${
                input && input.value === value
                  ? activeCss ?? "border-[#736EFF] text-[#443EFF] bg-[#E3E2FF]"
                  : inActiveCss ?? "border-[#DBDBDB] text-[#686868]"
              } rounded-[8px]`}
            >
              {icon && <img className="w-9 h-6 pb-1" src={icon} />}
              <p>{label}</p>
              {subLabel && <p className="mt-2 text-xs">{subLabel}</p>}
            </button>
          );
        })}
      </div>
      {hasError && !meta.active && (
        <p className="w-full mt-2 text-xs text-red-600">{meta.error}</p>
      )}
    </>
  );
};

export default RadioButton;
