import api from "service/api"
interface ApiResponseOutput {
  success: boolean
  data: any
}

const fetchSlotAvailableDateData = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=v2/workshop/speaking-club/30/300`,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    console.log(err)
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const postSpeakingWorkShop = async (batchId: any) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=workshop/enroll/${batchId}/SPEAKING_WORKSHOP`,
    )
    if (res.data.success) {
      return {
        success: true,
        payload: true,
      }
    } else {
      return {
        success: false,
        payload: res.data.message,
      }
    }
  } catch (err: any) {
    console.log(err)
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const fetchUpcomingSpeakingClasses = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=v2/workshop/enroll/my/SPEAKING_ELITE_WORKSHOP`,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    console.log(err)
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const fetchSpeakingClubCredits = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=workshop/enroll/credits/SPEAKING_WORKSHOP`,
    )
    return res
  } catch (err: any) {
    console.log(err)
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const fetchSpeakingClubPriceBundle = async (): Promise<ApiResponseOutput> => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=student/speaking-club/fee`,
    )
    if (res.data.success) {
      return {
        success: true,
        data: res.data.data,
      }
    } else {
      return {
        success: false,
        data: res.data.message,
      }
    }
  } catch (err: any) {
    return {
      success: false,
      data: "Something went wrong",
    }
  }
}

const cancelSlot = async (batchId: number) => {
  try {
    const res = await api.put(
      `/api/scholarRoute?path=workshop/enroll/user/${batchId}/SPEAKING_WORKSHOP`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const rescheduleSlot = async (payload: any) => {
  try {
    const { batchId, previousBatchId } = payload
    const res = await api.post(
      `/api/scholarRoute?path=workshop/enroll/reschedule/${batchId}/${previousBatchId}/SPEAKING_WORKSHOP`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const getUserFeedbackApi = async () => {
  try {
    const res = await api.get("/api/scholarRoute?path=workshop/feedback/flse")
    if (res?.data?.success) {
      return res.data?.data
    }
    return false
  } catch (error) {
    return false
  }
}

export const speakingClubApi = {
  fetchSlotAvailableDateData,
  postSpeakingWorkShop,
  fetchUpcomingSpeakingClasses,
  fetchSpeakingClubCredits,
  fetchSpeakingClubPriceBundle,
  cancelSlot,
  rescheduleSlot,
  getUserFeedbackApi,
}
