export enum QuizType {
  FETCH_QUIZ_LIST = "FETCH_QUIZ_LIST",
  FETCH_QUIZ_LIST_SUCCESS = "FETCH_QUIZ_LIST_SUCCESS",
  UPDATE_NEW_QUIZ_LIST = "UPDATE_NEW_QUIZ_LIST",
  UPDATE_COMPLETE_QUIZ_LIST = "UPDATE_COMPLETE_QUIZ_LIST",
  FETCH_QUIZ_QUESTION = "FETCH_QUIZ_QUESTION",
  FETCH_QUIZ_QUESTION_SUCCESS = "FETCH_QUIZ_QUESTION_SUCCESS",
  UPDATE_ERROR = "UPDATE_ERROR",
  UPDATE_ACTIVE_QUIZ_ID = "UPDATE_ACTIVE_QUIZ_ID",
  UPDATE_QUIZ_STATUS = "UPDATE_QUIZ_STATUS",
  UPDATE_QUIZ_ATTEMPT_ID = "UPDATE_QUIZ_ATTEMPT_ID",
  UPDATE_SUBMITTED_ANS = "UPDATE_SUBMITTED_ANS",
  START_QUIZ_TEST = "START_QUIZ_TEST",
  END_QUIZ_TEST = "END_QUIZ_TEST",
  SUBMIT_QUESTION_ANS = "SUBMIT_QUESTION_ANS",
  UPDATE_SUBMIT_MODAL = "UPDATE_SUBMIT_MODAL",
  UPDATE_EXIT_MODAL = "UPDATE_EXIT_MODAL",
  QUIZ_UPDATE_START_TEST_DATE = "QUIZ_UPDATE_START_TEST_DATE",
  QUIZ_UPDATE_END_TEST_DATE = "QUIZ_UPDATE_END_TEST_DATE",
  SUBMIT_SUCCESSFULLY_ACTION = "SUBMIT_SUCCESSFULLY_ACTION",
  UPDATE_QUIZ_TITLE = "UPDATE_QUIZ_TITLE",
  FETCH_QUIZ_MODAL_TYPE= "FETCH_QUIZ_MODAL_TYPE",
  FETCH_QUIZ_MODAL_TYPE_SUCCESS= "FETCH_QUIZ_MODAL_TYPE_SUCCESS",
  FETCH_QUIZ_BUNDLE_TYPE_REQUESTED= "FETCH_QUIZ_BUNDLE_TYPE_REQUESTED",
  FETCH_QUIZ_BUNDLE_TYPE_SUCCESS= "FETCH_QUIZ_BUNDLE_TYPE_SUCCESS",
}
