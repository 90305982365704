import { AnyAction } from 'redux'
import cloneDeep from 'lodash/cloneDeep'
import {
  MockTestSchema,
  SectionSchema,
  TestSchema,
} from 'Interfaces/reducerTypes/MockTestSchema'

const initialState = {
  mockTestData: null,
  error: '',
  view: 'mockCards',
  activeSection: null,
  activeMockTestIndex: null,
  essayUploaded: false,
  showMockTest: true,
  activeMockTestId: null,
}

const getColor = (
  status: string,
  completedSubmission: boolean,
  type: string
) => {
  if (status === 'LOCKED') {
    if (type === 'circle') {
      return 'bg-skin-200'
    } else {
      return 'bg-skin-100'
    }
  } else if (completedSubmission) {
    if (type === 'circle') {
      return 'bg-green-1100'
    } else {
      return 'bg-green-1000'
    }
  } else {
    if (type === 'circle') {
      return 'bg-green-500'
    } else {
      return 'bg-blue-1100'
    }
  }
}

const mockTestReducer = (
  state: MockTestSchema = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'FETCH_MOCK_TEST_SUCCESS':
      const newMockTestData = cloneDeep(action.payload)
      newMockTestData?.mockTests.forEach((mock: TestSchema) => {
        mock.completedSubmission =
          mock.sections.length -
            mock.sections.filter((section: SectionSchema) => section.submission)
              .length ===
          0
            ? true
            : false
        mock.title = `Mock Test ${mock.mockTestIndex}`
        mock.subText =
          mock.mockTestStatus === 'LOCKED'
            ? 'Your tutor will unlock the test soon'
            : 'Your test has been unlocked. Attempt now!'
        mock.sectionsRemaining =
          mock.sections.length -
          mock.sections.filter((section: SectionSchema) => section.submission)
            .length
        mock.statusText =
          mock.mockTestStatus === 'LOCKED'
            ? 'Locked'
            : mock.sectionsRemaining > 1
            ? 'Sections Remaining'
            : 'Section Remaining'
        mock.backgroundColor = getColor(
          mock.mockTestStatus,
          mock.completedSubmission,
          'background'
        )
        mock.circleColor = getColor(
          mock.mockTestStatus,
          mock.completedSubmission,
          'circle'
        )
      })
      newMockTestData?.mockTests.sort((a: TestSchema, b: TestSchema) => {
        return a.mockTestIndex > b.mockTestIndex ? 1 : -1
      })
      const completedMockTest = []
      const lockedMockTest = []
      const ongoingMockTest = []
      const len = newMockTestData?.mockTests.length
      for (let i = 0; i < len; i++) {
        if (newMockTestData.mockTests[i].completedSubmission) {
          completedMockTest.push(newMockTestData.mockTests[i])
        } else if (newMockTestData.mockTests[i].mockTestStatus === 'LOCKED') {
          lockedMockTest.push(newMockTestData.mockTests[i])
        } else {
          ongoingMockTest.push(newMockTestData.mockTests[i])
        }
      }
      const newMockList = [
        ...ongoingMockTest,
        ...lockedMockTest,
        ...completedMockTest,
      ]
      newMockTestData.mockTests = newMockList
      return {
        ...state,
        mockTestData: newMockTestData,
      }
    case 'UPDATE_MOCK_TEST_ERROR':
      return {
        ...state,
        error: action.payload,
      }
    case 'UPDATE_MOCK_TEST_VIEW':
      return {
        ...state,
        view: action.payload,
      }
    case 'UPDATE_MOCK_TEST_ACTIVE_SECTION':
      return {
        ...state,
        activeSection: action.payload,
      }
    case 'UPDATE_ACTIVE_MOCK_TEST_INDEX':
      return {
        ...state,
        activeMockTestIndex: action.payload,
      }
    case 'TOGGLE_UPLOAD_MOCK_TEST_STATUS':
      return {
        ...state,
        essayUploaded: action.payload,
      }
    case 'UPDATE_ACTIVE_MOCK_TEST_ID':
      return {
        ...state,
        activeMockTestId: action.payload,
      }
    case 'FETCH_ESSAY_LIST_SUCCESS':
      const hideMockTest = action.payload.length > 0
      return {
        ...state,
        showMockTest: !hideMockTest,
      }
    default:
      return state
  }
}

export default mockTestReducer
