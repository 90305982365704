import VisaAssistanceConstants from "../constants/visa-assistance-constants";

const fetchVisaAssistanceData = () => ({
  type: VisaAssistanceConstants.FETCH_VISA_ASSISTANCE_DATA,
});

const ffetchVisaAssistanceDataSuccess = (payload: string) => ({
  type: VisaAssistanceConstants.FETCH_VISA_ASSISTANCE_DATA_SUCCESS,
  payload,
});

const fetchVisaAssistanceDataError = (message: string) => ({
  type: VisaAssistanceConstants.FETCH_VISA_ASSISTANCE_DATA_ERROR,
  payload: message,
});

export const visaAssistanceActions = {
  fetchVisaAssistanceData,
  ffetchVisaAssistanceDataSuccess,
  fetchVisaAssistanceDataError,
};
