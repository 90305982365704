import { AnyAction } from "redux";
import { MasterclassSchema } from "Interfaces/reducerTypes/MasterclassSchema";

const initialState: MasterclassSchema = {
  subscriptionStatus: null,
  error: "",
  masterclassData: [],
  unsubscribedCtaText: "Subscribe For Free Masterclass",
  showEnrolledForm: false,
  showConfirmModal: false,
  beginnerMasterClass: [],
  userSubscriptionType: null,
  masterclassRecommendation: null,
};

const masterclassReducer = (
  state: MasterclassSchema = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case "FETCH_MASTERCLASS_SUBSCRIPTION_STATUS_SUCCESS":
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    case "FETCH_MASTERCLASS_DATA_SUCCESS":
      return {
        ...state,
        masterclassData: action.payload,
      };
    case "FETCH_BEGINNER_MASTERCLASS_DATA_SUCCESS":
      return {
        ...state,
        beginnerMasterClass: action.payload,
      };
    case "UPDATE_UNSUBSCRIBED_MASTERCLASS_CTA_TEXT":
      return {
        ...state,
        unsubscribedCtaText: action.payload,
      };
    case "TOGGLE_MASTERCLASS_ENROLL_MODAL":
      return {
        ...state,
        showEnrolledForm: action.payload,
      };
    case "MASTERCLASS_CONFIRMATION_MODAL":
      return {
        ...state,
        showConfirmModal: action.payload,
      };
    case "SET_MASTERCLASS_SUBSCRIPTION_STATUS":
      return {
        ...state,
        subscriptionStatus: action.payload,
      };
    case "FETCH_FREE_IELTS_COURSE_STATUS_SUCCESS":
      return {
        ...state,
        userSubscriptionType: action.payload,
      };
    case "FETCH_MASTERCLASS_RECOMMENDATION_SUCCESS":
      return {
        ...state,
        masterclassRecommendation: action.payload,
      };
    default:
      return state;
  }
};

export default masterclassReducer;
