import jwtDecode from "jwt-decode"

export type DecodedToken = {
  readonly email: string
  readonly exp: number
  readonly user_id?: {
    user_id: string
    application: string
    roles: string[]
    country: string
  }
}

export const TOKEN_STORAGE_KEY = "leapscholar.authToken"

export class AuthToken {
  readonly decodedToken: DecodedToken

  constructor(readonly token?: string) {
    // we are going to default to an expired decodedToken
    this.decodedToken = { email: "", exp: 0 }

    // then try and decode the jwt using jwt-decode
    try {
      if (token) this.decodedToken = jwtDecode(token)
    } catch (e: any) {}
  }

  getData() {
    return "Abhijeet Gairola"
  }

  get authorizationString() {
    return `Bearer ${this.token}`
  }

  get expiresAt(): Date {
    return new Date(this.decodedToken.exp * 1000)
  }

  get isExpired(): boolean {
    return new Date() > this.expiresAt
  }

  get isValid(): boolean {
    return !this.isExpired
  }

  get userCountry(): string | null {
    return (!this.isExpired && this.decodedToken.user_id?.country) || null
  }
}
