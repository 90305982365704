import { call, put, takeLatest } from 'redux-saga/effects'
import { upcomingAppointmentsActions } from '../actions/upcoming-appointments-actions'
import { upcomingAppointmentsApi } from '../apis/upcoming-appointments-apis'
import UpcomingAppointmentsConstants from '../constants/upcoming-appointments-constants'

function* fetchUpcomingApointments(): Generator<any, any, any> {
  try {
    const res = yield call(
      upcomingAppointmentsApi.fetchUpcomingAppointmentsRequest
    )
    if (res.data.success) {
      yield put(
        upcomingAppointmentsActions.fetchUpcomingAppointmentsDataSuccess(
          res.data.data
        )
      )
    } else {
      yield put(
        upcomingAppointmentsActions.fetchUpcomingAppointmentsDataError(
          res.data.message
        )
      )
    }
  } catch (e: any) {
    console.error(e)
    yield put(
      upcomingAppointmentsActions.fetchUpcomingAppointmentsDataError(e.message)
    )
  }
}

function* upcomingAppointmentsSaga() {
  yield takeLatest(
    UpcomingAppointmentsConstants.FETCH_UPCOMING_APPOINTMENTS_DATA,
    fetchUpcomingApointments
  )
}

export default upcomingAppointmentsSaga
