import { ReplaysDataSchema } from "Interfaces/reducerTypes/MasterClassReplaysSchema";
export const masterclassReplaysActionType = {
  FETCH_MASTERCLASS_REPLAYS: "FETCH_MASTERCLASS_REPLAYS",
  FETCH_MASTERCLASS_REPLAYS_SUCCESS: "FETCH_MASTERCLASS_REPLAYS_SUCCESS",
  UPDATE_ERROR: "UPDATE_ERROR",
  TOGGLE_SUBSCRIPTION_MODAL: "TOGGLE_SUBSCRIPTION_MODAL",
  RESUME_VIDEO_AFTER_SUBSCRIBE: "RESUME_VIDEO_AFTER_SUBSCRIBE",
};

const fetchMasterclassReplays = () => ({
  type: masterclassReplaysActionType.FETCH_MASTERCLASS_REPLAYS,
});

const fetchMasterclassReplaysSuccess = (payload: Array<ReplaysDataSchema>) => ({
  type: masterclassReplaysActionType.FETCH_MASTERCLASS_REPLAYS_SUCCESS,
  payload
});

const updateError = (payload: string) => ({
  type: masterclassReplaysActionType.UPDATE_ERROR,
  payload
});

const toggleSubscriptionModal = (payload: boolean) => ({
  type: masterclassReplaysActionType.TOGGLE_SUBSCRIPTION_MODAL,
  payload
});

const resumeVideoAfterSubscribe = (payload: ReplaysDataSchema | null) => ({
  type: masterclassReplaysActionType.RESUME_VIDEO_AFTER_SUBSCRIBE,
  payload
})

export const masterclassReplaysActions = {
  fetchMasterclassReplays,
  fetchMasterclassReplaysSuccess,
  toggleSubscriptionModal,
  resumeVideoAfterSubscribe,
  updateError
};
