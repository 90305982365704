import { AnyAction } from "redux";
import NoticeBoardConstants from "../constants/notice-board-constants";

const initialState: any = {
  noticeBoardData: [],
  error: "",
  isLoading: false,
  isError: false,
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case NoticeBoardConstants.FETCH_NOTICE_BOARD_DATA:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case NoticeBoardConstants.FETCH_NOTICE_BOARD_DATA_SUCCESS:
      return {
        ...state,
        noticeBoardData: action.payload,
        isLoading: false,
        isError: false,
      };
    case NoticeBoardConstants.FETCH_NOTICE_BOARD_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};

export default reducer;
