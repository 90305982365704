/* eslint-disable @typescript-eslint/no-unused-vars */
import { myClassApi } from 'components/Profile/IeltsProfile/MyClasses/redux/MyClassApi'
import { upcomingClassActions } from 'components/Profile/IeltsProfile/UpcomingClass/redux/UpcomingClassActions'
import { masterclassRegistrationActions } from 'components/Profile/MasterclassRegistrationForm/redux/MasterclassRegistrationFormAction'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import { masterclassActions } from '../../Masterclass/redux/MasterclassActions'
import { myClassAction, myClassActionType } from './MyClassActions'

function* enrollToMasterClass(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(myClassApi.enrollStudentToMasterClass)
    yield put(myClassAction.enrollToMasterClassSuccess(res))
    yield put(masterclassRegistrationActions.toggleShowForm(false))
    yield put(masterclassRegistrationActions.toggleMasterclassFormModal(true))
  } catch (e: any) {
    console.error(e)
    yield put(myClassAction.enrollToMasterClassFail(e.message))
  }
}

function* notifyUser(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(myClassApi.notifyUser, action.payload)
    if (res.success) {
      yield put(masterclassActions.fetchMasterclassData())
      yield put(upcomingClassActions.fetchUpcomingClassData())
      yield put(masterclassActions.toggleConfirmationModal(true))
    } else {
      console.log(res.message)
    }
  } catch (e: any) {
    console.error(e)
  }
}

function* fetchMasterclassSubscriptionStatus(): Generator<any, any, any> {
  try {
    const res = yield call(myClassApi.fetchMasterclassSubscriptionStatus)
    if (res.success) {
      yield put(
        myClassAction.fetchMasterclassSubscriptionStatusSuccess(res.data)
      )
    } else {
      yield put(
        myClassAction.fetchMasterclassSubscriptionStatusFail(res.message)
      )
    }
  } catch (e: any) {
    console.log(e)
  }
}

function* myClassSaga() {
  // yield takeLatest(myClassActionType.MASTER_FETCH_REQUESTED, fetchMasterData);
  // yield takeLatest(
  //   myClassActionType.ENROLL_TO_MASTER_CLASS,
  //   enrollToMasterClass
  // );
  yield takeLatest(myClassActionType.NOTIFY_USER_FOR_MASTERCLASS, notifyUser)
  // yield takeLatest("FETCH_MASTERCLASS_SUBSCRIPTION_STATUS", fetchMasterclassSubscriptionStatus);
}

export default myClassSaga
