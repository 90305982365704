import { AnyAction } from "redux";
import MyUniversitiesConstants from "../constants/my-universities-constants";

const initialState: any = {
  isOpenApplicationDetailsView: false,
  selectedCollegeDetails: null,
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case MyUniversitiesConstants.SHOW_APPLICATION_VIEW:
      return {
        ...state,
        isOpenApplicationDetailsView: true,
        selectedCollegeDetails: action.payload,
      };

    case MyUniversitiesConstants.HIDE_APPLICATION_VIEW:
      return {
        ...state,
        isOpenApplicationDetailsView: false,
      };

    default:
      return state;
  }
};

export default reducer;
