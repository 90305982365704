import { FastTrackVideoSchema } from "Interfaces/reducerTypes/FastTrackSchema";

export const fastTrackActionVideoType = {
  FETCH_FAST_TRACK_VIDEO_REQ: "FETCH_FAST_TRACK_VIDEO_REQ",
  FETCH_FAST_TRACK_VIDEO_SUCCESS: "FETCH_FAST_TRACK_VIDEO_SUCCESS",
  FETCH_FAST_TRACK_VIDEO_FAIL: "FETCH_FAST_TRACK_VIDEO_FAIL",
  FAST_TRACK_WATCH_VIDEO: "FAST_TRACK_WATCH_VIDEO",
  FAST_TRACK_WATCH_VIDEO_SUCCESS: "FAST_TRACK_WATCH_VIDEO_SUCCESS",
  FAST_TRACK_WATCH_VIDEO_FAIL: "FAST_TRACK_WATCH_VIDEO_FAIL",
  FAST_TRACK_REDIRECT: "FAST_TRACK_REDIRECT",
  FAST_TRACK_MODAL: "FAST_TRACK_MODAL",
  VIDEO_PREVIEW_END_MODAL: "VIDEO_PREVIEW_END_MODAL",
  UPDATE_NEXT_VIDEO_TO_PLAY_AFTER_PAYMENT: "UPDATE_NEXT_VIDEO_TO_PLAY_AFTER_PAYMENT",
};

const watchedVideo = (payload: number) => ({
  type: fastTrackActionVideoType.FAST_TRACK_WATCH_VIDEO,
  payload,
});

const watchedVideoSuccess = (payload: boolean) => ({
  type: fastTrackActionVideoType.FAST_TRACK_WATCH_VIDEO_SUCCESS,
  payload,
});

const watchedVideoFail = (payload: string) => ({
  type: fastTrackActionVideoType.FAST_TRACK_WATCH_VIDEO_FAIL,
  payload,
});

const fetchFastTrackVideoReq = (payload?: string) => ({
  type: fastTrackActionVideoType.FETCH_FAST_TRACK_VIDEO_REQ,
  payload,
});
const fetchFastTrackVideoSuccess = (payload: FastTrackVideoSchema) => ({
  type: fastTrackActionVideoType.FETCH_FAST_TRACK_VIDEO_SUCCESS,
  payload,
});
const fetchFastTrackVideoFail = (payload: string) => ({
  type: fastTrackActionVideoType.FETCH_FAST_TRACK_VIDEO_FAIL,
  payload,
});

const toggleFastTrackModal = (payload: boolean) => ({
  type: fastTrackActionVideoType.FAST_TRACK_MODAL,
  payload,
});

const toggleVideoPreviewEndModal = (payload: boolean) => ({
  type: fastTrackActionVideoType.VIDEO_PREVIEW_END_MODAL,
  payload,
});

const updateNextVideoToPlayAfterPayment = (payload: number) => ({
  type: fastTrackActionVideoType.UPDATE_NEXT_VIDEO_TO_PLAY_AFTER_PAYMENT,
  payload,
});

export const fastTrackVideoAction = {
  fetchFastTrackVideoReq,
  fetchFastTrackVideoSuccess,
  fetchFastTrackVideoFail,
  watchedVideo,
  watchedVideoSuccess,
  watchedVideoFail,
  toggleFastTrackModal,
  toggleVideoPreviewEndModal,
  updateNextVideoToPlayAfterPayment,
};
