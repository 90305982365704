import { counsellingActions } from 'components/Profile/CounsellingProfile/redux/CounsellingActions'
import { counsellingApi } from 'components/Profile/CounsellingProfile/redux/CounsellingApis'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchPostPaymentData(): Generator<any, any, any> {
  try {
    const res = yield call(counsellingApi.fetchPostPaymentData)
    if (res.data.success) {
      yield put(counsellingActions.fetchPostPaymentDataSuccess(res.data.data))
    } else {
      yield put(counsellingActions.fetchPostPaymentDataFail(res.data.message))
    }
  } catch (e: any) {
    console.error(e)
    yield put(counsellingActions.fetchCounsellingStageFail(e.message))
  }
}

function* fetchBookmarkData(): Generator<any, any, any> {
  try {
    const res = yield call(counsellingApi.fetchBookmarksData)
    if (res.data.success) {
      yield put(
        counsellingActions.fetchScholarshipBookmarksSuccess(res.data.data),
      )
    } else {
      yield put(
        counsellingActions.fetchScholarshipBookmarksFail(res.data.message),
      )
    }
  } catch (e: any) {
    console.error(e)
    yield put(counsellingActions.fetchScholarshipBookmarksFail(e.message))
  }
}

function* counsellingSaga() {
  yield takeLatest('POST_PAYMENT_DATA_FETCH_REQUESTED', fetchPostPaymentData)
  yield takeLatest('FECTH_BOOKMARK_DATA_FETCH_REQUESTED', fetchBookmarkData)
}

export default counsellingSaga
