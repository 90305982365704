import { AiEvaluationActionTypes } from 'components/Ielts/AiEvaluation/redux/AiEvalutionAction'
import { AnyAction } from 'redux'

export type smartTestsProgressStatusType = null | {
  freeSpeakingTestsTaken: number
  freeWritingTestsTaken: number
}
export interface AiEvaluationReducerInterface {
  error: string
  isAlreadyPaid: boolean | null
  showForm: boolean
  showLoader: boolean
  purchaseType: string | null
  smartTestsPrice: number | null
  smartTestsProgressStatus: smartTestsProgressStatusType
}

const initialState: AiEvaluationReducerInterface = {
  error: '',
  isAlreadyPaid: null,
  showForm: false,
  showLoader: false,
  purchaseType: null,
  smartTestsPrice: null,
  smartTestsProgressStatus: null,
}

const reducer = (
  state: AiEvaluationReducerInterface = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'GET_AI_EVALUTION_TOKEN':
      return {
        ...state,
        showLoader: true,
      }
    case 'GET_AI_EVALUTION_TOKEN_SUCCESS':
      return {
        ...state,
        showLoader: false,
      }
    case 'GET_AI_EVALUTION_TOKEN_FAil':
      return {
        ...state,
        error: action.payload,
        showLoader: false,
      }
    case 'AI_EVALUTION_ALREADY_PAID_SUCCESS':
      return {
        ...state,
        isAlreadyPaid: action.payload?.alreadyPaid,
      }
    case 'AI_EVALUTION_ALREADY_PAID_FAIL':
      return {
        ...state,
        error: action.payload,
      }
    case 'AI_EVALUATION_PURCHASE_TYPE': {
      return {
        ...state,
        purchaseType: action.payload,
      }
    }
    case 'TOGGLE_AI_EVALUATION_SHOW_FORM':
      return {
        ...state,
        showForm: action.payload,
      }
    case 'TOGGLE_AI_EVALUATION_SHOW_LOADER':
      return {
        ...state,
        showLoader: action.payload,
      }
    case 'UPDATE_ERROR':
      return {
        ...state,
        error: action.payload,
      }
    case AiEvaluationActionTypes.GET_SMART_TEST_PRICE_SUCCESS:
      return {
        ...state,
        smartTestsPrice: action.payload,
      }
    case AiEvaluationActionTypes.GET_SMART_TESTS_PROGRESS_STATUS_SUCCESS:
      return {
        ...state,
        smartTestsProgressStatus: action.payload,
      }
    default:
      return state
  }
}

export default reducer
