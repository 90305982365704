import { createCalendarEventObj } from 'utils/google_calendar'

const DISCOVERY_DOCS = [
  'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
]

const SCOPES = 'https://www.googleapis.com/auth/calendar.events'

const addToCalendar = (batchData: any) => {
  return new Promise((res: any, rej: any) => {
    try {
      const gapi = (window as any).gapi
      gapi.load('client:auth2', () => {
        gapi.client.init({
          apiKey: process.env.GOOGLE_API_KEY,
          clientId: process.env.GOOGLE_CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        gapi.client.load(`calendar`, `v3`, () => console.log('Gapi Loaded'))
        const eventObj = createCalendarEventObj(batchData)
        if (gapi.auth2.getAuthInstance().isSignedIn.get()) {
          gapi.auth2
            .getAuthInstance()
            .then(async () => {
              const request = gapi.client.calendar.events.insert({
                calendarId: 'primary',
                resource: eventObj,
              })
              await request.execute(function (event: any) {
                if (event.error) {
                  rej(false)
                } else {
                  res(true)
                }
              })
            })
            .catch(() => {
              rej(false)
            })
        } else {
          gapi.auth2
            .getAuthInstance()
            .signIn()
            .then(() => {
              const request = gapi.client.calendar.events.insert({
                calendarId: 'primary',
                resource: eventObj,
              })
              request.execute(function (event: any) {
                if (event.error) {
                  rej(false)
                } else {
                  res(true)
                }
              })
            })
            .catch(() => {
              rej(false)
            })
        }
      })
    } catch (err: any) {
      rej(false)
      console.log(err)
    }
  })
}

export const ieltsAttendanceApi = {
  addToCalendar,
}
