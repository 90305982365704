const toggleIeltsTestForm = (payload: boolean) => ({
  type: 'TOGGLE_IELTS_TEST_FORM',
  payload,
})

const fetchIeltsTestSubmission = () => ({
  type: 'FETCH_IELTS_TEST_SUBMISSION_REQUESTED',
})

const updateIeltsTestSubmissionStatus = (payload: string) => ({
  type: 'UPDATE_IELTS_TEST_SUBMISSION_STATUS',
  payload,
})

const saveIeltsSubmissionDetails = (payload: any) => ({
  type: 'SAVE_IELTS_TEST_SUBMISSION_DETAILS',
  payload,
})

const toggleShowIeltsSubmissionSection = (payload: boolean) => ({
  type: 'TOGGLE_SHOW_IELTS_TEST_SUBMISSION_SECTION',
  payload,
})

const updateSubmissionTime = (payload: any) => ({
  type: 'UPDATE_SUBMISSION_TIME',
  payload,
})

const toggleShowSubmittedDocsModal = (payload: boolean) => ({
  type: 'TOGGLE_SHOW_SUBMITTED_DOCS_MODAL',
  payload,
})

const setScreen = (payload: string) => ({
  type: 'SET_SCREEN',
  payload,
})

const fetchRewards = (payload: string) => ({
  type: 'FETCH_REWARDS',
  payload,
})

const setRewards = (payload: string) => ({
  type: 'SET_REWARDS',
  payload,
})

const fetchUserStatus = () => ({
  type: 'FETCH_USER_STATUS',
})

const setUserStatus = (payload: boolean) => ({
  type: 'SET_USER_STATUS',
  payload,
})

const fetchIeltsTestBannerStatusReq = () => ({
  type: 'FETCH_IELTS_TEST_BANNER_STATUS',
})
const setIeltsTestBannerStatusSuccess = (payload: boolean) => ({
  type: 'FETCH_IELTS_TEST_BANNER_STATUS_SUCCESS',
  payload,
})

export const ieltsTestActions = {
  toggleIeltsTestForm,
  fetchIeltsTestSubmission,
  updateIeltsTestSubmissionStatus,
  saveIeltsSubmissionDetails,
  toggleShowIeltsSubmissionSection,
  updateSubmissionTime,
  toggleShowSubmittedDocsModal,
  setScreen,
  fetchRewards,
  setRewards,
  fetchUserStatus,
  setUserStatus,
  fetchIeltsTestBannerStatusReq,
  setIeltsTestBannerStatusSuccess,
}
