import {
  DaignosticTests,
  ExamDashBoardCardDto,
  ExamDashBoardDto,
  IeltsScoreResponse,
  LiyDashboard,
  ModalTypes,
  PracticeTest,
  TestPrepSchema,
} from "Interfaces/reducerTypes/TestPrepSchema"
import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit"
import { WritingExamPreference } from "components/Profile/IeltsProfile/TestPrep/DataMapper"

const initialState: TestPrepSchema = {
  isLoading: false,
  errorMessage: "",
  MockTestData: null,
  examData: [],
  showModal: ModalTypes.INITIAL,
  mocktestModalData: null,
  initiatePayment: false,
  unlockPriceObj: null,
  daignosticTests: null,
  daignosticTestsError: null,
  singleMockTest: null,
  examResult: null,
  selfPrepBaughtData: null,
  practiceTests: null,
  submittedExamPref: false,
  showFLSEFeedback: false,
  liyMocktestDashboard: null,
}

const TestPrepSlice = createSlice({
  name: "mockTest",
  initialState,
  reducers: {
    setMockTestIsLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload }
    },
    setMockTestError(state, action: PayloadAction<string>) {
      return { ...state, errorMessage: action.payload }
    },

    setMockTestList(state, action: PayloadAction<Array<ExamDashBoardDto>>) {
      return { ...state, examData: action.payload }
    },
    setMockTest(state, action: PayloadAction<ExamDashBoardCardDto>) {
      return { ...state, singleMockTest: action.payload }
    },
    setShowModal(state, action: PayloadAction<ModalTypes>) {
      return { ...state, showModal: action.payload }
    },
    setMocktestModalData(state, action: PayloadAction<any>) {
      return { ...state, mocktestModalData: action.payload }
    },
    setInitiatePayment(state, action: PayloadAction<boolean>) {
      return { ...state, initiatePayment: action.payload }
    },
    setUnlockPrice(state, action: PayloadAction<any>) {
      return { ...state, unlockPriceObj: action.payload }
    },
    setDaignosticTests(state, action: PayloadAction<DaignosticTests>) {
      return { ...state, daignosticTests: action.payload }
    },
    setDaignosticTestsError(state, action: PayloadAction<boolean | string>) {
      return { ...state, daignosticTestsError: action.payload }
    },
    setExamResult(state, action: PayloadAction<IeltsScoreResponse | null>) {
      return { ...state, examResult: action.payload }
    },
    setSelfPrepBaughtData(state, action: PayloadAction<any | null>) {
      return { ...state, selfPrepBaughtData: action.payload }
    },
    setPracticeTests(state, action: PayloadAction<PracticeTest>) {
      return { ...state, practiceTests: action.payload }
    },
    setSubmittedExamPref(state, action: PayloadAction<boolean>) {
      return { ...state, submittedExamPref: action.payload }
    },
    setShowFLSEFeedback(state, action: PayloadAction<boolean>) {
      return { ...state, showFLSEFeedback: action.payload }
    },
    setLiyMocktestDashboard(state, action: PayloadAction<LiyDashboard | null>) {
      return { ...state, liyMocktestDashboard: action.payload }
    },
  },
})

export const fetchMockTestList = createAction<string>(
  "mockTest/fetchMockTestList",
)

export const fetchMockTest = createAction<number>("mockTest/fetchMockTest")

export const fetchMockTestModal = createAction<string>(
  "mockTest/fetchMockTestModal",
)

export const fetchMockTestPrice = createAction<string>(
  "mockTest/fetchMockTestModalPrice",
)

export const fetchGetDaignosticTests = createAction(
  "mockTest/fetchGetDaignosticTests",
)

export const fetchMocktestResult = createAction<string>(
  "mockTest/fetchMocktestResult",
)

export const saveForm = createAction<any>("mockTest/saveForm")

export const fetchSelfPrepBaughtStatusAction = createAction(
  "mockTest/fetchSelfPrepBaughtStatusAction",
)
export const fetchMockTestsCredits = createAction(
  "mockTest/fetchMockTestsCredits",
)

export const fetchFLSEFeedbackData = createAction(
  "mockTest/fetchFLSEFeedbackData",
)

export const postWritingExamPreference = createAction<{
  examId: string | number
  writingType: WritingExamPreference
}>("mockTest/postWritingExamPreference")

export const fetchLiyMocktestDashboard = createAction(
  `mockTest/fetchLiyMocktestDashboard`,
)

export const {
  setMockTestIsLoading,
  setMockTestError,
  setMockTestList,
  setMockTest,
  setShowModal,
  setMocktestModalData,
  setInitiatePayment,
  setUnlockPrice,
  setDaignosticTests,
  setDaignosticTestsError,
  setExamResult,
  setSelfPrepBaughtData,
  setPracticeTests,
  setSubmittedExamPref,
  setShowFLSEFeedback,
  setLiyMocktestDashboard,
} = TestPrepSlice.actions

export default TestPrepSlice.reducer
