const fetchExpertsData = () => ({
  type: "FETCH_EXPERTS_DATA",
});

const fetchExpertsSuccess = (payload: any) => ({
  type: "FETCH_EXPERTS_DATA_SUCCESS",
  payload,
});

const fetchExpertsFail = (message: string) => ({
  type: "FETCH_EXPERTS_DATA_FAIL",
  payload: message,
});

const fetchMeetCounsellerSlot = () => ({
  type: "FETCH_MEET_COUNSELLER_SLOT",
});

const fetchMeetCounsellerSlotSuccess = (payload: any) => ({
  type: "FETCH_MEET_COUNSELLER_SLOT_SUCCESS",
  payload,
});

const fetchMeetCounsellerSlotFail = (message: string) => ({
  type: "FETCH_MEET_COUNSELLER_SLOT_FAIL",
  payload: message,
});

const fetchTypedMeetCounsellerSlot = (payload: string) => ({
  type: "FETCH_TYPED_MEET_COUNSELLER_SLOT",
  payload,
});

const fetchTypedMeetCounsellerSlotSuccess = (payload: any) => ({
  type: "FETCH_TYPED_MEET_COUNSELLER_SLOT_SUCCESS",
  payload,
});

const fetchTypedMeetCounsellerSlotFail = (message: string) => ({
  type: "FETCH_TYPED_MEET_COUNSELLER_SLOT_FAIL",
  payload: message,
});

const confirmMeetCounsellerSlot = (payload: number) => ({
  type: "CONFIRM_MEET_COUNSELLER_SLOT",
  payload: payload,
});

const confirmMeetCounsellerSlotSuccess = (payload: any) => ({
  type: "CONFIRM_MEET_COUNSELLER_SLOT_SUCCESS",
  payload,
});

const confirmMeetCounsellerSlotFail = (message: string) => ({
  type: "CONFIRM_MEET_COUNSELLER_SLOT_FAIL",
  payload: message,
});

const confirmRescheduleSlot = (payload: number) => ({
  type: "CONFIRM_RESCHEDULE_SLOT",
  payload: payload,
});

const confirmRescheduleSlotSuccess = (payload: any) => ({
  type: "CONFIRM_RESCHEDULE_SLOT_SUCCESS",
  payload,
});

const confirmRescheduleSlotFail = (message: string) => ({
  type: "CONFIRM_RESCHEDULE_SLOT_FAIL",
  payload: message,
});

const fetchMeetProfile = () => ({
  type: "FETCH_MEET_PROFILE",
});

const fetchMeetProfileSuccess = (payload: any) => ({
  type: "FETCH_MEET_PROFILE_SUCCESS",
  payload,
});

const fetchMeetProfileFail = (message: string) => ({
  type: "FETCH_MEET_PROFILE_FAIL",
  payload: message,
});

const submitMeetProfile = (payload: any) => ({
  type: "SUBMIT_MEET_PROFILE",
  payload,
});

const submitMeetProfileSuccess = (payload: any) => ({
  type: "SUBMIT_MEET_PROFILE_SUCCESS",
  payload,
});

const submitMeetProfileFail = (message: string) => ({
  type: "SUBMIT_MEET_PROFILE_FAIL",
  payload: message,
});
const fetchVisaCounsellerSlotFail = (message: string) => ({
  type: "FETCH_VISA_COUNSELLER_SLOT_FAIL",
  payload: message,
});

const fetchVisaCounsellerSlot = (payload: string) => ({
  type: "FETCH_VISA_COUNSELLER_SLOT",
  payload: payload,
});

const fetchVisaCounsellerSlotSuccess = (payload: any) => ({
  type: "FETCH_VISA_COUNSELLER_SLOT_SUCCESS",
  payload,
});

export const meetCounsellerActions = {
  fetchExpertsData,
  fetchExpertsSuccess,
  fetchExpertsFail,
  fetchMeetCounsellerSlot,
  fetchMeetCounsellerSlotSuccess,
  fetchMeetCounsellerSlotFail,
  confirmMeetCounsellerSlot,
  confirmMeetCounsellerSlotSuccess,
  confirmMeetCounsellerSlotFail,
  confirmRescheduleSlot,
  confirmRescheduleSlotSuccess,
  confirmRescheduleSlotFail,
  fetchMeetProfile,
  fetchMeetProfileSuccess,
  fetchMeetProfileFail,
  submitMeetProfile,
  submitMeetProfileSuccess,
  submitMeetProfileFail,
  fetchTypedMeetCounsellerSlot,
  fetchTypedMeetCounsellerSlotSuccess,
  fetchTypedMeetCounsellerSlotFail,
  fetchVisaCounsellerSlot,
  fetchVisaCounsellerSlotFail,
  fetchVisaCounsellerSlotSuccess,
};
