import { AnyAction } from "redux";

const initialState: any = {
  isModalOpen: false,
  isSlotStarted: false,
  selectedSlot: {},
  isLiveViewOn: false,
  slotOptions: [],
  isLoading: false,
  selectedDate: "",
  isJoinLiveClicked: false,
};

// create your reducer
const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "TOGGLE_MODAL_OPEN":
      return {
        ...state,
        isModalOpen: action.payload,
      };

    case "TOGGLE_IS_SLOT_STARTED":
      return {
        ...state,
        isSlotStarted: action.payload,
      };

      case "SETTING_SELECTED_SLOT":
      return {
        ...state,
        selectedSlot: {...action.payload},
      };

      case "TOGGLE_LIVEVIEW_ON":
      return {
        ...state,
        isLiveViewOn: action.payload,
      };

      case "TOGGLE_IS_LOADING":
        return {
          ...state,
          isLoading: action.payload,
        };

      case "SET_SLOT_OPTIONS":
        return {
          ...state,
          slotOptions: [...action.payload],
        };

        case "SET_SELECTED_DATE":
        return {
          ...state,
          selectedDate: action.payload,
        };

        case "TOGGLE_JOIN_LIVE_BUTTON":
          return {
            ...state,
            isJoinLiveClicked: action.payload,
          };
      
    default:
      return state;
  }
};

export default reducer;
