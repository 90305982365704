import {
  COOKIES_CONSTANT,
  CookieReqContext,
  getCookie,
} from "utils/cookie-utiils"

const winston = require("winston")
const { combine, timestamp, printf, colorize, align } = winston.format

export const logger = winston.createLogger({
  level: process.env.LOG_LEVEL ?? "info",
  format: combine(
    colorize({ all: true }),
    timestamp({
      format: "YYYY-MM-DD hh:mm:ss.SSS",
    }),
    align(),
    printf(
      (info: any) =>
        `[${info.timestamp}] logLevel = ${info.level} | ${info.message}`,
    ),
  ),
  transports: [new winston.transports.Console()],
})

export const getUserId = (ctx: CookieReqContext) => {
  return getCookie(ctx, COOKIES_CONSTANT.USER_ID)
}
