import { IeltsToCounsellingEligibility } from 'components/TofuOnbordingForms/SpotCounsellingProgressiveForm/IeltsToCounselling/IeltsToCounsellingApi'
import { fireTagEvent, logEvent } from 'ga'
import { State } from 'Interfaces/storeSchema'
import { initDefaultPaymentGateway } from 'lib/Razorpay/paymentGateWay'
import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { removePromoCode } from 'utils/promoCodeUtils'
import {
  AiEvaluationActionTypes,
  aiEvalutionActions,
} from './AiEvalutionAction'
import { aiEvalutionApi } from './AiEvalutionApi'

function* fetchAiEvalutionToken(): Generator<any, any, any> {
  try {
    const res = yield call(aiEvalutionApi.fetchAiEvalutionToken)
    if (res) {
      yield put(aiEvalutionActions.getAiEvalutionTokenSuccess(res))
      yield put(aiEvalutionActions.openIelts8tab(res))
      // if (action.payload) {
      //   window.open(navRoutes.MOCKTEST_SECTION, "_self");
      // }
    }
  } catch (e) {
    console.error(e)
    yield put(
      aiEvalutionActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* aiEvalutionAlreadyPaid(): Generator<any, any, any> {
  try {
    const res = yield call(aiEvalutionApi.aiEvalutionAlreadyPaid)
    yield put(aiEvalutionActions.aiEvalutionAlreadyPaidSuccess(res))
  } catch (e) {
    console.error(e)
    yield put(
      aiEvalutionActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* saveData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(aiEvalutionApi.saveForm, {
      ...action.payload,
      formId: 'I2C_form',
    })
    if (res.success) {
      const type = yield select((state) => state.aiEvalution.purchaseType)
      const promoCode = yield select((state: State) => state.promoCode)
      const smartTestsPrice = yield select(
        (state: State) => state.aiEvalution.smartTestsPrice
      )
      IeltsToCounsellingEligibility(action.payload.values)
      yield put(aiEvalutionActions.toggleShowForm(false))
      if (type === 'paid') {
        const paymentInfo: any = {
          paymentType: 'AI_EVALUATION',
          formDescription: 'Automated IELTS Assessments',
          amount:
            typeof promoCode.priceAfterDiscount == 'number'
              ? promoCode.priceAfterDiscount
              : smartTestsPrice || 299,
        }
        if (promoCode.isValidCode) {
          paymentInfo['promoCode'] = {
            name: promoCode.promoCode.trim(),
            discount: promoCode.discount,
          }
        }
        const paymentReceived = yield initDefaultPaymentGateway(paymentInfo)
        if (paymentReceived) {
          removePromoCode()
          yield call(fireTagEvent, 'SmartTests_paid', {
            value: '299',
            currency: 'INR',
            content_id: 'smartTests',
            content_type: 'smartTests',
          })
          logEvent('SmartTests', 'Paid for SmartTests', ' ')
          yield put(aiEvalutionActions.getAiEvalutionToken(true))
        }
      } else {
        yield put(aiEvalutionActions.getAiEvalutionToken())
      }
    }
  } catch (e) {
    console.error(e)
    yield put(
      aiEvalutionActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* openIelts8tab(action: AnyAction): Generator<any, any, any> {
  try {
    window.open(`${process.env.IELTS8_URL}?token=${action.payload}`, '_self')
  } catch (e) {
    console.error(e)
    yield put(
      aiEvalutionActions.updateError(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* fetchGetSmartTestFeeSaga(): Generator<any, any, any> {
  try {
    const res = yield call(aiEvalutionApi.fetchGetSmartTestFee)
    if (res.data) {
      yield put(
        aiEvalutionActions.getSmartTestPriceSucess(res.data?.offerPrice)
      )
    }
  } catch (e) {}
}

function* fetchGetSmartTestProgressStatusSaga(): Generator<any, any, any> {
  try {
    const res = yield call(aiEvalutionApi.fetchGetSmartTestProgressStatus)
    if (res?.data) {
      yield put(aiEvalutionActions.getSmartTestsProgressStatusSuccess(res.data))
    }
  } catch (e) {}
}

function* aiEvalutionSaga() {
  yield takeLatest('GET_AI_EVALUTION_TOKEN', fetchAiEvalutionToken)
  yield takeLatest('AI_EVALUTION_ALREADY_PAID', aiEvalutionAlreadyPaid)
  yield takeLatest('AI_EVALUATION_FORM_SUBMIT', saveData)
  yield takeLatest('OPEN_IELTS8_TAB', openIelts8tab)
  yield takeLatest(
    AiEvaluationActionTypes.GET_SMART_TEST_PRICE,
    fetchGetSmartTestFeeSaga
  )
  yield takeLatest(
    AiEvaluationActionTypes.GET_SMART_TESTS_PROGRESS_STATUS,
    fetchGetSmartTestProgressStatusSaga
  )
}

export default aiEvalutionSaga
