import { monthNameList, weekdaysList } from "../utils/dateUtils"

function withSpaceString(str: string | number) {
  return `${str}`
}

function appendZeroInSingleDigit(number: string | number) {
  return number.toString().length > 1 ? number : "0" + number.toString()
}

export const generateUniqueValue = () => {
  try {
    const timestamp = Date.now()
    const randomNumber = Math.floor(Math.random() * 1000000)
    const uniqueValue = `${timestamp}-${randomNumber}`
    return uniqueValue
  } catch (err) {
    console.error(err)
  }
}

export function replaceDate(date: Date, formatString: string) {
  /*
   * Date format
   * dd for cardinal date
   * Do for ordinal date
   */
  const cardinalDate = date.getDate()
  const ordinalDate = () => {
    if (cardinalDate == 1) return cardinalDate + "st"
    if (cardinalDate == 2) return cardinalDate + "nd"
    if (cardinalDate == 3) return cardinalDate + "rd"
    return cardinalDate + "th"
  }
  return formatString
    .toString()
    .replace(/\b(dd|DD)\b/g, String(cardinalDate))
    .replace(/\bDo\b/g, ordinalDate())
}

export function replaceDay(date: Date, formatString: string) {
  /*
   * Date format
   * dd for cardinal date
   * Do for ordinal date
   */
  const getDay = date.getDay()
  const dayName = weekdaysList[getDay]
  const shortWeekName = dayName.slice(0, 3)
  return formatString
    .toString()
    .replace(/\b(ddd)\b/g, String(shortWeekName))
    .replace(/\bdddd\b/g, String(dayName))
}

export function replaceMonth(date: Date, formatString: string) {
  /*
   * MM-> month number 1-12
   * MMM -> month in short Form e.d Jan, Feb
   * MMMM-> month name full form
   */
  const monthNumber = new Date(date).getMonth() + 1
  const monthName = monthNameList[monthNumber - 1]
  const monthShortName = monthName.slice(0, 3)
  return formatString
    .toString()
    .replace(/\bMMMM\b/g, withSpaceString(monthName))
    .replace(/\bMMM\b/g, withSpaceString(monthShortName))
    .replace(/\bMM\b/g, withSpaceString(monthNumber))
}

export function replaceYear(date: Date, formatString: string) {
  /*
   * YYYY -> year full form
   *  YY -> last two digit of year
   */
  const thisYear = new Date(date).getFullYear().toString()
  const shortYear = thisYear.slice(2)
  return formatString
    .toString()
    .replace(/\bYYYY\b/g, withSpaceString(thisYear))
    .replace(/\bYY\b/g, withSpaceString(shortYear))
}

export function replaceTime(date: Date, formatString: string) {
  /*
   * HH -> 24hrs format
   * hh -> 12 hrs format
   */
  const currentTime = new Date(date)
  const varHour = currentTime.getHours()
  const clockHour = () => {
    if (varHour == 0) return { hour: "12", abbr: "am" }
    if (varHour < 12)
      return { hour: appendZeroInSingleDigit(varHour), abbr: "am" }
    return { hour: appendZeroInSingleDigit(varHour - 12), abbr: "pm" }
  }
  const varMin = appendZeroInSingleDigit(currentTime.getMinutes())

  const varSec = appendZeroInSingleDigit(currentTime.getSeconds())
  return formatString
    .toString()
    .replace(/\bHH\b/g, withSpaceString(varHour))
    .replace(/\bhh\b/g, withSpaceString(clockHour().hour))
    .replace(/\bmm\b/g, withSpaceString(varMin))
    .replace(/\bss\b/g, withSpaceString(varSec))
    .replace(/\ba\b/g, withSpaceString(clockHour().abbr))
    .replace(/\bA\b/g, withSpaceString(clockHour().abbr).toUpperCase())
}

export default function format(date: Date, formatString: string) {
  let dateString = replaceDay(date, formatString)
  dateString = replaceDate(date, dateString)
  dateString = replaceMonth(date, dateString)
  dateString = replaceYear(date, dateString)
  dateString = replaceTime(date, dateString)
  return dateString
}
