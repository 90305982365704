import React, { FunctionComponent, useState } from "react"
import Spinner from "components/common/Spinner"
import api from "service/api"
import Dropzone from "components/Inputs/Dropzone"
import styles from "public/static/scss/FileUpload.module.scss"
import { getFilename } from "utils/string-utils"
import { trackFileUpload } from "scripts/segment"
import { pageName } from "utils/events-utils"

interface Props {
  input: any
  meta: any
  label: string
  fileType?: string
}

const SingleUpload: FunctionComponent<Props> = ({
  input,
  meta,
  label,
  fileType,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const ctaZeroFilesText = "Select file"
  const ctaUploadedFilesText = "Replace file"
  const ctaText = input?.value ? ctaUploadedFilesText : ctaZeroFilesText
  const _fileUrl = input?.value?.url
  const generatedFileName = getFilename(_fileUrl || "")

  const getFileCategory = () => {
    if (input?.name == "CONSOLIDATED_PASSPORT") {
      return "Passport"
    } else if (
      input?.name == "WORK_EX" ||
      input?.name == "COUNSELING_OTHER" ||
      input?.name == "RESUME"
    ) {
      return "Other Documents"
    } else {
      return "Educational Documents"
    }
  }

  const getFileName = () => {
    if (
      input?.name == "TENTH_MARK_SHEET" ||
      input?.name == "TWELFTH_MARK_SHEET" ||
      input?.name == "COUNSELING_OTHER"
    ) {
      return label
    } else if (input?.name.includes("SEM_MARK_SHEET")) {
      return `Semester ${parseInt(label)} Marksheet`
    } else if (input?.name == "CONSOLIDATED_PASSPORT") {
      return "Passport"
    } else if (input?.name == "UG_CONSOLIDATED_MARK_SHEET") {
      return "Consolidated UG Marksheet"
    } else if (input?.name == "PG_CONSOLIDATED_MARK_SHEET") {
      return "Consolidate PG Marksheet"
    } else if (input?.name == "IELTS_SCORE_CARD") {
      return "IELTS Score Card"
    }
  }

  const handleUpload = async (file: any) => {
    try {
      const formData = new FormData()
      formData.append("file", file)
      const res = await api.post(`/api/upload?name=${input.name}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      if (res.data.success) {
        const trackPayload = {
          fileType: file?.type || "NA",
          fileCategory: getFileCategory() || "NA",
          fileName: getFileName() || "NA",
          // uploadSource: props?.uploadSource || "NA",
        }
        trackFileUpload(pageName.Application_Document_Page, trackPayload)
        return res.data.data
      }
    } catch (err: any) {
      console.log(err)
    }
  }

  const handleError = async (error: any) => {
    setError(error.message)
  }

  const handleChange = async (file: any) => {
    setError(null)
    setLoading(true)
    input.onFocus()
    const fileObject = { name: file.name, size: file.size }
    const url = await handleUpload(file)
    setLoading(false)
    input.onChange({ ...input.value, ...fileObject, url })
    input.onBlur()
  }
  return (
    <div>
      <Dropzone
        enabled={true}
        onChange={handleChange}
        onError={handleError}
        fileType={fileType}
      >
        {(isDragActive: boolean) => {
          return (
            <div
              className={`rounded-6 border border-lightgray-950 p-3 cursor-pointer w-full bg-white border-dashed ${
                isDragActive && "bg-gray-300"
              } ${
                (meta.touched && meta.error) || error
                  ? "border-red-600"
                  : "border-gray-400"
              }`}
            >
              <div className={`flex justify-between items-center`}>
                <div
                  className={`${
                    styles.fileUploadLabel
                  } text-normal text-yellow-400 mt-1 max-w-180 md:max-w-300 flex ${
                    (input.value && `text-green-200 ${styles.active}`) || true
                  } ${loading && `text-green-200 ${styles.loading}`} ${
                    ((meta.touched && meta.error) || error) &&
                    `text-red-600 ${styles.error}`
                  }`}
                >
                  {loading && (
                    <div className={styles.spinnerHolder}>
                      <Spinner size="small" />
                    </div>
                  )}
                  {label}
                </div>
                <div className={styles.uploadRightSection}>
                  {generatedFileName && (
                    <div className={styles.filename}>{generatedFileName}</div>
                  )}
                  <div className={styles.fileUploadCta}>{ctaText}</div>
                </div>
              </div>
            </div>
          )
        }}
      </Dropzone>
      {meta.touched && meta.error && (
        <p className="text-xs text-red-600">{meta.error}</p>
      )}
      {error && <p className="text-xs text-red-600">{error}</p>}
    </div>
  )
}

export default SingleUpload
