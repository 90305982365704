import { referalApis } from "components/Ielts/Referal/redux/ReferalApi"
import {
  postReferalData,
  setReferalError,
} from "components/Ielts/Referal/redux/IeltsReferalSlice"
import { AnyAction } from "redux"
import { call, put, takeLatest } from "redux-saga/effects"
import { isFunction } from "utils/common_utils"

function* postReferalDetailsSaga(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(referalApis.postReferalDetails, action.payload.data)
    if (res.data.success) {
      if (isFunction(action.payload.callBack)) {
        action.payload.callBack()
      }
    } else {
      throw Error(res.data.message ?? "")
    }
  } catch (error: any) {
    console.log(error?.message, "ksgkhjgsihygsiy")
    yield put(setReferalError(error?.message))
  }
}

export default function* referalSaga() {
  yield takeLatest(postReferalData, postReferalDetailsSaga)
}
