const buildAuthUrl = (
  auth_page?: string,
  callbackPath?: string,
  utmSource?: string,
  utmCampaign?: string,
  utmTerm?: string,
  gclid?: string,
  adName?: string,
): string => {
  let finalUrl = `/${auth_page}?redirected=true&callbackUrl=${encodeURIComponent(
    callbackPath || "",
  )}`
  if (utmSource) finalUrl += `&utm_source=${utmSource}`
  if (utmCampaign) finalUrl += `&utm_campaign=${utmCampaign}`
  if (utmTerm) finalUrl += `&utm_term=${utmTerm}`
  if (gclid) finalUrl += `&gclid=${gclid}`
  if (adName) finalUrl += `&ad_name=${adName}`
  return finalUrl
}

export const getRedirectLoginPath = (): string => {
  if (typeof window != "undefined") {
    const callbackUrl =
      window.location.pathname + window.location.search + window.location.hash
    let auth_page = null
    const url = new URL("https://test.com" + callbackUrl)
    auth_page = url.searchParams.get("auth_page")
    const utmSource = url.searchParams.get("utm_source") || undefined
    const utmCampaign = url.searchParams.get("utm_campaign") || undefined
    const gclid = url.searchParams.get("gclid") || undefined
    const adName = url.searchParams.get("ad_name") || undefined
    const utmTerm = url.searchParams.get("utm_term") || undefined
    const pageName = auth_page && auth_page !== "" ? auth_page : "login"
    return buildAuthUrl(
      pageName,
      callbackUrl,
      utmSource,
      utmCampaign,
      utmTerm,
      gclid,
      adName,
    )
  }
  return "/"
}
