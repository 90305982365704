export const stageMapper: { [key: string]: string } = {
  LS_USER_ACQUIRED: 'user_acquried',
  LS_LEAD_CAPTURED: 'user_acquried',
  LS_LEAD_CLOSED_AFTER_SALES_CALL: 'user_acquried',
  LS_COUNSELING_CALL_CANCELLED: 'user_acquried',
  LS_DEAD_LEAD: 'user_acquried',
  LS_WEBINAR_CANCELLED: 'user_acquried',
  LS_COUNSELING_CALL_MISSED: 'user_acquried',
  LS_COUNSELING_CALL_NO_SHOW: 'user_acquried',
  LS_COUNSELING_CALL_SCHEDULED: 'call_scheduled',
  LS_WEBINAR_SCHEDULED: 'call_scheduled',
  LS_COUNSELING_CALL_DONE: 'call_done',
  LS_WEBINAR_ATTENDED: 'call_done',
  LS_LEAD_CLOSED_AFTER_COUNSELLOR_CALL: 'call_done',
  LS_PAYMENT_DONE: 'payment_done',
  LS_WEBINAR_PAYMENT_DONE: 'payment_done',
  LS_COLLEGE_SHORTLISTED: 'payment_done',
  LS_COLLEGE_FINALIZED: 'college_finalized',
  LS_APPLICATION_PROCESS_STARTED: 'college_finalized',
  LS_APPLICATION_IN_PROCESS: 'college_finalized',
  LS_APPLICATION_PROCESS_ON_HOLD: 'college_finalized',
  LS_DOCUMENTS_UPLOADED: 'college_finalized',
  LS_APPLICATION_SUBMITTED_TO_AGGREGATOR: 'college_finalized',
  LS_APPLICATION_REJECTED_BY_AGGREGATOR: 'college_finalized',
  LS_APPLICATION_SUBMITTED_TO_INSTITUTE: 'college_finalized',
  LS_APPLICATION_ON_HOLD_BY_INSTITUTE: 'college_finalized',
  LS_APPLICATION_ON_HOLD_BY_AGGREGATOR: 'college_finalized',
  LS_CONDITIONAL_ADMIT_RECEIVED: 'college_finalized',
  LS_UNCONDITIONAL_ADMIT_RECEIVED: 'college_finalized',
  LS_ADMIT_REJECTED_BY_INSTITUTE: 'college_finalized',
  LS_ADMIT_DECLINED: 'college_finalized',
  LS_ADMIT_ACCEPTED: 'college_finalized',
  LS_OFFER_REVOKED_BY_UNIVERSITY: 'college_finalized',
  LS_DEPOSIT_PAID: 'college_finalized',
  LS_TUITION_FEE_PAID: 'college_finalized',
  LS_VISA_WIP: 'college_finalized',
  LS_VISA_APPLIED: 'college_finalized',
  LS_VISA_REJECTED: 'college_finalized',
  LS_VISA_GRANTED: 'college_finalized',
}

// Stage less than 7 : LS_Lead_Captured --- given by Vijay Dhingra sd-2049 PRD
// Stage 7 : LS_Webinar_Scheduled
// Stage between 8 to 9: LS_Webinar_Attended
// Stage above 9 : no change

export const counsellingStageRank: { [key: string]: number } = {
  LS_USER_ACQUIRED: 1,
  LS_LEAD_CAPTURED: 2,
  LS_DEAD_LEAD: 2,
  LS_LEAD_CLOSED_AFTER_SALES_CALL: 3,
  LS_COUNSELING_CALL_CANCELLED: 4,
  LS_WEBINAR_CANCELLED: 4.1,
  LS_COUNSELING_CALL_NO_SHOW: 5,
  LS_COUNSELING_CALL_MISSED: 6,
  LS_COUNSELING_CALL_SCHEDULED: 7,
  LS_WEBINAR_SCHEDULED: 7,
  LS_COUNSELING_CALL_DONE: 8,
  LS_WEBINAR_ATTENDED: 8,
  LS_LEAD_CLOSED_AFTER_COUNSELLOR_CALL: 9,
  LS_PAYMENT_DONE: 10,
  LS_WEBINAR_PAYMENT_DONE: 10,
  LS_COLLEGE_SHORTLISTED: 11,
  LS_COLLEGE_FINALIZED: 12,
  LS_APPLICATION_PROCESS_STARTED: 12.1,
  LS_APPLICATION_IN_PROCESS: 12.2,
  LS_APPLICATION_PROCESS_ON_HOLD: 12.3,
  LS_DOCUMENTS_UPLOADED: 13,
  LS_APPLICATION_SUBMITTED_TO_AGGREGATOR: 14,
  LS_APPLICATION_REJECTED_BY_AGGREGATOR: 15,
  LS_APPLICATION_SUBMITTED_TO_INSTITUTE: 16,
  LS_APPLICATION_ON_HOLD_BY_INSTITUTE: 17,
  LS_APPLICATION_ON_HOLD_BY_AGGREGATOR: 18,
  LS_CONDITIONAL_ADMIT_RECEIVED: 19,
  LS_UNCONDITIONAL_ADMIT_RECEIVED: 20,
  LS_ADMIT_REJECTED_BY_INSTITUTE: 21,
  LS_ADMIT_DECLINED: 22,
  LS_ADMIT_ACCEPTED: 23,
  LS_OFFER_REVOKED_BY_UNIVERSITY: 23.1,
  LS_DEPOSIT_PAID: 24,
  LS_TUITION_FEE_PAID: 25,
  LS_LEAD_DROP_OFF: 30,
  // all visa state
  LS_VISA_WIP: 26,
  LS_VISA_APPLIED: 27,
  LS_VISA_REJECTED: 28,
  LS_VISA_GRANTED: 29,
  LS_VISA_PROCESS_ON_HOLD: 30,
  LS_VISA_FILING_STARTED: 31,
  LS_VISA_DROPPED: 32,
  //new live flow states
  LS_COUNSELLING_CALL_SCHEDULED_NONLIVE : 2.1,
  LS_COUNSELLING_CALL_SCHEDULED_LIVE : 2.2,
  LS_DUMMY_SCHEDULED_LIVE : 2.7,
  LS_USER_DROP_LIVE : 2.3,
  LS_USER_DROP_NON_LIVE : 2.4,
  LS_LEAD_QUALIFIED : 2.5,
  LS_WELCOME_CALL_DONE : 2.6,
}

/* aplication status and its sub-stages ----- START */
export const applicationStatus: { [key: string]: string } = {
  INITIAL_SHORTLIST: 'INITIAL_SHORTLIST', // default
  ARCHIVED: 'ARCHIVED', // Deleted application
  APPLICATION_DROPPED: 'APPLICATION_DROPPED', // this means application is dropped
  RECOMMENDATION_GIVEN: 'RECOMMENDATION_GIVEN', // this means recommendation is final
  SEND_FILING_REQUEST: 'SEND_FILING_REQUEST', //this means the application process got started
  PROCESS_ON_HOLD_DUE_TO_MISSING_DOCUMENTS:
    'PROCESS_ON_HOLD_DUE_TO_MISSING_DOCUMENTS', //this means the application process is put on hold due to missing documents
  FILING_IN_PROCESS: 'FILING_IN_PROCESS', //this means the application is in process
  APPLICATION_ON_HOLD: 'APPLICATION_ON_HOLD', //this means the application is on hold
  SUBMITTED_TO_AGGREGATOR: 'SUBMITTED_TO_AGGREGATOR', //this means the application is submitted to aggregator
  ON_HOLD_BY_AGGREGATOR: 'ON_HOLD_BY_AGGREGATOR', //this means the application is put on hold to aggregator
  CASE_CLOSED_BY_AGGREGATOR: 'CASE_CLOSED_BY_AGGREGATOR', //this means the application is closed by aggregator due to some reasons
  PROGRAM_CLOSED: 'PROGRAM_CLOSED', // this means the appication is sent to university
  SUBMITTED_TO_INSTITUTE: 'SUBMITTED_TO_INSTITUTE', // this means the appication is sent to university
  ON_HOLD_BY_INSTITUTE: 'ON_HOLD_BY_INSTITUTE', // this means the appication is set on hold status by university
  UNCONDITIONAL_ADMIT_RECEIVED: 'UNCONDITIONAL_ADMIT_RECEIVED', // this means the student got an admit
  CONDITIONAL_ADMIT_RECEIVED: 'CONDITIONAL_ADMIT_RECEIVED', // this means the student got a conditional admit
  ADMIT_REJECTED: 'ADMIT_REJECTED', // this means the University rejected the applicant
  APPLICATION_DEFERRED: 'APPLICATION_DEFERRED', // this means the application is deferred after admit received
  ADMIT_ACCEPTED: 'ADMIT_ACCEPTED', // this means the student accepted the admit (paid the fees)
  ADMIT_DECLINED: 'ADMIT_DECLINED', // this means the application is declined
  DEPOSIT_PAID: 'DEPOSIT_PAID', // this means the deposit is paid
  TUITION_FEE_PAID: 'TUITION_FEE_PAID', // this means the tution fee payment is done
}
export const applicationPostSubMissionStatus: { [key: string]: string } = {
  UNDER_REVIEW: 'UNDER_REVIEW',
  ADDITIONAL_DOCUMENTS_REQUIRED: 'ADDITIONAL_DOCUMENTS_REQUIRED',
  ON_HOLD_OTHER_REASONS: 'ON_HOLD_OTHER_REASONS',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  SUBMITTED_TO_INSTITUTION: 'SUBMITTED_TO_INSTITUTION',
  CASE_CLOSED_BY_AGGREGATOR: 'CASE_CLOSED_BY_AGGREGATOR',
  PROGRAM_CLOSED: 'PROGRAM_CLOSED',
}
export const applicationFilingStatus: { [key: string]: string } = {
  IN_PROGRESS: 'IN_PROGRESS',
  DOCS_PENDING: 'DOCS_PENDING',
  ON_HOLD: 'ON_HOLD',
  DROPPED: 'DROPPED',
  COUNSELLOR_TO_REVIEW: 'COUNSELLOR_TO_REVIEW',
  PAYMENT_PENDING: 'PAYMENT_PENDING',
  SUBMITTED: 'SUBMITTED',
  RECEIVED: 'RECEIVED',
}

/* aplication status and its sub-stages ----- END */
