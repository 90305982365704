import { cookiesConstant } from "DataMapper/Common/cookiesConstant"
import { CountryCode, CountryList } from "DataMapper/CountryCode"
import { NextPageContext } from "next"
import { navRoutes } from "page_routes"
import { deleteCookie, getCookie, saveCookie } from "utils/cookie-utiils"
import { AuthToken } from "lib/auth_token"
import { IpApiSchema } from "Interfaces/common/ipStackSchema"
import api from "service/api"
import { basicAuthToken } from "DataMapper/Common/basicAuth"

export const getCountry = (): string | null => {
  try {
    if (!checkIfItsServer()) {
      return localStorage.getItem("currentSelectedCountry")
    }
  } catch (error) {}
  return null
}
export const checkIfItsServer = (): boolean => {
  return typeof window === "undefined"
}

export const checkForCountriesAndRedirect = ({
  country,
  countriesNotAllowed,
  ctx,
  redirectUrl,
  allowedPaths,
  currentPath,
}: {
  country: CountryCode
  countriesNotAllowed: CountryCode[]
  redirectUrl: string
  ctx: NextPageContext | null
  currentPath: string
  allowedPaths: string[]
}): void => {
  if (allowedPaths.find((item) => item === currentPath)) {
    return
  }
  if (countriesNotAllowed.find((item) => item === country)) {
    if (ctx) {
      ctx.res?.writeHead(302, {
        Location: redirectUrl,
      })
      ctx.res?.end()
    } else {
      window.open(redirectUrl, "_self")
    }
  }
}
export const checkIfMENAUser = (
  userCountry: string | null | undefined,
): boolean => {
  let country = userCountry
  if (!country && !checkIfItsServer()) {
    country = getCookie(null, cookiesConstant.USER_COUNTRY)
  }
  if (country)
    if (MIDDLE_EAST_COUNTRIES.find((item) => item === country)) return true
  return false
}
export const MIDDLE_EAST_COUNTRIES = [
  CountryCode.BAHRAIN,
  CountryCode.KUWAIT,
  CountryCode.OMAN,
  CountryCode.QATAR,
  CountryCode.SAUDI,
  CountryCode.UAE,
  CountryCode.ALGERIA,
  CountryCode.EGYPT,
  CountryCode.IRAN,
  CountryCode.IRAQ,
  CountryCode.ISRAEL,
  CountryCode.JORDAN,
  CountryCode.LEBANON,
  CountryCode.LIBYA,
  CountryCode.MOROCCO,
  CountryCode.SYRIA,
  CountryCode.TUNISIA,
  CountryCode.PALESTINE,
]

export const ME_USERS_ALLOWED_PATHS = [
  navRoutes.IELTS_PLUS,
  `${navRoutes.IELTS_INTERMEDIATE_SCREEN}[batchId]`,
  navRoutes.IELTS_ALL_BATCHES,
  navRoutes.IELTS_BATCH_DETAILS,
  navRoutes.BOOK_IELTS_DEMO_FLOW,
  navRoutes.RESCHEDULE_IELTS_DEMO,
  navRoutes.IELTS_STUDENT_DASHBOARD,
  navRoutes.IELTS_STUDY_MATERIAL,
  navRoutes.IELTS_CLASS_RECORDINGS,
  navRoutes.SELF_PREP,
]
export const getCountryFromCookies = () =>
  getCookie(null, cookiesConstant.IP_COUNTRY_KEY) || null

export const setCountryInCookies = (countryName: string) => {
  deleteCookie(null, cookiesConstant.IP_COUNTRY_KEY)
  saveCookie(null, cookiesConstant.IP_COUNTRY_KEY, countryName, 30 * 60)
}

const getCountryUserInfo = async (): Promise<string | undefined> => {
  try {
    const userInfoRes = await api.get(
      "api/scholarOpenRoute?path=user/location",
      {
        headers: {
          Authorization: basicAuthToken,
        },
      },
    )
    if (userInfoRes.data.data) {
      const userInfo: IpApiSchema = userInfoRes.data.data
      return (
        CountryList[userInfo.country as unknown as keyof typeof CountryList] ||
        CountryCode.INDIA
      )
    } else {
      return CountryCode.INDIA
    }
  } catch (e) {
    return CountryCode.INDIA
  }
}

export const getUserCountry = async (
  auth: AuthToken | undefined,
): Promise<string> => {
  const saveCountry = getCountryFromCookies()
  if (auth?.isValid && auth.userCountry) {
    return auth.userCountry
  } else if (saveCountry) {
    return saveCountry
  } else {
    const ipCountry = await getCountryUserInfo()
    return ipCountry || CountryCode.INDIA
  }
}

export const getDemoFormUrl = (
  params: Record<string, string>,
  userCountry: string | null,
): string => {
  if (checkIfMENAUser(userCountry)) {
    return `${navRoutes.BOOK_IELTS_DEMO_FORM_MENA}&${getStringFromQueryParams(
      params,
    )}`
  } else {
    return `${navRoutes.BOOK_IELTS_DEMO_FLOW}?${getStringFromQueryParams(
      params,
    )}`
  }
}

export const getStringFromQueryParams = (
  params: Record<string, string>,
): string => {
  return Object.keys(params).reduce(
    (acc, curr, index) =>
      `${acc}${index > 0 ? "&" : ""}${curr}=${params[curr]}`,
    "",
  )
}
