export const chatActionsConstants = {
  QUERY_RAISE_API: "QUERY_RAISE_API",
  QUERY_RAISE_API_SUCCESS: "QUERY_RAISE_API_SUCCESS",
  QUERY_RAISE_API_ERROR: "QUERY_RAISE_API_ERROR",
  RESET_QUERY_STATE: "RESET_QUERY_STATE",
};

const queryRiseApi = (payload: any) => ({
  type: chatActionsConstants.QUERY_RAISE_API,
  payload: payload,
});

const resetQuery = () => ({
  type: chatActionsConstants.RESET_QUERY_STATE,
});

export const chatActions = {
  queryRiseApi,
  resetQuery,
};
