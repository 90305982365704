import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloClient, DefaultOptions } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import fetch from 'isomorphic-unfetch'
import withApollo from 'next-with-apollo'

const GRAPHQL_URL = process.env.CMS_URL || 'https://cms.leapscholar.com'

const link = createHttpLink({
  fetch,
  uri: GRAPHQL_URL + '/graphql?_limit=100&verified=false',
})

export default withApollo(
  ({ initialState }) =>
    new ApolloClient({
      link: link,
      cache: new InMemoryCache()
        //  rehydrate the cache using the initial data passed from the server:
        .restore(initialState || {}),
    })
)

export function createApolloClient(initialState: any) {
  const ssrMode = typeof window === 'undefined'
  return new ApolloClient({
    ssrMode,
    link,
    cache: new InMemoryCache().restore(initialState),
    defaultOptions: getDefaultOptions(ssrMode),
  })
}

const getDefaultOptions = (isSsr: boolean): DefaultOptions => {
  return {
    watchQuery: {
      fetchPolicy: isSsr ? 'no-cache' : 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: isSsr ? 'no-cache' : 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  }
}

export const apoloClient = createApolloClient({
  resultCaching: true,
  addTypename: true,
})
