export interface leadPersonalDetails {
  id?: number
  name?: string
  email?: string
  preferredCountry?: PreferredCountry
  planedCourse?: string
  preferredIntake?: PreferredIntake
  preferredProgram?: PreferredProgram
  ieltsStatus?: IeltsStatus
  typeOfHelp?: TypeOfHelp
  payOfExpenses?: PayOfExpenses
  questionsForCounsellor?: QuestionForCounsellor
  documentsAvailable?: DocumentsAvailable
}

export enum PreferredCountry {
  Canada = 'Canada',
  USA = 'USA',
  UK = 'UK',
  Germany = 'Germany',
  Australia = 'Australia',
  NewZealand = 'NewZealand',
  Singapore = 'Singapore',
  Netherlands = 'Netherlands',
  China = 'China',
  RestOfEurope = 'RestOfEurope',
  MiddleEasternCountries = 'MiddleEasternCountries',
  OpenToAnyCountry = 'OpenToAnyCountry',
}

export enum PreferredIntake {
  JANUARY_2021 = 'JANUARY_2021',
  SEPTEMBER_2020 = 'SEPTEMBER_2020',
  MAY_2021 = 'MAY_2021',
  SEPTEMBER_2021 = 'SEPTEMBER_2021',
  OTHER = 'OTHER',
}

export enum PreferredProgram {
  POST_GRADUATE_DIPLOMA = 'POST_GRADUATE_DIPLOMA',
  MASTERS = 'MASTERS',
  UNDER_GRADUATION = 'UNDER_GRADUATION',
  MBA = 'MBA',
  NOT_DECIDED = 'NOT_DECIDED',
}

export enum IeltsStatus {
  NOT_DECIDED = 'NOT_DECIDED',
  ALREADY_GIVEN = 'ALREADY_GIVEN',
  EXAM_BOOKED = 'EXAM_BOOKED',
  PLANING_TO_BOOK = 'PLANING_TO_BOOK',
}

export enum HighestLevelEducation {
  TENTH = 'TENTH',
  TWELFTH = 'TWELFTH',
  BTECH_BE_BARCH = 'BTECH_BE_BARCH',
  BSC_BA_BCOM_BBA_LLB = 'BSC_BA_BCOM_BBA_LLB',
  MTECH = 'MTECH',
  MBBS = 'MBBS',
  MA_MSC_LLM = 'MA_MSC_LLM',
  MD = 'MD',
}

export enum TypeOfHelp {
  COUNSELLING_AND_COLLEGE_APPLICATION = 'COUNSELLING_AND_COLLEGE_APPLICATION',
  SCHOLARSHIP = 'SCHOLARSHIP',
  VISA_SERVICES = 'VISA_SERVICES',
  IELTS_CLASSES = 'IELTS_CLASSES',
  EDUCATIOIN_LOAN = 'EDUCATIOIN_LOAN',
}

export enum PayOfExpenses {
  NO_CONCERN = 'NO_CONCERN',
  EDUCATION_LOAN = 'EDUCATION_LOAN',
  FAMILY_HELP = 'FAMILY_HELP',
  FULL_SCHOLARSHIP = 'FULL_SCHOLARSHIP',
  NO_MONEY = 'NO_MONEY',
}

export enum HighestLevelEducation {}

export enum CounselingStage {
  LS_LEAD_CAPTURED = 'LS_LEAD_CAPTURED',
  LS_LEAD_CLOSED_AFTER_SALES_CALL = 'LS_LEAD_CLOSED_AFTER_SALES_CALL',
  LS_COUNSELING_CALL_SCHEDULED = 'LS_COUNSELING_CALL_SCHEDULED',
  LS_COUNSELING_CALL_CANCELLED = 'LS_COUNSELING_CALL_CANCELLED',
  LS_COUNSELING_CALL_DONE = 'LS_COUNSELING_CALL_DONE',
  LS_COUNSELING_CALL_NO_SHOW = 'LS_COUNSELING_CALL_NO_SHOW',
  LS_LEAD_CLOSED_AFTER_COUNSELLOR_CALL = 'LS_LEAD_CLOSED_AFTER_COUNSELLOR_CALL',
  LS_PAYMENT_DONE = 'LS_PAYMENT_DONE',
  LS_COLLEGE_SHORTLISTED = 'LS_COLLEGE_SHORTLISTED',
  LS_COLLEGE_FINALIZED = 'LS_COLLEGE_FINALIZED',
  LS_DOCUMENTS_UPLOADED = 'LS_DOCUMENTS_UPLOADED',
  LS_APPLICATION_SUBMITTED_TO_AGGREGATOR = 'LS_APPLICATION_SUBMITTED_TO_AGGREGATOR',
}

export enum QuestionForCounsellor {
  COUNTRY_TO_CHOOSE = 'COUNTRY_TO_CHOOSE',
  COURSE_TO_CHOOSE = 'COURSE_TO_CHOOSE',
  BEST_COLLEGE = 'BEST_COLLEGE',
  IMPROVING_PROFILE = 'IMPROVING_PROFILE',
  JOBS_PROSPECTS = 'JOBS_PROSPECTS',
  APPLYING_FOR_SCHOLARSHIP = 'APPLYING_FOR_SCHOLARSHIP',
  SCHOLARSHIP_FROM_LEAP = 'SCHOLARSHIP_FROM_LEAP',
  FINANCING = 'FINANCING',
}

export enum DocumentsAvailable {
  TWELFTH_MARKSHEET = 'TWELFTH_MARK_SHEET',
  SMESTER_WISE_MARKSHEET = 'UG_DMC',
  GRADUATE_DEGREE = 'UG_PASSING_CERT',
  SOP = 'SOP',
  LOR = 'LOR',
  PASSPORT = 'PASSPORT_FRONT',
}
