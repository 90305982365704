import api from "service/api"

export const getUserInfo = async (): Promise<UserInfoType | undefined> => {
  try {
    const res = await api.get(`/api/scholarRoute?path=student/basic`)
    if (res.data.success) {
      return res.data.data
    } else {
      return undefined
    }
  } catch (err: any) {
    console.log(err)
    return undefined
  }
}

export interface UserInfoType {
  name: string
  email: string
  phone: string
}
