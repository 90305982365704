import { useEffect, useState } from "react"
import { getCookie } from "utils/cookie-utiils"
import { cookiesConstant } from "DataMapper/Common/cookiesConstant"
import { getUserCountry, setCountryInCookies } from "utils/country_utils"
import { getTokenCookie } from "lib/auth-cookies-new"
import { AuthToken } from "lib/auth_token"

const useLocation = () => {
  const [ipCountry, setIpCountry] = useState<string | null>(
    getCookie(null, cookiesConstant.IP_COUNTRY_KEY) || null,
  )
  const token = getTokenCookie()
  const auth = new AuthToken(token)

  const getUserCountryDetails = async () => {
    const country = await getUserCountry(auth)
    setIpCountry(country)
    setCountryInCookies(country)
  }

  useEffect(() => {
    if (auth) {
      getUserCountryDetails()
    }
  }, [auth?.isValid])

  return { userCountry: ipCountry }
}

export default useLocation
