const fetchInitialFormValuesRequested = () => ({
  type: "FETCH_INITIAL_FORM_VALUES_REQUESTED",
});

const fetchInitialFormValuesSuccess = (payload: any) => ({
  type: "FETCH_INITIAL_FORM_VALUES_SUCCESS",
  payload,
});

const updateError = (message: string) => ({
  type: "UPDATE_IELTS_REGISTRATION_FORM_ERROR",
  paylaod: message,
});

export const registrationFormAction = {
  fetchInitialFormValuesRequested,
  fetchInitialFormValuesSuccess,
  updateError,
};
