import { counsellingFormActions } from 'components/Profile/CounsellingFormExperiment/redux/CounsellingFormActions'
import { counsellingFormApis } from 'components/Profile/CounsellingFormExperiment/redux/CounsellingFormApis'
import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { isFunction } from 'utils/common_utils'

function* fetchProfile(): Generator<any, any, any> {
  try {
    const res = yield call(counsellingFormApis.fetchProfileData)
    if (res.data.success) {
      yield put(counsellingFormActions.fetchProfileSuccess(res.data.data))
    } else {
      yield put(counsellingFormActions.fetchProfileFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(counsellingFormActions.fetchProfileFail(e.message))
  }
}

function* saveProfile(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(counsellingFormActions.updateSaveCta(true))
    const res = yield call(counsellingFormApis.saveProfile, action.payload)
    if (res.data.success) {
      yield put(counsellingFormActions.saveProfileSuccess(res.data.data))
      yield put(counsellingFormActions.fetchEligibility())
      yield put(counsellingFormActions.updateSaveCta(false))
    } else {
      yield put(counsellingFormActions.updateSaveCta(false))
      yield put(counsellingFormActions.saveProfileFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(counsellingFormActions.updateSaveCta(false))
    yield put(counsellingFormActions.fetchProfileFail(e.message))
  }
}

function* fetchEligibility(): Generator<any, any, any> {
  try {
    const res = yield call(counsellingFormApis.checkEligibilty)
    if (res.data.success) {
      yield put(counsellingFormActions.fetchEligibilitySuccess(res.data.data))
      const callBackAfterFetchingEligibility = yield select(
        (state: any) => state.counsellingForm.postFetchEligibilityAction
      )
      if (isFunction(callBackAfterFetchingEligibility)) {
        callBackAfterFetchingEligibility(res.data.data)
      }
    } else {
      yield put(counsellingFormActions.fetchEligibilityFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(counsellingFormActions.fetchEligibilityFail(e.message))
  }
}

function* fetchIeltsNudgeData(): Generator<any, any, any> {
  try {
    const res = yield call(counsellingFormApis.fetchIeltsNudgeData)
    if (res.data.success) {
      yield put(
        counsellingFormActions.fetchIeltsNudgeDataSuccess(res.data.data)
      )
    } else {
      yield put(
        counsellingFormActions.fetchIeltsNudgeDataFail(res.data.message)
      )
    }
  } catch (e: any) {
    console.log(e)
    yield put(counsellingFormActions.fetchIeltsNudgeDataFail(e.message))
  }
}

function* saveIeltsNudgeData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      counsellingFormApis.saveIeltsNudgeData,
      action.payload
    )
    if (res.data.success) {
      yield put(counsellingFormActions.saveIeltsNudgeDataSuccess(res.data.data))
      yield put(counsellingFormActions.fetchEligibility())
      // yield put(counsellingFormActions.updateSaveCta(false));
    } else {
      yield put(counsellingFormActions.updateSaveCta(false))
      yield put(counsellingFormActions.saveProfileFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(counsellingFormActions.saveIeltsNudegeDataFail(e.message))
  }
}

function* counsellingFormSaga() {
  yield takeLatest('PROFILE_FETCH_REQUESTED', fetchProfile)
  yield takeLatest('SAVE_PROFILE_REQUESTED', saveProfile)
  yield takeLatest('ELIGIBILITY_FETCH_REQUESTED', fetchEligibility)
  yield takeLatest('FETCH_IELTS_NUDGE_DATA', fetchIeltsNudgeData)
  yield takeLatest('SAVE_IELTS_NUDGE_DATA', saveIeltsNudgeData)
}

export default counsellingFormSaga
