import { ReferalData } from "Interfaces/reducerTypes/referalSchema"
import api from "service/api"

const postReferalDetails = (refData: ReferalData) =>
  api.post(
    `/api/scholarRoute?path=ielts-elite/refer${
      refData.leadSource ? `?leadSource=${refData.leadSource}` : ""
    }`,
    refData,
  )
export const referalApis = { postReferalDetails }
