import {
  fastTrackActionVideoType,
  fastTrackVideoAction,
} from 'components/Profile/FastTrackVideo/redux/FastTrackVideoAction'
import { fastTrackVideoApi } from 'components/Profile/FastTrackVideo/redux/FastTrackVideoApi'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchFastTrackVideoStatus(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const res = yield call(
      fastTrackVideoApi.fetchFastTrackVideoApi,
      action.payload
    )
    if (res.success) {
      yield put(fastTrackVideoAction.fetchFastTrackVideoSuccess(res.data))
    } else {
      yield put(fastTrackVideoAction.fetchFastTrackVideoFail(res.data))
    }
  } catch (e: any) {
    console.error(e)
    yield put(
      fastTrackVideoAction.fetchFastTrackVideoFail('Something went wrong')
    )
    throw new Error(e.message)
  }
}

function* fetchFastTrackVideoMarkedWatch(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const res = yield call(
      fastTrackVideoApi.fetchFastTrackMarkVideoWatched,
      action.payload
    )
    if (res.success) {
      yield put(fastTrackVideoAction.watchedVideoSuccess(true))
    } else {
      yield put(fastTrackVideoAction.watchedVideoFail(res.data))
    }
  } catch (e: any) {
    console.error(e)
    yield put(fastTrackVideoAction.watchedVideoFail('Something went wrong'))
    throw new Error(e.message)
  }
}

function* fastTrackVideoSaga() {
  yield takeLatest(
    fastTrackActionVideoType.FETCH_FAST_TRACK_VIDEO_REQ,
    fetchFastTrackVideoStatus
  )
  yield takeLatest(
    fastTrackActionVideoType.FAST_TRACK_WATCH_VIDEO,
    fetchFastTrackVideoMarkedWatch
  )
}

export default fastTrackVideoSaga
