import api from "service/api"

const fetchMasterclassReplays = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=video/youtubeRecording`)
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

export const masterclassReplaysApis = {
  fetchMasterclassReplays,
}
