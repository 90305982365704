import {
  MockTestDataSchema,
  SectionSchema,
} from "Interfaces/reducerTypes/MockTestSchema";

const fetchMockTest = (payload?: number) => ({
  type: "FETCH_MOCK_TEST_REQUESTED",
  payload,
});

const fetchMockTestSuccess = (payload: MockTestDataSchema) => ({
  type: "FETCH_MOCK_TEST_SUCCESS",
  payload,
});

const updateError = (message: string) => ({
  type: "UPDATE_MOCK_TEST_ERROR",
  payload: message,
});

const updateView = (payload: string) => ({
  type: "UPDATE_MOCK_TEST_VIEW",
  payload,
});

const updateActiveSection = (payload: SectionSchema) => ({
  type: "UPDATE_MOCK_TEST_ACTIVE_SECTION",
  payload,
});

const updateActiveTestIndex = (payload: number) => ({
  type: "UPDATE_ACTIVE_MOCK_TEST_INDEX",
  payload,
});

const fetchEssayStatus = (payload: number) => ({
  type: "FETCH_ESSAY_STATUS_REQUESTED",
  payload,
});

const uploadWritingMockTest = (payload: File) => ({
  type: "UPLOAD_WRITING_MOCK_TEST",
  payload,
});

const toggleUploadMockTestStatus = (payload: boolean) => ({
  type: "TOGGLE_UPLOAD_MOCK_TEST_STATUS",
  payload,
});

const updateActiveMockTestId = (payload: number) => ({
  type: "UPDATE_ACTIVE_MOCK_TEST_ID",
  payload,
});

const fetchEssayList = () => ({
  type: "FETCH_ESSAY_LIST_REQUESTED",
});

const fetchEssayListSuccess = (payload: Array<any>) => ({
  type: "FETCH_ESSAY_LIST_SUCCESS",
  payload,
});

const replaceWritingMockTest = (payload: File) => ({
  type: "REPLACE_WRITING_MOCK_TEST",
  payload,
});

const fetchSampleMockTest = () => ({
  type: "FETCH_SAMPLE_MOCK_TEST_REQUESTED",
});

export const mockTestActions = {
  fetchMockTest,
  fetchMockTestSuccess,
  updateError,
  updateView,
  updateActiveSection,
  updateActiveTestIndex,
  fetchEssayStatus,
  uploadWritingMockTest,
  toggleUploadMockTestStatus,
  updateActiveMockTestId,
  fetchEssayList,
  fetchEssayListSuccess,
  replaceWritingMockTest,
  fetchSampleMockTest,
};
