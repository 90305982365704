import { call, put, takeLatest } from "redux-saga/effects";
import { facultyApis } from "components/Ielts/Faculty/redux/FacultyApi";
import { facultyActions } from "components/Ielts/Faculty/redux/FacultyActions";

function* fetchData(): Generator<any, any, any> {
  try {
    const res = yield call(facultyApis.fetchFaculties);
    yield put(facultyActions.fetchSuccess(res));
  } catch (e:any) {
    console.error(e);
    yield put(facultyActions.fetchFail(e.message));
  }
}

function* fetchDataClientSide(): Generator<any, any, any> {
  try {
    const res = yield call(facultyApis.fetchFacultiesClientSide);
    yield put(facultyActions.fetchSuccess(res));
  } catch (e:any) {
    console.error(e);
    yield put(facultyActions.fetchFail(e.message));
  }
}

function* facultiesSaga() {
  yield takeLatest("FETCH_FACULTIES_REQUESTED", fetchData);
  yield takeLatest("FETCH_FACULTIES_REQUESTED_CLIENT", fetchDataClientSide);
}

export default facultiesSaga;
