import { UpcomingClassSchema } from "Interfaces/reducerTypes/MyClassSchema";
import { WorkshopSchema } from "Interfaces/reducerTypes/IeltsPlusSchema";

export const upcomingType = {
  UPCOMING_CLASS_FETCH_REQUESTED: "UPCOMING_CLASS_FETCH_REQUESTED",
  LIVE_CLASS_FETCH_REQUESTED: "LIVE_CLASS_FETCH_REQUESTED",
  LIVE_MASTERCLASS_FETCH_REQUESTED: "LIVE_MASTERCLASS_FETCH_REQUESTED",
  UPCOMING_CLASS_FETCH_SUCCESS: "UPCOMING_CLASS_FETCH_SUCCESS",
  UPCOMING_CLASS_FETCH_FAIL: "UPCOMING_CLASS_FETCH_FAIL",
  LIVE_CLASS_FETCH_SUCCESS: "LIVE_CLASS_FETCH_SUCCESS",
  UPCOMING_EXTRA_CLASS_FETCH_SUCCESS: "UPCOMING_EXTRA_CLASS_FETCH_SUCCESS",
  SET_LIVE_MASTERCLASS: "SET_UPCOMING_MASTERCLASS",
  SET_LIVE_IELTS: "SET_UPCOMING_IELTS",
  FETCH_VOICE_ROOM_DATA: "FETCH_VOICE_ROOM_DATA",
  FETCH_VOICE_ROOM_SUCCESS: "FETCH_VOICE_ROOM_SUCCESS",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_ORIENTATION_CLASS: "SET_ORIENTATION_CLASS",
  SET_ORIENTATION_ENROLMENT: "SET_ORIENTATION_ENROLMENT",
  FETCH_ENHANCE_ENROLLMENT: "FETCH_ENHANCE_ENROLLMENT",
  GET_LIVE_CLASS_MEETING_LINK: "GET_LIVE_CLASS_MEETING_LINK",
};

const fetchUpcomingClassData = () => ({
  type: upcomingType.UPCOMING_CLASS_FETCH_REQUESTED,
});

const fetchUpcomingClassSuccess = (payload: UpcomingClassSchema) => ({
  type: upcomingType.UPCOMING_CLASS_FETCH_SUCCESS,
  payload,
});

const fetchUpcomingClassFail = (message: string) => ({
  type: upcomingType.UPCOMING_CLASS_FETCH_FAIL,
  payload: message,
});

const fetchLiveClassSuccess = (payload: Array<WorkshopSchema>) => ({
  type: upcomingType.LIVE_CLASS_FETCH_SUCCESS,
  payload,
});

const fetchUpcomingextraClassSuccess = (payload: Array<WorkshopSchema>) => ({
  type: upcomingType.UPCOMING_EXTRA_CLASS_FETCH_SUCCESS,
  payload,
});

const setUpcomingMasterclass = (payload: Array<WorkshopSchema>) => ({
  type: upcomingType.SET_LIVE_MASTERCLASS,
  payload,
});

const setUpcomingIelts = (payload: Array<WorkshopSchema>) => ({
  type: upcomingType.SET_LIVE_IELTS,
  payload,
});

const setOrientationClass = (payload: Array<WorkshopSchema>) => ({
  type: upcomingType.SET_ORIENTATION_CLASS,
  payload,
});

const fetchLiveClassData = () => ({
  type: upcomingType.LIVE_CLASS_FETCH_REQUESTED,
});

const fetchLiveMasterClassData = (payload?: number) => ({
  type: upcomingType.LIVE_MASTERCLASS_FETCH_REQUESTED,
  payload,
});

const fetchVoiceRoomData = () => ({
  type: upcomingType.FETCH_VOICE_ROOM_DATA,
});

const fetchVoiceRoomSuccess = (payload: any) => ({
  type: upcomingType.FETCH_VOICE_ROOM_SUCCESS,
  payload,
});

const setLoading = (payload: boolean) => ({
  type: upcomingType.SET_IS_LOADING,
  payload,
});

const setOrientationEnrollment = (payload: Array<WorkshopSchema>) => ({
  type: upcomingType.SET_ORIENTATION_ENROLMENT,
  payload,
});

const fetchEnhanceEnrollment = () => ({
  type: upcomingType.FETCH_ENHANCE_ENROLLMENT,
});

export const upcomingClassActions = {
  fetchUpcomingClassData,
  fetchUpcomingClassSuccess,
  fetchUpcomingClassFail,
  fetchLiveClassSuccess,
  fetchUpcomingextraClassSuccess,
  setUpcomingMasterclass,
  setUpcomingIelts,
  fetchLiveClassData,
  fetchLiveMasterClassData,
  fetchVoiceRoomData,
  fetchVoiceRoomSuccess,
  setLoading,
  setOrientationClass,
  setOrientationEnrollment,
  fetchEnhanceEnrollment,
};
