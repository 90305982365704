import { call, put, takeLatest } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { intermediateScreenApi } from "components/Profile/IntermediateScreen/redux/IntermediateScreenApi";
import { intermediateScreenActions } from "components/Profile/IntermediateScreen/redux/IntermediateScreenActions";
import { referAndEarnActions } from "components/Profile/ReferAndEarn/redux/ReferAndEarnActions";
import {
  eventName,
  userAttributeName,
  WEeventDataAttributeNames,
} from "DataMapper/EventTracker/EventData";
import { webUserTracker } from "webEngage";
import { eventTracker } from "eventTracker";
import { overviewActions } from "components/Profile/CounsellingProfile/redux/actions/overview-actions";

function* updateUserInfo(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      intermediateScreenApi.updateUserInfo,
      action.payload
    );
    if (res.success) {
      eventTracker(eventName.CHANGE_USER_DETAIL, {
        [WEeventDataAttributeNames.USER_NAME]: action.payload.name,
        [WEeventDataAttributeNames.USER_EMAIL]: action.payload.email,
      });
      const { WE_FIRST_NAME, WE_EMAIL } = userAttributeName;
      webUserTracker(WE_FIRST_NAME, action.payload.name);
      webUserTracker(WE_EMAIL, action.payload.email);
      yield put(referAndEarnActions.fetchUserInfo());
      yield put(overviewActions.fetchOverviewData())
      yield put(intermediateScreenActions.toggleView(false));
    }
  } catch (err:any) {
    console.log(err);
  }
}

function* intermediateScreenSaga() {
  yield takeLatest("UPDATE_USER_INFO_REQUESTED", updateUserInfo);
}

export default intermediateScreenSaga;
