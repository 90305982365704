import { QuizType } from 'components/Profile/IeltsProfile/QuizSection/redux/QuizType'
import {
  QuizQuestionSubmitPayloadDto,
  QuizSchema,
  QuizStatus,
  submittedQuestionDto,
} from 'Interfaces/reducerTypes/IeltsPlusQuizSchema'

const fetchQuizListReq = (batchId: string) => ({
  type: QuizType.FETCH_QUIZ_LIST,
  payload: batchId,
})

const fetchQuizListSuccess = (payload: Array<QuizSchema>) => ({
  type: QuizType.FETCH_QUIZ_LIST_SUCCESS,
  payload,
})

const updateCompleteQuizList = (payload: Array<QuizSchema>) => ({
  type: QuizType.UPDATE_COMPLETE_QUIZ_LIST,
  payload,
})

const updateNewQuizList = (payload: Array<QuizSchema>) => ({
  type: QuizType.UPDATE_NEW_QUIZ_LIST,
  payload,
})

const fetchQuizQuestionReq = (
  payload: string,
  isRetake = false,
  quizBundleId: number | null,
  batchId: number | null,
  callback = () => {}
) => ({
  type: QuizType.FETCH_QUIZ_QUESTION,
  payload,
  isRetake,
  quizBundleId,
  batchId,
  callback,
})

const fetchQuizQuestionSuccess = (payload: any) => ({
  type: QuizType.FETCH_QUIZ_QUESTION_SUCCESS,
  payload,
})

const updateQuizStatus = (payload: QuizStatus | null) => ({
  type: QuizType.UPDATE_QUIZ_STATUS,
  payload,
})

const updateQuizSubmittedAns = (payload: Array<submittedQuestionDto>) => ({
  type: QuizType.UPDATE_SUBMITTED_ANS,
  payload,
})

const updateError = (payload: string) => ({
  type: QuizType.UPDATE_ERROR,
  payload,
})

const startQuizTest = (
  payload: string,
  quizBundleId: number | null,
  batchId: number | null
) => ({
  type: QuizType.START_QUIZ_TEST,
  payload,
  quizBundleId,
  batchId,
})

const updateAttemptId = (payload: number | null) => ({
  type: QuizType.UPDATE_QUIZ_ATTEMPT_ID,
  payload,
})

const submitQuestion = (payload: QuizQuestionSubmitPayloadDto) => ({
  type: QuizType.SUBMIT_QUESTION_ANS,
  payload,
})

const updateSubmitModal = (payload: boolean) => ({
  type: QuizType.UPDATE_SUBMIT_MODAL,
  payload,
})

const updateExitModal = (payload: boolean) => ({
  type: QuizType.UPDATE_EXIT_MODAL,
  payload,
})

const updateQuizTestDate = (payload: Date) => ({
  type: QuizType.QUIZ_UPDATE_START_TEST_DATE,
  payload,
})

const updateQuizEndTestDate = (payload: Date) => ({
  type: QuizType.QUIZ_UPDATE_END_TEST_DATE,
  payload,
})

const endQuizTest = (payload: {
  quizId: string
  attemptId: number
  quizBundleId: number | null
}) => ({
  type: QuizType.END_QUIZ_TEST,
  payload,
})

const updateQuizSubmitSuccessfully = (payload: boolean) => ({
  type: QuizType.SUBMIT_SUCCESSFULLY_ACTION,
  payload,
})

const updateQuizTitle = (payload: boolean) => ({
  type: QuizType.UPDATE_QUIZ_TITLE,
  payload,
})

const fetchQuizModalType = (payload: string) => ({
  type: QuizType.FETCH_QUIZ_MODAL_TYPE,
  payload,
})

const fetchQuizModalTypeSuccess = (payload: any) => ({
  type: QuizType.FETCH_QUIZ_MODAL_TYPE_SUCCESS,
  payload,
})

const fetchQuizBundleListReq = (
  payload: { batchId: string; priority: number },
  callback = () => {}
) => ({
  type: QuizType.FETCH_QUIZ_BUNDLE_TYPE_REQUESTED,
  payload,
  callback,
})

const fetchQuizBundleListSuccess = (payload: any) => ({
  type: QuizType.FETCH_QUIZ_BUNDLE_TYPE_SUCCESS,
  payload,
})

export const quizActions = {
  fetchQuizListReq,
  fetchQuizListSuccess,
  updateError,
  updateCompleteQuizList,
  fetchQuizQuestionReq,
  fetchQuizQuestionSuccess,
  updateNewQuizList,
  updateQuizSubmittedAns,
  updateQuizStatus,
  startQuizTest,
  updateAttemptId,
  submitQuestion,
  updateSubmitModal,
  updateExitModal,
  endQuizTest,
  updateQuizTestDate,
  updateQuizSubmitSuccessfully,
  updateQuizEndTestDate,
  updateQuizTitle,
  fetchQuizModalType,
  fetchQuizModalTypeSuccess,
  fetchQuizBundleListReq,
  fetchQuizBundleListSuccess,
}
