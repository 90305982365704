import api from "service/api"

const fetchIeltsUserStates = () => api.get("/api/ielts/studentDashboard")

const fetchDemoDetails = (counsellingType: string) =>
  api.get(
    `/api/scholarRoute?path=counselling/slots/${counsellingType}/booked`,
    { headers: { "X-API-VERSION": "2" } },
  )

const fetchIeltsUserDetails = () =>
  api.get(`/api/scholarRoute?path=ielts-elite/onboarding-details`)

const fetchIeltsPaymentInfo = (batchId: string) =>
  api.get(`/api/scholarRoute?path=payment/details/${batchId}`)

const fetchIeltsEliteState = () =>
  api.get(`/api/scholarRoute?path=ielts-elite/stage`)

const fetchI2CEligibility = () =>
  api.get(`/api/scholarRoute?path=student/eligibility/i2c`)

export const ieltsScreenApi = {
  fetchIeltsUserStates,
  fetchDemoDetails,
  fetchIeltsUserDetails,
  fetchIeltsPaymentInfo,
  fetchIeltsEliteState,
  fetchI2CEligibility,
}
