import { FinancialCounsellingDataSchema } from "Interfaces/reducerTypes/FinancialCounsellingSchema";

const fetchFinancialCounsellingData = () => ({
  type: "FINANCIAL_COUNSELLING_FETCH_REQUESTED",
});

const fetchSuccess = (payload: FinancialCounsellingDataSchema) => ({
  type: "FINANCIAL_COUNSELLING_FETCH_SUCCEEDED",
  payload,
});

const fetchFail = (message: string) => ({
  type: "FINANCIAL_COUNSELLING_FETCH_FAILED",
  payload: message,
});

const updateJoinStatus = (payload: boolean) => ({
  type: "UPDATE_JOIN_STATUS",
  payload,
});

const updateCanAttendStatus = (payload: boolean) => ({
  type: "UPDATE_CAN_ATTEND_STATUS",
  payload,
});

const fetchFinancialCounsellingPageData = () => ({
  type: "FINANCIAL_COUNSELLING_PAGE_FETCH_REQUESTED",
});

const fetchFinancialCounsellingPageDataSuccess = (payload: boolean) => ({
  type: "FINANCIAL_COUNSELLING_PAGE_FETCH_SUCCEEDED",
  payload,
});

const fetchFinancialCounsellingPageDataFail = (message: string) => ({
  type: "FINANCIAL_COUNSELLING_PAGE_FETCH_FAILED",
  message,
});

export const financialCounsellingActions = {
  fetchFinancialCounsellingData,
  fetchSuccess,
  fetchFail,
  updateJoinStatus,
  updateCanAttendStatus,
  fetchFinancialCounsellingPageData,
  fetchFinancialCounsellingPageDataSuccess,
  fetchFinancialCounsellingPageDataFail,
};
