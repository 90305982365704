import { call, put, takeLatest } from "@redux-saga/core/effects";

import { AnyAction } from "redux";
import {
  joinMasterClassActions,
  joinMasterClassType,
} from "components/Profile/IeltsProfile/JoinMasterClass/redux/joinMasterClassActions";
import { joinMasterClassApi } from "components/Profile/IeltsProfile/JoinMasterClass/redux/joinMasterClassApi";

function* fetchRegisteredMeetingData(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const res = yield call(
      joinMasterClassApi.fetchRegisteredMeetingData,
      action.payload
    );
    if (res?.data?.success) {
      yield put(
        joinMasterClassActions.fetchRegisteredMeetingDataSuccess(
          res?.data?.data
        )
      );
    } else {
      if (
        res?.data?.message === "Not enrolled for master class" ||
        res?.data?.message === "Not enrolled for this class"
      ) {
        yield put(
          joinMasterClassActions.fetchRegisteredMeetingDatafail("notEnrolled")
        );
      } else if (
        res?.data?.message === "unable to get response at this moment"
      ) {
        yield put(
          joinMasterClassActions.fetchRegisteredMeetingDatafail("error")
        );
      } else {
        yield put(
          joinMasterClassActions.fetchRegisteredMeetingDatafail("ended")
        );
      }
    }
  } catch (err: any) {
    yield put(joinMasterClassActions.fetchRegisteredMeetingDatafail("error"));
  }
}

function* fetchIeltsDemoData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      joinMasterClassApi.fetchIeltsDemoData,
      action.payload
    );
    if (res?.data?.success) {
      yield put(joinMasterClassActions.setIeltsDemoData(res?.data?.data));
    } else {
      if (
        res?.data?.message === "Not enrolled for master class" ||
        res?.data?.message === "Not enrolled for this class"
      ) {
        yield put(
          joinMasterClassActions.fetchRegisteredMeetingDatafail("notEnrolled")
        );
      } else if (
        res?.data?.message === "unable to get response at this moment"
      ) {
        yield put(
          joinMasterClassActions.fetchRegisteredMeetingDatafail("error")
        );
      } else {
        yield put(
          joinMasterClassActions.fetchRegisteredMeetingDatafail("ended")
        );
      }
    }
  } catch (err: any) {
    yield put(joinMasterClassActions.fetchRegisteredMeetingDatafail("error"));
  }
}

function* joinMasterClassSaga() {
  yield takeLatest(
    joinMasterClassType.FETCH_REGISTERED_MEETING_DATA,
    fetchRegisteredMeetingData
  );

  yield takeLatest(
    joinMasterClassType.FETCH_IELTS_DEMO_DATA,
    fetchIeltsDemoData
  );
}

export default joinMasterClassSaga;
