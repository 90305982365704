export default {
  FETCH_OVERVIEW_DATA: "FETCH_OVERVIEW_DATA",
  FETCH_OVERVIEW_DATA_SUCCESS: "FETCH_OVERVIEW_DATA_SUCCESS",
  FETCH_OVERVIEW_DATA_ERROR: "FETCH_OVERVIEW_DATA_ERROR",

  TOGGLE_BOOK_COUNSELLING_MODAL: "TOGGLE_BOOK_COUNSELLING_MODAL",
  TOGGLE_SPEAK_TO_COUNSELLOR_MODAL: "TOGGLE_SPEAK_TO_COUNSELLOR_MODAL",
  TOGGLE_GET_LS_PLUS_MODAL: "TOGGLE_GET_LS_PLUS_MODAL",
  FETCH_COUNSELLING_FEE_DATA: "FETCH_COUNSELLING_FEE_DATA",
  FETCH_COUNSELLING_FEE_DATA_SUCCESS: "FETCH_COUNSELLING_FEE_DATA_SUCCESS",
  FETCH_COUNSELLING_FEE_DATA_ERROR: "FETCH_COUNSELLING_FEE_DATA_ERROR",

  FETCH_POST_PAYMENT_DATA: "FETCH_POST_PAYMENT_DATA",
  FETCH_POST_PAYMENT_DATA_SUCCESS: "FETCH_POST_PAYMENT_DATA_SUCCESS",
  FETCH_POST_PAYMENT_DATA_ERROR: "FETCH_POST_PAYMENT_DATA_ERROR",

  FETCH_OFFER: "FETCH_OFFER",
  FETCH_OFFER_SUCCESS: "FETCH_OFFER_SUCCESS",
  FETCH_OFFER_ERROR: "FETCH_OFFER_ERROR",

  TOGGLE_ALL_TASK_VIEW: "TOGGLE_ALL_TASK_VIEW",

  FETCH_PLAYLIST:"FETCH_PLAYLIST",
  FETCH_PLAYLIST_SUCCESS:"FETCH_PLAYLIST_SUCCESS",
  FETCH_PLAYLIST_FAIL:"FETCH_PLAYLIST_FAIL"
};
