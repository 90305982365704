import { saveUtmData } from "utils/utm_utils"

interface UTMValues {
    [key: string]: string
}

export const captureAndSaveUtmWhileLoginIn = async () => {
    const pathName: string = window.location.pathname || ""
    const getDefaultUtmValues = (): UTMValues => {
        try {
            const previousPageUrl = document.referrer || "";
            if (previousPageUrl?.includes(`${process.env.DOMAIN_NAME}/blog`)) {
                return {
                    utmMedium: "Blogs",
                    utmSource: previousPageUrl?.includes("ielts")
                        ? "IELTS_SEO"
                        : "Counselling_SEO",
                    utmCampaign: previousPageUrl?.includes("ielts")
                        ? "IELTS_Blog"
                        : "Counselling_Blog",
                    utmTerm: previousPageUrl,
                }
            }

            if (pathName === "/") {
                return {
                    utmMedium: "Homepage",
                    utmSource: "Direct",
                    utmCampaign: "Direct",
                    utmTerm: `${process.env.DOMAIN_NAME}${pathName}`,
                }
            }
            if (previousPageUrl?.includes(`${process.env.DOMAIN_NAME}/exams`)) {
                let utmCampaign = "General_Pages"
                const pageTypes: { [key: string]: string } = {
                    "/ielts/practice-test/speaking": "Speaking_Pages",
                    "/ielts/practice-test/reading": "Reading_Pages",
                    "/ielts/practice-test/writing": "Writing_Pages",
                    "/ielts/practice-test/listening": "Listening_Pages",
                    "/exams/gre": "Gre",
                    "/exams/toefl": "Toefl",
                    "/exams/pte": "Pte",
                    "/exams/gmat": "Gmat",
                    "/exams/duolingo": "Duolingo",
                    "/exams/sat": "Sat",
                }

                Object.entries(pageTypes).forEach(([path, campaign]) => {
                    if (previousPageUrl.includes(path)) {
                        utmCampaign = campaign
                    }
                })

                const isExamPage = [
                    "/exams/toefl",
                    "/exams/gre",
                    "/exams/pte",
                    "/exams/gmat",
                    "/exams/duolingo",
                    "/exams/sat",
                ]
                const matchesPattern = isExamPage.some((pattern) =>
                    previousPageUrl.includes(`${process.env.DOMAIN_NAME}${pattern}`),
                )
                return {
                    utmCampaign,
                    utmSource: matchesPattern ? "Other_Exams_SEO" : "IELTS_SEO",
                    utmMedium: "Pillar_Pages",
                    utmTerm: previousPageUrl,
                }
            }

            return {}
        } catch (err) {
            console.error(err)
            return {}
        }
    }

    try {
        const urlParams = new URLSearchParams(window.location.search)
        const utmParamsMap: { [key: string]: string } = {
            utm_source: "utmSource",
            utm_medium: "utmMedium",
            utm_campaign: "utmCampaign",
            utm_term: "utmTerm",
        }

        const utmData: { [key: string]: string } = {}

        Object.keys(utmParamsMap).forEach((param) => {
            const value = urlParams.get(param)
            if (value !== null) {
                utmData[utmParamsMap[param]] = value
            }
        })

        const defaultUtmValues = getDefaultUtmValues()
        Object.entries(defaultUtmValues).forEach(([key, value]) => {
            if (!utmData[key]) utmData[key] = value
        })
        if (Object.keys(utmData).length > 0) {
            saveUtmData({}, { ...utmData })
        }
    } catch (err) {
        console.error(err)
    }
}
