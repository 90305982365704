import { AnyAction } from 'redux'
import { IeltsNpsSchema } from 'Interfaces/reducerTypes/IeltsNpsSchema'

const initialState: IeltsNpsSchema = {
  showNps: false,
  npsData: null,
  error: '',
  currentQuestion: null,
  npsId: '',
  questionType: 'rating',
  questionTitle: '',
  submitRating: null,
}

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'FETCH_NPS_STATUS_SUCCESS':
      return {
        ...state,
        npsData: action.payload,
      }
    case 'UPDATE_ERROR_MESSAGE':
      return {
        ...state,
        error: action.payload,
      }
    case 'UPDATE_NPS_QUESTION':
      return {
        ...state,
        currentQuestion: action.payload,
      }
    case 'UPDATE_NPS_STATUS':
      return {
        ...state,
        showNps: action.payload,
      }
    case 'UPDATE_NPS_ID':
      return {
        ...state,
        npsId: action.payload,
      }
    case 'UPDATE_QUESTION_TYPE':
      return {
        ...state,
        questionType: action.payload,
      }
    case 'UPDATE_QUESTION_TITLE':
      return {
        ...state,
        questionTitle: action.payload,
      }
    case 'UPDATE_SUBMIT_RATING':
      return {
        ...state,
        submitRating: action.payload,
      }
    default:
      return state
  }
}

export default reducer
