import api from "service/api"

const fetchInitialFormValues = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=workshop/master-class`)
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

export const registrationFormApi = {
  fetchInitialFormValues,
}
