import api from "service/api"
import { ValidationPayload } from "components/Ielts/PromoCode"

const fetchPromoCodeValidation = async (payload: ValidationPayload) => {
  try {
    const res = await api.post(
      `/api/scholarOpenRoute?path=promocode/apply/${payload.promoCode}`,
      {
        bundleId: payload.bundleId,
      },
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

export const promoCodeApi = {
  fetchPromoCodeValidation,
}
