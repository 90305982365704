import api from "service/api"

const fetchStudyMaterial = async (batchId: string) => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=workshop/study-material/section/${batchId}`,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please contact the teach team")
  }
}

const fetchFastTrackStudyMaterial = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=workshop/study-material/global-sections`,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please contact the teach team")
  }
}

export const studyMaterialApi = {
  fetchStudyMaterial,
  fetchFastTrackStudyMaterial,
}
