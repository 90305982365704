import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { trackPage } from 'scripts/segment'

const FourOhFour = () => {
  const router = useRouter()

  useEffect(() => {
    trackPage('404 Page not found' + router.asPath)
  }, [])

  return (
    <div className="flex items-center justify-center w-full h-[90vh] m-auto bg-white">
      <div className="flex flex-col text-gray-700 lg:flex-row m-auto">
        <div className="order-1 max-w-md px-2 text-sm md:text-base lg:px-0 md:mt-[4rem]">
          <header className="mb-2">
            <h2 className="text-4xl font-bold leading-none text-gray-400 select-none lg:text-6xl">
              404.
            </h2>
            <h3 className="text-xl font-light leading-normal lg:text-3xl md:text-3xl">
              Looks like you're lost
            </h3>
          </header>
          <p className="max-w-sm mb-[32px] leading-5 md:leading-7">
            We can't find the page you're looking for. You can find plenty of
            other things on our homepage.
          </p>
          <a
            href="/"
            className="p-4 text-base font-medium leading-5 text-white rounded shadow bg-primary"
          >
            Back to Homepage
          </a>
        </div>
        <div className="max-w-lg">
          <img src="https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif" />
        </div>
      </div>
    </div>
  )
}

export default FourOhFour
