import { apoloClient } from "apollo/apollo"
import semLandingQuery from "apollo/queries/semPageDetail"
import api from "service/api"

const fetchSemPageDetails = async () => {
  try {
    const data = await apoloClient.query({
      query: semLandingQuery,
    })
    return data
  } catch (e: any) {
    console.log(e)
  }
}

const saveSemForm = async (values: Record<string, any>) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=preferences/semlead`,
      values,
    )
    return res.data
  } catch (err) {
    console.log(err)
  }
}

export const semPageApi = {
  fetchSemPageDetails,
  saveSemForm,
}
