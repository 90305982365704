import { AnyAction } from "redux";
import UpcomingAppointmentsConstants from "../constants/upcoming-appointments-constants";

const initialState: any = {
  upcomingAppointmentData: [],
  error: "",
  isLoading: false,
  isError: false,
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case UpcomingAppointmentsConstants.FETCH_UPCOMING_APPOINTMENTS_DATA:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case UpcomingAppointmentsConstants.FETCH_UPCOMING_APPOINTMENTS_DATA_SUCCESS:
      return {
        ...state,
        upcomingAppointmentData: action.payload,
        isLoading: false,
        isError: false,
      };
    case UpcomingAppointmentsConstants.FETCH_UPCOMING_APPOINTMENTS_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};

export default reducer;
