import api from "service/api"

const zohoRecord = async (data: any) => {
  try {
    const res = await api.post(`/api/scholarRoute?path=user/zoho-record`, data)

    if (res?.data?.success) {
      return { success: true, payload: res.data.data }
    }
    return { success: false, payload: res.data.message }
  } catch (e: any) {
    throw new Error("Something went wrong. Please refresh the page")
  }
}

export const NeedHelpApis = {
  zohoRecord,
}
