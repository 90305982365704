import { referAndEarnActions } from 'components/Profile/ReferAndEarn/redux/ReferAndEarnActions'
import { referAndEarnApi } from 'components/Profile/ReferAndEarn/redux/ReferAndEarnApi'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchData(): Generator<any, any, any> {
  try {
    const userDetails = yield call(referAndEarnApi.fetchUserDetails)
    yield put(referAndEarnActions.fetchSuccess(userDetails))
  } catch (e: any) {
    console.error(e)
    yield put(referAndEarnActions.fetchFail(e.message))
  }
}

function* referAndEarnSaga() {
  yield takeLatest('USER_DETAILS_FETCH_REQUESTED', fetchData)
}

export default referAndEarnSaga
