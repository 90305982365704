import { tofuObordingActions } from 'components/TofuOnbordingForms/redux/Actions'
import { tofuOnbordingApis } from 'components/TofuOnbordingForms/redux/Api'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchSpotCounsellingFromData(): Generator<any, any, any> {
  try {
    const res = yield call(tofuOnbordingApis.fetchSpotCounsellingFromData)
    if (res.success) {
      yield put(
        tofuObordingActions.fetchSpotCounsellingFromDataSuccess(res.data)
      )
    } else {
      yield put(tofuObordingActions.fetchSpotCounsellingFromDataFail(res))
    }
  } catch (e: any) {
    yield put(tofuObordingActions.fetchSpotCounsellingFromDataFail(e.message))
  }
}

function* tofuOnbordingSaga() {
  yield takeLatest('FETCH_SPOT_FORM_DATA', fetchSpotCounsellingFromData)
}

export default tofuOnbordingSaga
