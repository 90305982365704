import api from "service/api"
async function fetchMockTestAutoLogin() {
  try {
    const res = await api.get(`/api/scholarRoute?path=mocktest/tcy/autologin`, {
      headers: {
        "X-API-VERSION": "1",
      },
    })
    if (res.data?.success) return { success: true, data: res.data.data }
    else return { success: false, data: res.data.message }
  } catch (error) {
    return { success: false, data: "Something Went Wrong" }
  }
}

async function getMockTestStatus() {
  try {
    const res = await api.get(`/api/scholarRoute?path=fee/cb-mocktest/status`, {
      headers: {
        "X-API-VERSION": "1",
      },
    })
    if (res.data?.success) return { success: true, data: res.data.data }
    else return { status: false, data: res.data.message }
  } catch (error) {
    return { success: false, data: "Something Went Wrong" }
  }
}

async function getTcyBannerStatus() {
  try {
    const res = await api.get(`/api/scholarRoute?path=mocktest/tcy/autologin`, {
      headers: {
        "X-API-VERSION": "1",
      },
    })
    if (res.data?.success) return { success: true, data: res.data.data }
    else return { status: false, data: res.data.message }
  } catch (error) {
    return { success: false, data: "Something Went Wrong" }
  }
}
export const MockTestPriceApi = {
  fetchMockTestAutoLogin,
  getMockTestStatus,
  getTcyBannerStatus,
}
