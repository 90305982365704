import { AnyAction } from 'redux'
import { IeltsTestReducer } from 'Interfaces/reducerTypes/IeltsTestSchema'

const initialState: IeltsTestReducer = {
  showTestForm: false,
  status: '',
  showIeltsSubmissionSection: false,
  submissionTime: '',
  showSubmittedDocsModal: false,
  screen: '',
  rewardsArray: null,
  userStatus: false,
  showIeltsTestBanner: false,
}

const mockTestReducer = (
  state: IeltsTestReducer = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case 'TOGGLE_IELTS_TEST_FORM':
      return {
        ...state,
        showTestForm: action.payload,
      }
    case 'UPDATE_IELTS_TEST_SUBMISSION_STATUS':
      return {
        ...state,
        status: action.payload,
      }
    case 'TOGGLE_SHOW_IELTS_TEST_SUBMISSION_SECTION':
      return {
        ...state,
        showIeltsSubmissionSection: action.payload,
      }
    case 'UPDATE_SUBMISSION_TIME':
      return {
        ...state,
        submissionTime: action.payload,
      }
    case 'TOGGLE_SHOW_SUBMITTED_DOCS_MODAL':
      return {
        ...state,
        showSubmittedDocsModal: action.payload,
      }
    case 'SET_SCREEN':
      return {
        ...state,
        screen: action.payload,
      }
    case 'SET_REWARDS':
      return {
        ...state,
        rewardsArray: action.payload,
      }
    case 'SET_USER_STATUS':
      return {
        ...state,
        userStatus: action.payload,
      }
    case 'FETCH_IELTS_TEST_BANNER_STATUS_SUCCESS':
      return {
        ...state,
        showIeltsTestBanner: action.payload,
      }
    default:
      return state
  }
}

export default mockTestReducer
