import { onboardingActions } from 'components/Onboarding/redux/OnboardingActions'
import { onboardingApi } from 'components/Onboarding/redux/OnboardingApis'
import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { createOptionsObject } from 'utils/common-utils'

function* submitFirstStep(action: AnyAction): Generator<any, any, any> {
  const getStep = (state: any) => state.onboardingReducer.activeStep
  const activeStep = yield select(getStep)

  try {
    const res = yield call(onboardingApi.submitFirstStepRequest, action.payload)
    if (res.success) {
      yield put(onboardingActions.submitFirstStepSuccess(res.data))
      yield put(onboardingActions.setComplete(activeStep))
      yield put(onboardingActions.handleStep(activeStep + 1))
      yield put(onboardingActions.setCurrentProgress(10))
    } else {
      yield put(onboardingActions.submitFirstStepError(res.message))
    }
  } catch (e: any) {
    yield put(
      onboardingActions.submitFirstStepError(
        'Something went wrong. Please try after sometime.'
      )
    )
    console.error(e)
  }
}

function* fetchInitialData(): Generator<any, any, any> {
  const getProgressArray = (state: any) =>
    state.onboardingReducer.percentCompletionList
  const progressArray = yield select(getProgressArray)

  try {
    const res = yield call(onboardingApi.fetchInitialDataRequest)
    if (res.data.success) {
      yield put(onboardingActions.fetchInitialDataSuccess(res.data.data))
      yield put(
        onboardingActions.setCurrentProgress(
          Number(progressArray[res?.data?.data?.stepsCompleted]) || Number(5)
        )
      )
      let i: any
      if (res?.data?.data?.stepsCompleted > 0) {
        for (i = 0; i <= res?.data?.data?.stepsCompleted; i++) {
          yield put(onboardingActions.setComplete(i))
        }
        yield put(
          onboardingActions.handleStep(res?.data?.data?.stepsCompleted || 0)
        )
      } else {
        yield put(
          onboardingActions.handleStep(res?.data?.data?.stepsCompleted || 0)
        )
      }
    } else {
      yield put(onboardingActions.fetchInitialDataError(res.message))
    }
  } catch (e: any) {
    yield put(
      onboardingActions.fetchInitialDataError(
        'Something went wrong. Please try after sometime.'
      )
    )
    console.error(e)
  }
}

function* submitEducationDetails(action: AnyAction): Generator<any, any, any> {
  const getStep = (state: any) => state.onboardingReducer.activeStep
  const activeStep = yield select(getStep)

  try {
    const res = yield call(
      onboardingApi.submitEducationDetailsRequest,
      action.payload
    )
    if (res.success) {
      yield put(onboardingActions.submitEducationDetailsSuccess(res.data))
      yield put(onboardingActions.handleStep(activeStep + 1))
      yield put(onboardingActions.setComplete(activeStep))
      yield put(onboardingActions.setCurrentProgress(80))
    } else {
      yield put(onboardingActions.submitEducationDetailsError(res.message))
    }
  } catch (e: any) {
    yield put(
      onboardingActions.submitEducationDetailsError(
        'Something went wrong. Please try after sometime.'
      )
    )
    console.error(e)
  }
}

function* submitExamFormData(action: AnyAction): Generator<any, any, any> {
  const getStep = (state: any) => state.onboardingReducer.activeStep
  const activeStep = yield select(getStep)

  try {
    const res = yield call(onboardingApi.submitExamFormRequest, action.payload)
    const getAddedCountries = (state: any) =>
      state.onboardingReducer.addedCountries
    const addedCountries = yield select(getAddedCountries)

    const getIeltsNotInitiatedStatus = (state: any) =>
      state.onboardingReducer.isIeltsNotInitiated
    const isIeltsNotInitiated = yield select(getIeltsNotInitiatedStatus)

    if (res.success) {
      yield put(onboardingActions.submitExamFormDataSuccess(res.data))
      yield put(onboardingActions.setCurrentProgress(100))

      if (
        (addedCountries?.length === 1 && addedCountries?.includes('UK')) ||
        (action.payload?.examDetails?.examType === 'IELTS' &&
          action.payload?.examDetails?.examStatus !== 'NOT_DECIDED' &&
          action.payload?.examDetails?.examStatus !== 'PLANING_TO_BOOK')
      ) {
        yield put(onboardingActions.handleStep(activeStep + 1))
        yield put(onboardingActions.setComplete(activeStep))
        yield put(onboardingActions.toggleSuccessModal(true))
      } else if (
        action.payload?.examDetails?.examType === 'IELTS' &&
        isIeltsNotInitiated
      ) {
        yield put(onboardingActions.setIeltsRequired(true))
      } else {
        yield put(onboardingActions.handleStep(activeStep + 1))
        yield put(onboardingActions.setComplete(activeStep))
        yield put(onboardingActions.toggleSuccessModal(true))
      }
    } else {
      yield put(onboardingActions.submitExamFormDataError(res.message))
    }
  } catch (e: any) {
    yield put(
      onboardingActions.submitEducationDetailsError(
        'Something went wrong. Please try after sometime.'
      )
    )
    console.error(e)
  }
}

function* submitCollegeForms(): Generator<any, any, any> {
  const getStep = (state: any) => state.onboardingReducer.activeStep
  const activeStep = yield select(getStep)
  const getCountryFormData = (state: any) =>
    state.onboardingReducer.countryFormData
  const countryFormData = yield select(getCountryFormData)
  try {
    const res = yield call(
      onboardingApi.submitCollegeFormsRequest,
      countryFormData
    )
    if (res.success) {
      yield put(onboardingActions.submitCollegeFormsSuccess(res.data))
      yield put(onboardingActions.handleStep(activeStep + 1))
      yield put(onboardingActions.setComplete(activeStep))
      yield put(onboardingActions.setCurrentProgress(50))
    } else {
      yield put(onboardingActions.submitCollegeFormsError(res.message))
    }
  } catch (e: any) {
    yield put(
      onboardingActions.submitCollegeFormsError(
        'Something went wrong. Please try after sometime.'
      )
    )
    console.error(e)
  }
}

function* fetchCitiesFromCountry(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      onboardingApi.fetchCityListFromCountry,
      action.payload
    )
    if (res.data.success) {
      const cities = createOptionsObject(res.data.data)
      yield put(onboardingActions.fetchCityFromCountrySuccess(cities))
    } else {
      yield put(onboardingActions.fetchCityFromCountryError(res.data.message))
    }
  } catch (e: any) {
    yield put(onboardingActions.fetchCityFromCountryError(e.message))
  }
}

function* fetchPreferredProgramsList(): Generator<any, any, any> {
  try {
    const res = yield call(onboardingApi.fetchPreferredPrograms)
    if (res.success) {
      yield put(onboardingActions.fetchPreferredProgramsSuccess(res.data))
    } else {
      yield put(onboardingActions.fetchPreferredProgramsError(res.data.message))
    }
  } catch (e: any) {
    yield put(onboardingActions.fetchPreferredProgramsError(e?.message))
  }
}
function* getPreferredLocationLists(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const res = yield call(
      onboardingApi.getPreferredLocationLists,
      action.payload
    )
    if (res?.data?.success) {
      yield put(onboardingActions.getPreferredLocationListsSuccess(res))
    } else {
      yield put(
        onboardingActions.getPreferredLocationListsError(res.data.message)
      )
    }
  } catch (e: any) {
    yield put(onboardingActions.getPreferredLocationListsError(e?.message))
  }
}
function* getPreferredProgramCourseLists(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const res = yield call(
      onboardingApi.getPreferredProgramCourseLists,
      action.payload
    )
    if (res?.data?.success) {
      yield put(onboardingActions.getPreferredProgramCourseListsSuccess(res))
    } else {
      yield put(
        onboardingActions.getPreferredProgramCourseListsError(res.data.message)
      )
    }
  } catch (e: any) {
    yield put(onboardingActions.getPreferredProgramCourseListsError(e?.message))
  }
}

function* getPreferredCountryIdLists(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const res = yield call(onboardingApi.getPreferredCountryIdLists)
    if (res?.data?.success) {
      yield put(
        onboardingActions.getPreferredCountryIdListsSuccess([
          res,
          action.payload,
        ])
      )
    } else {
      yield put(
        onboardingActions.getPreferredCountryIdListsError(res.data.message)
      )
    }
  } catch (e: any) {
    yield put(onboardingActions.getPreferredCountryIdListsError(e?.message))
  }
}

function* deleteCountryFromCountryFormData(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const res = yield call(
      onboardingApi.deleteCountryFromFormData,
      action.payload
    )
    if (res.data.success) {
      yield put(
        onboardingActions.deleteCountryFromFormDataSuccess(res.data.data)
      )
    } else {
      yield put(
        onboardingActions.deleteCountryFromFormDataError(res.data.message)
      )
    }
  } catch (e: any) {
    yield put(onboardingActions.deleteCountryFromFormDataError(e?.message))
  }
}

function* onboardingSaga() {
  yield takeLatest('SUBMIT_FIRST_STEP', submitFirstStep)
  yield takeLatest('FETCH_INITIAL_DATA', fetchInitialData)
  yield takeLatest('SUBMIT_EDUCATION_DETAILS', submitEducationDetails)
  yield takeLatest('SUBMIT_EXAM_FORM_DATA', submitExamFormData)
  yield takeLatest('SUBMIT_COLLEGE_FORMS', submitCollegeForms)
  yield takeLatest('FETCH_CITY_FROM_COUNTRY', fetchCitiesFromCountry)
  yield takeLatest(
    'DELETE_COUNTRY_FROM_FORM_DATA',
    deleteCountryFromCountryFormData
  )
  yield takeLatest('FETCH_PREFERRED_PROGRAMS', fetchPreferredProgramsList)
  yield takeLatest('GET_PREFERRED_LOCATION_LISTS', getPreferredLocationLists)
  yield takeLatest('GET_PREFERRED_COUNTRY_ID_LISTS', getPreferredCountryIdLists)
  yield takeLatest(
    'GET_PREFERRED_PROGRAM_COURSE_LISTS',
    getPreferredProgramCourseLists
  )
}

export default onboardingSaga
