import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SubscriptionType } from 'Interfaces/reducerTypes/IeltsPlusSchema'

const DemoRewardsSliceName = 'DemoRewards'
export interface ScratchSubscriptionData {
  startDate: string
  endDate: string
  isActive: Boolean
}
export interface DemoRewardsSchema {
  isScratchModalOpen: boolean
  scratchSubscriptionData: ScratchSubscriptionData | null
  whatsappTipsData: ScratchSubscriptionData | null
  whatsappTipsStatus: boolean
  scratchCardStatus: boolean
}
const initialState: DemoRewardsSchema = {
  isScratchModalOpen: false,
  scratchSubscriptionData: null,
  whatsappTipsData: null,
  whatsappTipsStatus: false,
  scratchCardStatus: false,
}

const DemoRewardsSlice = createSlice({
  name: DemoRewardsSliceName,
  initialState,
  reducers: {
    setIsScratchModalOpen(state, action: PayloadAction<boolean>) {
      return { ...state, isScratchModalOpen: action.payload }
    },
    setScratchSubscriptionData(
      state,
      action: PayloadAction<ScratchSubscriptionData | null>,
    ) {
      return { ...state, scratchSubscriptionData: action.payload }
    },
    setWhatsappTipsData(
      state,
      action: PayloadAction<ScratchSubscriptionData | null>,
    ) {
      return { ...state, whatsappTipsData: action.payload }
    },
    setWhatsappTipsStatus(state, action: PayloadAction<boolean>) {
      return { ...state, whatsappTipsStatus: action.payload }
    },
    setScratchCardStatus(state, action: PayloadAction<boolean>) {
      return { ...state, scratchCardStatus: action.payload }
    },
  },
})

export const fetchScratchCardStatusAction = createAction<SubscriptionType>(
  `${DemoRewardsSliceName}/fetchScratchCardStatus`,
)

export const fetchWhatsappTipsDataAction = createAction<SubscriptionType>(
  `${DemoRewardsSliceName}/fetchWhatsappTipsData`,
)

export const updateScratchCardStatusAction = createAction(
  `${DemoRewardsSliceName}/updateScratchCardStatus`,
)

export const updateTipsTricksStatusAction = createAction<boolean>(
  `${DemoRewardsSliceName}/updateTipsTricksStatus`,
)

export const {
  setIsScratchModalOpen,
  setScratchSubscriptionData,
  setWhatsappTipsData,
  setWhatsappTipsStatus,
  setScratchCardStatus,
} = DemoRewardsSlice.actions

export default DemoRewardsSlice.reducer
