export default {
  SET_LOADING_TRUE: "SET_LOADING_TRUE",
  SET_LOADING_FALSE: "SET_LOADING_FALSE",

  FETCH_USER_GETSOCIAL_TOKEN: "FETCH_USER_GETSOCIAL_TOKEN",
  FETCH_USER_GETSOCIAL_TOKEN_SUCCESS: "FETCH_USER_GETSOCIAL_TOKEN_SUCCESS",
  FETCH_USER_GETSOCIAL_TOKEN_FAIL: "FETCH_USER_GETSOCIAL_TOKEN_FAIL",

  SET_GETSOCIAL_USER_PROFILE: "SET_GETSOCIAL_USER_PROFILE",

  SET_FOLLOWED_TOPICS: "SET_FOLLOWED_TOPICS",
  SET_ALL_TOPICS: "SET_ALL_TOPICS",
  SET_TIMELINE_POSTS: "SET_TIMELINE_POSTS",
  SET_SEARCH_POSTS_RESULT: "SET_SEARCH_POSTS_RESULT",
  SET_SUGGESTED_POSTS_RESULT: "SET_SUGGESTED_POSTS_RESULT",
  SET_SINGLE_FEED_VIWE_DATA: "SET_SINGLE_FEED_VIWE_DATA",
  SET_SELECTED_TOPICS_FOR_FEED: "SET_SELECTED_TOPICS_FOR_FEED",
};
