import { AnyAction } from "redux";
import CommunityConstants from "./CommunityConstants";

const initialState = {
  isLoading: false,
  userDetailsWithToken:null,
  userGetSocialProfile: null,
  
  followedTopics: null,
  allTopics: null,
  timelinePosts: null,
  singleFeedData: null,
  searchPostsResults: null,
  suggestedPostsResults: null,
  isError: false,
  selectedTopicForFeed: null,
};

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case CommunityConstants.SET_LOADING_TRUE:
      return {
        ...state,
        isLoading: true,
      };
    case CommunityConstants.SET_LOADING_FALSE:
      return {
        ...state,
        isLoading: false,
      };
    case CommunityConstants.FETCH_USER_GETSOCIAL_TOKEN:
      return {
        ...state,
        isLoading: true,
      };
    case CommunityConstants.FETCH_USER_GETSOCIAL_TOKEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetailsWithToken: action.payload,
      };
    case CommunityConstants.FETCH_USER_GETSOCIAL_TOKEN_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case CommunityConstants.SET_GETSOCIAL_USER_PROFILE:
      return {
        ...state,
        userGetSocialProfile: action.payload,
      };
    case CommunityConstants.SET_FOLLOWED_TOPICS:
      return {
        ...state,
        followedTopics: action.payload,
      };
    case CommunityConstants.SET_ALL_TOPICS:
      return {
        ...state,
        allTopics: action.payload,
      };
    case CommunityConstants.SET_TIMELINE_POSTS:
      return {
        ...state,
        timelinePosts: action.payload,
      };
    case CommunityConstants.SET_SINGLE_FEED_VIWE_DATA:
      return {
        ...state,
        singleFeedData: action.payload,
      };
    case CommunityConstants.SET_SEARCH_POSTS_RESULT:
      return {
        ...state,
        searchPostsResults: action.payload,
      };
    case CommunityConstants.SET_SELECTED_TOPICS_FOR_FEED:
      return {
        ...state,
        selectedTopicForFeed: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
