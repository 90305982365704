import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { essayApi } from "components/Profile/IeltsProfile/EssayEvaluation/redux/EssayApi";
import {
  essayType,
  essayAction,
} from "components/Profile/IeltsProfile/EssayEvaluation/redux/EssayActions";

function* uploadEssaySaga(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(essayApi.uploadEssayApi, action.payload);
    if (res.success) {
      yield put(essayAction.uploadEssaySuccess(res.payload));
    } else {
      yield put(essayAction.uploadEssayFail(res.payload));
    }
  } catch (e:any) {
    yield put(essayAction.uploadEssayFail(e.message));
  }
}

function* replaceEssaySaga(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(essayApi.replaceEssayApi, action.payload);
    if (res.success) {
      yield put(essayAction.replaceEssaySuccess(res.payload));
    } else {
      yield put(essayAction.replaceEssayFail(res.payload));
    }
  } catch (e:any) {
    yield put(essayAction.replaceEssayFail(e.message));
  }
}

function* fetchEssaySaga(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(essayApi.fetchEssayApi, action.payload);
    if (res.success) {
      yield put(essayAction.fetchEssaySuccess(res.payload));
    } else {
      yield put(essayAction.fetchEssayFail(res.payload));
    }
  } catch (e:any) {
    yield put(essayAction.fetchEssayFail(e.message));
  }
}

function* essaySaga() {
  yield takeLatest(essayType.UPLOAD_ESSAY_REQ, uploadEssaySaga);
  yield takeLatest(essayType.ESSAY_FETCH_REQ, fetchEssaySaga);
  yield takeLatest(essayType.REPLACE_ESSAY_REQ, replaceEssaySaga);
}

export default essaySaga;
