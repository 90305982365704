import api from "service/api"
import { getPlatformV2 } from "utils/events-utils"

const fetchNpsStatus = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=nps/eligible`, {
      headers: {
        "X-API-VERSION": 2,
      },
    })
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const fetchFastTrackNpsStatus = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=nps/eligible/fast_track`)
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const submitRatings = async (data: any) => {
  let pathName = ""
  if (data.pathName) {
    pathName = `${window.location.pathname}/${data.pathName}`
  }
  try {
    const body = {
      rating: data.ratings,
      originatingUrl: pathName || window.location.pathname,
      isSkipped: data?.isSkipped || false,
    }
    const res = await api.post(`/api/scholarRoute?path=nps/rating`, body, {
      headers: {
        "X-API-VERSION": 2,
        "X-PLATFORM": getPlatformV2(),
      },
    })
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const submitForm = async (values: Record<string, any>) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=nps/supporting_answer`,
      values,
      {
        headers: {
          "X-API-VERSION": 2,
          "X-PLATFORM": getPlatformV2(),
        },
      },
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

export const npsApis = {
  fetchNpsStatus,
  submitRatings,
  submitForm,
  fetchFastTrackNpsStatus,
}
