import api from "service/api"
import { WritingExamPreference } from "components/Profile/IeltsProfile/TestPrep/DataMapper"

const fetchMockTestList = (examType: string) =>
  api.get(`/api/scholarRoute?path=test-prep/dashboard/${examType}`, {
    headers: {
      "X-API-VERSION": "7",
    },
  })

const fetchMockTest = (examId: string) =>
  api.get(`/api/scholarRoute?path=test-prep/card/${examId}`)

const fetchModalDataCms = () =>
  api.get(`${process.env.CMS_URL}/mocktest-modals?status_eq=ACTIVE`)

const fetchMocktestPrice = () =>
  api.get(`/api/scholarRoute?path=fee/mock-test/status`)

const fetchGetDaignosticTests = () =>
  api.get("/api/scholarRoute?path=test-prep/diagnostic-test/result")

const fetchMockTestResult = (mockTestId: string) =>
  api.get(
    `/api/scholarRoute?path=test-prep/exam/${mockTestId}/ieltsScoreCard`,
    {
      headers: {
        "X-API-VERSION": 3,
      },
    },
  )

const fetchMockTestResultV4 = (mockTestId: string) =>
  api.get(
    `/api/scholarRoute?path=test-prep/exam/${mockTestId}/ieltsScoreCard`,
    {
      headers: {
        "X-API-VERSION": 4,
      },
    },
  )

const fetchSelfPrepBaughtStatus = () =>
  api.get("/api/scholarRoute?path=fee/self-prep/status")

const fetchMockTestsCredits = () =>
  api.get("/api/scholarRoute?path=mocktest/credit-slot/details")

const postWritingExamPreferenceApi = async (payload: {
  examId: string | number
  writingType: WritingExamPreference
}) =>
  api.post(
    `/api/scholarRoute?path=test-prep/exam/${payload.examId}/preference`,
    payload,
  )

const fetchLiyMocktestDashboard = () =>
  api.get(`/api/scholarRoute?path=test-prep/liy/dashboard`)

export const TestPrepApi = {
  fetchMockTestList,
  fetchMockTest,
  fetchModalDataCms,
  fetchMocktestPrice,
  fetchGetDaignosticTests,
  fetchMockTestResult,
  fetchSelfPrepBaughtStatus,
  fetchMockTestsCredits,
  postWritingExamPreferenceApi,
  fetchLiyMocktestDashboard,
  fetchMockTestResultV4,
}
