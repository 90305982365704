import { call, put, takeLatest } from '@redux-saga/core/effects'
import { ieltsTestActions } from 'components/Profile/IeltsProfile/IeltsTest/redux/IeltsTestActions'
import { ieltsTestApis } from 'components/Profile/IeltsProfile/IeltsTest/redux/IeltsTestApis'
import { IeltsTestStatus } from 'Interfaces/reducerTypes/IeltsTestSchema'
import { AnyAction } from 'redux'

function* fetchIeltsTestSubmission(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsTestApis.fetchIeltsTestSubmissionStatus)
    if (res.success) {
      const submissionStatus = res.data
      yield put(
        ieltsTestActions.updateIeltsTestSubmissionStatus(submissionStatus),
      )
    } else {
      console.log(res.message)
    }
  } catch (e: any) {
    console.log('something went wonrg')
  }
}

function* saveIeltsTestSubmissionDetails(
  action: AnyAction,
): Generator<any, any, any> {
  try {
    const res = yield call(ieltsTestApis.submitIeltsTestDetails, action.payload)
    if (res.success) {
      yield put(ieltsTestActions.toggleShowSubmittedDocsModal(true))
      yield put(ieltsTestActions.toggleIeltsTestForm(false))
      yield put(
        ieltsTestActions.updateIeltsTestSubmissionStatus(
          IeltsTestStatus.SUBMITTED,
        ),
      )
    } else {
      console.log(res.message)
    }
  } catch (e: any) {
    console.log('something went wrong')
  }
}

function* fetchIeltsDateRewards(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(ieltsTestApis.fetchIeltsDateRewards, action.payload)
    if (res.success) {
      yield put(ieltsTestActions.setRewards(res.data))
    } else {
      console.log(res.message)
    }
  } catch (e: any) {
    console.log('something went wrong')
  }
}

function* fetchIeltsDateUserStatus(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsTestApis.fetchIeltsDateUserStatus)
    if (res.success) {
      yield put(ieltsTestActions.setUserStatus(res.data))
    } else {
      console.log(res.message)
    }
  } catch (e: any) {
    console.log('something went wrong')
  }
}

function* fetchIeltsTestBannerStatus(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsTestApis.fetchIeltsTestBannerStatusApi)
    if (res.data.success) {
      const bannerStatus: { showIeltsUploadBanner: boolean } = res.data.data
      yield put(
        ieltsTestActions.setIeltsTestBannerStatusSuccess(
          bannerStatus.showIeltsUploadBanner,
        ),
      )
    } else {
      console.log(res.message)
    }
  } catch (e: any) {
    console.log('something went wrong')
  }
}

function* ieltsTestSaga() {
  yield takeLatest(
    'FETCH_IELTS_TEST_SUBMISSION_REQUESTED',
    fetchIeltsTestSubmission,
  )
  yield takeLatest(
    'SAVE_IELTS_TEST_SUBMISSION_DETAILS',
    saveIeltsTestSubmissionDetails,
  )
  yield takeLatest('FETCH_REWARDS', fetchIeltsDateRewards)
  yield takeLatest('FETCH_USER_STATUS', fetchIeltsDateUserStatus)
  yield takeLatest('FETCH_IELTS_TEST_BANNER_STATUS', fetchIeltsTestBannerStatus)
}

export default ieltsTestSaga
