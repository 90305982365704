import NoticeBoardConstants from "../constants/notice-board-constants";

const fetchNoticeBoardData = () => ({
  type: NoticeBoardConstants.FETCH_NOTICE_BOARD_DATA,
});

const fetchNoticeBoardDataSuccess = (payload: string) => ({
  type: NoticeBoardConstants.FETCH_NOTICE_BOARD_DATA_SUCCESS,
  payload,
});

const fetchNoticeBoardDataError = (message: string) => ({
  type: NoticeBoardConstants.FETCH_NOTICE_BOARD_DATA_ERROR,
  payload: message,
});

export const noticeBoardActions = {
  fetchNoticeBoardData,
  fetchNoticeBoardDataSuccess,
  fetchNoticeBoardDataError,
};
