import { ReactNode } from "react"

export const MOCK_TEST_CAMPAIGN_QUERY = "free_mock_test"

export const MOCK_TEST_TYPE = "mock_test_type"

export const mockTestCategory = {
  event_mock_test: "event_mock_test",
  masterclass_mock_test: "masterclass_mock_test",
}

export const speakingEvaluationBannerData: {
  title: string
  subTitle: string | ReactNode
  ctaText: string
  bgImg: string
  desktopBgImg: string
} = {
  title: "Full Length Speaking Evaluation",
  subTitle: (
    <span className="text-[#686868]">
      If you have Speaking evaluation as part of your bundle, <br />
      please access it through Dashboard
    </span>
  ),
  ctaText: "Go to Dashboard",
  bgImg: `${process.env.CDN_URL}/assets/images/ielts-profile/dashboard/batch_card/speaking_bg.png`,
  desktopBgImg:
    `${process.env.CDN_URL}/assets/images/ielts-profile/dashboard/batch_card/speaking_desktop.webp`,
}
