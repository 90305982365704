import { AnyAction } from "redux"

export interface IeltsRegistrationFormReducerSchema {
  ctaText: string
  isModalOpen: null | boolean
  error: string
  ieltsPaymentSuccess: null | boolean
  ieltsFreeTrailEligiblity: null | boolean
  initPayment: boolean
}
const initialState: IeltsRegistrationFormReducerSchema = {
  ctaText: "Done",
  isModalOpen: false,
  error: "",
  ieltsPaymentSuccess: false,
  ieltsFreeTrailEligiblity: null,
  initPayment: false,
}

// create your reducer
const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "TOGGLE_IELTS_FORM_CTA_TEXT":
      return {
        ...state,
        ctaText: action.payload,
      }
    case "TOGGLE_IELTS_FORM_MODAL":
      return {
        ...state,
        isModalOpen: action.payload,
      }
    case "IELTS_FORM_ERROR":
      return {
        ...state,
        error: action.payload,
      }
    //ub
    case "IELTS_PLUS_PAYMENT_SUCCESS":
      return {
        ...state,
        ieltsPaymentSuccess: action.payload,
      }
    case "TOGGLE_FREE_TRAIL_ELIGIBILITY":
      return {
        ...state,
        ieltsFreeTrailEligiblity: action.payload,
      }
    case "TOGGLE_IELTS_INIT_PAYMENT":
      return {
        ...state,
        initPayment: action.payload,
      }
    default:
      return state
  }
}

export default reducer
