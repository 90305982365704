import { spotCounsellingActions } from "components/SpotCounselling/redux/SpotCounsellingActions";
import { counsellingStageRank } from "DataMapper/Profile/StageMapper";
import { State } from "Interfaces/storeSchema";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const nonVisibleTags = ["counselling_stage", "ielts_stage"];

export const checkIfPrivateEvent = (
  eventId: number,
  tags: any,
  bookedList: any
) => {
  const tagNames = tags.map((item: any) => item?.tag?.Name);
  if (tagNames.includes("PRIVATE_EVENT")) {
    const filtererdEvents = bookedList?.some(
      (event: any) => event?.id === eventId
    );
    if (filtererdEvents) return false;
    return true;
  } else return false;
};

export const checkEventsToShow = (
  eventId: number,
  tags: any,
  stage: string,
  preferredCountry: string,
  bookedList: any
) => {
  const counsellingStages = tags
    .filter((item: any) => nonVisibleTags.includes(item?.tag?.type))
    .map((item: any) => item?.tag?.Name);
  const countries = tags
    .filter((item: any) => item?.tag?.type === "country")
    .map((item: any) => item?.tag?.Name);
  const isPrivateEvent = checkIfPrivateEvent(eventId, tags, bookedList);
  if (isPrivateEvent) return false;
  if (
    location.pathname === "/profile/counselling" &&
    counsellingStageRank[stage] >= 10
  ) {
    return (
      (countries.includes(preferredCountry) &&
        counsellingStages.includes(stage)) ||
      counsellingStages.includes("OPEN_TO_ALL")
    );
  } else {
    return stage && tags?.length > 0
      ? counsellingStages.includes(stage) ||
          counsellingStages.includes("OPEN_TO_ALL")
      : true;
  }
};

export const checkTofuVisibility = (
  eventId: number,
  tags: any,
  bookedList: any
) => {
  const counsellingStages = tags
    .filter((item: any) => nonVisibleTags.includes(item?.tag?.type))
    .map((item: any) => item?.tag?.Name);

  const success = counsellingStages.includes("OPEN_TO_ALL");
  const isPrivateEvent = checkIfPrivateEvent(eventId, tags, bookedList);
  if (isPrivateEvent) return false;
  return success;
};

export default function useUpcomingEventsData() {
  const dispatch = useDispatch();
  const upcomingEventData = useSelector(
    (state: State) => state.spotCounselling.eventsData
  );

  useEffect(() => {
    dispatch(spotCounsellingActions.fetchUpcomingEvents());
  }, []);

  return { upcommingEventsData: upcomingEventData.upcomingEvents, bookedEvents: upcomingEventData.bookedEvents };
}
