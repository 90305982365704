import { AnyAction } from "redux"
import { MasterclassRegistrationFormSchema } from "Interfaces/reducerTypes/MasterclassSchema"

const initialState: MasterclassRegistrationFormSchema = {
  ctaText: "Done",
  isModalOpen: false,
  error: "",
  showForm: false,
  showLoader: false,
  //ub
  saveFormSuccess: false,
  postFormAction: null,
  formValues: {},
  isLoading: false,
  shouldRedirectI2cTimeline : false
}

// create your reducer
const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "TOGGLE_MASTERCLASS_FORM_CTA_TEXT":
      return {
        ...state,
        ctaText: action.payload,
      }
    case "TOGGLE_MASTERCLASS_FORM_MODAL":
      return {
        ...state,
        isModalOpen: action.payload,
      }
    case "MASTERCLASS_FORM_ERROR":
      return {
        ...state,
        error: action.payload,
      }
    case "TOGGLE_MASTERCLASS_SHOW_FORM":
      return {
        ...state,
        showForm: action.payload,
      }
    case "TOGGLE_MASTERCLASS_SHOW_LOADER":
      return {
        ...state,
        showLoader: action.payload,
      }
    //ub
    case "MASTERCLASS_FORM_SUBMIT_SUCCESS":
      return {
        ...state,
        saveFormSuccess: action.payload,
      }
    case "POST_FORM_ACTION":
      return {
        ...state,
        postFormAction: action.payload,
      }
    case "SAVE_MASTERCLASS_FORM_VALUES":
      return {
        ...state,
        formValues: action.payload,
      }
    case "TOGGLE_MC_LOADING_STATUS":
      return {
        ...state,
        isLoading: action.payload,
      }
    case "TOGGLE_I2C_REDIRECT_STATUS":{

    console.log("sadasd",  action.payload);
    
      return {
        ...state,
        shouldRedirectI2cTimeline: action.payload,
      }
    }
    default:
      return state
  }
}

export default reducer
