import api from "service/api"

const fetchRecordings = async (batchId: string) => {
  try {
    const res = await api.get(`/api/scholarRoute?path=video/batch/${batchId}`, {
      headers: {
        "X-API-VERSION": "4",
      },
    })
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime")
  }
}

export const fetchVideo = async (payload: any) => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=video/batch/${payload.batchId}/${payload.videoId}`,
      {
        headers: {
          "X-API-VERSION": "4",
        },
      },
    )
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime")
  }
}

const fetchSelfPrepRecordings = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=video/test-prep`, {
      headers: {
        "X-API-VERSION": "2",
      },
    })
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime")
  }
}

const fetchGrammarRecordings = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=video/grammar-class`, {})
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime")
  }
}

export const classRecordingsApi = {
  fetchRecordings,
  fetchVideo,
  fetchSelfPrepRecordings,
  fetchGrammarRecordings,
}
