import Image from "next/image"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { trackPage } from "scripts/segment"
import withWindowDimensions from "uiKit/utils/withWindowDimensions"

type Props = {
  handleCloseModal: () => void
  isMobileSized?: boolean
}

type ModalContent = {
  desktopImage: {
    url: string
  }
  mobileImage: {
    url: string
  }
  title: string
  subtitle: string
  description: string
  bulletPoints: Array<any>
  ctaText: string
  ctaUrl: string
}

const InactiveUserModal = ({ handleCloseModal, isMobileSized }: Props) => {
  const [modalContent, setModalContent] = useState<ModalContent | null>(null)
  const [shouldShowModal, setShouldShowModal] = useState<boolean>(false)
  const router = useRouter()

  useEffect(() => {
    const fetchModalContent = async () => {
      try {
        const response = await fetch(
          `${process.env.CMS_URL}/modals?type=inactive_user_modal`,
        )
        const data = await response.json()
        if (data?.length) {
          setModalContent(data[0]?.modalContent?.[0])
          const paths = data[0]?.modalContent?.[0]?.paths
          if (paths.length) {
            const matchedPaths = paths?.filter((path: Record<string, string>) =>
              router.asPath.includes(path?.path),
            )
            const isPathMatched = matchedPaths?.length
            const matchedPathDetail = matchedPaths?.[0]
            const shouldPathExactMatch = matchedPathDetail?.exactmatch
            if (shouldPathExactMatch) {
              const isExactPathMatched =
                router.pathname === matchedPathDetail?.path
              if (isExactPathMatched) {
                trackPage(matchedPathDetail?.name)
                setShouldShowModal(true)
              }
            } else if (isPathMatched) {
              trackPage(matchedPathDetail?.name)
              setShouldShowModal(true)
            }
          }
        }
      } catch (error) {
        console.error("Error fetching modal content:", error)
      }
    }

    fetchModalContent()
  }, [])

  if (!shouldShowModal) {
    return null
  }

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-[#000000] bg-opacity-60 flex md:w-full p-4">
      <div className="relative md:p-8 p-6 bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        <div className="flex justify-end absolute top-[0.5rem] right-[0.5rem]">
          <img
            src={`${process.env.CDN_URL}/assets/icons/closeIcon.svg`}
            className="cursor-pointer"
            alt="closeIcon"
            onClick={handleCloseModal}
          />
        </div>
        {(modalContent?.desktopImage || modalContent?.mobileImage) && (
          <div className="flex justify-center">
            <Image
              src={
                isMobileSized
                  ? modalContent?.mobileImage?.url
                  : (modalContent?.desktopImage?.url as string)
              }
              width={isMobileSized ? 320 : 400}
              height={isMobileSized ? 200 : 220}
              alt="modal_desktop_image"
            />
          </div>
        )}
        {modalContent?.title?.length ? (
          <p className="text-[20px] font-[700] leading-[28px] text-center pt-4 text-[#332EBF]">
            {modalContent?.title}
          </p>
        ) : null}
        {modalContent?.subtitle?.length ? (
          <p className="md:text-[18px] text-[14px] font-[400] leading-[32px] md:pt-2 text-center text-[#332EBF]">
            {modalContent?.subtitle}
          </p>
        ) : null}
        {modalContent?.description?.length ? (
          <p
            className="md:text-[16px] text-[700] leading-[28px] md:pt-2 text-center"
            dangerouslySetInnerHTML={{
              __html: modalContent?.description,
            }}
          ></p>
        ) : null}
        {modalContent?.bulletPoints?.length ? (
          <div className="justify-around flex md:flex-row flex-col pt-4">
            {modalContent?.bulletPoints?.map((item: any, index: number) => (
              <div className="flex items-start align-baseline" key={index}>
                <img
                  src={`${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`}
                  alt="tick"
                  width={20}
                  height={20}
                  className="mr-2"
                />
                <p className="md:text-[14px]">{item?.Value}</p>
              </div>
            ))}
          </div>
        ) : null}
        {modalContent?.ctaText && (
          <div className="flex cursor-pointer justify-center w-full">
            <a
              href={modalContent.ctaUrl}
              className="mt-4 bg-primary text-white font-bold py-2 px-4 w-full text-center rounded"
              onClick={handleCloseModal}
            >
              {modalContent?.ctaText}
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default withWindowDimensions(InactiveUserModal)
