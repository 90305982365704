import { call, put, takeLatest } from 'redux-saga/effects'
import { overviewActions } from '../actions/overview-actions'
import { overviewApi } from '../apis/overview-apis'
import OverviewConstants from '../constants/overview-constants'

function* fetchOverview(): Generator<any, any, any> {
  try {
    const res = yield call(overviewApi.fetchOverviewRequest)
    if (res.data.success) {
      const { events } = res.data.data
      const eventsData = [] as any
      events &&
        events.length > 0 &&
        events.map((e: any, i: any) => {
          const event = {
            id: i,
            title: e.profile.name,
            subtitle: e.profile.shortDescription,
            image: e.profile.mobileImage,
            meetingDate: e.startTime,
          }
          eventsData.push(event)
        })
      const data = res.data.data
      yield put(overviewActions.fetchOverviewDataSuccess({ eventsData, data }))
    } else {
      yield put(overviewActions.fetchOverviewDataError(res.data.message))
    }
  } catch (e: any) {
    console.error(e)
    yield put(overviewActions.fetchOverviewDataError(e.message))
  }
}

function* fetchCounsellingFee(): Generator<any, any, any> {
  try {
    const res = yield call(overviewApi.fetchCounsellingFeeRequest)
    if (res.data.success) {
      yield put(overviewActions.fetchCounsellingFeeDataSuccess(res.data))
    } else {
      yield put(overviewActions.fetchCounsellingFeeDataError(res.data.message))
    }
  } catch (e: any) {
    console.error(e)
    yield put(overviewActions.fetchCounsellingFeeDataError(e.message))
  }
}

function* fetchPostPaymentData(): Generator<any, any, any> {
  try {
    const res = yield call(overviewApi.fetchPostPaymentDataRequest)
    if (res.data.success) {
      yield put(overviewActions.fetchPostPaymentDataSuccess(res.data.data))
    } else {
      yield put(overviewActions.fetchPostPaymentDataError(res.data.message))
    }
  } catch (e: any) {
    console.error(e)
    yield put(overviewActions.fetchPostPaymentDataError(e.message))
  }
}

function* fetchOffer(): Generator<any, any, any> {
  try {
    const res = yield call(overviewApi.fetchOfferRequest)
    if (res.data.success) {
      yield put(overviewActions.fetchOfferSuccess(res.data.data))
    } else {
      yield put(overviewActions.fetchOfferError(res.data.message))
    }
  } catch (e: any) {
    console.error(e)
    yield put(overviewActions.fetchOfferError(e.message))
  }
}

function* playList(): Generator<any, any, any> {
  try {
    const res = yield call(overviewApi.fetchPlaylist)
    const playListData = res?.data?.plAyLists || []
    yield put(overviewActions.fetchPlayListSuccess(playListData))
  } catch (e: any) {
    yield put(
      overviewActions.fetchPlayListFail(
        'Something went wrong. Please try after sometime'
      )
    )
  }
}

function* overviewSaga() {
  yield takeLatest(OverviewConstants.FETCH_OVERVIEW_DATA, fetchOverview)
  yield takeLatest(
    OverviewConstants.FETCH_COUNSELLING_FEE_DATA,
    fetchCounsellingFee
  )
  yield takeLatest(
    OverviewConstants.FETCH_POST_PAYMENT_DATA,
    fetchPostPaymentData
  )
  yield takeLatest(OverviewConstants.FETCH_OFFER, fetchOffer)
  yield takeLatest(OverviewConstants.FETCH_PLAYLIST, playList)
}

export default overviewSaga
