import { FunctionComponent } from "react";

export interface InfoAlertProps {
  type: "success" | "danger";
  icon: string;
  text: string;
  isOpen: boolean;
}

export const InfoAlert: FunctionComponent<InfoAlertProps> = ({
  type,
  icon,
  text,
  isOpen,
}) => {
  const getColorOfText = () => {
    switch (type) {
      case "success":
        return "text-white bg-green-300";
      case "danger":
        return "text-white bg-red-960";
    }
  };
  if (isOpen)
    return (
      <div
        className={[
          "w-full flex items-center p-2 md:px-4 md:py-2",
          getColorOfText(),
        ].join(" ")}
      >
        <img src={icon} width={25} height={25} />
        <div className="font-bold text-font14 pl-4">{text}</div>
      </div>
    );
  else return null;
};
