import { HYDRATE } from "next-redux-wrapper";
import { AnyAction } from "redux";

const initialState: any = {
  testimonialData: [],
  videoReviews: [],
  interviewData: [],
};

const reviewReducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        testimonialData: action.payload.review.testimonialData,
        videoReviews: action.payload.review.videoReviews,
        interviewData: action.payload.review.interviewData,
      };
    case "FETCH_IELTS_REVIEWS_SUCCESS":
      return {
        ...state,
        testimonialData: action.payload["Testimonial"] || [],
        videoReviews: action.payload["Video"] || [],
        interviewData: action.payload["Interview"] || [],
      };
    default:
      return state;
  }
};

export default reviewReducer;
