export const getClassRecordingPayload = (playList: any) => {
  if (playList && playList.length > 0) {
    const mainVideo = playList[0]
    if (!playList) {
      playList = []
    }
    return {
      ...mainVideo,
      playList: [...playList],
    }
  }
  return null
}
