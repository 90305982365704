import gql from "graphql-tag";

const FETCH_IELTS_REVIEWS = gql`
  query {
    reviews(where: { platform: "IELTS" }) {
      id
      Name
      platform
      review
      score
      youtube
      thumbnail {
        url
      }
      user_image {
        url
      }
      social_platform {
        link
        platform
      }
      type
    }
  }
`;

export default FETCH_IELTS_REVIEWS;
