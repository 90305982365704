export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
}
export const getFilename = (_fileUrl: string) => {
  const _lastIndexUrlSeparator = _fileUrl?.lastIndexOf("/")
  return (
    _lastIndexUrlSeparator && _fileUrl?.substring(_lastIndexUrlSeparator + 1)
  )
}

export const pluralizeText = (text: string, suffix: string, count: number) => {
  if (count > 1) return text + suffix
  return text
}
