import { AnyAction } from "redux";
import { UpcomingClassSchema } from "Interfaces/reducerTypes/UpcomingSchema";
import { upcomingType } from "components/Profile/IeltsProfile/UpcomingClass/redux/UpcomingClassActions";

const initialState: UpcomingClassSchema = {
  loading: true,
  errorMessage: "",
  upcomingClass: null,
  liveClass: [],
  upcomingExtraClass: [],
  liveIeltClasses: [],
  liveMasterclasses: [],
  voiceRoom: [],
  isLiveLoading: false,
  orientationClass: [],
  enrollOrientationClass: [],
};

// create your reducer
const reducer = (
  state: UpcomingClassSchema = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case upcomingType.UPCOMING_CLASS_FETCH_SUCCESS:
      return {
        ...state,
        upcomingClass: action.payload,
        loading: false,
      };
    case upcomingType.UPCOMING_CLASS_FETCH_FAIL:
      return {
        ...state,
        errorMessage: action.message,
        loading: false,
      };
    case upcomingType.LIVE_CLASS_FETCH_SUCCESS:
      return {
        ...state,
        liveClass: action.payload,
      };
    case upcomingType.UPCOMING_EXTRA_CLASS_FETCH_SUCCESS:
      return {
        ...state,
        upcomingExtraClass: action.payload,
      };
    case upcomingType.SET_LIVE_IELTS:
      return {
        ...state,
        liveIeltClasses: action.payload,
      };
    case upcomingType.SET_LIVE_MASTERCLASS:
      return {
        ...state,
        liveMasterclasses: action.payload,
      };
    case upcomingType.FETCH_VOICE_ROOM_SUCCESS:
      return {
        ...state,
        voiceRoom: action.payload,
      };
    case upcomingType.SET_IS_LOADING:
      return {
        ...state,
        isLiveLoading: action.payload,
      };
    case upcomingType.SET_ORIENTATION_CLASS:
      return { ...state, orientationClass: action.payload };
    case upcomingType.SET_ORIENTATION_ENROLMENT:
      return { ...state, enrollOrientationClass: action.payload };
    default:
      return state;
  }
};

export default reducer;
