import api, { ApiRequestConfig } from "service/api"
import { getTokenCookie } from "lib/auth-cookies-new"
import { NextPageContext } from "next"
import { BatchDetailsPayload } from "components/Ielts/BatchDetails/redux/BatchDetailsAction"
import { basicAuthToken } from "DataMapper/Common/basicAuth"

const fetchBatchDetails = async (batchId: string, ctx: NextPageContext) => {
  try {
    const headers: { [key: string]: any } = {
      Authorization: basicAuthToken,
    }

    const config: ApiRequestConfig = {
      method: "POST",
      url: `${process.env.SCHOLAR_URL}/v2/workshop/${batchId}/details`,
      data: { productTypes: null },
      headers,
    }
    const res = await api(config)
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}

const fetchBatchDetailsClientSide = async (batchId: string) => {
  try {
    const headers = {
      "X-API-VERSION": "4",
    }
    const res = await api.get(
      `/api/scholarRoute?path=v2/workshop/${batchId}/details`,
      { headers },
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}

const fetchBatchTeacherDetails = async (
  teacherId: string,
  ctx: NextPageContext,
) => {
  try {
    const accessToken = getTokenCookie(ctx)
    let headers
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      }
    }
    const res = await api.get(
      `${process.env.SCHOLAR_URL}/teacher/${teacherId}/details`,
      { headers },
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}

const fetchBatchTeacherDetailsClient = async (teacherId: string) => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=teacher/${teacherId}/details`,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}
const fetchProductBatchDetailsClientSide = async (
  payload: BatchDetailsPayload,
) => {
  try {
    const { batchId } = payload
    const config: ApiRequestConfig = {
      method: "POST",
      url: `/api/scholarOpenRoute?path=v2/workshop/${batchId}/details`,
      data: { productTypes: null },
    }
    const res = await api(config)
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}
export const batchDetailsApi = {
  fetchBatchDetails,
  fetchBatchTeacherDetails,
  fetchBatchDetailsClientSide,
  fetchBatchTeacherDetailsClient,
  fetchProductBatchDetailsClientSide,
}
