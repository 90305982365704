export const actionConstants = {
  //academic details form
  FETCH_ACADEMIC_DATA: "FETCH_ACADEMIC_DATA",
  FETCH_ACADEMIC_DATA_SUCCESS: "FETCH_ACADEMIC_DATA_SUCCESS",
  FETCH_ACADEMIC_DATA_FAIL: "FETCH_ACADEMIC_DATA_FAIL",
  SAVE_ACADEMIC_DATA: "SAVE_ACADEMIC_DATA",
  SAVE_ACADEMIC_DATA_SUCCESS: "SAVE_ACADEMIC_DATA_SUCCESS",
  SAVE_ACADEMIC_DATA_FAIL: "SAVE_ACADEMIC_DATA_FAIL",

  //emergency contact details form
  FETCH_EMERGENCY_CONTACT_DATA: "FETCH_EMERGENCY_CONTACT_DATA",
  FETCH_EMERGENCY_CONTACT_DATA_SUCCESS: "FETCH_EMERGENCY_CONTACT_DATA_SUCCESS",
  FETCH_EMERGENCY_CONTACT_DATA_FAIL: "FETCH_EMERGENCY_CONTACT_DATA_FAIL",
  SAVE_EMERGENCY_CONTACT_DATA: "SAVE_EMERGENCY_CONTACT_DATA",
  SAVE_EMERGENCY_CONTACT_DATA_SUCCESS: "SAVE_EMERGENCY_CONTACT_DATA_SUCCESS",
  SAVE_EMERGENCY_CONTACT_DATA_FAIL: "SAVE_EMERGENCY_CONTACT_DATA_FAIL",

  //state & city actions
  GET_STATE_LIST: "GET_STATE_LIST",
  GET_STATE_LIST_SUCCESS: "GET_STATE_LIST_SUCCESS",
  GET_STATE_LIST_FAIL: "GET_STATE_LIST_FAIL",
  GET_CITY_LIST: "GET_CITY_LIST",
  GET_CITY_LIST_SUCCESS: "GET_CITY_LIST_SUCCESS",
  GET_CITY_LIST_FAIL: "GET_CITY_LIST_FAIL",

  //exam score details
  FETCH_EXAM_SCORE_DATA: "FETCH_EXAM_SCORE_DATA",
  FETCH_EXAM_SCORE_DATA_SUCCESS: "FETCH_EXAM_SCORE_DATA_SUCCESS",
  FETCH_EXAM_SCORE_DATA_FAIL: "FETCH_EXAM_SCORE_DATA_FAIL",
  SAVE_EXAM_SCORE: "SAVE_EXAM_SCORE",
  SAVE_EXAM_SCORE_SUCCESS: "SAVE_EXAM_SCORE_SUCCESS",
  SAVE_EXAM_SCORE_FAIL: "SAVE_EXAM_SCORE_FAIL",

  //work experience details
  FETCH_WORK_EXP_DATA: "FETCH_WORK_EXP_DATA",
  FETCH_WORK_EXP_DATA_SUCCESS: "FETCH_WORK_EXP_DATA_SUCCESS",
  FETCH_WORK_EXP_DATA_FAIL: "FETCH_WORK_EXP_DATA_FAIL",
  SAVE_WORK_EXP: "SAVE_WORK_EXP",
  SAVE_WORK_EXP_SUCCESS: "SAVE_WORK_EXP_SUCCESS",
  SAVE_WORK_EXP_FAIL: "SAVE_WORK_EXP_FAIL",
  DELETE_WORK_EXP: "DELETE_WORK_EXP",
  DELETE_WORK_EXP_SUCCESS: "DELETE_WORK_EXP_SUCCESS",
  DELETE_WORK_EXP_FAIL: "DELETE_WORK_EXP_FAIL",

  RESET_ERROR: "RESET_ERROR"
};

const fetchPersonalData = () => ({
  type: "FETCH_PERSONAL_DATA",
});

const fetchPersonalDataSuccess = (payload: any) => ({
  type: "FETCH_PERSONAL_DATA_SUCCESS",
  payload,
});

const fetchPersonalDataFail = (message: string) => ({
  type: "FETCH_PERSONAL_DATA_FAIL",
  payload: message,
});

const savePersonalData = (payload: any) => ({
  type: "SAVE_PERSONAL_DATA",
  payload,
});

const savePersonalDataSuccess = (payload: any) => ({
  type: "SAVE_PERSONAL_DATA_SUCCESS",
  payload,
});

const savePersonalDataFail = (message: string) => ({
  type: "SAVE_PERSONAL_DATA_FAIL",
  payload: message,
});

/*----------- Academic details - start ---------- */
const fetchAcademicDetails = () => ({
  type: actionConstants.FETCH_ACADEMIC_DATA,
});

const fetchAcademicDetailsSuccess = (payload: any) => ({
  type: actionConstants.FETCH_ACADEMIC_DATA_SUCCESS,
  payload,
});

const fetchAcademicDetailsFail = (message: string) => ({
  type: actionConstants.FETCH_ACADEMIC_DATA_FAIL,
  payload: message,
});

const saveAcademicDetails = (payload: any) => ({
  type: actionConstants.SAVE_ACADEMIC_DATA,
  payload,
});

const saveAcademicDetailsSuccess = (payload: any) => ({
  type: actionConstants.SAVE_ACADEMIC_DATA_SUCCESS,
  payload,
});

const saveAcademicDetailsFail = (message: string) => ({
  type: actionConstants.SAVE_ACADEMIC_DATA_FAIL,
  payload: message,
});

/*----------- Academic details - start ---------- */

/*----------- Emergency contact details - start ---------- */
const fetchEmergencyContactDetails = () => ({
  type: actionConstants.FETCH_EMERGENCY_CONTACT_DATA,
});

const fetchEmergencyContactDetailsSuccess = (payload: any) => ({
  type: actionConstants.FETCH_EMERGENCY_CONTACT_DATA_SUCCESS,
  payload,
});

const fetchEmergencyContactDetailsFail = (message: string) => ({
  type: actionConstants.FETCH_EMERGENCY_CONTACT_DATA_FAIL,
  payload: message,
});

const saveEmergencyContactDetails = (payload: any) => ({
  type: actionConstants.SAVE_EMERGENCY_CONTACT_DATA,
  payload,
});

const saveEmergencyContactDetailsSuccess = (payload: any) => ({
  type: actionConstants.SAVE_EMERGENCY_CONTACT_DATA_SUCCESS,
  payload,
});

const saveEmergencyContactDetailsFail = (message: string) => ({
  type: actionConstants.SAVE_EMERGENCY_CONTACT_DATA_FAIL,
  payload: message,
});
/*----------- Emergency contact details - start ---------- */

/*----------- State,Cities - start ---------- */
const getStateList = (payload: string) => ({
  type: actionConstants.GET_STATE_LIST,
  payload,
});

const getStateListSuccess = (payload: any) => ({
  type: actionConstants.GET_STATE_LIST_SUCCESS,
  payload,
});

const getStateListFail = (message: string) => ({
  type: actionConstants.GET_STATE_LIST_FAIL,
  payload: message,
});

const getCityList = (payload: any) => {
  return {
    type: actionConstants.GET_CITY_LIST,
    payload,
  };
};

const getCityListSuccess = (payload: any) => ({
  type: actionConstants.GET_CITY_LIST_SUCCESS,
  payload,
});

const getCityListFail = (message: string) => ({
  type: actionConstants.GET_CITY_LIST_FAIL,
  payload: message,
});
/*----------- State,Cities - start ---------- */

/*----------- Exam score details - start ---------- */
const fetchExamScoreDetails = () => ({
  type: actionConstants.FETCH_EXAM_SCORE_DATA,
});

const fetchExamScoreDetailsSuccess = (payload: any) => ({
  type: actionConstants.FETCH_EXAM_SCORE_DATA_SUCCESS,
  payload,
});

const fetchExamScoreDetailsFail = (message: string) => ({
  type: actionConstants.FETCH_EXAM_SCORE_DATA_FAIL,
  payload: message,
});

const saveExamScoreDetails = (payload: any) => ({
  type: actionConstants.SAVE_EXAM_SCORE,
  payload,
});

const saveExamScoreDetailsSuccess = (payload: any) => ({
  type: actionConstants.SAVE_EXAM_SCORE_SUCCESS,
  payload,
});

const saveExamScoreDetailsFail = (message: string) => ({
  type: actionConstants.SAVE_EXAM_SCORE_FAIL,
  payload: message,
});
/*----------- Exam score details - end ---------- */

/*----------- Work Experience details - start ---------- */
const fetchWorkExpDetails = () => ({
  type: actionConstants.FETCH_WORK_EXP_DATA,
});

const fetchWorkExpDetailsSuccess = (payload: any) => ({
  type: actionConstants.FETCH_WORK_EXP_DATA_SUCCESS,
  payload,
});

const fetchWorkExpDetailsFail = (message: string) => ({
  type: actionConstants.FETCH_WORK_EXP_DATA_FAIL,
  payload: message,
});

const saveWorkExpDetails = (payload: any) => ({
  type: actionConstants.SAVE_WORK_EXP,
  payload,
});

const saveWorkExpDetailsSuccess = (payload: any) => ({
  type: actionConstants.SAVE_WORK_EXP_SUCCESS,
  payload,
});

const saveWorkExpDetailsFail = (message: string) => ({
  type: actionConstants.SAVE_WORK_EXP_FAIL,
  payload: message,
});

const deleteWorkExp = (payload: any) => ({
  type: actionConstants.DELETE_WORK_EXP,
  payload,
});

const deleteWorkExpSuccess = (payload: any) => ({
  type: actionConstants.DELETE_WORK_EXP_SUCCESS,
  payload,
});

const deleteWorkExpFail = (message: string) => ({
  type: actionConstants.DELETE_WORK_EXP_FAIL,
  payload: message,
});

/*----------- Work Experience details - end ---------- */

const fetchAddressData = () => ({
  type: "FETCH_ADDRESS_DATA",
});

const fetchAddressDataSuccess = (payload: any) => ({
  type: "FETCH_ADDRESS_DATA_SUCCESS",
  payload,
});

const fetchAddressDataFail = (message: string) => ({
  type: "FETCH_ADDRESS_DATA_FAIL",
  payload: message,
});

const saveAddressData = (payload: any) => ({
  type: "SAVE_ADDRESS_DATA",
  payload,
});

const saveAddressDataSuccess = (payload: any) => ({
  type: "SAVE_ADDRESS_DATA_SUCCESS",
  payload,
});

const saveAddressDataFail = (message: string) => ({
  type: "SAVE_ADDRESS_DATA_FAIL",
  payload: message,
});

const fetchPassportData = () => ({
  type: "FETCH_PASSPORT_DATA",
});

const fetchPassportDataSuccess = (payload: any) => ({
  type: "FETCH_PASSPORT_DATA_SUCCESS",
  payload,
});

const fetchPassportDataFail = (message: string) => ({
  type: "FETCH_PASSPORT_DATA_FAIL",
  payload: message,
});

const savePassportData = (payload: any) => ({
  type: "SAVE_PASSPORT_DATA",
  payload,
});

const savePassportDataSuccess = (payload: any) => ({
  type: "SAVE_PASSPORT_DATA_SUCCESS",
  payload,
});

const savePassportDataFail = (message: string) => ({
  type: "SAVE_PASSPORT_DATA_FAIL",
  payload: message,
});

const fetchBackgroundData = () => ({
  type: "FETCH_BACKGROUND_DATA",
});

const fetchBackgroundDataSuccess = (payload: any) => ({
  type: "FETCH_BACKGROUND_DATA_SUCCESS",
  payload,
});

const fetchBackgroundDataFail = (message: string) => ({
  type: "FETCH_BACKGROUND_DATA_FAIL",
  payload: message,
});

const saveBackgroundData = (payload: any) => ({
  type: "SAVE_BACKGROUND_DATA",
  payload,
});

const saveBackgroundDataSuccess = (payload: any) => ({
  type: "SAVE_BACKGROUND_DATA_SUCCESS",
  payload,
});

const saveBackgroundDataFail = (message: string) => ({
  type: "SAVE_BACKGROUND_DATA_FAIL",
  payload: message,
});

const getCountryList = () => ({
  type: "GET_COUNTRY_LIST",
});

const getCountryListSuccess = (payload: any) => ({
  type: "GET_COUNTRY_LIST_SUCCESS",
  payload,
});

const getCountryListFail = (message: string) => ({
  type: "GET_COUNTRY_LIST_FAIL",
  payload: message,
});

const getLanguageList = () => ({
  type: "GET_LANGUAGE_LIST",
});

const getLanguageListSuccess = (payload: any) => ({
  type: "GET_LANGUAGE_LIST_SUCCESS",
  payload,
});

const getLanguageListFail = (message: string) => ({
  type: "GET_LANGUAGE_LIST_FAIL",
  payload: message,
});

//education details

const fetchEducationData = () => ({
  type: "FETCH_EDUCATION_DATA",
});

const fetchEducationDataSuccess = (payload: any) => ({
  type: "FETCH_EDUCATION_DATA_SUCCESS",
  payload,
});

const fetchEducationDataFail = (message: string) => ({
  type: "FETCH_EDUCATION_DATA_FAIL",
  payload: message,
});

const saveEducationData = (payload: any) => ({
  type: "SAVE_EDUCATION_DATA",
  payload,
});

const saveEducationDataSuccess = (payload: any) => ({
  type: "SAVE_EDUCATION_DATA_SUCCESS",
  payload,
});

const saveEducationDataFail = (message: string) => ({
  type: "SAVE_EDUCATION_DATA_FAIL",
  payload: message,
});

//referee form

const fetchRefereeData = () => ({
  type: "FETCH_REFEREE_DATA",
});

const fetchRefereeDataSuccess = (payload: any) => ({
  type: "FETCH_REFEREE_DATA_SUCCESS",
  payload,
});

const fetchRefereeDataFail = (message: string) => ({
  type: "FETCH_REFEREE_DATA_FAIL",
  payload: message,
});

const saveRefereeData = (payload: any) => ({
  type: "SAVE_REFEREE_DATA",
  payload,
});

const saveRefereeDataSuccess = (payload: any) => ({
  type: "SAVE_REFEREE_DATA_SUCCESS",
  payload,
});

const saveRefereeDataFail = (message: string) => ({
  type: "SAVE_REFEREE_DATA_FAIL",
  payload: message,
});

const resetFetchError = () => ({
  type: "RESET_FETCH_ERROR",
});

const resetSaveError = () => ({
  type: "RESET_SAVE_ERROR",
});

const resetError = () => ({
  type: "RESET_ERROR",
});

export const updateProfileActions = {
  fetchPersonalData,
  fetchPersonalDataSuccess,
  fetchPersonalDataFail,

  savePersonalData,
  savePersonalDataSuccess,
  savePersonalDataFail,

  fetchAddressData,
  fetchAddressDataSuccess,
  fetchAddressDataFail,

  saveAddressData,
  saveAddressDataSuccess,
  saveAddressDataFail,

  fetchPassportData,
  fetchPassportDataSuccess,
  fetchPassportDataFail,

  savePassportData,
  savePassportDataSuccess,
  savePassportDataFail,

  fetchBackgroundData,
  fetchBackgroundDataSuccess,
  fetchBackgroundDataFail,

  saveBackgroundData,
  saveBackgroundDataSuccess,
  saveBackgroundDataFail,

  getCountryList,
  getCountryListSuccess,
  getCountryListFail,

  getLanguageList,
  getLanguageListSuccess,
  getLanguageListFail,
  
  fetchAcademicDetails,
  fetchAcademicDetailsSuccess,
  fetchAcademicDetailsFail,

  saveAcademicDetails,
  saveAcademicDetailsSuccess,
  saveAcademicDetailsFail,

  fetchEmergencyContactDetails,
  fetchEmergencyContactDetailsSuccess,
  fetchEmergencyContactDetailsFail,

  saveEmergencyContactDetails,
  saveEmergencyContactDetailsSuccess,
  saveEmergencyContactDetailsFail,

  getStateList,
  getStateListSuccess,
  getStateListFail,

  getCityList,
  getCityListSuccess,
  getCityListFail,

  fetchExamScoreDetails,
  fetchExamScoreDetailsSuccess,
  fetchExamScoreDetailsFail,

  saveExamScoreDetails,
  saveExamScoreDetailsSuccess,
  saveExamScoreDetailsFail,
  
  fetchEducationData,
  fetchEducationDataSuccess,
  fetchEducationDataFail,

  saveEducationData,
  saveEducationDataSuccess,
  saveEducationDataFail,

  fetchRefereeData,
  fetchRefereeDataSuccess,
  fetchRefereeDataFail,

  saveRefereeData,
  saveRefereeDataSuccess,
  saveRefereeDataFail,

  fetchWorkExpDetails,
  fetchWorkExpDetailsSuccess,
  fetchWorkExpDetailsFail,

  saveWorkExpDetails,
  saveWorkExpDetailsSuccess,
  saveWorkExpDetailsFail,
  
  deleteWorkExp,
  deleteWorkExpSuccess,
  deleteWorkExpFail,

  resetFetchError,
  resetSaveError,

  resetError,
};
