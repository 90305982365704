import api from "service/api"
import { deleteCookie } from "utils/cookie-utiils"
import { captureAndSaveUtmWhileLoginIn } from "utils/helperFunctionUtils"

const generateOtp = async (
  countryCode: string,
  country: string,
  phone: string,
  whatsAppConsent: boolean,
) => {
  const appName = process.env.AUTH_APP_NAME
  try {
    const res = await api.post(
      `/api/auth?path=otp/generate/${appName}/%2B${countryCode}${phone}/&nocache=${new Date().getTime()}&isWebRequest=${true}&whatsappConsent=${whatsAppConsent}`,
      null,
      {
        params: {
          country_code: country,
        },
      },
    )

    return res.data
  } catch (err: any) {
    console.log(err)
    return err
  }
}

const validateOtp = async (
  corelationId: string,
  otp: string,
  whatsAppConsent: boolean,
  country: string,
) => {
  const appName = process.env.AUTH_APP_NAME
  try {
    const res = await api.post(
      `/api/auth?path=otp/validate/${appName}/${corelationId}/${otp}/${whatsAppConsent}&nocache=${new Date().getTime()}&isWebRequest=${true}`,
      null,
      {
        params: {
          country_code: country,
        },
      },
    )
    if (res?.data?.success) {
      localStorage.setItem("userDataCollectedByChatBot", "true")
    }
    return res.data
  } catch (err: any) {
    console.log(err)
    return err
  }
}

const resendOtp = async (
  country: string,
  corelationId: string,
  whatsAppConsent: boolean,
) => {
  const appName = process.env.AUTH_APP_NAME
  try {
    const res = await api.post(
      `/api/auth?path=otp/resend/${appName}/${corelationId}&nocache=${new Date().getTime()}&isWebRequest=${true}&whatsappConsent=${whatsAppConsent}`,
      null,
      {
        params: {
          country_code: country,
        },
      },
    )
    return res.data
  } catch (err: any) {
    console.log(err)
    return err
  }
}

const registerUser = async (flowType?: string) => {
  let payload = {}
  if (flowType) {
    payload = { flowType }
  }
  try {
    await captureAndSaveUtmWhileLoginIn()
    const res = await api.post("/api/register", payload)
    if (res.data?.success) deleteCookie(null, "referrer_id")
    return res.data
  } catch (err: any) {
    console.log(err)
    return err
  }
}

const verifyTruecaller = async (
  requestId: string,
  whatsAppSubscription: boolean,
) => {
  try {
    const res = await api.post(`/api/auth?path=truecaller/verify`, {
      requestId,
      whatsAppSubscription,
    })
    return res.data
  } catch (err: any) {
    console.log(err)
    return err
  }
}

const initTruecallerLogin = async (requestId: string) => {
  const app = process.env.AUTH_APP_NAME
  try {
    const res = await api.post(`/api/auth?path=truecaller/initTruecaller`, {
      requestId,
      app,
    })
    return res.data
  } catch (err: any) {
    console.log(err)
    return err
  }
}

export const loginApis = {
  generateOtp,
  validateOtp,
  registerUser,
  resendOtp,
  initTruecallerLogin,
  verifyTruecaller,
}
