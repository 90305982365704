import { call, put, takeLatest } from 'redux-saga/effects'
import { noticeBoardActions } from '../actions/notice-board-actions'
import { noticeApi } from '../apis/notice-board-apis'
import NoticeBoardConstants from '../constants/notice-board-constants'

function* fetchNoticeBoard(): Generator<any, any, any> {
  try {
    const res = yield call(noticeApi.fetchNoticeRequest)
    if (res.data.success) {
      yield put(noticeBoardActions.fetchNoticeBoardDataSuccess(res.data.data))
    } else {
      yield put(noticeBoardActions.fetchNoticeBoardDataError(res.data.message))
    }
  } catch (e: any) {
    console.error(e)
    yield put(noticeBoardActions.fetchNoticeBoardDataError(e.message))
  }
}

function* noticeBoardSaga() {
  yield takeLatest(
    NoticeBoardConstants.FETCH_NOTICE_BOARD_DATA,
    fetchNoticeBoard
  )
}

export default noticeBoardSaga
