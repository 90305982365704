export const counsellingFormActionsTypes = {
  UPDATE_POST_FETCH_ELIGIBILITY_ACTION: "UPDATE_POST_FETCH_ELIGIBILITY_ACTION",
};
const fetchProfileData = () => ({
  type: "PROFILE_FETCH_REQUESTED",
});

const fetchProfileSuccess = (payload: any) => ({
  type: "PROFILE_FETCH_SUCCESS",
  payload,
});

const fetchProfileFail = (message: string) => ({
  type: "PROFILE_FETCH_FAIL",
  payload: message,
});

const saveProfile = (payload: any) => ({
  type: "SAVE_PROFILE_REQUESTED",
  payload,
});

const saveProfileSuccess = (payload: any) => ({
  type: "SAVE_PROFILE_SUCCESS",
  payload,
});

const saveProfileFail = (message: string) => ({
  type: "SAVE_PROFILE_FAIL",
  payload: message,
});

const fetchEligibility = () => ({
  type: "ELIGIBILITY_FETCH_REQUESTED",
});

const fetchEligibilitySuccess = (payload: any) => ({
  type: "ELIGIBILITY_FETCH_SUCCESS",
  payload,
});

/** while dispatching this action you should give it a callback.In that callback u can get the
 eligibility data you can use that data. this callback function will be called after fetching
the eligibility
 */
const updatePostFetchEligibilityAction = (payload: (data: any) => void) => ({
  type: counsellingFormActionsTypes.UPDATE_POST_FETCH_ELIGIBILITY_ACTION,
  payload,
});

const fetchEligibilityFail = (message: string) => ({
  type: "ELIGIBILITY_FETCH_FAIL",
  payload: message,
});

const updateSaveCta = (payload: boolean) => ({
  type: "UPDATE_SAVE_CTA_STATUS",
  payload,
});

const fetchIeltsNudgeData = () => ({
  type: "FETCH_IELTS_NUDGE_DATA",
});

const fetchIeltsNudgeDataSuccess = (payload: any) => ({
  type: "FETCH_IELTS_NUDGE_DATA_SUCCESS",
  payload,
});

const fetchIeltsNudgeDataFail = (payload: any) => ({
  type: "FETCH_IELTS_NUDGE_DATA_SUCCESS",
  payload,
});
const saveIeltsNudgeData = (payload: any) => ({
  type: "SAVE_IELTS_NUDGE_DATA",
  payload,
});

const saveIeltsNudgeDataSuccess = (payload: any) => ({
  type: "SAVE_IELTS_NUDGE_DATA_SUCCESS",
  payload,
});

const saveIeltsNudegeDataFail = (message: string) => ({
  type: "SAVE_IELTS_NUDGE_DATA_FAIL",
  payload: message,
});

export const counsellingFormActions = {
  fetchProfileData,
  fetchProfileSuccess,
  fetchProfileFail,
  saveProfile,
  saveProfileSuccess,
  saveProfileFail,
  fetchEligibility,
  fetchEligibilitySuccess,
  fetchEligibilityFail,
  updateSaveCta,
  fetchIeltsNudgeData,
  fetchIeltsNudgeDataSuccess,
  fetchIeltsNudgeDataFail,
  saveIeltsNudgeDataSuccess,
  saveIeltsNudegeDataFail,
  saveIeltsNudgeData,
  updatePostFetchEligibilityAction,
};
