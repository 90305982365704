import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { NotifyUserForExtraClassPayload } from "components/Profile/IeltsProfile/ExtraClass/redux/ExtraClassActions"
import {
  SpotCounselingLiveCalendarResponseDto,
  SpotCounsellingCardDto,
} from "components/SpotCounselling/Calendar/calendarInterface"
import {
  CalendarView,
  MasterclassLimit,
} from "DataMapper/IeltsProfile/IeltsDemo"
import { MasterclassDataSchema } from "Interfaces/reducerTypes/MasterclassSchema"
import { RejoinDemoData } from "components/Ielts/IeltsDemo/DataMapper/interface"

const IeltsDemoSliceName = "IeltsDemo"

export interface IeltsDemoSchema {
  calendarView: CalendarView
  ieltsSlotsDetails: SpotCounselingLiveCalendarResponseDto | null
  slotsArray: Array<SpotCounsellingCardDto>
  selectedSlot: { slot: SpotCounsellingCardDto | null; index: number } | null
  error: boolean
  hasBookedSlot: boolean
  hasNoSlots: boolean
  isLoading: boolean
  formData: Record<string, any> | null
  masterClassListObj: Record<string, Array<MasterclassDataSchema>> | null
  masterClassBooked: boolean
  rejoinDemoModalData: RejoinDemoData | null
  anroidAppMagicLink: string | null
}
const initialState: IeltsDemoSchema = {
  calendarView: CalendarView.LOADING,
  ieltsSlotsDetails: null,
  slotsArray: [],
  selectedSlot: null,
  error: false,
  hasBookedSlot: false,
  hasNoSlots: false,
  isLoading: true,
  formData: null,
  masterClassListObj: null,
  masterClassBooked: false,
  rejoinDemoModalData: null,
  anroidAppMagicLink: null,
}

const ieltsDemoSlice = createSlice({
  name: IeltsDemoSliceName,
  initialState,
  reducers: {
    setCalendarView(state, action: PayloadAction<CalendarView>) {
      return { ...state, calendarView: action.payload }
    },
    setIeltsSlotsDetails(
      state,
      action: PayloadAction<SpotCounselingLiveCalendarResponseDto | null>,
    ) {
      return { ...state, ieltsSlotsDetails: action.payload }
    },
    setSlotsArray(state, action: PayloadAction<Array<SpotCounsellingCardDto>>) {
      return { ...state, slotsArray: action.payload }
    },
    setSelectedSlot(
      state,
      action: PayloadAction<{
        slot: SpotCounsellingCardDto
        index: number
      } | null>,
    ) {
      return { ...state, selectedSlot: action.payload }
    },
    setError(state, action: PayloadAction<boolean>) {
      return { ...state, error: action.payload }
    },
    setHasBookedSlot(state, action: PayloadAction<boolean>) {
      return { ...state, hasBookedSlot: action.payload }
    },
    setHasNoSlots(state, action: PayloadAction<boolean>) {
      return { ...state, hasNoSlots: action.payload }
    },
    setIsLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload }
    },
    setFormData(state, action: PayloadAction<Record<string, any>>) {
      return { ...state, formData: action.payload }
    },
    setMasterClassListObj(
      state,
      action: PayloadAction<Record<
        string,
        Array<MasterclassDataSchema>
      > | null>,
    ) {
      return { ...state, masterClassListObj: action.payload }
    },

    setMasterClassBooked(state, action: PayloadAction<boolean>) {
      return { ...state, masterClassBooked: action.payload }
    },
    setRejoinDemoModalData(
      state,
      action: PayloadAction<RejoinDemoData | null>,
    ) {
      return { ...state, rejoinDemoModalData: action.payload }
    },
    setAnroidAppMagicLink(state, action: PayloadAction<string | null>) {
      return { ...state, anroidAppMagicLink: action.payload }
    },
  },
})

export const fetchIeltsSlots = createAction<number>(
  `${IeltsDemoSliceName}/fetchIeltsSlots`,
)

export const fetchMasterclassData = createAction<MasterclassLimit>(
  `${IeltsDemoSliceName}/fetchMasterclassData`,
)

export const bookIeltsSlot = createAction<{
  payload: any
  isReschedule: boolean
  segmentPayload?: any
  postBookingCallBack?: null | (() => void)
}>(`${IeltsDemoSliceName}/bookIeltsSlot`)

export const enrollWorkshop = createAction<NotifyUserForExtraClassPayload>(
  `${IeltsDemoSliceName}/enrollWorkshop`,
)

export const subscribeMasterClass = createAction<() => void>(
  `${IeltsDemoSliceName}/subscribeMasterClass`,
)

export const fetchRejoinModalData = createAction(
  `${IeltsDemoSliceName}/fetchRejoinModalStatus`,
)

export const fetchAnroidAppMagicLinkSlice = createAction(
  `${IeltsDemoSliceName}/fetchAnroidAppMagicLinkSlice`,
)

export const {
  setCalendarView,
  setIeltsSlotsDetails,
  setSlotsArray,
  setSelectedSlot,
  setError,
  setHasBookedSlot,
  setHasNoSlots,
  setIsLoading,
  setFormData,
  setMasterClassListObj,
  setMasterClassBooked,
  setRejoinDemoModalData,
  setAnroidAppMagicLink,
} = ieltsDemoSlice.actions

export default ieltsDemoSlice.reducer
