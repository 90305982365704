import api from "service/api"

const fetchVisaAssistanceDataRequest = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=profile/post/visa-counsellor`,
    )
    console.log("response for visa", res.data)
    return res
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page")
  }
}

export const visaAssistanceApi = {
  fetchVisaAssistanceDataRequest,
}
