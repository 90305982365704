import { AnyAction } from "redux";
import { IeltsCertificatePayloadSchema } from "Interfaces/reducerTypes/IeltsCertificateSchema";
import { certificateActionType } from "components/Profile/IeltsProfile/Certificates/redux/CertificateAction";

const initialState: IeltsCertificatePayloadSchema = {
  certificateData: null,
  error: "",
};

const reducer = (
  state: IeltsCertificatePayloadSchema = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case certificateActionType.FETCH_CERTIFICATE_SUCCESS:
      return {
        ...state,
        certificateData: action.payload,
      };
    case certificateActionType.FETCH_CERTIFICATE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case certificateActionType.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        error: "",
      };
    default:
      return state;
  }
};

export default reducer;
