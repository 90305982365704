import { FunctionComponent, ReactNode, useCallback } from "react";
import { useDropzone } from "react-dropzone";

interface Props {
  onChange: Function;
  onError: Function;
  children: (isDragActive: boolean) => ReactNode;
  enabled?: any;
  essayEvaluationDrop?: boolean;
  multipleUpload?: boolean;
  fileType?: string;
}

const Dropzone: FunctionComponent<Props> = ({
  onChange,
  onError,
  enabled,
  children,
  essayEvaluationDrop,
  multipleUpload = true,
  fileType,
}) => {
  const onDrop = useCallback((acceptedFiles) => {
    console.log("acceptedFiles = ", acceptedFiles);
    const file = acceptedFiles[0];
    if (!file) {
      onError({
        name: "InvalidFileException",
        message: essayEvaluationDrop
          ? "Invalid file format. Only jpg, png, pdf or doc are allowed"
          : "Invalid file format. Only jpg, png or pdf are allowed",
      });
      return;
    }
    if (file.size > 20000000) {
      onError({
        name: "FileSizeException",
        message: "File size should be less than 20mb",
      });
      return;
    }
    onChange(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: essayEvaluationDrop
      ? "application/pdf, image/jpeg application/docx, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, image/png, .jpg"
      : fileType
      ? `.${fileType}`
      : "text/csv, .csv, .xlsx, .png, .jpg, .docx, .doc, .pdf, application/pdf, image/jpeg, image/png",
    multiple: multipleUpload,
  });

  return (
    <div>
      <div {...getRootProps()}>
        {enabled && <input {...getInputProps()} />}
        {children(isDragActive)}
      </div>
    </div>
  );
};

export default Dropzone;
