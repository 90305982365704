import DatePicker from "components/Inputs/DatePicker"
import RadioButton from "components/Inputs/RadioButton"
import SingleUpload from "components/Inputs/SingleUpload"

export interface RadioOptions {
  label: string
  value: string
  icon?: string
}

export interface FieldProps {
  name: string
  component: any
  heading?: string
  label?: string
  options?: Array<RadioOptions>
  validate?: any
  placeholder?: string
  fileType?: string
  customDatePickerCss?: string
  showDatePickerIcon?: boolean
  defaultValue?: any
}

export enum OptionalBinaryAnswer {
  YES = "YES",
  NO = "NO",
  NOT_SURE = "NOT_SURE",
}

export enum ieltsExamType {
  ACADEMIC = "ACADEMIC",
  GENERAL = "GENERAL",
  OTHER = "OTHER",
}
export enum batchExamTypeDto {
  ACADEMIC = "ACADEMIC",
  GENERAL = "GENERAL",
  BOTH = "BOTH",
}

const ieltsExamTypeOptions = [
  {
    label: "Academic",
    value: ieltsExamType.ACADEMIC,
  },
  {
    label: "General",
    value: ieltsExamType.GENERAL,
  },
]

const oneToOneCounsellingSession = [
  {
    label: "Yes",
    value: OptionalBinaryAnswer.YES,
  },
  {
    label: "No",
    value: OptionalBinaryAnswer.NO,
  },
]

export const dateUploadFormData: FieldProps[] = [
  {
    heading: "Which IELTS test are you appearing for?",
    name: "ieltsExamType",
    component: RadioButton,
    options: ieltsExamTypeOptions,
    validate: (v: string) => {
      if (!v) return "Please select the exam date"
    },
    defaultValue: ieltsExamType.ACADEMIC,
  },
  {
    heading: "Do you want Free 1-1 Study Abroad Counselling?",
    name: "oneToOneCounsellingSession",
    component: RadioButton,
    options: oneToOneCounsellingSession,
    validate: (v: string) => {
      if (!v) return "Please select the exam date"
    },
    defaultValue: OptionalBinaryAnswer.YES,
  },
  {
    heading: "When is your IELTS exam?",
    name: "examDate",
    placeholder: "DD/MM/YYYY",
    component: DatePicker,
    validate: (v: string) => {
      if (!v) return "Please select the exam date"
    },
    customDatePickerCss:
      "border-[#BDBDBD] placeholder:text-[#BDBDBD] placeholder:opacity-50 w-full cursor-pointer",
    showDatePickerIcon: true,
  },
  {
    heading: "Upload your IELTS booking proof",
    name: "IELTS_TEST_DETAILS",
    label: "PNG or PDF",
    component: SingleUpload,
    fileType: ".pdf, .jpeg, .jpg, .png, application/pdf, image/jpeg, image/png",
    validate: (v: string) => {
      if (!v) return "Please upload document"
    },
  },
]
