import api from "service/api"

const requestStories = () => {
  return api.get(`/api/scholarRoute?path=stories/get`, {
    headers: {
      "X-API-VERSION": "3",
    },
  })
}

const requestUpdateStoryStatus = (storyId: number) => {
  return api.post(`/api/scholarRoute?path=stories/update/story/SEEN/${storyId}`)
}

export const storiesApis = {
  requestStories,
  requestUpdateStoryStatus,
}
