/* eslint-disable prefer-spread */
import { priceBundleName } from "DataMapper/Common/WorkshopData"
import { ietlsPlusFilterData } from "DataMapper/IeltsSection/valueProps"
import format from "date-fns/format"
import { BatchSchema } from "Interfaces/reducerTypes/BatchDetailsSchema"
import { WorkshopSchema } from "Interfaces/reducerTypes/IeltsPlusSchema"
import moment from "moment"
import {
  diffWeeks,
  isPremiumBatch,
  isPremiumBundleCheck,
} from "utils/ielts_batch_utils"
import { getNextClassTiming } from "utils/timeUtils"

export const filterLiveClass = (data: WorkshopSchema) =>
  moment(data.meetingStartTime) <= moment() &&
  moment(data.meetingEndTime) > moment()

export const repackUpcomingClass = (
  data: any,
  cb?: Function | null,
  filterPriorityList?: Array<string>,
) => {
  if (!cb) {
    if (!filterPriorityList) {
      return [].concat.apply([], Object.values(data))
    } else {
      return [].concat.apply(
        [],
        filterPriorityList.map((workshop: string) => data[workshop]),
      )
    }
  }
  if (filterPriorityList)
    return [].concat
      .apply(
        [],
        filterPriorityList.map((workshop: string) => data[workshop]),
      )
      .filter((data: WorkshopSchema) => (cb ? cb(data) : true))
  else
    return [].concat
      .apply([], Object.values(data))
      .filter((data: WorkshopSchema) => (cb ? cb(data) : true))
}

export const reduceOnlineWorkshopEnrollment = (
  result: {
    plusEnrollment: number
    foundationEnrolment: number
    premiumEnrolment: number
    selectBatchEnrollment: number
    eliteBatchEnrollment: number
  },
  data: BatchSchema,
) => {
  if (data.pricingBundleName === priceBundleName.IELTS_FOUNDATION)
    return { ...result, foundationEnrolment: result.foundationEnrolment + 1 }
  else if (data.pricingBundleName === priceBundleName.IELTS_PLUS)
    return { ...result, plusEnrollment: result.plusEnrollment + 1 }
  else if (
    data.pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM ||
    data.pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM_2
  ) {
    return { ...result, premiumEnrolment: result.premiumEnrolment + 1 }
  } else {
    return {
      ...result,
      selectBatchEnrollment: result.selectBatchEnrollment + 1,
      eliteBatchEnrollment: result.eliteBatchEnrollment + 1,
    }
  }
}

export const getPriceBundleName = (
  priceBundle: priceBundleName | string,
  removeIelts: boolean | undefined = false,
) => {
  if (priceBundle == priceBundleName.IELTS_FOUNDATION) {
    if (removeIelts) return "Foundation"
    return "IELTS Foundation"
  }
  if (isPremiumBundleCheck(priceBundle)) {
    if (removeIelts) return "Premium"
    return "IELTS Premium"
  }
  if (removeIelts) return "Plus"
  return "IELTS Plus"
}

export const excludeTimeFromDate = (date: Date): string => {
  const newDate = new Date()
  newDate.setDate(date.getDate())
  newDate.setMonth(date.getMonth())
  newDate.setFullYear(date.getFullYear())
  return newDate.toString()
}

export const getDateBundler = (classData: Array<WorkshopSchema>) => {
  const dateBundler: any = {}
  classData.forEach((value) => {
    const classDate = excludeTimeFromDate(new Date(value.meetingStartTime))
    if (dateBundler[classDate]) {
      dateBundler[classDate] = [...dateBundler[classDate], value]
    } else {
      dateBundler[classDate] = [value]
    }
  })
  return dateBundler
}

export const classIsLive = (startTime: string, endTime: string) => {
  return moment() >= moment(startTime) && moment() <= moment(endTime)
}

export type singleFilterType = { id: number; text: string }
export type filteredBatchesMapType = Record<string, WorkshopSchema[]>
export const getFilteredBatchesMap = (
  upcomingIeltsBatchesData: WorkshopSchema[],
  includeIeltsPlusPart2Batches = false,
): {
  filteredBatchesMap: filteredBatchesMapType
  typesToBeShown: singleFilterType[]
} => {
  let BatchesToBeShown: WorkshopSchema[] = []

  BatchesToBeShown = upcomingIeltsBatchesData.filter((item) =>
    isPremiumBatch(item),
  )
  const weeksFilters: filteredBatchesMapType = BatchesToBeShown.reduce(
    (acc: filteredBatchesMapType, curr) => {
      const weeksDurationNumber: number = diffWeeks(
        curr.startTime,
        curr.endTime,
      )
      if (Array.isArray(acc[weeksDurationNumber])) {
        acc[weeksDurationNumber].push(curr)
      } else {
        acc[weeksDurationNumber] = [curr]
      }
      return acc
    },
    {},
  )
  const typesToBeShown = Object.keys(weeksFilters).map((item) => ({
    id: parseInt(item),
    text: `${item} ${parseInt(item) === 1 ? "week" : "weeks"}`,
  }))
  typesToBeShown.unshift({ id: ietlsPlusFilterData.ALL, text: "All" })
  weeksFilters[ietlsPlusFilterData.ALL] = BatchesToBeShown

  return {
    filteredBatchesMap: weeksFilters,
    typesToBeShown,
  }
}

export const getDateText = (startDate: string): string => {
  if (startDate) {
    const batchStartDateString = new Date(startDate).toDateString()
    const tommorowDateString = new Date(
      new Date().setDate(new Date().getDate() + 1),
    ).toDateString()
    if (batchStartDateString === new Date().toDateString()) {
      return "Today"
    } else if (batchStartDateString === tommorowDateString) {
      return "Tommorow"
    }
    return format(new Date(startDate), "do MMMM")
  }
  return ""
}

export const getNextClassDate = (batchDate: WorkshopSchema): string => {
  const batchClassData = batchDate.batchClassResponseDto
  try {
    if (batchClassData) {
      const dayToShow = batchClassData.dayTobeShown
      const mapOfBatches = batchClassData.mapOfDayToBatchClassTopicDto
      if (!mapOfBatches) return batchDate.startDate
      if (dayToShow) return mapOfBatches && mapOfBatches[dayToShow].date
      return (
        Object.values(mapOfBatches).find(
          (data) => new Date() < new Date(data.date),
        )?.date || batchDate.startDate
      )
    }
  } catch (e) {
    console.log(e)
  }
  return batchDate.startDate
}

export const getLectureDetails = (
  batchDate: WorkshopSchema,
  excludeNextClass?: boolean,
): { current: number; total: number; str: string } => {
  if (batchDate.batchClassResponseDto) {
    const totalClass = Object.keys(
      batchDate.batchClassResponseDto.mapOfDayToBatchClassTopicDto || {},
    ).length
    return {
      current: batchDate.batchClassResponseDto.dayTobeShown || 0,
      total: totalClass,
      str: `${
        excludeNextClass
          ? batchDate.batchClassResponseDto.completedClasses
          : batchDate.batchClassResponseDto.dayTobeShown
      }/${totalClass}`,
    }
  } else return { current: 0, total: 0, str: `0/0` }
}

export const getLectureDateText = (batchData: WorkshopSchema) => {
  const day = getNextClassTiming(getNextClassDate(batchData))
  return `${day && `${day}, `}${format(
    new Date(getNextClassDate(batchData)),
    "dd LLL",
  )}`
}

export const getLectureDateObject = (batchData: WorkshopSchema) => {
  const nextLectureDate = getNextClassDate(batchData)
  const day = getNextClassTiming(nextLectureDate)
  return {
    relativeDate: day,
    date: new Date(nextLectureDate),
  }
}

export function getLectureNumber(
  batchDate: WorkshopSchema,
  excludeNextClass?: boolean,
) {
  return `Lecture ${getLectureDetails(batchDate, excludeNextClass).str}`
}

export function getLectureTime(batchData: WorkshopSchema) {
  return `${format(new Date(batchData.startTime), "hh:mm a")}-${format(
    new Date(batchData.endTime),
    "hh:mm a",
  )}`
}

export const hideScheduleClassList = (batchData: WorkshopSchema) => {
  const classSchedule =
    batchData.batchClassResponseDto?.mapOfDayToBatchClassTopicDto || null
  if (classSchedule) {
    return !!Object.keys(classSchedule)
      .map((value) => classSchedule[value])
      .find((value) => !value.topic)
  } else {
    return true
  }
}
