import { AnyAction } from "redux";

const initialState: any = {
  initialData: {},
  isLoading: false,
  isError:false,
  error: "",
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "FETCH_SPOT_FORM_DATA":
      return {
        ...state,
        isLoading:true,
        isError:false,
      };
    case "FETCH_SPOT_FORM_DATA_SUCCESS":
      return {
        ...state,
        initialData: action.payload,
        isLoading:false,
        isError:false
      };
    case "FETCH_SPOT_FORM_DATA_FAIL":
      return {
        ...state,
        isLoading:false,
        isError:true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
