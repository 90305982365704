import api from "service/api"

const fetchDocumentDetailsRequest = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=profile/post/doc`)
    return res
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page")
  }
}

export const documentDetailsApi = {
  fetchDocumentDetailsRequest,
}
