import { AnyAction } from "redux";

const initialState: any = {
  stories: [],
  activeCategory: null,
  activeStory: null,
  showStory: false,
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "FETCH_STORIES_SUCCESS":
      return {
        ...state,
        stories: action.payload,
      };
    case "UPDATE_ACTIVE_CATEGORY":
      return {
        ...state,
        activeCategory: action.payload,
      };
    case "UPDATE_ACTIVE_STORY":
      return {
        ...state,
        activeStory: action.payload,
      };
    case "TOGGLE_SHOW_STORY":
      return {
        ...state,
        showStory: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
