import { AnyAction } from "redux";
import { JoinMasterClassMeetingSchema } from "Interfaces/reducerTypes/MasterclassSchema";
import { joinMasterClassType } from "components/Profile/IeltsProfile/JoinMasterClass/redux/joinMasterClassActions";

const initialState: JoinMasterClassMeetingSchema = {
  startDate: "",
  endDate: "",
  meetingUrl: "",
  meetingStatus: "loading",
  title: "",
  workshopType: null,
  batchDay: null,
  slotType: null,
};

const joinMasterClassReducer = (
  state: JoinMasterClassMeetingSchema = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case joinMasterClassType.FETCH_REGISTERED_MEETING_DATA_SUCCESS:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        meetingUrl: action.payload.meetingUrl,
        title: action.payload.title,
        workshopType: action.payload.workshopType,
        batchDay: action.payload.batchDay,
      };
    case joinMasterClassType.SET_IELTS_DEMO_DATA:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        meetingUrl: action.payload.meetingUrl,
      };
    case joinMasterClassType.FETCH_REGISTERED_MEETING_DATA_FAIL:
      return {
        ...state,
        meetingStatus: action.payload,
      };
    case joinMasterClassType.CHANGE_MEETING_STATUS:
      return {
        ...state,
        meetingStatus: action.payload,
      };
    default:
      return state;
  }
};
export default joinMasterClassReducer;
