import api from "service/api"
import { ResponseDto } from "Interfaces/common/ResponseDto"
import { PaymentDetailDto } from "Interfaces/common/PaymentSchema"

const fetchUserDetailsForPayment = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=student/pre/webinar/payment`,
    )
    return res
  } catch (err: any) {
    throw new Error(err || "Something went wrong. Please refresh the page")
  }
}

const fetchPaymentData = async (payload: any) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=student/pre/webinar/payment/update`,
      payload,
    )
    return res
  } catch (err: any) {
    throw new Error(err || "Something went wrong. Please refresh the page")
  }
}

const validatePromoCode = async (promo: string) => {
  try {
    const res = await api.post(
      `/api/scholarOpenRoute?path=promocode/apply/${promo}`,
      {
        bundleId: 0,
      },
    )
    return res
  } catch (err: any) {
    throw new Error(err || "Something went wrong. Please refresh the page")
  }
}

// const initilizePayment = async () => {
//   try {
//     const res = await api.get(
//       `/api/scholarRoute?path=profile/post/visa-counsellor`
//     );
//     if (res.data.success) {
//       return res.data.data;
//     } else {
//       throw new Error(res.data.message);
//     }
//   } catch (err: any) {
//     throw new Error(err || "Something went wrong. Please refresh the page");
//   }
// };

const fetchPaymentDetails = async (
  payload: string,
): Promise<ResponseDto<PaymentDetailDto>> => {
  try {
    const res = await api.get<ResponseDto<PaymentDetailDto>>(
      `/api/scholarRoute?path=payment/info/${payload}`,
    )
    return res.data
  } catch (err: any) {
    return { success: false, message: "Something went wrong" }
  }
}

export const paymentApi = {
  fetchPaymentDetails,
  fetchPaymentData,
  validatePromoCode,
  //initilizePayment,
  fetchUserDetailsForPayment,
}
