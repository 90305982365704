import { call, put, takeLatest } from 'redux-saga/effects'
import { visaAssistanceActions } from '../actions/visa-assistance-action'
import { visaAssistanceApi } from '../apis/visa-assistance-apis'
import VisaAssistanceConstants from '../constants/visa-assistance-constants'

function* fetchVisaAssistanceData(): Generator<any, any, any> {
  try {
    const res = yield call(visaAssistanceApi.fetchVisaAssistanceDataRequest)
    if (res.data.success) {
      yield put(
        visaAssistanceActions.ffetchVisaAssistanceDataSuccess(res.data.data)
      )
    } else {
      yield put(
        visaAssistanceActions.fetchVisaAssistanceDataError(res.data.message)
      )
    }
  } catch (e: any) {
    console.error(e)
    yield put(visaAssistanceActions.fetchVisaAssistanceDataError(e.message))
  }
}

function* visaAssistanceSaga() {
  yield takeLatest(
    VisaAssistanceConstants.FETCH_VISA_ASSISTANCE_DATA,
    fetchVisaAssistanceData
  )
}

export default visaAssistanceSaga
