import OverviewConstants from "../constants/overview-constants";

const fetchOverviewData = () => ({
  type: OverviewConstants.FETCH_OVERVIEW_DATA,
});

const fetchOverviewDataSuccess = (payload: any) => ({
  type: OverviewConstants.FETCH_OVERVIEW_DATA_SUCCESS,
  payload,
});

const fetchOverviewDataError = (message: string) => ({
  type: OverviewConstants.FETCH_OVERVIEW_DATA_ERROR,
  payload: message,
});

const toggleBookCounsellorModal = (payload: boolean) => ({
  type: OverviewConstants.TOGGLE_BOOK_COUNSELLING_MODAL,
  payload,
});

const toggleSpeakToCounsellorModal = (payload: boolean) => ({
  type: OverviewConstants.TOGGLE_SPEAK_TO_COUNSELLOR_MODAL,
  payload,
});

const toggleGetLsPlusModal = (payload: boolean) => ({
  type: OverviewConstants.TOGGLE_GET_LS_PLUS_MODAL,
  payload,
});

const fetchCounsellingFeeData = () => ({
  type: OverviewConstants.FETCH_COUNSELLING_FEE_DATA,
});

const fetchCounsellingFeeDataSuccess = (payload: any) => ({
  type: OverviewConstants.FETCH_COUNSELLING_FEE_DATA_SUCCESS,
  payload,
});

const fetchCounsellingFeeDataError = (message: string) => ({
  type: OverviewConstants.FETCH_COUNSELLING_FEE_DATA_ERROR,
  payload: message,
});

const fetchPostPaymentData = () => ({
  type: OverviewConstants.FETCH_POST_PAYMENT_DATA,
});

const fetchPostPaymentDataSuccess = (payload: any) => ({
  type: OverviewConstants.FETCH_POST_PAYMENT_DATA_SUCCESS,
  payload,
});

const fetchPostPaymentDataError = (message: string) => ({
  type: OverviewConstants.FETCH_POST_PAYMENT_DATA_ERROR,
  payload: message,
});

const fetchOffer = () => ({
  type: OverviewConstants.FETCH_OFFER,
});

const fetchOfferSuccess = (payload: any) => ({
  type: OverviewConstants.FETCH_OFFER_SUCCESS,
  payload,
});

const fetchOfferError = (message: string) => ({
  type: OverviewConstants.FETCH_OFFER_ERROR,
  payload: message,
});

const toggleAllTaskView = (isOpen: boolean) => ({
  type: OverviewConstants.TOGGLE_ALL_TASK_VIEW,
  payload: isOpen,
});

const fetchPlaylist = () => ({
  type: OverviewConstants.FETCH_PLAYLIST,
});

const fetchPlayListSuccess = (payload: any) => ({
  type: OverviewConstants.FETCH_PLAYLIST_SUCCESS,
  payload,
});

const fetchPlayListFail = (payload: any) => ({
  type: OverviewConstants.FETCH_PLAYLIST_FAIL,
  payload,
});

export const overviewActions = {
  fetchOverviewData,
  fetchOverviewDataSuccess,
  fetchOverviewDataError,

  toggleBookCounsellorModal,
  toggleSpeakToCounsellorModal,
  toggleGetLsPlusModal,
  fetchCounsellingFeeData,
  fetchCounsellingFeeDataSuccess,
  fetchCounsellingFeeDataError,

  fetchPostPaymentData,
  fetchPostPaymentDataSuccess,
  fetchPostPaymentDataError,

  fetchOffer,
  fetchOfferSuccess,
  fetchOfferError,
  toggleAllTaskView,

  fetchPlaylist,
  fetchPlayListSuccess,
  fetchPlayListFail,
};
