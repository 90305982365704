import api from "service/api"
import { batchType } from "DataMapper/Common/WorkshopData"

const fetchMasterclassSubscriptionStatus = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=workshop/master-class-subscription-status`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const fetchMasterclassData = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=workshop/MASTER_CLASS/0/20/v3`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const fetchBeginnerMasterclassData = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=workshop/${batchType.BEGINNER_MASTER_CLASS}/0/12/v3`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const enrollForMasterclass = async () => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=workshop/master-class/subscribe`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const fetchMasterclassSubscriptionState = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=v2/workshop/ieltsFreeCourse/status`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}
const fetchMasterclassRecommendation = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=workshop/recommended/master-class`,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const subscribeToMasterClass = async (): Promise<any> => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=workshop/master-class/subscribe`,
    )
    return res.data
  } catch (err: any) {
    return {
      success: false,
      message:
        "Something went wrong please try later after some time. server is down now we are working on it",
    }
  }
}
export const masterclassApis = {
  fetchMasterclassSubscriptionStatus,
  fetchMasterclassData,
  enrollForMasterclass,
  fetchBeginnerMasterclassData,
  fetchMasterclassSubscriptionState,
  fetchMasterclassRecommendation,
  subscribeToMasterClass,
}
