import api from "service/api"

const saveForm = async (values: Record<string, any>) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=workshop/ielts-class`,
      values,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

const enrollToIeltsPlus = async (batchId: string) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=student/workshop/1/${batchId}/enroll`,
    )
    return res.data
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime")
  }
}

const updateUserExamType = async (ieltsExamType: string) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=ielts-elite/examtype/${ieltsExamType}`,
    )
    return res.data
  } catch (e) {
    console.log("Something went wrong")
  }
}

export const ieltsRegistrationFormApi = {
  saveForm,
  enrollToIeltsPlus,
  updateUserExamType,
}
