// # Speed in Seconds (spinner rotating speed)
// # Spinner Size in em units relative to the font size of the parent element.
// # borderSize in em units relative to the font size of the parent element.
// # borderColor color background for border.
import { FunctionComponent } from "react"

interface Props {
  speed?: number | string
  size?: number | string
  borderSize?: number
  borderColor?: string
  color?: string
}

const Spinner: FunctionComponent<Props> = ({
  speed = 0.8,
  size = 2.5,
  borderSize = 0.1,
  borderColor = "#ffffff",
  color = "#00b0aa",
}) => {
  const sizeMapper: any = {
    small: 1.1,
    medium: 2.5,
    large: 5,
    extraLarge: 10,
  }

  const sizeValue = sizeMapper[size] ?? size

  return (
    <div className={`loadingspinner rounded-full animate-spin pointer-events-none`}>
      <style jsx>
        {`
          .loadingspinner {
            width: ${sizeValue}em;
            height: ${sizeValue}em;
            border: ${borderSize}em solid transparent;
            border-color: ${borderColor};
            border-top-color: ${color};
          }
          }
        `}
      </style>
    </div>
  )
}

export default Spinner
