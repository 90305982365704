import React, { FunctionComponent } from "react"
import { loggedInNav, loggedOutNav } from "utils/navbarUtilsLs"
import { navRoutes } from "page_routes"
import { trackEventAsync } from "scripts/segment"
import Navbar from "@leapfinance/frontend-commons/components/LeapScholar/Navbar"
import { LoginProps, withLogin } from "components/Login/withLogin"
import { getTokenCookie } from "lib/auth-cookies-new"
import { AuthToken } from "lib/auth_token"
import { useRouter } from "next/router"
import { isIeltsFlow } from "utils/navbarUtils"

const NavbarWrapper: FunctionComponent<LoginProps> = ({ initLogin }) => {
  const token = getTokenCookie()
  const auth = new AuthToken(token)
  const isLoggedIn = auth?.isValid
  const router = useRouter()

  const handleSegmentEvents = async (title: string) => {
    await trackEventAsync("Click", {
      contentFormat: "Button",
      widgetName: "Website Header",
      contentName: title,
      pageName: "NA",
    })
  }

  const getNavData = () => {
    if (isLoggedIn) return loggedInNav
    else
      return {
        ...loggedOutNav,
        buttons: [
          {
            title: "Sign in",
            buttonType: "SECONDARY",
            handleClick: () =>
              initLogin(
                "Enter your mobile number to continue",
                true,
                "",
                isIeltsFlow(router.pathname) ? "ielts" : "NA",
                () => {},
              ),
          },
          {
            title: "Sign Up",
            buttonType: "PRIMARY",
            handleClick: () =>
              initLogin(
                "Enter your mobile number to continue",
                true,
                "",
                isIeltsFlow(router.pathname) ? "ielts" : "NA",
                () => {},
              ),
          },
        ],
      }
  }

  const getSelectedTitle = () => {
    if (router.pathname.includes("/profile/counselling")) return "Counselling"
    if (router.pathname.includes("/test-prep/dashboard")) return "IELTS Prep"
    return ""
  }

  return (
    <div className="pb-[69px] md:pb-[74px]">
      <Navbar
        {...getNavData()}
        isLoggedIn={isLoggedIn}
        logoLink={navRoutes.HOMEPAGE}
        handleSegmentEvents={handleSegmentEvents}
        selectedTitle={getSelectedTitle()}
      />
    </div>
  )
}

export default withLogin(NavbarWrapper)
