import { call, put, takeLatest } from 'redux-saga/effects'
import { storiesApis } from './StoriesApis'
import { storiesActions } from './StoriesActions'
import cloneDeep from 'lodash/cloneDeep'
import { AnyAction } from 'redux'

function* handleFetchStories(): any {
  try {
    const res = yield call(storiesApis.requestStories)
    if (res.data.success) {
      let newRes = cloneDeep(res.data.data)
      newRes = newRes.filter(
        (data: any) =>
          data.listOfImages &&
          Array.isArray(data.listOfImages) &&
          data.listOfImages.length > 0
      )
      for (let i = 0; i < newRes.length; i += 1) {
        const temp = newRes[i].listOfImages.filter((img: any) => {
          return img.userStatus === 'NOT_SEEN'
        })
        if (temp.length > 0) {
          newRes[i].userstatus = 'NOT_SEEN'
        }
        newRes[i].listOfImages.sort((a: any, b: any) => {
          return a.userStatus === b.userStatus
            ? 0
            : a.userStatus === 'NOT_SEEN'
            ? -1
            : 1
        })
      }
      newRes = newRes.sort((a: any, b: any) => {
        return a.userstatus === b.userstatus
          ? 0
          : a.userstatus === 'NOT_SEEN'
          ? -1
          : 1
      })
      yield put(storiesActions.fetchStoriesSuccess(newRes))
    }
  } catch (err: any) {
    console.log(err)
  }
}

function* handleUpdateStoryStatus(action: AnyAction): any {
  try {
    const res = yield call(storiesApis.requestUpdateStoryStatus, action.payload)
    if (res.data.success) {
    }
  } catch (err: any) {
    console.log(err)
  }
}

function* storiesSaga() {
  yield takeLatest('FETCH_STORIES', handleFetchStories)
  yield takeLatest('UPDATE_STORY_SEEN', handleUpdateStoryStatus)
}

export default storiesSaga
