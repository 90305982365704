import UpcomingAppointmentsConstants from "../constants/upcoming-appointments-constants";

const fetchUpcomingAppointmentsData = () => ({
  type: UpcomingAppointmentsConstants.FETCH_UPCOMING_APPOINTMENTS_DATA,
});

const fetchUpcomingAppointmentsDataSuccess = (payload: string) => ({
  type: UpcomingAppointmentsConstants.FETCH_UPCOMING_APPOINTMENTS_DATA_SUCCESS,
  payload,
});

const fetchUpcomingAppointmentsDataError = (message: string) => ({
  type: UpcomingAppointmentsConstants.FETCH_UPCOMING_APPOINTMENTS_DATA_ERROR,
  payload: message,
});

export const upcomingAppointmentsActions = {
  fetchUpcomingAppointmentsData,
  fetchUpcomingAppointmentsDataSuccess,
  fetchUpcomingAppointmentsDataError,
};
