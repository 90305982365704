import { AnyAction } from 'redux'
import DocumentDetailsConstants from '../constants/document-details-constants'

const initialState: any = {
  documentDetailsData: {},
  error: '',
  isLoading: false,
  isError: false,
}

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case DocumentDetailsConstants.FETCH_DOCUMENT_DETAILS_DATA:
      return {
        ...state,
        isLoading: true,
        isError: false,
      }
    case DocumentDetailsConstants.FETCH_DOCUMENT_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        documentDetailsData: action.payload,
        isLoading: false,
        isError: false,
      }
    case DocumentDetailsConstants.FETCH_DOCUMENT_DETAILS_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isError: true,
      }
    default:
      return state
  }
}

export default reducer
