import { call, put, select, takeLatest } from "@redux-saga/core/effects"
import { ieltsEliteApis } from "components/Ielts/IeltsEliteDemo/redux/IeltsEliteApi"
import {
  fetchExamBookedStatusSlice,
  fetchGetIeltsStage,
  fetchGetStudentEligibility,
  fetchIeltsQeStatusSlice,
  fetchLiyStatusSlice,
  fetchPostDetailsIeltsClass,
  fetchPostIeltsEliteNudgeForm,
  fetchQeFormData,
  lsqTriggerSlice,
  postExamBookingDataSlice,
  runIeltsQualificationEngineAction,
  setExamBookedStatus,
  setIeltsStageError,
  setIeltsStageLoading,
  setIsExamBookedSubmit,
  setIsQeFormSubmit,
  setLiyPaymentStatus,
  setQeFormData,
  setStudentEligibilityError,
  setStudentEligibilityLoading,
  setUserQeData,
  updateIeltsStage,
  updateStudentEligibility,
} from "components/Ielts/IeltsEliteDemo/redux/IeltsEliteSlice"
import { State } from "Interfaces/storeSchema"
import { AnyAction } from "redux"
import { trackEventAsync } from "scripts/segment"
import { isFunction } from "utils/common_utils"

function* fetchGetIeltsStageSaga(): Generator<any, any, any> {
  yield put(setIeltsStageLoading(true))
  const postGetStageAction = yield select(
    (state: State) => state.IeltsElite.postGetStageAction,
  )
  try {
    const res = yield call(ieltsEliteApis.fetchGetIeltsStage)
    const userStage = res.data.userStage
    yield put(setIeltsStageLoading(false))
    if (res.success) {
      yield put(updateIeltsStage(userStage))
    } else {
      yield put(setIeltsStageError(res.data))
    }
    if (isFunction(postGetStageAction)) {
      postGetStageAction(userStage)
    }
  } catch (error) {
    if (isFunction(postGetStageAction)) {
      postGetStageAction(null)
    }
    yield put(setIeltsStageLoading(false))
    yield put(
      setIeltsStageError("Something went wrong Please try after some time"),
    )
  }
}

function* fetchGetStudentEligibilitySaga(): Generator<any, any, any> {
  yield put(setStudentEligibilityLoading(true))
  const postGetEligibilityAction = yield select(
    (state: State) => state.IeltsElite.postGetEligibilityAction,
  )
  try {
    const res = yield call(ieltsEliteApis.fetchGetStudentEligibility)
    yield put(setStudentEligibilityLoading(false))
    if (res.success) {
      yield put(updateStudentEligibility(res.data))
    } else {
      yield put(setStudentEligibilityError(res.data))
    }
    if (isFunction(postGetEligibilityAction)) {
      postGetEligibilityAction(res.data)
    }
  } catch (error) {
    yield put(setStudentEligibilityLoading(false))
    yield put(
      setStudentEligibilityError(
        "Something went wrong Please try after some time",
      ),
    )
    if (isFunction(postGetEligibilityAction)) {
      postGetEligibilityAction(null)
    }
  }
}

function* fetchPostIeltsEliteNudgeFormSaga(
  action: AnyAction,
): Generator<any, any, any> {
  try {
    const res = yield call(
      ieltsEliteApis.fetchPostIeltsEliteNudgeForm,
      action.payload,
    )
    if (res.success) {
      yield put(setIsQeFormSubmit(true))
      const postFillIeltsEliteNudgeForm = yield select(
        (state: State) => state.IeltsElite.postFillIeltsEliteNudgeForm,
      )
      if (isFunction(postFillIeltsEliteNudgeForm)) {
        postFillIeltsEliteNudgeForm(action.payload)
      }
      const qeResponse = yield call(
        ieltsEliteApis.ieltsQualificationEngineApi,
        false,
        action.payload.source,
      )
      if (qeResponse.success) {
        if (isFunction(action.payload.qeEngineCallBack)) {
          yield action.payload.qeEngineCallBack(qeResponse.data)
        }
      }
    } else {
      yield put(setIsQeFormSubmit(false))
    }
  } catch (error) {
    yield put(setIsQeFormSubmit(false))
  }
}

function* fetchPostDetailsIeltsClassSaga(
  action: AnyAction,
): Generator<any, any, any> {
  const postFillIeltsQeFormAction = yield select(
    (state: State) => state.IeltsElite.postFillIeltsQeFormAction,
  )
  try {
    yield call(ieltsEliteApis.fetchPostDetailsIeltsClass, action.payload)
  } catch (error) {}
  if (isFunction(postFillIeltsQeFormAction)) {
    postFillIeltsQeFormAction(action.payload)
  }
}

function* fetchQeFormDatasaga(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(ieltsEliteApis.fetchQeFormDataApi)
    if (res.data.success) {
      yield put(setQeFormData(res.data.data))
      if (isFunction(action.payload)) {
        action.payload(res.data.data)
      }
    } else {
      yield put(setQeFormData(null))
      if (isFunction(action.payload)) {
        action.payload(null)
      }
    }
  } catch (error) {
    yield put(setQeFormData(null))
    if (isFunction(action.payload)) {
      action.payload(null)
    }
  }
}

function* runIeltsQESaga(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      ieltsEliteApis.ieltsQualificationEngineApi,
      action.payload,
    )
    yield put(setUserQeData(res?.data))
  } catch (e) {
    console.log("err", e)
  }
}

function* fetchIeltsQeStatusSaga(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsEliteApis.fetchIeltsQeStatus)
    if (res.data.success) {
      yield put(setUserQeData(res?.data.data))
    } else {
      yield put(setUserQeData(null))
    }
  } catch (e) {
    console.log("err", e)
  }
}
function* postExamBookingDataSaga(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      ieltsEliteApis.postExamBookingDataApi,
      action.payload,
    )
    if (res.data.success) {
      yield trackEventAsync("Form Submitted", {
        formId: "Exam Booking Form",
        examStatus: action.payload.examBooked,
        assistanceStatus: action.payload.examBookingAssistance,
      })
      yield put(setIsExamBookedSubmit(true))
    } else {
      yield put(setIsExamBookedSubmit(false))
    }
  } catch (e) {
    yield put(setIsExamBookedSubmit(false))
    console.log("err", e)
  }
}

function* fetchLiyStatusSaga(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsEliteApis.fetchLiyStatusApi)
    if (res.data.success) {
      yield put(setLiyPaymentStatus(res.data.data))
    } else {
      yield put(setLiyPaymentStatus(null))
    }
  } catch (e) {
    yield put(setLiyPaymentStatus(null))
    console.log("err", e)
  }
}

function* fetchExamBookedStatusSaga(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsEliteApis.fetchExamBookedStatusApi)
    if (res.data.success) {
      yield put(setExamBookedStatus(res.data.data))
    } else {
      yield put(setExamBookedStatus(null))
    }
  } catch (e) {
    yield put(setExamBookedStatus(null))
    console.log("err", e)
  }
}

function* lsqTriggerSaga(action: AnyAction): Generator<any, any, any> {
  try {
    yield call(ieltsEliteApis.lsqTriggerApi, action.payload)
  } catch (error) {
    console.log("err", error)
  }
}

export default function* ieltsEliteSaga() {
  yield takeLatest(fetchGetIeltsStage, fetchGetIeltsStageSaga)
  yield takeLatest(
    fetchPostIeltsEliteNudgeForm,
    fetchPostIeltsEliteNudgeFormSaga,
  )
  yield takeLatest(fetchGetStudentEligibility, fetchGetStudentEligibilitySaga)
  yield takeLatest(fetchPostDetailsIeltsClass, fetchPostDetailsIeltsClassSaga)
  yield takeLatest(fetchQeFormData, fetchQeFormDatasaga)
  yield takeLatest(runIeltsQualificationEngineAction, runIeltsQESaga)
  yield takeLatest(fetchIeltsQeStatusSlice, fetchIeltsQeStatusSaga)
  yield takeLatest(postExamBookingDataSlice, postExamBookingDataSaga)
  yield takeLatest(fetchLiyStatusSlice, fetchLiyStatusSaga)
  yield takeLatest(fetchExamBookedStatusSlice, fetchExamBookedStatusSaga)
  yield takeLatest(lsqTriggerSlice, lsqTriggerSaga)
}
