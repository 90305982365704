import api from "service/api"
import { CallBackRequest } from "components/Ielts/DemoVideo/DataMapper"
import { toast } from "react-toastify"

const fetchRequestCallBack = async (formData: any) => {
  try {
    const path = "v2/workshop/callbackDetails"
    const res = await api.post(`/api/scholarRoute?path=${path}`, formData)
    return res.data
  } catch (error) {
    toast?.error("Something went wrong. Please try after sometime")
    return "something went wrong"
  }
}

const fetchRequestCallBackStatus = async (userCategory: string) => {
  try {
    const path = `v2/workshop/callback-request/${userCategory}/status`
    const res = await api.get(`/api/scholarRoute?path=${path}`)
    return res.data
  } catch (error) {
    return "something went wrong"
  }
}

const submitRequestCallback = (callbackData: CallBackRequest) =>
  api.post("/api/scholarRoute?path=v2/workshop/callbackDetails", callbackData, {
    headers: {
      "X-API-VERSION": "2",
    },
  })

const RequestCallBackApi = {
  fetchRequestCallBack,
  fetchRequestCallBackStatus,
  submitRequestCallback,
}
export default RequestCallBackApi
