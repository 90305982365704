import {
  ModalTextSchema,
  IeltsPlusDataSchema,
  IsEnrolledForBatchesType,
} from 'Interfaces/reducerTypes/IeltsPlusSchema'

export const IeltsPlusActionsTypes = {
  CHECK_IELTS_BATCHES_ENROLLMENT: 'CHECK_IELTS_BATCHES_ENROLLMENT',
}

const fetchIeltsData = () => ({
  type: 'IELTS_FETCH_REQUESTED',
})

const fetchSuccess = (payload: Array<IeltsPlusDataSchema>) => ({
  type: 'IElTS_FETCH_SUCCEEDED',
  payload,
})

const fetchFail = (message: string) => ({
  type: 'IELTS_FETCH_FAILED',
  payload: message,
})

const updateModalText = (payload: ModalTextSchema) => ({
  type: 'UPDATE_MODAL_TEXT',
  payload,
})

const toggleConfirmModal = (payload: boolean) => ({
  type: 'TOGGLE_CONFIRM_MODAL',
  payload,
})

const enrollToIeltsPlus = (batchId: number) => ({
  type: 'ENROLL_TO_IELTS_PLUS',
  payload: batchId,
})

const enrollSuccess = (payload: boolean) => ({
  type: 'ENROLL_SUCCESS',
  payload,
})

const enrollFail = (message: string) => ({
  type: 'ENROLL_FAILED',
  payload: message,
})

const fetchObjects = (batchId: string) => ({
  type: 'FETCH_OBJECTS_REQUESTED',
  payload: batchId,
})

const fetchObjectsSuccess = (payload: any) => ({
  type: 'FETCH_OBJECTS_SUCCESS',
  payload,
})

const fetchObjectsFail = (message: string) => ({
  type: 'FETCH_OBJECTS_FAIL',
  payload: message,
})

const toggleRegisterModal = (payload: boolean) => ({
  type: 'TOGGLE_REGISTER_MODAL',
  payload,
})

const toggleJoinModal = (payload: boolean) => ({
  type: 'TOGGLE_JOIN_MODAL',
  payload,
})

const fetchEnrolledBatches = () => ({
  type: 'FETCH_ENROLLED_BATCHES_REQUESTED',
})

const fetchEnrolledBatchesSuccess = (payload: any) => ({
  type: 'FETCH_ENROLLED_BATCHES_SUCCESS',
  payload,
})

const updateError = (message: string) => ({
  type: 'UPDATE_IELTS_PLUS_ERROR_MESSAGE',
  payload: message,
})

const updateActiveBatchId = (payload: number) => ({
  type: 'UPDATE_ACTIVE_BATCH_ID',
  payload,
})

const updateAddToCalendar = (payload: number) => ({
  type: 'UPDATE_ADD_TO_CALENDAR_STATUS',
  payload,
})

const addCompletedIeltsClasses = (payload: any) => ({
  type: 'ADD_COMPLETED_IELTS_CLASSES',
  payload,
})

const addUpcomingIeltsClasses = (payload: any) => ({
  type: 'ADD_UPCOMING_IELTS_CLASSES',
  payload,
})

const addAllIeltsClasses = (payload: any) => ({
  type: 'ADD_ALL_IELTS_CLASSES',
  payload,
})

const toggleLoadingEnrolledClasses = (payload: boolean) => ({
  type: 'TOGGLE_LOADING_ENROLLED_CLASSES',
  payload,
})

const checkIeltsEnrollment = (payload: boolean) => ({
  type: 'CHECK_IETLS_ENROLLMENT',
  payload,
})

const checkFoundationEnrollment = (payload: boolean) => ({
  type: 'CHECK_FOUNDATION_ENROLLMENT',
  payload,
})
const checkPlusEnrollment = (payload: boolean) => ({
  type: 'CHECK_PLUS_ENROLLMENT',
  payload,
})

const checkPremiumEnrollment = (payload: boolean) => ({
  type: 'CHECK_PREMIUM_ENROLLMENT',
  payload,
})

const checkIeltsBatchesEnrollment = (payload: IsEnrolledForBatchesType) => ({
  type: IeltsPlusActionsTypes.CHECK_IELTS_BATCHES_ENROLLMENT,
  payload,
})

const fetchRewardList = (payload: string) => ({
  type: 'FETCH_REWARD_LIST_REQUESTED',
  payload,
})
const fetchRewardListSuccess = (payload: any) => ({
  type: 'FETCH_REWARD_LIST_SUCCESS',
  payload,
})
const toggleRewardModal = (payload: boolean) => ({
  type: 'TOGGLE_REWARD_MODAL',
  payload,
})
const getClickedRewardDay = (payload: string) => ({
  type: 'GET_CLICKED_REWARD_DAY',
  payload,
})

export const ieltsPlusActions = {
  fetchIeltsData,
  fetchSuccess,
  fetchFail,
  updateModalText,
  toggleConfirmModal,
  enrollToIeltsPlus,
  enrollSuccess,
  enrollFail,
  fetchObjects,
  fetchObjectsSuccess,
  fetchObjectsFail,
  toggleRegisterModal,
  toggleJoinModal,
  fetchEnrolledBatches,
  fetchEnrolledBatchesSuccess,
  updateError,
  updateActiveBatchId,
  updateAddToCalendar,
  addCompletedIeltsClasses,
  addUpcomingIeltsClasses,
  toggleLoadingEnrolledClasses,
  checkIeltsEnrollment,
  checkFoundationEnrollment,
  checkPlusEnrollment,
  checkIeltsBatchesEnrollment,
  fetchRewardList,
  fetchRewardListSuccess,
  toggleRewardModal,
  getClickedRewardDay,
  checkPremiumEnrollment,
  addAllIeltsClasses,
}
