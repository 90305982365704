import api from "service/api"

const fetchNotices = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=workshop/notice-board/notice`,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please try after sometime")
  }
}

export const noticeBoardApi = {
  fetchNotices,
}
