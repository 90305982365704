import api from "service/api"

export enum LOGIN_STEPS {
  GENERATE_OTP = "GENERATE_OTP",
  SUBMIT_OTP = "SUBMIT_OTP",
  RESEND_OTP = "RESEND_OTP",
  LOGIN_WITH_TC = "LOGIN_WITH_TC",
  VERFY_LOGIN_WITH_TC = "VERFY_LOGIN_WITH_TC",
  REGISTER_USER = "REGISTER_USER",
}

export function removeLeadingZeros(phoneNumber: string) {
  // Use a regular expression to match and remove leading zeros
  return phoneNumber.replace(/^0+/, "")
}

export const validateAndRedirectForIeltsForm = async (callbackUrl: string, isDownloadPage?: boolean) => {
  const openUrl = (url: string) => window.open(url, "_self");
  const decodedUrl = decodeURIComponent(callbackUrl);
  if (isDownloadPage || decodedUrl?.includes("mock-tests/band-predictor-test")) {
    try {
      const res = await api.get('/api/scholarRoute?path=ielts-elite/details');
      const { success, data, message } = res?.data || {};

      if (success) {
        const requiredFields = ["ieltsExamType", "whenIelts", "whatCareerStage", "name"];
        const isFormNotFilled = !data || requiredFields.some((field) => !data[field]);

        const redirectUrl: string = isFormNotFilled
          ? isDownloadPage
            ? `/ielts-seo-form/ielts_seo_blog_download?url=${encodeURIComponent(callbackUrl)}`
            : `/ielts-seo-form/ielts_seo_bpt?callbackUrl=${encodeURIComponent(callbackUrl)}`
          : callbackUrl || "";

        openUrl(redirectUrl);
      } else {
        throw new Error(message);
      }
    } catch (err: any) {
      console.error(err?.message || "Something went wrong. Please try after sometime");
      openUrl(callbackUrl || "");
    }
  } else {
    openUrl(callbackUrl || "");
  }
};
