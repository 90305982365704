import api from "service/api"

const saveForm = async (values: Record<string, any>) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=workshop/master-class`,
      values,
      {
        headers: {
          "X-API-VERSION": "2",
        },
      },
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

export const masterclassRegistrationFormApi = {
  saveForm,
}
