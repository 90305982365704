import CommunityConstants from './CommunityConstants'

const setLoadingTrue = () => ({
  type: CommunityConstants.SET_LOADING_TRUE,
})

const setLoadingFalse = () => ({
  type: CommunityConstants.SET_LOADING_FALSE,
})

const fetchUserGetSocialToken = () => ({
  type: CommunityConstants.FETCH_USER_GETSOCIAL_TOKEN,
})

const fetchUserGetSocialTokenSuccess = (payload: any) => ({
  type: CommunityConstants.FETCH_USER_GETSOCIAL_TOKEN_SUCCESS,
  payload,
})

const fetchUserGetSocialTokenFail = (payload: any) => ({
  type: CommunityConstants.FETCH_USER_GETSOCIAL_TOKEN_FAIL,
  payload,
})

// states to store getSocial Data

const setGetSocialUserProfile = (payload: any) => ({
  type: CommunityConstants.SET_GETSOCIAL_USER_PROFILE,
  payload,
})

const setFollowedTopics = (payload: any) => ({
  type: CommunityConstants.SET_FOLLOWED_TOPICS,
  payload,
})

const setAllTopics = (payload: any) => ({
  type: CommunityConstants.SET_ALL_TOPICS,
  payload,
})

const setTimelinePosts = (payload: any) => ({
  type: CommunityConstants.SET_TIMELINE_POSTS,
  payload,
})

const setSearchPostResult = (payload: any) => ({
  type: CommunityConstants.SET_SEARCH_POSTS_RESULT,
  payload,
})

const setSuggestedPostsResult = (payload: any) => ({
  type: CommunityConstants.SET_SUGGESTED_POSTS_RESULT,
  payload,
})

const setSingleFeedViewData = (payload: any) => ({
  type: CommunityConstants.SET_SINGLE_FEED_VIWE_DATA,
  payload,
})

const setSelectedTopicForFeed = (payload: any) => ({
  type: CommunityConstants.SET_SELECTED_TOPICS_FOR_FEED,
  payload,
})

export const communityAction = {
  setLoadingTrue,
  setLoadingFalse,
  fetchUserGetSocialToken,
  fetchUserGetSocialTokenSuccess,
  fetchUserGetSocialTokenFail,
  setGetSocialUserProfile,

  setFollowedTopics,
  setAllTopics,
  setTimelinePosts,
  setSearchPostResult,
  setSuggestedPostsResult,
  setSingleFeedViewData,
  setSelectedTopicForFeed,
}
