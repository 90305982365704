import { AnyAction } from "redux";
import { ExtraClassType } from "components/Profile/IeltsProfile/ExtraClass/redux/ExtraClassActions";
import { ExtraClassSchema } from "Interfaces/reducerTypes/ExtraClassSchema";

const initialState: ExtraClassSchema = {
  loading: false,
  showHasJoinedModal: false,
  errorMessage: "",
  enrolledExtraClass: null,
  postAction: null,
  plusExclusive: [],
  showHasJoinedOrientation: false,
};

const reducer = (state: ExtraClassSchema = initialState, action: AnyAction) => {
  switch (action.type) {
    case ExtraClassType.NOTIFY_USER_FOR_EXTRA_CLASS:
      return {
        ...state,
        loading: true,
      };
    case ExtraClassType.NOTIFY_USER_FOR_EXTRA_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        showHasJoinedModal: action.payload,
      };
    case ExtraClassType.NOTIFY_USER_FOR_EXTRA_CLASS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case ExtraClassType.TOGGLE_HAS_JOINED_MODAL:
      return {
        ...state,
        showHasJoinedModal: action.payload,
      };
    case ExtraClassType.FETCH_ENROLLED_EXTRA_CLASS_REQ:
      return {
        ...state,
        loading: true,
      };
    case ExtraClassType.FETCH_ENROLLED_EXTRA_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        enrolledExtraClass: action.payload,
      };
    case ExtraClassType.FETCH_ENROLLED_EXTRA_CLASS_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    case ExtraClassType.NOTIFY_ORIENTATION_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        showHasJoinedOrientation: action.payload,
      };
    case ExtraClassType.NOTIFY_USER_POST_ACTION:
      return {
        ...state,
        postAction: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
