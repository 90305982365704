const updateError = (message: string) => ({
  type: "IELTS_ATTENDANCE_ERROR",
  payload: message,
});

const updateSelectedBatch = (payload: any) => ({
  type: "UPDATE_SELECTED_BATCH",
  payload,
});

const addToCalendar = (payload: any) => ({
  type: "ADD_IELTS_CLASS_TO_CALENDAR",
  payload,
});

const updateAddedToCalendar = (payload: boolean) => ({
  type: "UPDATE_ADDED_TO_CALENDAR",
  payload,
})

export const ieltsAttendanceActions = {
  updateError,
  updateSelectedBatch,
  addToCalendar,
  updateAddedToCalendar,
};
