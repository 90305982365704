import { call, put, takeLatest } from "@redux-saga/core/effects";
import { ieltsPlusActions } from "components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusActions";
import { schedulePageApis } from "components/Profile/IeltsProfile/IeltsPlus/SchedulePage/redux/schedulePageApi";
import {
  fetchSetReminder,
  fetchSetReminderFailure,
  fetchSetReminderRequest,
  fetchSetReminderSuccess,
} from "components/Profile/IeltsProfile/IeltsPlus/SchedulePage/redux/schedulePageSlice";
import { AnyAction } from "redux";

function* fetchsetReminderSaga(action: AnyAction) {
  yield put(fetchSetReminderRequest());
  try {
    const res: { success: boolean; data: any } = yield call(
      schedulePageApis.fetchSetReminderApi,
      action.payload
    );
    if (res.success) {
      yield put(fetchSetReminderSuccess());
      yield put(ieltsPlusActions.fetchEnrolledBatches());
    }
  } catch (error) {
    yield put(fetchSetReminderFailure());
  }
}

function* SchedulePageSaga() {
  yield takeLatest(fetchSetReminder, fetchsetReminderSaga);
}
export default SchedulePageSaga;
