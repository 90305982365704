import { AnyAction } from 'redux'
import { IeltsPlusReducerSchema } from 'Interfaces/reducerTypes/IeltsPlusSchema'
import { sortBatches } from 'utils/ielts_batch_sort_utils'
import cloneDeep from 'lodash/cloneDeep'
import { IeltsPlusActionsTypes } from 'components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusActions'

const initialState: IeltsPlusReducerSchema = {
  ieltsData: null,
  loading: true,
  showHasJoinedModal: false,
  showConfirmModal: false,
  errorMessage: '',
  modalText: {
    batchId: 1,
    calendarText: '',
    clockText: '',
  },
  batchId: '',
  objects: {
    vidoes: 0,
    materials: 0,
    lastUpdatedVideo: 0,
    lastUpdatedMaterial: 0,
  },
  showRegisterModal: false,
  enrolledBatches: [],
  activeBatchId: null,
  completedClasses: [],
  upcomingClasses: [],
  loadingEnrolledClasses: true,
  isEnrolled: null,
  isEnrolledForFoundation: null,
  isEnrolledForPlus: null,
  isEnrolledForPremium: null,
  isEnrolledForBatches: {
    isEnrolledForPlus: null,
    isEnrolledForPremium: null,
    isEnrolledForElite: null,
    isEnrolledForSelect: null,
  },
  rewardList: null,
  isRewardModalOpen: false,
  getClickedRewardDay: '',
  allIeltsBatches: [],
}

const reducer = (state: any = initialState, action: AnyAction) => {
  const changeAddToCalendarStatus = (batchId: number) => {
    const activeBatchIndex = state.upcomingClasses.findIndex(
      (batch: any) => batch.batchId === batchId,
    )
    const newEnrolledBatches = cloneDeep(state.upcomingClasses)
    newEnrolledBatches[activeBatchIndex].addedToCalendar = true
    return newEnrolledBatches
  }

  switch (action.type) {
    case 'IElTS_FETCH_SUCCEEDED':
      const newBatchList = sortBatches(action.payload.workshop)
      const newPayload = cloneDeep(action.payload)
      newPayload.workshop = newBatchList
      return {
        ...state,
        ieltsData: newPayload,
        loading: false,
        batchId: newPayload.isEnrolled && newPayload.workshop[0].batchId,
      }
    case 'IELTS_FETCH_FAILED':
      return {
        ...state,
        errorMessage: action.message,
      }
    case 'ENROLL_SUCCESS':
      return {
        ...state,
        showHasJoinedModal: action.payload,
      }
    case 'TOGGLE_JOIN_MODAL':
      return {
        ...state,
        showHasJoinedModal: action.payload,
      }
    case 'ENROLL_FAILED':
      return {
        ...state,
        errorMessage: action.message,
      }
    case 'UPDATE_MODAL_TEXT':
      return {
        ...state,
        modalText: action.payload,
      }
    case 'TOGGLE_CONFIRM_MODAL':
      return {
        ...state,
        showConfirmModal: action.payload,
      }
    case 'FETCH_OBJECTS_SUCCESS':
      return {
        ...state,
        objects: action.payload,
      }
    case 'FETCH_OBJECTS_FAIL':
      return {
        ...state,
        error: action.payload,
      }
    case 'TOGGLE_REGISTER_MODAL':
      return {
        ...state,
        showRegisterModal: action.payload,
      }
    case 'FETCH_ENROLLED_BATCHES_SUCCESS':
      return {
        ...state,
        enrolledBatches: action.payload,
      }
    case 'UPDATE_ACTIVE_BATCH_ID':
      return {
        ...state,
        activeBatchId: action.payload,
      }
    case 'UPDATE_ADD_TO_CALENDAR_STATUS':
      const newEnrolledBatches = changeAddToCalendarStatus(action.payload)
      return {
        ...state,
        upcomingClasses: newEnrolledBatches,
      }
    case 'ADD_COMPLETED_IELTS_CLASSES':
      return {
        ...state,
        completedClasses: action.payload,
      }
    case 'ADD_UPCOMING_IELTS_CLASSES':
      return {
        ...state,
        upcomingClasses: action.payload,
      }
    case 'TOGGLE_LOADING_ENROLLED_CLASSES':
      return {
        ...state,
        loadingEnrolledClasses: false,
      }
    case 'CHECK_IETLS_ENROLLMENT':
      return {
        ...state,
        isEnrolled: action.payload,
      }
    case 'CHECK_FOUNDATION_ENROLLMENT':
      return {
        ...state,
        isEnrolledForFoundation: action.payload,
      }
    case 'CHECK_PLUS_ENROLLMENT':
      return {
        ...state,
        isEnrolledForPlus: action.payload,
      }
    case 'CHECK_PREMIUM_ENROLLMENT':
      return {
        ...state,
        isEnrolledForPremium: action.payload,
      }
    case IeltsPlusActionsTypes.CHECK_IELTS_BATCHES_ENROLLMENT:
      return {
        ...state,
        isEnrolledForBatches: action.payload,
      }
    case 'FETCH_REWARD_LIST_SUCCESS':
      return {
        ...state,
        rewardList: action.payload,
      }
    case 'TOGGLE_REWARD_MODAL':
      return {
        ...state,
        isRewardModalOpen: action.payload,
      }
    case 'GET_CLICKED_REWARD_DAY':
      return {
        ...state,
        getClickedRewardDay: action.payload,
      }
    case 'ADD_ALL_IELTS_CLASSES':
      return {
        ...state,
        allIeltsBatches: action.payload,
      }
    default:
      return state
  }
}

export default reducer
