import { call, put, select, takeLatest } from "@redux-saga/core/effects";
import { wordOfTheDayApis } from "components/Profile/AndroidApp/PractiseTab/WordOfTheDay/redux/WordOfTheDayApi";
import {
  fetchGetWordOfTheDay,
  fetchPostWordLike,
  setUserLikeStatus,
  setWordLikeCount,
  setWordOfTheDayData,
  setWordOfTheDayError,
  setWordOfTheDayLoading,
} from "components/Profile/AndroidApp/PractiseTab/WordOfTheDay/redux/WordOfTheDaySlice";
import { State } from "Interfaces/storeSchema";
import { AnyAction } from "redux";
function* fetchGetWordOfTheDaySaga(): Generator<any, any, any> {
  yield put(setWordOfTheDayLoading(true));
  try {
    const res = yield call(wordOfTheDayApis.fetchGetWordOfTheDay);
    if (res.success) {
      yield put(setWordOfTheDayData(res.data));
      if (res.data?.totalLikes !== undefined) {
        yield put(setWordLikeCount(res.data?.totalLikes));
      }
      if (res.data?.userLikeStatus) {
        yield put(setUserLikeStatus(res.data?.userLikeStatus));
      }
      yield put(setWordOfTheDayLoading(false));
    } else {
      yield put(
        setWordOfTheDayError("Something went wrong Please try after some time")
      );
    }
  } catch (error) {
    yield put(setWordOfTheDayLoading(false));
    yield put(
      setWordOfTheDayError("Something went wrong Please try after some time")
    );
  }
}
function* fetchPostLikeSaga(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(wordOfTheDayApis.fetchPostLike, action.payload);
    if (res.success) {
      yield put(setUserLikeStatus(true));
      const wordOfTheDaystate = yield select(
        (state: State) => state.WordOfTheDay
      );
      yield put(setWordLikeCount(wordOfTheDaystate.wordLikeCount + 1));
    }
  } catch (error) {}
}

export default function* wordOfTheDaySaga() {
  yield takeLatest(fetchGetWordOfTheDay, fetchGetWordOfTheDaySaga);
  yield takeLatest(fetchPostWordLike, fetchPostLikeSaga);
}
