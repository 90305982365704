const fetchFastTrackStudyMaterial = () => ({
  type: "FAST_TRACK_STUDY_MATERIAL_FETCH_REQUESTED",
});

const fetchStudyMaterial = (payload: string) => ({
  type: "STUDY_MATERIAL_FETCH_REQUESTED",
  payload,
});

const fetchStudyMaterialSuccess = (payload: any) => ({
  type: "STUDY_MATERIAL_FETCH_SUCCESS",
  payload,
});

const fetchStudyMaterialFail = (payload: string) => ({
  type: "STUDY_MATERIAL_FETCH_FAIL",
  payload,
});

export const studyMaterialActions = {
  fetchStudyMaterial,
  fetchStudyMaterialSuccess,
  fetchStudyMaterialFail,
  fetchFastTrackStudyMaterial,
};
