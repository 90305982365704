import { FunctionComponent, useRef } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { isValid } from "date-fns"

interface Props {
  placeholder: string
  customDatePickerCss?: string
  meta: any
  input: any
  showDatePickerIcon?: boolean
}

const DatePickerComponent: FunctionComponent<Props> = ({
  input,
  placeholder,
  meta,
  customDatePickerCss,
  showDatePickerIcon = false,
}) => {
  const datePickerRef = useRef<any>(null)
  const hasError = meta && meta.touched && meta.error
  const toggleCalendar = () => {
    if (datePickerRef && datePickerRef.current) {
      datePickerRef.current.setOpen(true)
    }
  }
  return (
    <div>
      <div
        className="flex justify-between date_container"
        onClick={toggleCalendar}
      >
        <DatePicker
          locale="en"
          ref={datePickerRef}
          placeholderText={placeholder}
          className={customDatePickerCss}
          dateFormat="dd-MM-yyyy"
          selected={input.value}
          disabledKeyboardNavigation
          onChange={(date) => {
            if (isValid(date)) {
              input.onChange(new Date(date as any))
            } else {
              input.onChange(null)
            }
          }}
          onBlur={input.onBlur}
          onFocus={input.onFocus}
        />
        {showDatePickerIcon && (
          <img
            src={`${process.env.CDN_URL}/assets/icons/blue_calendar.svg`}
            alt="calendar"
            style={{ cursor: "pointer" }}
          />
        )}
      </div>

      {hasError && !meta.active && (
        <p className="mt-1 text-xs text-red-600">{meta.error}</p>
      )}
      <style jsx>{`
        .date_container {
          border: solid 1px ${hasError ? "#FF695B" : "#d1d5db"};
          border-radius: 6px;
          padding: 12px;
          color: #616161;
          box-sizing: border-box;
          background-color: #ffffff;
        }
        date_container:focus {
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-color: #443eff;
        }
        input::placeholder {
          color: #616161;
          opacity: 1;
        }
      `}</style>
    </div>
  )
}

export default DatePickerComponent
