import { AnyAction } from "redux";
import { MasterclassReplaysSchema } from "Interfaces/reducerTypes/MasterClassReplaysSchema";
import { masterclassReplaysActionType } from './MasterclassReplaysActions';

const initialState = {
  replaysData: null,
  showModal: false,
  error: "",
  resumeVideo: null
};

const masterclassReplaysReducer = (
  state: MasterclassReplaysSchema = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case masterclassReplaysActionType.FETCH_MASTERCLASS_REPLAYS:
      return {
        ...state,
      };
    case masterclassReplaysActionType.FETCH_MASTERCLASS_REPLAYS_SUCCESS:
      return {
        ...state,
        replaysData: action.payload,
      };
    case masterclassReplaysActionType.TOGGLE_SUBSCRIPTION_MODAL:
      return {
        ...state,
        showModal: action.payload,
      };
    case masterclassReplaysActionType.RESUME_VIDEO_AFTER_SUBSCRIBE:
      return {
        ...state,
        resumeVideo: action.payload
      };
    case masterclassReplaysActionType.UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default masterclassReplaysReducer;
