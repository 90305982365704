import { WorkshopSchema } from "Interfaces/reducerTypes/IeltsPlusSchema";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FoundationReducerDto {
  isEnrolledInFoundation: boolean | null;
  foundationClass: Array<WorkshopSchema>;
  myFoundationClass: Array<WorkshopSchema>;
}
const initialState: FoundationReducerDto = {
  isEnrolledInFoundation: null,
  foundationClass: [],
  myFoundationClass: [],
};

const foundationSlice = createSlice({
  name: "foundationClass",
  initialState,
  reducers: {
    setIsEnrolledInFoundation(state, action: PayloadAction<boolean>) {
      return { ...state, isEnrolledInFoundation: action.payload };
    },
    setFoundationClass(state, action: PayloadAction<Array<WorkshopSchema>>) {
      return { ...state, foundationClass: action.payload };
    },
    setMyFoundationClass(state, action: PayloadAction<Array<WorkshopSchema>>) {
      return { ...state, myFoundationClass: action.payload };
    },
  },
});

export const {
  setFoundationClass,
  setIsEnrolledInFoundation,
  setMyFoundationClass,
} = foundationSlice.actions;

export default foundationSlice.reducer;
