import { noticeBoardActions } from 'components/Profile/IeltsProfile/NoticeBoard/redux/NoticeBoardActions'
import { noticeBoardApi } from 'components/Profile/IeltsProfile/NoticeBoard/redux/NoticeBoardApi'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchAllNotices(): Generator<any, any, any> {
  try {
    const res = yield call(noticeBoardApi.fetchNotices)
    yield put(noticeBoardActions.fetchNoticeSuccess(res))
  } catch (e: any) {
    console.error(e)
    yield put(noticeBoardActions.fetchNoticeFail(e.message))
    throw new Error(e.message)
  }
}

function* noticeBoardSaga() {
  yield takeLatest('ALL_NOTICE_FETCH_REQUESTED', fetchAllNotices)
}

export default noticeBoardSaga
