import api from "service/api"

const fetchFinancialCounsellingData = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=financial_counselling/upcoming_webinar`,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    console.log(err)
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const fetchFinancialCounsellingPageData = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=profile/post/financial-event`,
    )
    if (res.data.success) {
      return res
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    console.log(err)
    throw new Error("Something went wrong. Please refresh the page")
  }
}

export const financialCounsellingApi = {
  fetchFinancialCounsellingData,
  fetchFinancialCounsellingPageData,
}
