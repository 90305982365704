export interface QuizOptionInterface {
  id: string;
  option_description: string;
  is_correct: boolean;
}

export enum QuestionTypeDto {
  SINGLE_CHECK = "SINGLE_CHOICE",
  MULTI_CHECK = "MULTIPLE_CHOICE",
}

export enum QuizStatus {
  ATTEMPTED = "ATTEMPTED",
  FINISHED = "FINISHED",
  NOT_ATTEMPTED = "NOT_ATTEMPTED",
}

export interface QuizSchema {
  id: string;
  quizId: string;
  status: QuizStatus;
  icon: string;
  title: string;
  score?: number;
  question: [any];
  startDate?: Date;
  endDate?: Date;
  quizBundleId?: number;
  priority: number;
}

export interface submittedQuestionDto {
  questionId: string;
  optionsSelected: Array<{ id: string; is_correct: boolean }>;
}

export interface QuizReduceSchema {
  quizList: Array<QuizSchema>;
  newQuizList: Array<QuizSchema>;
  completeQuizList: Array<QuizSchema>;
  isLoading: boolean;
  error: string;
  activeQuizId: string | null;
  activeQuestionId: string | null;
  ieltsQuizQuestionList: Array<QuizQuestionDto>;
  quizStatus: QuizStatus | null;
  submittedQuestionsList: Array<submittedQuestionDto>;
  attemptId: number | null;
  isSubmitModalOpen: boolean;
  isExitModalOpen: boolean;
  submitSuccessfully: boolean;
  quizStartTime: Date | null;
  quizEndTime: Date | null;
  quizTitle: string;
  quizModalType: null | string;
  QuizBundleList: any;
}

export interface QuizQuestionDto {
  id: string;
  questionTitle: string;
  optionList: Array<QuizOptionInterface>;
  chooseOptionList: Array<QuizOptionInterface>;
  questionType: QuestionTypeDto;
  explanationOfAnswers: string;
}

export interface QuizQuestionSubmitPayloadDto {
  questionId: string;
  questionType: QuestionTypeDto;
  quizId: string;
  attemptId: number | null;
  quizBundleId: number | null;
  optionSelectedId: Array<{ id: string; is_correct: boolean }>;
}
