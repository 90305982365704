export function initRazorpayScript() {
  let razorpayScript = document.getElementById("_razorpay_script_tag");
  if (razorpayScript) return;
  let script = document.createElement("script");
  script.id = "_razorpay_script_tag";
  script.type = "text/javascript";
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  let head = document.head;
  head.insertBefore(script, head.firstElementChild);
}
