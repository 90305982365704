export const documentUploadActionConstants = {
  FETCH_DOCUMENT_DATA: "FETCH_DOCUMENT_DATA",
  FETCH_DOCUMENT_DATA_SUCCESS: "FETCH_DOCUMENT_DATA_SUCCESS",
  FETCH_DOCUMENT_DATA_ERROR: "FETCH_DOCUMENT_DATA_ERROR",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  UPLOAD_DOCUMENT_SUCCESS: "UPLOAD_DOCUMENT_SUCCESS",
  UPLOAD_DOCUMENT_ERROR: "UPLOAD_DOCUMENT_ERROR",
  DELETE_DOCUMENT: "DELETE_DOCUMENT",
  DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_ERROR: "DELETE_DOCUMENT_ERROR",
  DOWNLOAD_DOCUMENT: "DOWNLOAD_DOCUMENT",
  DOWNLOAD_DOCUMENT_SUCCESS: "DOWNLOAD_DOCUMENT_SUCCESS",
  DOWNLOAD_DOCUMENT_ERROR: "DOWNLOAD_DOCUMENT_ERROR",
  VIEW_DOCUMENT: "VIEW_DOCUMENT",
  VIEW_DOCUMENT_SUCCESS: "VIEW_DOCUMENT_SUCCESS",
  VIEW_DOCUMENT_ERROR: "VIEW_DOCUMENT_ERROR",
  ADD_HIGHEST_EDUCATION: "ADD_HIGHEST_EDUCATION",
  ADD_HIGHEST_EDUCATION_SUCCESS: "ADD_HIGHEST_EDUCATION_SUCCESS",
  ADD_HIGHEST_EDUCATION_ERROR: "ADD_HIGHEST_EDUCATION_ERROR",
  ADD_WORK_EX: "ADD_WORK_EX",
  ADD_WORK_EX_SUCCESS: "ADD_WORK_EX_SUCCESS",
  ADD_WORK_EX_ERROR: "ADD_WORK_EX_ERROR",
  ADD_MISC_DOCUMENT: "ADD_MISC_DOCUMENT",
  ADD_MISC_DOCUMENT_SUCCESS: "ADD_MISC_DOCUMENT_SUCCESS",
  ADD_MISC_DOCUMENT_ERROR: "ADD_MISC_DOCUMENT_ERROR",
  FILTER_DOCUMENTS: "FILTER_DOCUMENTS",
  ADD_FILE_LABEL: "ADD_FILE_LABEL",
  ADD_FILE_LABEL_SUCCESS: "ADD_FILE_LABEL_SUCCESS",
  ADD_FILE_LABEL_ERROR: "ADD_FILE_LABEL_ERROR",
  SELECT_ENGLISH_EXAM_TYPE: "SELECT_ENGLISH_EXAM_TYPE",
  SELECT_ENGLISH_EXAM_TYPE_SUCCESS: "SELECT_ENGLISH_EXAM_TYPE_SUCCESS",
  SELECT_ENGLISH_EXAM_TYPE_ERROR: "SELECT_ENGLISH_EXAM_TYPE_ERROR",
  EDIT_TITLE: "EDIT_TITLE",
  EDIT_TITLE_SUCCESS: "EDIT_TITLE_SUCCESS",
  EDIT_TITLE_ERROR: "EDIT_TITLE_ERROR",
  EDIT_MISC_TITLE: "EDIT_MISC_TITLE",
};

const fetchDocumentData = () => ({
  type: documentUploadActionConstants.FETCH_DOCUMENT_DATA,
});

const fetchDocumentDataSuccess = (payload: any) => ({
  type: documentUploadActionConstants.FETCH_DOCUMENT_DATA_SUCCESS,
  payload,
});

const fetchDocumentDataError = (payload: any) => ({
  type: documentUploadActionConstants.FETCH_DOCUMENT_DATA_ERROR,
  payload,
});

const uploadDocument = (payload: any) => {
  return {
    type: documentUploadActionConstants.UPLOAD_DOCUMENT,
    payload,
  };
};

const uploadDocumentSuccess = (payload: any) => ({
  type: documentUploadActionConstants.UPLOAD_DOCUMENT_SUCCESS,
  payload,
});

const uploadDocumentError = (payload: any) => ({
  type: documentUploadActionConstants.UPLOAD_DOCUMENT_ERROR,
  payload,
});

const deleteDocument = (payload: any) => ({
  type: documentUploadActionConstants.DELETE_DOCUMENT,
  payload,
});

const deleteDocumentSuccess = (payload: any) => ({
  type: documentUploadActionConstants.DELETE_DOCUMENT_SUCCESS,
  payload,
});

const deleteDocumentError = (payload: any) => ({
  type: documentUploadActionConstants.DELETE_DOCUMENT_ERROR,
  payload,
});

const downloadDocument = (payload: any) => ({
  type: documentUploadActionConstants.DOWNLOAD_DOCUMENT,
  payload,
});

const downloadDocumentSuccess = (payload: any) => ({
  type: documentUploadActionConstants.DOWNLOAD_DOCUMENT_SUCCESS,
  payload,
});

const downloadDocumentError = (payload: any) => ({
  type: documentUploadActionConstants.DOWNLOAD_DOCUMENT_ERROR,
  payload,
});

const viewDocument = (payload: any) => ({
  type: documentUploadActionConstants.VIEW_DOCUMENT,
  payload,
});

const viewDocumentSuccess = (payload: any) => ({
  type: documentUploadActionConstants.VIEW_DOCUMENT_SUCCESS,
  payload,
});

const viewDocumentError = (payload: any) => ({
  type: documentUploadActionConstants.VIEW_DOCUMENT_ERROR,
  payload,
});

const addHighestEducation = (payload: any) => ({
  type: documentUploadActionConstants.ADD_HIGHEST_EDUCATION,
  payload,
});

const addHighestEducationSuccess = (payload: any) => {
  return {
    type: documentUploadActionConstants.ADD_HIGHEST_EDUCATION_SUCCESS,
    payload,
  };
};

const addHighestEducationError = (payload: any) => ({
  type: documentUploadActionConstants.ADD_HIGHEST_EDUCATION_ERROR,
  payload,
});

const addWorkEx = (payload: any) => ({
  type: documentUploadActionConstants.ADD_WORK_EX,
  payload,
});

const addWorkExSuccess = (payload: any) => ({
  type: documentUploadActionConstants.ADD_WORK_EX_SUCCESS,
  payload,
});

const addWorkExError = (payload: any) => ({
  type: documentUploadActionConstants.ADD_WORK_EX_ERROR,
  payload,
});

const addMiscDocument = (payload: any) => ({
  type: documentUploadActionConstants.ADD_MISC_DOCUMENT,
  payload,
});

const addMiscDocumentSuccess = (payload: any) => ({
  type: documentUploadActionConstants.ADD_MISC_DOCUMENT_SUCCESS,
  payload,
});

const addMiscDocumentError = (payload: any) => ({
  type: documentUploadActionConstants.ADD_MISC_DOCUMENT_ERROR,
  payload,
});
const editMiscTitle = (payload: any) => {
  return {
    type: documentUploadActionConstants.EDIT_MISC_TITLE,
    payload,
  };
};
const filterDocuments = (payload: any) => ({
  type: documentUploadActionConstants.FILTER_DOCUMENTS,
  payload,
});

const addFileLabel = (payload: any) => {
  return {
    type: documentUploadActionConstants.ADD_FILE_LABEL,
    payload,
  };
};

const addFileLabelSuccess = (payload: any) => {
  return {
    type: documentUploadActionConstants.ADD_FILE_LABEL_SUCCESS,
    payload,
  };
};

const addFileLabelError = (payload: any) => {
  return {
    type: documentUploadActionConstants.ADD_FILE_LABEL_ERROR,
    payload,
  };
};
const editTitle = (payload: any) => {
  return {
    type: documentUploadActionConstants.EDIT_TITLE,
    payload,
  };
};

const editTitleSuccess = (payload: any) => {
  return {
    type: documentUploadActionConstants.EDIT_TITLE_SUCCESS,
    payload,
  };
};

const editTitleError = (payload: any) => {
  return {
    type: documentUploadActionConstants.EDIT_TITLE_ERROR,
    payload,
  };
};
const selectEnglishExamType = (payload: any) => {
  return {
    type: documentUploadActionConstants.SELECT_ENGLISH_EXAM_TYPE,
    payload,
  };
};

const selectEnglishExamTypeSuccess = (payload: any) => {
  return {
    type: documentUploadActionConstants.SELECT_ENGLISH_EXAM_TYPE_SUCCESS,
    payload,
  };
};

const selectEnglishExamTypeError = (payload: any) => {
  return {
    type: documentUploadActionConstants.SELECT_ENGLISH_EXAM_TYPE_ERROR,
    payload,
  };
};

export const documentUploadActions = {
  fetchDocumentData,
  fetchDocumentDataSuccess,
  fetchDocumentDataError,
  uploadDocument,
  uploadDocumentSuccess,
  uploadDocumentError,
  deleteDocument,
  deleteDocumentSuccess,
  deleteDocumentError,
  downloadDocument,
  downloadDocumentSuccess,
  downloadDocumentError,
  viewDocument,
  viewDocumentSuccess,
  viewDocumentError,
  addHighestEducation,
  addHighestEducationSuccess,
  addHighestEducationError,
  addWorkEx,
  addWorkExSuccess,
  addWorkExError,
  addMiscDocument,
  addMiscDocumentSuccess,
  addMiscDocumentError,
  filterDocuments,
  addFileLabel,
  addFileLabelSuccess,
  addFileLabelError,
  selectEnglishExamType,
  selectEnglishExamTypeSuccess,
  selectEnglishExamTypeError,
  editTitle,
  editTitleSuccess,
  editTitleError,
  editMiscTitle,
};
