import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface batchScheduleReducerDto {
  isApiLoading: boolean
  error: null | string
  notifySuccess: boolean
}
const initialState: batchScheduleReducerDto = {
  isApiLoading: false,
  error: null,
  notifySuccess: false,
}

const schedulePageSlice = createSlice({
  name: 'batchSchedule',
  initialState,
  reducers: {
    fetchSetReminderRequest(state) {
      return {
        ...state,
        isApiLoading: true,
      }
    },
    fetchSetReminderSuccess(state) {
      return {
        ...state,
        isApiLoading: false,
      }
    },
    fetchSetReminderFailure(state) {
      return {
        ...state,
        isApiLoading: false,
        error: 'something went wrong',
      }
    },
    setNotificationSuccess(state, action: PayloadAction<boolean>) {
      return { ...state, notifySuccess: action.payload }
    },
  },
})
export const fetchSetReminder = createAction<Record<string, any>>(
  'batchSchedule/fetchSetReminder'
)

export const {
  fetchSetReminderRequest,
  fetchSetReminderSuccess,
  fetchSetReminderFailure,
  setNotificationSuccess,
} = schedulePageSlice.actions

export default schedulePageSlice.reducer
