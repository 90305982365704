const fetchAllNotices = () => ({
  type: "ALL_NOTICE_FETCH_REQUESTED",
});

const fetchNoticeSuccess = (payload: any) => ({
  type: "ALL_NOTICE_FETCH_SUCCESS",
  payload,
});

const fetchNoticeFail = (payload: string) => ({
  type: "ALL_NOTICE_FETCH_FAIL",
  payload,
});

export const noticeBoardActions = {
  fetchAllNotices,
  fetchNoticeSuccess,
  fetchNoticeFail,
};
