import { priceBundleName } from 'DataMapper/Common/WorkshopData'

import { WorkshopSchema } from 'Interfaces/reducerTypes/IeltsPlusSchema'

export const isPlusBatch = (batchData: WorkshopSchema) =>
  batchData.pricingBundleName === priceBundleName.IELTS_PLUS
export const getThreeDatesWhichHasBatches = (
  batches: WorkshopSchema[]
): {
  datesWithBatchesObject: Record<string, WorkshopSchema[]>
  threeDates: { dateString: string; dateObject: Date }[]
} => {
  const datesWithBatchesObject: Record<string, WorkshopSchema[]> =
    batches.reduce(
      (prevObject: Record<string, WorkshopSchema[]>, batchData) => {
        const startDate = new Date(batchData.startDate).toLocaleDateString()
        if (startDate in prevObject) {
          prevObject[startDate].push(batchData)
        } else prevObject[startDate] = [batchData]
        return prevObject
      },
      {}
    )
  const threeDates: { dateString: string; dateObject: Date }[] = []
  const currentDate = { dateObj: new Date(), dateNow: Date.now() }
  let i = 0
  while (threeDates.length <= 3 && i < 1000) {
    if (
      datesWithBatchesObject[currentDate.dateObj.toLocaleDateString()] &&
      datesWithBatchesObject[currentDate.dateObj.toLocaleDateString()].length >
        0
    ) {
      threeDates.push({
        dateString: currentDate.dateObj.toLocaleDateString(),
        dateObject: currentDate.dateObj,
      })
    }
    currentDate.dateObj = new Date(currentDate.dateNow + 24 * 60 * 60 * 1000)
    currentDate.dateNow = currentDate.dateNow + 24 * 60 * 60 * 1000
    i++
  }

  return { datesWithBatchesObject, threeDates }
}

export const getWeeklyScheduleOfBatch = (
  batchData: WorkshopSchema
): string[] => {
  const daysWithNumbers: any = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  }
  let [firstDayInWeeklySchedule, lastDayInWeeklySchedule] =
    batchData?.weeklySchedule?.split('-') || []
  firstDayInWeeklySchedule = daysWithNumbers[firstDayInWeeklySchedule]
  lastDayInWeeklySchedule = daysWithNumbers[lastDayInWeeklySchedule]
  const schedule: string[] = []
  for (const key in daysWithNumbers) {
    if (
      daysWithNumbers[key] >= firstDayInWeeklySchedule &&
      daysWithNumbers[key] <= lastDayInWeeklySchedule
    ) {
      schedule.push(key)
    }
  }
  return schedule
}
