import api from "service/api"

const fetchRegisteredMeetingData = async (batchId: any) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=workshop/attendance/${batchId}`,
    )
    return res
  } catch (err: any) {
    console.log(err || "Something went wrong. Please refresh the page")
  }
}

const fetchIeltsDemoData = async (batchId: any) =>
  await api.get(
    `/api/scholarRoute?path=ielts-counselling/attendance/${batchId}`,
  )

export const joinMasterClassApi = {
  fetchRegisteredMeetingData,
  fetchIeltsDemoData,
}
