import { CounsellingSchema } from 'Interfaces/reducerTypes/CounsellingSchema'
import { AnyAction } from 'redux'

const initialState: CounsellingSchema = {
  error: '',
  postPaymentData: null,
  isLoading: true,
  scholarshipBookmarkData: [],
}

const reducer = (
  state: CounsellingSchema = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case 'COUNSELLING_STAGE_FETCH_SUCCESS':
      return {
        ...state,
        counsellingStage: action.payload,
        isLoading: false,
      }
    case 'COUNSELLING_STAGE_FETCH_FAIL':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }
    case 'POST_PAYMENT_DATA_FETCH_SUCCESS':
      return {
        ...state,
        postPaymentData: action.payload,
      }
    case 'POST_PAYMENT_DATA_FETCH_FAIL':
      return {
        ...state,
        error: action.payload,
      }
    case 'FETCH_BOOKMARK_DATA_FETCH_SUCCESS':
      return {
        ...state,
        scholarshipBookmarkData: action.payload,
        isLoading: false,
      }
    case 'FETCH_BOOMARK_FETCH_FAIL':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      }

    default:
      return state
  }
}

export default reducer
