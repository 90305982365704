import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { certificateApi } from "components/Profile/IeltsProfile/Certificates/redux/CertificateApi";
import {
  certificateActionType,
  certificateAction,
} from "components/Profile/IeltsProfile/Certificates/redux/CertificateAction";

function* fetchCertificateSaga(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(certificateApi.fetchCertificateData, action.payload);
    if (res.success) {
      yield put(certificateAction.fetchCertificateSuccess(res.data));
    } else {
      yield put(certificateAction.fetchCertificateFail(res.data));
    }
  } catch (e:any) {
    console.error(e);
    yield put(certificateAction.fetchCertificateFail(e.message));
    throw new Error(e.message);
  }
}

function* certificateSaga() {
  yield takeLatest(
    certificateActionType.FETCH_CERTIFICATE_REQ,
    fetchCertificateSaga
  );
}

export default certificateSaga;
