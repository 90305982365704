import { AnyAction } from "redux";
import { speakingClubType } from "./SpeakingClubActions";
import { SpeakingClubSchema } from "Interfaces/reducerTypes/SpeakingClubSchema";

const initialState: SpeakingClubSchema | any = {
  SpeakingClubSlotAvailableDate: [],
  bookingDate: "",
  error: "",
  loading: false,
  showHasJoinedSpeakingClubModal: false,
  upcomingSpeakingClass: null,
  modalText: "",
  credits: { totalCredits: 0, creditsLeft: 0 },
  speakingClubPriceBundle: null,
  showRescheduleModal: false,
  showCancelldModal: false,
  isRescheduling: false,
};

const {
  TOOGLE_SPEAKING_CLUB_RESCHEDULE_MODAL,
  TOGGLE_SPEAKING_CLUB_CANCELLED_MODAL,
  TOGGLE_SPEAKING_CLUB_IS_RESCHEDULING,
  UPDATE_SPEAKING_CLUB_ERROR,
} = speakingClubType;

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case speakingClubType.SPEAKING_CLUB_SLOT_SUCCESS:
      return {
        ...state,
        SpeakingClubSlotAvailableDate: action.payload,
        loading: false,
      };
    case speakingClubType.SPEAKING_CLUB_SLOT_FETCH_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case speakingClubType.SPEAKING_WORKSHOP_POST_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case speakingClubType.SPEAKING_WORKSHOP_POST_SUCCESS:
      return {
        ...state,
        showHasJoinedSpeakingClubModal: action.payload,
        loading: false,
      };
    case speakingClubType.SPEAKING_WORKSHOP_POST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case speakingClubType.UPCOMING_SPEAKING_CLUB_SUCCESS:
      return {
        ...state,
        upcomingSpeakingClass: action.payload,
      };
    case speakingClubType.UPCOMING_SPEAKING_CLUB_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case speakingClubType.CLOSE_MODAL:
      return {
        ...state,
        showHasJoinedSpeakingClubModal: false,
      };
    case speakingClubType.SET_MODAL_TEXT:
      return {
        ...state,
        modalText: action.payload,
      };
    case speakingClubType.FETCH_SPEAKING_CLUB_CREDITS_SUCCESS:
      return {
        ...state,
        credits: action.payload,
      };
    case speakingClubType.FETCH_SPEAKING_CLUB_CREDITS_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case speakingClubType.FETCH_SPEAKING_BUNDLE_PRICE_SUCCESS:
      return {
        ...state,
        speakingClubPriceBundle: action.payload,
      };
    case speakingClubType.FETCH_SPEAKING_BUNDLE_PRICE_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case TOOGLE_SPEAKING_CLUB_RESCHEDULE_MODAL:
      return {
        ...state,
        showRescheduleModal: action.payload,
      };
    case TOGGLE_SPEAKING_CLUB_CANCELLED_MODAL:
      return {
        ...state,
        showCancelldModal: action.payload,
      };
    case TOGGLE_SPEAKING_CLUB_IS_RESCHEDULING:
      return {
        ...state,
        isRescheduling: action.payload,
      };
    case UPDATE_SPEAKING_CLUB_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
