import { SemReducerSchema } from "Interfaces/reducerTypes/SemSchema";
import { AnyAction } from "redux";
import { actionConstant } from "./SemLandingAction";

const initialState: SemReducerSchema = {
  semPageDetails: null,
  error: "",
  loader: true,
  isModalOpen: false
};

const reducer = (state: SemReducerSchema = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionConstant.SEM_PAGE_DETAIL_REQUEST:
      return {
        ...state,
        loader: true
      };
    case actionConstant.SEM_PAGE_DETAIL_SUCCESS:
      return {
        ...state,
        loader: false,
        semPageDetails: action.payload
      };
    case actionConstant.UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
      case actionConstant.TOGGEL_MODAL:
      return {
        ...state,
        isModalOpen: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
