import { AnyAction } from "redux";

const initialState = {
  initialFormValues: {},
  error: "",
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "FETCH_INITIAL_FORM_VALUES_SUCCESS":
      return {
        ...state,
        initialFormValues: action.payload,
      };
    case "UPDATE_IELTS_REGISTRATION_FORM_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
