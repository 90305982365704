import { AnyAction } from 'redux'
import { PromoCodeSchema } from 'Interfaces/reducerTypes/PromoCodeSchema'

const initialState: PromoCodeSchema = {
  promoCodeApplied: false,
  promoCode: '',
  promoMsg: '',
  priceAfterDiscount: null,
  isValidCode: null,
  discount: null,
  showPromoCodeForm: false,
}

const promoCodeReducer = (state: any = initialState, action: AnyAction) => {
  const getPromoMsg = (offerPrice: number, discount: number) => {
    if (offerPrice === 0) {
      return 'This product is already free of cost for you!'
    } else {
      return `Congrats! You have unlocked ${discount}% off`
    }
  }

  switch (action.type) {
    case 'FETCH_PROMO_CODE_VALIDATION_SUCCESS':
      const price =
        action.payload.offerPrice -
        (action.payload.data.discount / 100) * action.payload.offerPrice
      const msg = getPromoMsg(
        action.payload.offerPrice,
        action.payload.data.discount,
      )
      return {
        ...state,
        promoCodeApplied: true,
        priceAfterDiscount: Math.floor(price),
        promoMsg: msg,
        isValidCode: true,
        discount: action.payload.data.discount,
      }
    case 'FETCH_PROMO_CODE_VALIDATION_FAIL':
      return {
        ...state,
        promoMsg: action.payload,
        isValidCode: false,
      }
    case 'UPDATE_PROMO_CODE_APPLIED':
      return {
        ...state,
        promoCodeApplied: action.payload,
      }
    case 'REMOVE_PROMO_CODE':
      return {
        ...state,
        promoCodeApplied: false,
        promoMsg: '',
        isValidCode: null,
        priceAfterDiscount: null,
        promoCode: '',
      }
    case 'FETCH_PROMO_CODE_VALIDATION':
      return {
        ...state,
        promoCode: action.payload.promoCode,
      }
    case 'TOGGLE_PROMO_CODE_FORM':
      return {
        ...state,
        showPromoCodeForm: action.payload,
      }
    default:
      return state
  }
}

export default promoCodeReducer
