const fetchFaculties = () => ({
  type: "FETCH_FACULTIES_REQUESTED",
});

const fetchFacultiesClientSide = () => ({
  type: "FETCH_FACULTIES_REQUESTED_CLIENT",
});

const fetchSuccess = (payload: any) => ({
  type: "FETCH_FACULTIES_SUCCESS",
  payload,
});

const fetchFail = (message: string) => ({
  type: "FETCH_FACULTIES_FAIL",
  payload: message,
});

export const facultyActions = {
  fetchFaculties,
  fetchSuccess,
  fetchFail,
  fetchFacultiesClientSide,
};
