import { masterclassActions } from 'components/Profile/IeltsProfile/Masterclass/redux/MasterclassActions'
import { masterclassRegistrationActions } from 'components/Profile/MasterclassRegistrationForm/redux/MasterclassRegistrationFormAction'
import { masterclassRegistrationFormApi } from 'components/Profile/MasterclassRegistrationForm/redux/MasterclassRegistrationFormApi'
import sha256 from 'crypto-js/sha256'
import { eventName } from 'DataMapper/EventTracker/EventData'
import { eventTracker } from 'eventTracker'
import { fireTagEvent } from 'ga'
import { State } from 'Interfaces/storeSchema'
import { getUserInfo } from 'lib/getUserInfo'
import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { IeltsToCounsellingEligibility } from 'components/TofuOnbordingForms/SpotCounsellingProgressiveForm/IeltsToCounselling/IeltsToCounsellingApi'
import { registrationFormApi } from 'components/Profile/RegistrationForm/redux/RegistrationFormApi'
import { ieltsExamType } from 'DataMapper/IeltsDateUploadData'
import { spotCounsellingApi } from 'components/SpotCounselling/redux/SpotCounsellingApi'
import { localStorageSetItem } from 'utils/common_utils'

const getMasterclassRegistration = (state: State) =>
  state.masterclassRegistrationForm

function* saveData(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(masterclassRegistrationActions.toggleIsLoading(true))
    yield put(masterclassRegistrationActions.toggleCtaText('Saving'))
    const res = yield call(
      masterclassRegistrationFormApi.saveForm,
      action.payload
    )
    yield put(masterclassRegistrationActions.saveFormValues(action.payload))
    if (res.success) {
      const slotType = yield call(IeltsToCounsellingEligibility,action.payload,"masterclass_form")
      const masterClassDataRes =  yield call(registrationFormApi.fetchInitialFormValues)
      const variant = yield call(spotCounsellingApi.getAbVariant, "i2cTimeLineExp")
      const shouldRedirectTOI2cTimeline = [1,2,7].includes(slotType) && masterClassDataRes?.success && masterClassDataRes?.data?.ieltsExamType !== ieltsExamType.GENERAL && variant?.success && variant?.data === 2
      localStorageSetItem("shouldShowI2cTimeLine", shouldRedirectTOI2cTimeline)
      
      const userData = yield call(getUserInfo)
      eventTracker(eventName.MASTERCLASS_REGISTERED, {
        category: eventName.MASTERCLASS_REGISTERED,
        action: window.location.pathname,
        label: '',
      })
      yield call(fireTagEvent, 'masterclass_registered', {
        hashed_email: sha256(action.payload.email).toString(),
        user_email: action.payload.email,
        hashed_phone: sha256(userData?.phone || '').toString(),
      })
      yield put(masterclassRegistrationActions.toggleCtaText('Next'))
      yield put(masterclassRegistrationActions.toggleShowForm(false))
      //ub
      if(!shouldRedirectTOI2cTimeline) yield put(masterclassRegistrationActions.saveFormSuccess(true)) // here
      const masterclassRegistrationStore = yield select(
        getMasterclassRegistration
      )
      if (
        masterclassRegistrationStore.postFormAction &&
        typeof masterclassRegistrationStore.postFormAction == 'function'
      ) {
        masterclassRegistrationStore.postFormAction()
      } else {
        yield put(
          masterclassRegistrationActions.toggleMasterclassFormModal(true)
        )
      }
      
      yield put(masterclassActions.setMasterclassSubscriptionStatus(true))
      yield put(masterclassRegistrationActions.postFormAction(null))
      yield put(masterclassRegistrationActions.toggleIsLoading(false))
      if(shouldRedirectTOI2cTimeline){
       yield put(masterclassRegistrationActions.toggleShouldRedirectToI2cTimeline(true))
      }
    } else {
      yield put(masterclassRegistrationActions.postFormAction(null))
      yield put(
        masterclassRegistrationActions.updateError(
          'Something went wrong. Please try after sometime.'
        )
      )
      //ub
      yield put(masterclassRegistrationActions.saveFormSuccess(false))
      yield put(masterclassRegistrationActions.toggleIsLoading(false))
    }
  } catch (e: any) {
    yield put(masterclassRegistrationActions.toggleCtaText('Next'))
    yield put(masterclassRegistrationActions.postFormAction(null))
    yield put(
      masterclassRegistrationActions.updateError(
        'Something went wrong. Please try after sometime.'
      )
    )
    //ub
    yield put(masterclassRegistrationActions.saveFormSuccess(false))
    yield put(masterclassRegistrationActions.toggleIsLoading(false))
    console.error(e)
  }
}

function* resetFormAction(action: AnyAction) {
  if (action.payload == false) {
    yield put(masterclassRegistrationActions.postFormAction(null))
  }
}

function* masterclassSaga() {
  yield takeLatest('MASTERCLASS_FORM_SUBMIT', saveData)
  yield takeLatest('TOGGLE_MASTERCLASS_FORM_MODAL', resetFormAction)
}

export default masterclassSaga
