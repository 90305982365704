// import api, { ApiRequestConfig } from "service/api"
import { saveAs } from "file-saver"
import api, { ApiRequestConfig } from "service/api"

const fetchDocumentDataRequest = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=student/counseling/document/categories`,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}

const uploadDocumentRequest = async (data: any) => {
  const { file, d } = data
  const idStatus = d.hasOwnProperty("id")
  if (idStatus == false) {
    d.id = 0
  }
  try {
    try {
      const formData = new FormData()
      formData.append("file", file)
      let url = ""
      if (d.key === "WORK_EX") {
        url = `/api/upload?path=student/doc/WORK_EX/${d.id}/${d.workExId}`
      } else if (d.key === "COUNSELING_OTHER") {
        url = `/api/upload?path=student/doc/COUNSELING_OTHER/${d.id}/${d.fileLabel}`
      } else {
        url = `/api/upload?path=student/doc/${d.key}/${d.id}/document`
      }

      const res = await api.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      return res.data.data
    } catch (err: any) {
      console.log(err)
    }
  } catch (err: any) {
    console.log(err)
  }
}

const deleteDocumentRequest = async (data: any) => {
  const { d } = data
  const { key, id } = d
  let url: any
  if (key == "WORK_EX") {
    url = `/api/scholarRoute?path=student/deleteWorkExDocument/${id}/${
      d?.workExId || 0
    }`
  } else {
    url = `/api/scholarRoute?path=student/deleteDocument/${key}/${id}`
  }
  try {
    const res = await api.delete(url)
    if (res.data.success) {
      return res.data.success
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}

const downloadDocumentRequest = async (docId: any) => {
  const downloadUrl = `artifact/student/download/${docId}`
  try {
    const config: ApiRequestConfig = {
      method: "GET",
      url: `/api/download?path=${downloadUrl}`,
      responseType: "blob",
    }
    const res = await api(config)
    if (res && res.data && res.data.size > 0) {
      const fileName = res.headers["content-disposition"].split("filename=")[1]
      saveAs(res.data, fileName)
    }
  } catch (err: any) {
    throw new Error("Something went wrong. Please contact the tech team")
  }
}

const viewDocumentRequest = async (docId: any) => {
  try {
    const res = await api.get(`/api/scholarRoute?path=student/viewdoc/${docId}`)
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}

const addHighestEducationRequest = async (data: any) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=student/highestEducation`,
      data,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}

const editTitleRequest = async (datas: any) => {
  const { data, fileLabelId, modalType, workId } = datas

  let url: any
  if (modalType == "COUNSELING_OTHER") {
    url = `/api/scholarRoute?path=student/addFileLabel/${fileLabelId || 0}`
  } else if (modalType == "WORK_EX") {
    url = `/api/scholarRoute?path=student/workExperience/${workId}`
  }

  try {
    const res = await api.post(url, data)
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}
const addWorkExRequest = async (data: any) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=student/workExperience/0`,
      data,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}

const addMiscDocumentRequest = async (data: any) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=student/workExperience`,
      data,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}

const selectEnglishExamRequest = async (examType: any) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=student/englishExam/${examType}`,
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err: any) {
    console.log(err)
  }
}

export const documentUploadApi = {
  fetchDocumentDataRequest,
  uploadDocumentRequest,
  deleteDocumentRequest,
  downloadDocumentRequest,
  viewDocumentRequest,
  addHighestEducationRequest,
  addWorkExRequest,
  addMiscDocumentRequest,
  selectEnglishExamRequest,
  editTitleRequest,
}
