import { call, put, takeLatest } from "@redux-saga/core/effects";
import { masterclassReplaysApis } from "./MasterclassReplaysApis";
import { masterclassReplaysActions } from "./MasterclassReplaysActions";
import { masterclassReplaysActionType } from './MasterclassReplaysActions';

function* fetchMasterclassReplays(): Generator<any, any, any> {
  try {
    const res = yield call(masterclassReplaysApis.fetchMasterclassReplays);
    if (res.success) {
      const result = res.data?.map((video: any, i: number) => ({
        ...video,
        id: i + 1
      }))
      yield put(
        masterclassReplaysActions.fetchMasterclassReplaysSuccess(result || [])
      );
    } else {
      yield put(masterclassReplaysActions.updateError(res.message));
    }
  } catch (err: any) {
    yield put(
      masterclassReplaysActions.updateError(
        "Something went wrong. Please try after sometime"
      )
    );
  }
}

function* masterclassReplaysSaga() {
  yield takeLatest(masterclassReplaysActionType.FETCH_MASTERCLASS_REPLAYS, fetchMasterclassReplays);
}

export default masterclassReplaysSaga;
