import { MasterclassDataSchema } from "Interfaces/reducerTypes/MasterclassSchema"

const fetchMasterclassSubscriptionStatus = (callback = () => {}) => ({
  type: "FETCH_MASTERCLASS_SUBSCRIPTION_REQUESTED",
  callback,
})

const fetchMasterclassSubscriptionStatusSuccess = (
  payload: boolean | null,
) => ({
  type: "FETCH_MASTERCLASS_SUBSCRIPTION_STATUS_SUCCESS",
  payload,
})

const updateError = (message: string) => ({
  type: "UPDATE_MASTERCLASS_ERROR",
  payload: message,
})

const fetchMasterclassData = () => ({
  type: "FETCH_MASTERCLASS_DATA_REQUESTED",
})

const fetchMasterclassDataSuccess = (
  payload: Array<MasterclassDataSchema>,
) => ({
  type: "FETCH_MASTERCLASS_DATA_SUCCESS",
  payload,
})

const enrollForMasterclassRequested = () => ({
  type: "ENROLL_FOR_MASTERCLASS_REQUESTED",
})

const updateUnsubscribedCtaText = (payload: string) => ({
  type: "UPDATE_UNSUBSCRIBED_MASTERCLASS_CTA_TEXT",
  payload,
})

const toggleConfirmationModal = (payload: boolean) => ({
  type: "MASTERCLASS_CONFIRMATION_MODAL",
  payload,
})

const toggleMasterclassEnrollModal = (payload: boolean) => ({
  type: "TOGGLE_MASTERCLASS_ENROLL_MODAL",
  payload,
})

const setMasterclassSubscriptionStatus = (payload: boolean | null) => ({
  type: "SET_MASTERCLASS_SUBSCRIPTION_STATUS",
  payload,
})
const subscribeToMasterClass = (payload: (success: boolean) => void) => ({
  type: "SUBSCRIBE_TO_MASTERCLASSES",
  payload,
})

const fetchBeginnerMasterclass = () => ({
  type: "FETCH_BEGINNER_MASTERCLASS",
})

const fetchBeginnerMasterclassDataSuccess = (
  payload: Array<MasterclassDataSchema>,
) => ({
  type: "FETCH_BEGINNER_MASTERCLASS_DATA_SUCCESS",
  payload,
})

const fetchFreeIeltsCourseStatus = () => ({
  type: "FETCH_FREE_IELTS_COURSE_STATE",
})

const fetchFreeIeltsCourseSuccess = (payload: any) => ({
  type: "FETCH_FREE_IELTS_COURSE_STATUS_SUCCESS",
  payload,
})

const fetchMasterclassRecommendation = () => ({
  type: "FETCH_MASTERCLASS_RECOMMENDATION",
})

const fetchMasterclassRecommendationSuccess = (payload: any) => ({
  type: "FETCH_MASTERCLASS_RECOMMENDATION_SUCCESS",
  payload,
})

export const masterclassActions = {
  fetchMasterclassSubscriptionStatus,
  fetchMasterclassSubscriptionStatusSuccess,
  updateError,
  fetchMasterclassData,
  fetchMasterclassDataSuccess,
  enrollForMasterclassRequested,
  updateUnsubscribedCtaText,
  toggleMasterclassEnrollModal,
  toggleConfirmationModal,
  setMasterclassSubscriptionStatus,
  fetchBeginnerMasterclass,
  fetchBeginnerMasterclassDataSuccess,
  fetchFreeIeltsCourseStatus,
  fetchFreeIeltsCourseSuccess,
  fetchMasterclassRecommendation,
  fetchMasterclassRecommendationSuccess,
  subscribeToMasterClass,
}
