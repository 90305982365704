import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

export enum ModuleType {
  'header' = 'header',
  'video' = 'video',
}

export interface VideoMetaInfo {
  index: string
  id: string
  videoChapter: number
  thumbnail: string
  video_title: string
  video_description: string
  last_watched: string
  duration: string
  video_url: string
}

export interface AndroidAppReducer {
  isLoading: boolean
  error: string
  moduleData: any
  videoUpdateCompletionLoading: boolean
  videoUpdateCompletionError: boolean | string
}

const initialState: AndroidAppReducer = {
  isLoading: false,
  error: '',
  moduleData: null,
  videoUpdateCompletionLoading: false,
  videoUpdateCompletionError: false,
}

export const fetchPracticeTabData = createAction('practiseTab/fetchAndroidData')

export const fetchUpdateVideoCompletionAction = createAction<
  Record<number, any>
>('practiseTab/fetchUpdateVideoCompletionAction')

const practiseAppSlice = createSlice({
  name: 'practiseTab',
  initialState,
  reducers: {
    setPractiseLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload }
    },
    setPractiseError(state, action: PayloadAction<string>) {
      return { ...state, error: action.payload }
    },
    setPractiseData(state, action: PayloadAction<any>) {
      return { ...state, moduleData: action.payload }
    },
    setUpdateVideoCompletionLoading(state, action: PayloadAction<boolean>) {
      return { ...state, videoUpdateCompletionLoading: action.payload }
    },
    setUpdateVideoCompletionError(state, action: PayloadAction<string>) {
      return { ...state, videoUpdateCompletionError: action.payload }
    },
    setUpdateVideoCompletionSuccess(state) {
      return { ...state }
    },
  },
})

export const {
  setPractiseError,
  setPractiseLoading,
  setPractiseData,
  setUpdateVideoCompletionLoading,
  setUpdateVideoCompletionError,
  setUpdateVideoCompletionSuccess,
} = practiseAppSlice.actions

export default practiseAppSlice.reducer
