import { AnyAction } from "redux";
import { IntermediateScreenSchema } from "Interfaces/reducerTypes/IntermediateScreenSchema";

const initialState = {
  showUpdateUserModal: false,
  showUpdateUserView: false,
};

const intermediateScreenReducer = (
  state: IntermediateScreenSchema = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case "TOGGLE_INTERMEDIATE_SCREEN_VIEW":
      if (window.innerWidth > 767) {
        return {
          ...state,
          showUpdateUserModal: action.payload,
        };
      } else {
        return {
          ...state,
          showUpdateUserView: action.payload,
        };
      }
    default:
      return state;
  }
};

export default intermediateScreenReducer;
