import { logEvent } from 'ga'
import { webEngageGetForever, webEventTracker } from 'webEngage'
export const eventTracker = (
  eventType: string,
  eventData: any,
  shouldFireGA = true
) => {
  return new Promise(async (resolve) => {
    const { category, action, label } = eventData
    if (shouldFireGA) {
      logEvent(category, action, label)
    }
    webEngageGetForever()
      .then(() => {
        webEventTracker(eventType, eventData)
        resolve(true)
      })
      .catch(() => {
        resolve(false)
      })
  })
}
