import api from "service/api"
import { NotifyUserForExtraClassPayload } from "components/Profile/IeltsProfile/ExtraClass/redux/ExtraClassActions"
import { SlotTypes } from "components/SpotCounselling/Calendar/utils"
import { MasterclassLimit } from "DataMapper/IeltsProfile/IeltsDemo"

const fetchIeltsEliteDemoSlots = (days: number) =>
  api.get(
    `/api/scholarRoute?path=counselling/slots/${SlotTypes.IELTS_SPOT_COUNSELLING}/live/${days}`,
    {
      headers: {
        "X-API-VERSION": 3,
      },
    },
  )

const fetchMasterclassDataApi = (masterclassLimit: MasterclassLimit) =>
  api.get(`/api/ielts/masterclassSlotStructure`, {
    headers: {
      starting_limit: masterclassLimit.start,
      ending_limit: masterclassLimit.end,
      data_limit: masterclassLimit.dataLimit,
    },
  })

const enrollWorkshop = (workshop: NotifyUserForExtraClassPayload) =>
  api.post(
    `/api/scholarRoute?path=workshop/enroll/${workshop.batchId}/${workshop.workshopType}`,
  )

const subscribeMasterClassApi = () =>
  api.post(`/api/scholarRoute?path=workshop/master-class/subscribe`)

const fetchRejoinModalStatus = () =>
  api.get(`/api/scholarRoute?path=ielts-elite/eligible/rejoin`)

const fetchAnroidAppMagicLink = () =>
  api.get(`/api/scholarRoute?path=ielts-elite/magic-link`)

export const IeltsDemoApi = {
  fetchIeltsEliteDemoSlots,
  fetchMasterclassDataApi,
  enrollWorkshop,
  subscribeMasterClassApi,
  fetchRejoinModalStatus,
  fetchAnroidAppMagicLink,
}
