import { AnyAction } from "redux";
import { ReferAndEarnSchema } from "Interfaces/reducerTypes/ReferAndEarnSchema";

const initialState: ReferAndEarnSchema = {
  userInfo: {
    email: "",
    firstname: "",
    lastname: "",
    phone: "",
    name: "",
  },
  referralData: [],
  error: "",
};

// create your reducer
const reducer = (
  state: ReferAndEarnSchema = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case "USER_DETAILS_FETCH_SUCCESS":
      return {
        ...state,
        userInfo: action.payload,
      };
    case "USER_DETAILS_FETCH_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    case "USER_REFERRALS_FETCH_SUCCESS":
      return {
        ...state,
        referralData: action.payload,
      };
    case "USER_REFERRALS_FETCH_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    case "POST_REFERRAL_CODE_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    case "REGISTER_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
