import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WorkshopSchema } from "Interfaces/reducerTypes/IeltsPlusSchema";

export interface OrientationReducer {
  isLoading: boolean;
  formSubmit: boolean;
  error: string;
  selectedBatch: null | WorkshopSchema;
}
const initialState: OrientationReducer = {
  isLoading: false,
  formSubmit: false,
  error: "",
  selectedBatch: null,
};

export const orientationFormSubmitReq = createAction("orientation/formSubmit");

const orientationSlice = createSlice({
  name: "orientation",
  initialState,
  reducers: {
    setIsLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload };
    },
    setError(state, action: PayloadAction<string>) {
      return { ...state, error: action.payload };
    },
    setOrientationSelectedBatch(
      state,
      action: PayloadAction<WorkshopSchema | null>
    ) {
      return { ...state, selectedBatch: action.payload };
    },
  },
});

export const { setIsLoading, setError, setOrientationSelectedBatch } =
  orientationSlice.actions;

export default orientationSlice.reducer;
