import PaymentConstants from "components/Payment/redux/PaymentReduxConstants";

const fetchUserDataForPayment = () => ({
  type: PaymentConstants.FETCH_USER_DATA_FOR_PAYMENT,
});

const fetchUserDataForPaymentSuccess = (payload: string) => ({
  type: PaymentConstants.FETCH_USER_DATA_FOR_PAYMENT_SUCCESS,
  payload,
});

const fetchUserDataForPaymentError = (message: string) => ({
  type: PaymentConstants.FETCH_USER_DATA_FOR_PAYMENT_ERROR,
  payload: message,
});


const fetchPaymentData = (payload:any) => ({
  type: PaymentConstants.FETCH_PAYMENT_DATA,
  payload,
});

const fetchPaymentDataSuccess = (payload: string) => ({
  type: PaymentConstants.FETCH_PAYMENT_DATA_SUCCESS,
  payload,
});

const fetchPaymentDataError = (message: string) => ({
  type: PaymentConstants.FETCH_PAYMENT_DATA_ERROR,
  payload: message,
});

const validatePromoCode = (payload:any) => ({
  type: PaymentConstants.VALIDATE_PROMO_CODE,
  payload,
});

const validatePromoCodeSuccess = (payload: string) => ({
  type: PaymentConstants.VALIDATE_PROMO_CODE_SUCCESS,
  payload,
});

const validatePromoCodeError = (message: string) => ({
  type: PaymentConstants.VALIDATE_PROMO_CODE_ERROR,
  payload: message,
});

const initilizePayment = (payload:any) => ({
  type: PaymentConstants.INITIALIZE_PAYMENT,
  payload,
});

const initilizePaymentSuccess = (payload: string) => ({
  type: PaymentConstants.INITIALIZE_PAYMENT_SUCCESS,
  payload,
});

const initilizePaymentError = (message: string) => ({
  type: PaymentConstants.INITIALIZE_PAYMENT_ERROR,
  payload: message,
});

// const applyPromoCode = (payload?:any) => ({
//   type: PaymentConstants.APPLY_PROMO_CODE,
//   payload,
// });

export const paymentActions = {
  fetchUserDataForPayment,
  fetchUserDataForPaymentSuccess,
  fetchUserDataForPaymentError,

  fetchPaymentData,
  fetchPaymentDataSuccess,
  fetchPaymentDataError,

  validatePromoCode,
  validatePromoCodeSuccess,
  validatePromoCodeError,

  // applyPromoCode,

  initilizePayment,
  initilizePaymentSuccess,
  initilizePaymentError,
};
