import { ApolloProvider } from "@apollo/react-common"
import * as Sentry from "@sentry/nextjs"
import { cookiesConstant } from "DataMapper/Common/cookiesConstant"
import { CountryCode } from "DataMapper/CountryCode"
import { ApolloClient } from "apollo-client"
import withData from "apollo/apollo"
import GoogleAnalytics from "components/GoogleAnalytics"
import { spotCounsellingActions } from "components/SpotCounselling/redux/SpotCounsellingActions"
import InactiveUserModal from "components/common/InactiveUserModal"
import Layout from "components/common/Layout"
import { authActions } from "components/decorator/redux/AuthActions"
import { logPageView } from "ga"
import { getTokenCookie } from "lib/auth-cookies-new"
import { AuthToken } from "lib/auth_token"
import { NextPageContext } from "next"
import App, { AppContext, AppProps } from "next/app"
import Router, { useRouter } from "next/router"
import Script from "next/script"
import { navRoutes } from "page_routes"

import { ParsedUrlQuery } from "querystring"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Store } from "redux"
import { END } from "redux-saga"
import { wrapper } from "redux/store"
import { getCookie, saveCookie, saveUtmCookie } from "utils/cookie-utiils"
import { saveUtmData } from "utils/utm_utils"
import FourOhFour from "./404"
import { getUserId } from "winston/winston.config"
import { setIsGetSupportModalOpen } from "components/UserDashboard/GetSupport/getSupportSlice"
import { RootStateOrAny, useSelector } from "react-redux"
import dynamic from "next/dynamic"
import "@leapfinance/frontend-commons/tailwind-compiled.css"
import "public/static/scss/GlobalStyleSheet.scss"
import "public/static/scss/GlobalVendor.css"

const GetSupportModal = dynamic(
  () => import("components/UserDashboard/GetSupport/GetSupportModal"),
)

const CANONICAL_DOMAIN = "https://leapscholar.com"

interface Props {
  apollo: ApolloClient<{}>
  query: ParsedUrlQuery
  err: Error
}

declare global {
  interface Window {
    dataLayer: object
  }
}

declare module "next" {
  export interface NextPageContext {
    /**
     * Provided by next-redux-wrapper: The redux store
     */
    store: Store
  }
}

if (process.env.SENTRY_DSN) {
  if (getUserId(null)) {
    Sentry.setUser({ username: getUserId(null) })
  }
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.SENTRY_DSN,
    sampleRate: 1.0,
    autoSessionTracking: true,
  })
}

const savePromocode = (context: NextPageContext, query: any) => {
  const promocode = query && query.promocode
  if (!!promocode) saveCookie(context, "promocode", promocode)
}

const captureAndSaveUtm = (context: NextPageContext, query: any) => {
  const utmSource = query && query.utm_source
  const utmCampaign = query && query.utm_campaign
  const utmTerm = query && query.utm_term
  const utmMedium = query && query.utm_medium
  const campaignType = query && query.campaign_type
  const adId = query && query.ad_id
  const trackingId = query && query.tracking_id
  const gclid = query && query.gclid
  const fbclid = query && query.fbclid
  const adName = query && query.ad_name
  saveUtmData(context, {
    gclid: gclid as string,
    adName: adName as string,
    utmSource: utmSource as string,
    utmCampaign: utmCampaign as string,
    utmMedium: utmMedium as string,
    utmTerm: utmTerm as string,
    adId: adId as string,
    fbclid: fbclid as string,
    campaignType: campaignType as string,
    trackingId: trackingId as string,
  })
}
const ScholarApp = ({
  Component,
  pageProps,
  apollo,
  err,
}: Props & AppProps) => {
  const router = useRouter()
  const dispatch = useDispatch()
  pageProps.auth = new AuthToken(pageProps.auth.token)
  const isGetSupportModalOpen = useSelector(
    (state: RootStateOrAny) => state.getSupportSlice.isGetSupportModalOpen,
  )
  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
    sessionStorage.setItem("modalShown", "true")
  }

  useEffect(() => {
    if (router.isReady) {
      if (pageProps.auth?.isValid) {
        dispatch(spotCounsellingActions.fetchStage())
        dispatch(spotCounsellingActions.fetchAppNudgeEligibility())
      }
      logPageView(router.asPath)
    }

    const onRouteChangeComplete = () => {
      if (getTokenCookie(null)) {
        dispatch(spotCounsellingActions.fetchStage())
        dispatch(spotCounsellingActions.fetchAppNudgeEligibility())
      }
    }
    router.events.on("routeChangeComplete", onRouteChangeComplete)
    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete)
    }
  }, [])

  useEffect(() => {
    const _pathSliceLength = Math.min.apply(Math, [
      Router.asPath.indexOf("?") > 0
        ? Router.asPath.indexOf("?")
        : Router.asPath.length,
      Router.asPath.indexOf("#") > 0
        ? Router.asPath.indexOf("#")
        : Router.asPath.length,
    ])
    const canonicalURL =
      CANONICAL_DOMAIN + Router.asPath.substring(0, _pathSliceLength)
    const link = document.createElement("link")
    link.rel = "canonical"
    link.href = canonicalURL
    document.head.appendChild(link)

    if (
      ["blog", "university", "universities"].includes(
        document?.referrer?.split("/")[3],
      )
    ) {
      saveUtmData({}, { referer: document?.referrer as string })
    }

    const refererUrl = getCookie({}, "refererUrl")
    const decodedCallbackUrl = decodeURIComponent(window.location.href)
    const referrer = decodedCallbackUrl.split("referrer=")[1]
    const referrerInCookie = getCookie({}, "referrer_id")

    if (!refererUrl?.length && document?.referrer) {
      saveUtmCookie({}, "refererUrl", document?.referrer as string)
    }

    if (
      (!referrerInCookie || referrerInCookie === "undefined") &&
      !pageProps?.auth?.token
    ) {
      saveUtmCookie(
        {},
        "referrer_id",
        referrer || (Router?.query?.referrer as string),
      )
    }

    let inactivityTimer: ReturnType<typeof setTimeout>
    const time = process.env.INACTIVE_MODAL_POPUP_TIME || "120000"

    const resetTimer = () => {
      clearTimeout(inactivityTimer)
      inactivityTimer = setTimeout(() => {
        if (!sessionStorage.getItem("modalShown")) {
          setShowModal(true)
        }
      }, parseInt(time))
    }

    const handleClick = () => {
      resetTimer()
      if (window.parent !== window) {
        window.parent.postMessage({ eventType: "click" }, "*")
      }
      sessionStorage.setItem("modalShown", "true")
    }

    document.addEventListener("click", handleClick)

    resetTimer()

    return () => {
      clearTimeout(inactivityTimer)
      document.removeEventListener("click", handleClick)
    }
  }, [])

  const isDev = process.env.NODE_ENV === "development"

  if (pageProps?.error) {
    return (
      <Layout>
        <FourOhFour />
      </Layout>
    )
  }

  const userId = getCookie(null, "user_id")

  return (
    <>
      {!isDev && (
        <Script
          id="leap-tracker-clarity"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
      console.log("in clarity", ${userId})
      clarity("set", "preUserId", "${userId}");
    })(window, document, "clarity", "script", '${process.env.CLARITY_KEY}');
  `,
          }}
        />
      )}
      <Script
        id="leap-tracker-gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${process.env.GTM_ID}');`,
        }}
      />

      <Script
        id="vwoCode"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window._vwo_code=window._vwo_code || (function() {
            var account_id=${process.env.VWO_ACCOUNT_ID},
            version = 1.5,
            settings_tolerance=2000,
            library_tolerance=2500,
            use_existing_jquery=false,
            is_spa=1,
            hide_element='body',
            hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
            /* DO NOT EDIT BELOW THIS LINE */
            f=false,w=window,d=document,vwoCodeEl=d.querySelector('#vwoCode'),code={use_existing_jquery:function(){return use_existing_jquery},library_tolerance:function(){return library_tolerance},hide_element_style:function(){return'{'+hide_element_style+'}'},finish:function(){if(!f){f=true;var e=d.getElementById('_vis_opt_path_hides');if(e)e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement('script');t.fetchPriority='high';t.src=e;t.type='text/javascript';t.onerror=function(){_vwo_code.finish()};d.getElementsByTagName('head')[0].appendChild(t)},getVersion:function(){return version},getMatchedCookies:function(e){var t=[];if(document.cookie){t=document.cookie.match(e)||[]}return t},getCombinationCookie:function(){var e=code.getMatchedCookies(/(?:^|;)\s?(_vis_opt_exp_\d+_combi=[^;$]*)/gi);e=e.map(function(e){try{var t=decodeURIComponent(e);if(!/_vis_opt_exp_\d+_combi=(?:\d+,?)+\s*$/.test(t)){return''}return t}catch(e){return''}});var i=[];e.forEach(function(e){var t=e.match(/([\d,]+)/g);t&&i.push(t.join('-'))});return i.join('|')},init:function(){if(d.URL.indexOf('__vwo_disable__')>-1)return;w.settings_timer=setTimeout(function(){_vwo_code.finish()},settings_tolerance);var e=d.currentScript,t=d.createElement('style'),i=e&&!e.async?hide_element?hide_element+'{'+hide_element_style+'}':'':code.lA=1,n=d.getElementsByTagName('head')[0];t.setAttribute('id','_vis_opt_path_hides');vwoCodeEl&&t.setAttribute('nonce',vwoCodeEl.nonce);t.setAttribute('type','text/css');if(t.styleSheet)t.styleSheet.cssText=i;else t.appendChild(d.createTextNode(i));n.appendChild(t);var o=this.getCombinationCookie();this.load('https://dev.visualwebsiteoptimizer.com/j.php?a='+account_id+'&u='+encodeURIComponent(d.URL)+'&f='+ +is_spa+'&vn='+version+(o?'&c='+o:''));return settings_timer}};w._vwo_settings_timer = code.init();return code;}());`,
        }}
        async
      />
      {isGetSupportModalOpen && (
        <GetSupportModal
          isModalOpen={isGetSupportModalOpen}
          handleClose={() => dispatch(setIsGetSupportModalOpen(false))}
        />
      )}
      <GoogleAnalytics
        userId={pageProps?.auth?.decodedToken?.user_id?.user_id}
      />
      <ApolloProvider client={apollo}>
        {showModal && <InactiveUserModal handleCloseModal={handleCloseModal} />}
        <Component {...pageProps} err={err} />
      </ApolloProvider>
    </>
  )
}

ScholarApp.getInitialProps = async (
  appContext: AppContext & NextPageContext,
) => {
  captureAndSaveUtm(appContext.ctx, appContext.ctx.query)
  savePromocode(appContext.ctx, appContext.ctx.query)
  const appProps = await App.getInitialProps(appContext)

  if (appProps.pageProps?.error?.statusCode && appContext.ctx.res) {
    appContext.ctx.res.statusCode = appProps.pageProps.error.statusCode
  }

  if (appContext.ctx.req && appContext.Component.getInitialProps) {
    appContext.ctx.store.dispatch(END)
    await (appContext.ctx.store as any).sagaTask.toPromise()
  }

  const token = getTokenCookie(appContext.ctx)
  const auth = new AuthToken(token)
  appContext.ctx.store.dispatch(authActions.saveAuth(auth?.token))
  appProps.pageProps.auth = auth
  return {
    query: appContext.ctx.query,
    ...appProps,
  }
}

export default wrapper.withRedux(withData(ScholarApp))
