import api from "service/api"

const fetchProfileData = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=student/profile`)
    return res
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const saveProfile = async (values: Record<string, any>) => {
  try {
    const res = await api.post(`/api/scholarRoute?path=student/profile`, values)
    return res
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const checkEligibilty = async () => {
  try {
    const res = await api.get(
      "/api/scholarRoute?path=student/eligibility/ielts",
    )
    return res
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const fetchIeltsNudgeData = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=student/ielts-nudge`)
    return res
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page")
  }
}

const saveIeltsNudgeData = async (values: Record<string, any>) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=student/ielts-nudge`,
      values,
    )
    console.log("res from api", res.data.data)
    return res
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page")
  }
}

export const counsellingFormApis = {
  fetchProfileData,
  saveProfile,
  checkEligibilty,
  fetchIeltsNudgeData,
  saveIeltsNudgeData,
}
