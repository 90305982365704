import { AnyAction } from "redux";

const initialState: any = {
  expertsData: [],
  error: null,
  stage: null,
  slots: [],
  isEnrolled: false,
  isLoadingSlots: false,
  meetProfileData: null,
  isSubmittedMeetProfile: false,
  isLiveSlotAvailable: null,
  liveSlot: null,
  typedSlots: [],
  visaCounsellorSlot: [],
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "FETCH_EXPERTS_DATA":
      return {
        ...state,
      };
    case "FETCH_EXPERTS_DATA_SUCCESS":
      return {
        ...state,
        expertsData: action.payload,
      };
    case "FETCH_EXPERTS_DATA_FAIL":
      return {
        ...state,
        error: action.payload,
        expertsData: [],
      };
    case "FETCH_STAGE":
      return {
        ...state,
      };
    case "FETCH_STAGE_SUCCESS":
      return {
        ...state,
        stage: action.payload,
      };
    case "FETCH_STAGE_FAIL":
      return {
        ...state,
        error: action.payload,
        stage: null,
      };
    case "FETCH_MEET_COUNSELLER_SLOT":
      return {
        ...state,
        slots: [],
        isLoadingSlots: true,
      };
    case "FETCH_MEET_COUNSELLER_SLOT_SUCCESS":
      return {
        ...state,
        slots: action.payload.calendarResponses,
        isLoadingSlots: false,
        isLiveSlotAvailable: action.payload.liveSlotAvailable,
        liveSlot: action.payload.liveSlot,
      };

    case "FETCH_MEET_COUNSELLER_SLOT_FAIL":
      return {
        ...state,
        error: action.payload,
        slots: [],
        isLoadingSlots: false,
        liveSlot: null,
        isLiveSlotAvailable: false,
      };
    case "FETCH_TYPED_MEET_COUNSELLER_SLOT":
      return {
        ...state,
        typedSlots: [],
        isLoadingSlots: true,
      };
    case "FETCH_TYPED_MEET_COUNSELLER_SLOT_SUCCESS":
      return {
        ...state,
        typedSlots: action.payload.calendarResponses,
        isLoadingSlots: false,
        isLiveSlotAvailable: action.payload.liveSlotAvailable,
        liveSlot: action.payload.liveSlot,
      };

    case "FETCH_TYPED_MEET_COUNSELLER_SLOT_FAIL":
      return {
        ...state,
        error: action.payload,
        typedSlots: [],
        isLoadingSlots: false,
        liveSlot: null,
        isLiveSlotAvailable: false,
      };
    case "FETCH_VISA_COUNSELLER_SLOT":
      return {
        ...state,
        visaCounsellorSlot: [],
        isLoadingSlots: true,
      };
    case "FETCH_VISA_COUNSELLER_SLOT_SUCCESS":
      return {
        ...state,
        visaCounsellorSlot: action.payload.calendarResponses,
        isLoadingSlots: false,
        isLiveSlotAvailable: action.payload.liveSlotAvailable,
        liveSlot: action.payload.liveSlot,
      };

    case "FETCH_VISA_COUNSELLER_SLOT_FAIL":
      return {
        ...state,
        error: action.payload,
        visaCounsellorSlot: [],
        isLoadingSlots: false,
        liveSlot: null,
        isLiveSlotAvailable: false,
      };
    case "CONFIRM_MEET_COUNSELLER_SLOT_SUCCESS":
      return {
        ...state,
        isEnrolled: true,
      };
    case "CONFIRM_MEET_COUNSELLER_SLOT_FAIL":
      return {
        ...state,
        isEnrolled: false,
        error: action.payload,
      };
    case "CONFIRM_MEET_COUNSELLER_SLOT":
      return {
        ...state,
        error: null,
        isEnrolled: false,
      };
    case "CONFIRM_RESCHEDULE_SLOT_SUCCESS":
      return {
        ...state,
        isEnrolled: action.payload,
      };
    case "CONFIRM_RESCHEDULE_SLOT_FAIL":
      return {
        ...state,
        isEnrolled: false,
        error: action.payload,
      };
    case "CONFIRM_RESCHEDULE_SLOT":
      return {
        ...state,
        error: null,
        isEnrolled: false,
      };
    case "FETCH_MEET_PROFILE":
      return {
        ...state,
      };
    case "FETCH_MEET_PROFILE_SUCCESS":
      return {
        ...state,
        meetProfileData: action.payload,
      };
    case "FETCH_MEET_PROFILE_FAIL":
      return {
        ...state,
        error: action.payload,
        meetProfileData: null,
      };
    case "SUBMIT_MEET_PROFILE":
      return {
        ...state,
      };
    case "SUBMIT_MEET_PROFILE_SUCCESS":
      return {
        ...state,
        isSubmittedMeetProfile: action.payload,
      };
    case "SUBMIT_MEET_PROFILE_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
