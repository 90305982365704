import moment from 'moment'

export const createCalendarEventObj = (batchData: any) => {
  const { selectedBatch, name } = batchData
  const startTime = getTimeInCorrectFormat(
    selectedBatch.startDate,
    selectedBatch.startTime
  )
  const endTime = getTimeInCorrectFormat(
    selectedBatch.startDate,
    selectedBatch.endTime
  )
  const recurrence = getRecurrence(selectedBatch)
  return {
    summary: 'LeapScholar IELTS Plus class',
    location: 'https://bit.ly/3bV70u7',
    description: `
      Hello ${name},
        You can join your class here: https://bit.ly/3bV70u7 
        Go to your batch details page by clicking the blue arrow and click enter class.
        To join these classes, you have to sign in to your Zoom account. 
        Steps to follow when you join before the session:

        Step 1: Download Zoom App
        Step 2: Click on the link
        Step 3: Click on Sign In
        Step 4: SIGN IN WITH GOOGLE
        Step 5: Enter your gmail ID and password
        Step 6: Connect with Internet Audio
        
        If you face any issues, please refer to these images for steps to follow: http://bit.ly/3r5iaRs 
        Also, do join your batch Telegram group here: ${selectedBatch.groupChatLink}.`,
    start: {
      dateTime: startTime,
      timeZone: 'Asia/Kolkata',
    },
    end: {
      dateTime: endTime,
      timeZone: 'Asia/Kolkata',
    },
    recurrence: recurrence,
    reminders: {
      useDefault: false,
      overrides: [{ method: 'popup', minutes: 10 }],
    },
    attendees: [],
  }
}

const getTimeInCorrectFormat = (date: string, time: string) => {
  return `${date?.split('T')[0]}T${time?.split('T')[1]}`
}

const weekList = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']

const getRecurrence = (batch: any) => {
  const weeklySchedule = batch.weeklySchedule
    ?.split('-')
    .map((str: string) => str.replace(/\s/g, ''))
  const arr: any = []
  weekList.forEach((day: string) => {
    if (
      weekList.indexOf(day) >=
        weekList.indexOf(weeklySchedule[0].substring(0, 2).toUpperCase()) &&
      weekList.indexOf(day) <=
        weekList.indexOf(weeklySchedule[1].substring(0, 2).toUpperCase())
    ) {
      arr.push(day)
    }
  })
  const newDate = moment(batch.endDate).add(1, 'days').toISOString()
  return [
    `RRULE:FREQ=DAILY;UNTIL=${newDate
      .split('T')[0]
      .split('-')
      .join('')};INTERVAL=1;BYDAY=${arr.join(',')}`,
  ]
}
