export function sortJSON(arr: Array<any>, key: string, asc: any) {
  return arr.sort(function (a: any, b: any) {
    const x = a[key];
    const y = b[key];
    if (asc) {
      return x < y ? -1 : x > y ? 1 : 0;
    } else {
      return x > y ? -1 : x < y ? 1 : 0;
    }
  });
}

export function sortJSONWithTwoParameter(
  arr: Array<any>,
  key1: string,
  key2: string,
  asc: any
) {
  return arr.sort(function (a: any, b: any) {
    const x = a[key1];
    const y = b[key1];
    const m = a[key2];
    const n = b[key2];
    if (asc) {
      return x < y ? -1 : x > y ? 1 : m < n ? -1 : m > n ? 1 : 0;
    } else {
      return x > y ? -1 : x < y ? 1 : m > n ? -1 : m < n ? 1 : 0;
    }
  });
}

export function sortJSONWithThreeParameter(
  arr: Array<any>,
  key1: string,
  key2: string,
  key3: string,
  asc: any
) {
  return arr.sort(function (a: any, b: any) {
    const x = a[key1];
    const y = b[key1];
    const m = a[key2];
    const n = b[key2];
    const l = a[key3];
    const k = b[key3];
    if (asc) {
      return x < y
        ? -1
        : x > y
        ? 1
        : m < n
        ? -1
        : m > n
        ? 1
        : l < k
        ? -1
        : l > k
        ? 1
        : 0;
    } else {
      return x > y
        ? -1
        : x < y
        ? 1
        : m > n
        ? -1
        : m < n
        ? 1
        : l > k
        ? -1
        : l < k
        ? 1
        : 0;
    }
  });
}
