import { IeltsStageType } from "components/Ielts/IeltsEliteDemo/redux/IeltsEliteSlice"
import { priceBundleName } from "DataMapper/Common/WorkshopData"
import { PaymentType } from "Interfaces/common/PaymentSchema"
import { BatchSchema } from "Interfaces/reducerTypes/BatchDetailsSchema"
import {
  IsEnrolledForBatchesType,
  WorkshopSchema,
} from "Interfaces/reducerTypes/IeltsPlusSchema"
import moment from "moment"
import { getFullDate } from "utils/ielts_date_utils"
import isValidDate from "date-fns/isValid"
import { productType } from "DataMapper/Common/ProductType"
import { PriceDTO } from "Interfaces/reducerTypes/TestPrepSchema"

export const getBatchSlots = (startHours: number) => {
  if (startHours < 12) {
    return "Morning Batch"
  } else if (startHours < 18) {
    return "Afternoon Batch"
  } else {
    return "Evening Batch"
  }
}

export const shortenWeeklySchedule = (weekday: string) => {
  // expected monday-friday
  // output mon-fri
  const weekArray = weekday.split("-")
  if (weekArray.length !== 2) return weekday
  return `${weekArray[0].slice(0, 3)}-${weekArray[1].slice(0, 3)}`
}

export const diffWeeks = (startDate: string, endDate: string) => {
  const dt1 = new Date(startDate)
  const dt2 = new Date(endDate)

  let diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= 60 * 60 * 24 * 7
  return Math.abs(Math.round(diff))
}

export const getStartingHour = (startDate: string) => {
  return Number(moment(startDate).format("HH"))
}

export const diffeDays = (
  startDate: string,
  hours: number,
  minutes: number,
) => {
  const startHours = hours < 10 ? `${0}${hours}` : `${hours}`
  const startMinutes = minutes < 10 ? `${0}${minutes}` : `${minutes}`
  const DateString =
    startDate + "T" + startHours + ":" + startMinutes + ":" + "00"
  return getDayWithoutWeekend(DateString)
}

const getDayWithoutWeekend = (startDate: string) => {
  const ONE_DAY = 1000 * 60 * 60 * 24
  let variableDate = Date.parse(startDate)
  const currentDate = Date.parse(new Date().toString())
  let totalDate = 0
  while (variableDate <= currentDate) {
    const currentDayNumber = new Date(variableDate).getDay()
    if (currentDayNumber !== 0 && currentDayNumber !== 6) {
      totalDate += 1
    }
    variableDate += ONE_DAY
  }
  return totalDate
}

export const dayDifference = (startDate: string) => {
  return getDayWithoutWeekend(startDate)
}

export const getStartingDate = (startDate: string) => {
  return Number(moment(startDate).format("DD"))
}

export const isPremiumBatch = (batchData: any) => {
  return isPremiumBundleCheck(batchData?.pricingBundleName)
}

export const isPremiumBundleCheck = (
  pricingBundleName: priceBundleName | string,
): boolean => {
  return !pricingBundleName?.includes("PLUS")
}

export const showDaignoisticTests = (
  isEnrolledForBatches: IsEnrolledForBatchesType,
): boolean => {
  if (
    isEnrolledForBatches.isEnrolledForElite === null &&
    isEnrolledForBatches.isEnrolledForSelect === null &&
    isEnrolledForBatches.isEnrolledForPlus === null &&
    isEnrolledForBatches.isEnrolledForPremium === null
  ) {
    return true //this means api is not called yet and everything is in it default state
  }
  if (
    isEnrolledForBatches.isEnrolledForElite === true ||
    isEnrolledForBatches.isEnrolledForSelect === true ||
    isEnrolledForBatches.isEnrolledForPlus === true ||
    isEnrolledForBatches.isEnrolledForPremium === true
  ) {
    return false //this means user is enrolled in any of the batch
  }

  return true //this means everything is false user is not enrolled in any batch
}
export const getPriceBundleNameFromPaymentType = (
  paymentType: PaymentType | string,
) => {
  switch (paymentType) {
    case PaymentType.IELTS_FOUNDATION_FEE:
      return priceBundleName.IELTS_FOUNDATION
    case PaymentType.IELTS_PREMIUM_FEE:
      return priceBundleName.IELTS_PLUS_PREMIUM
    case PaymentType.IELTS_PREMIUM_2_FEE:
      return priceBundleName.IELTS_PLUS_PREMIUM_2
    case PaymentType.IELTS_PREMIUM_3_FEE:
      return priceBundleName.IELTS_PLUS_PREMIUM_3
    case PaymentType.REWARD_BOOSTER_FEE:
      return priceBundleName.BOOSTER_PACK
    case PaymentType.IELTS_PLUS_PART_2_FEE:
      return priceBundleName.IELTS_PLUS_PART_2
    case PaymentType.IELTS_FEE:
    default:
      return priceBundleName.IELTS_PLUS
  }
}

const isBatchFull = (bookedSeat: number, numberOfSeat: number) => {
  return numberOfSeat <= bookedSeat
}

const classAlreadyStarted = (classStartDate: Date) => {
  return classStartDate < new Date()
}

export const getBatchErrorMessage = (batchDetails: BatchSchema) => {
  if (isBatchFull(batchDetails.seatBooked, batchDetails.numberOfSeats))
    return "Sorry you cannot enrol in this batch as all seats are booked."
  if (
    classAlreadyStarted(
      getFullDate(
        batchDetails.startDate,
        batchDetails.startHour,
        batchDetails.startMinute,
      ),
    )
  )
    return "Sorry, You cannot enrol in this batch as it has already started."
  return ""
}

export const removeNullValueFromArray = (arr: Array<any>) =>
  arr?.filter((data) => data)

export const selectOtherCountryIfGermanyIsThere = (arr: Array<any>) => {
  if (arr && arr.length > 1 && arr.includes("Other")) {
    const index = arr.indexOf("Other")
    arr.splice(index, 1)
    return arr
  } else return arr
}

export const IsDemoBooked = (ieltsStage: IeltsStageType | null): boolean => {
  return (
    ieltsStage === IeltsStageType.IELTS_DEMO_BOOKED ||
    ieltsStage === IeltsStageType.IELTS_DEMO_ATTENDED
  )
}

export const isIeltsEliteBundleAvataran = (
  pricingBundleName: priceBundleName | string,
): boolean => {
  return pricingBundleName.toUpperCase().includes("ELITE")
}

export const getEliteAndSelectBatches = (
  batches: Array<WorkshopSchema>,
): {
  selectBatch: Array<WorkshopSchema>
  eliteBatch: Array<WorkshopSchema>
} =>
  batches.reduce<{
    eliteBatch: Array<WorkshopSchema>
    selectBatch: Array<WorkshopSchema>
  }>(
    (acc, batch) => {
      if (isIeltsEliteBundleAvataran(batch.pricingBundleName || "")) {
        return { ...acc, eliteBatch: [...acc.eliteBatch, batch] }
      } else {
        return { ...acc, selectBatch: [...acc.selectBatch, batch] }
      }
    },
    {
      eliteBatch: [],
      selectBatch: [],
    },
  )

const addISTLabelToTime = (dateString: string) => {
  const istLabelString = dateString + ".000+05:30"
  return isValidDate(new Date(istLabelString)) ? istLabelString : dateString
}

export const getLatestUpcomingClass = (
  batches: Array<WorkshopSchema>,
): WorkshopSchema | null => {
  try {
    if (batches.length < 1) return null
    let nextUpcomingClass: WorkshopSchema | null = null
    batches.forEach((batch) => {
      if (
        new Date(addISTLabelToTime(batch.startTime)) > new Date() &&
        (!nextUpcomingClass ||
          new Date(addISTLabelToTime(batch.startTime)) <
            new Date(addISTLabelToTime(nextUpcomingClass?.startTime)))
      ) {
        nextUpcomingClass = batch
      }
    })

    return nextUpcomingClass
  } catch (e) {
    return null
  }
}
export const eligibleForNewFlow = (bundleName: string) =>
  bundleName != priceBundleName.IELTS_CUSTOM

export const eligibleForNewFlowByPrice = (price: number) => price > 6800

export const getIeltsFlowType = (
  enrolment: Array<WorkshopSchema>,
  selfPrepDetails: null | PriceDTO,
  isEnrolledInLiveClasses: boolean | null,
): { flowType: string; activeBatchId: null | number } | null => {
  if (enrolment.length > 0) {
    const latestBatch = getLatestUpcomingClass(enrolment)
    if (latestBatch) {
      return {
        flowType: productType.ielts,
        activeBatchId: latestBatch.batchId,
      }
    }
    return {
      flowType: productType.ielts,
      activeBatchId: enrolment[0].batchId,
    }
  } else if (selfPrepDetails && selfPrepDetails.alreadyPaid) {
    return { flowType: productType.selfPrep, activeBatchId: null }
  } else if (
    isEnrolledInLiveClasses == false &&
    selfPrepDetails &&
    !selfPrepDetails.alreadyPaid
  ) {
    return { flowType: productType.free, activeBatchId: null }
  }
  return null
}

export const getLatestLiveClass = (
  batches: Array<WorkshopSchema>,
): WorkshopSchema | null => {
  try {
    if (batches.length < 1) return null
    let nextUpcomingClass: WorkshopSchema | null = null
    batches.forEach((batch) => {
      if (
        new Date(addISTLabelToTime(batch.startTime)) > new Date() ||
        (new Date(addISTLabelToTime(batch.startTime)) < new Date() &&
          new Date(addISTLabelToTime(batch.endTime)) > new Date() &&
          (!nextUpcomingClass ||
            new Date(addISTLabelToTime(batch.startTime)) <
              new Date(addISTLabelToTime(nextUpcomingClass?.startTime))))
      ) {
        nextUpcomingClass = batch
      }
    })

    return nextUpcomingClass
  } catch (e) {
    return null
  }
}
