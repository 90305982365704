import { AnyAction } from "redux";

const initialState: any = {
  error: "",
  selectedBatch: {},
  addedToCalendar: false,
};

const reducer = (
  state: any = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case "IELTS_ATTENDANCE_ERROR":
      return {
        ...state,
        error: action.payload,
      }
    case "UPDATE_SELECTED_BATCH":
      return {
        ...state,
        selectedBatch: action.payload,
      }
    case "UPDATE_ADDED_TO_CALENDAR":
      return {
        ...state,
        addedToCalendar: action.payload,
      }
    default:
      return state;
  }
};

export default reducer;
