import { quizActions } from 'components/Profile/IeltsProfile/QuizSection/redux/QuizActions'
import { quizApis } from 'components/Profile/IeltsProfile/QuizSection/redux/QuizApis'
import { QuizType } from 'components/Profile/IeltsProfile/QuizSection/redux/QuizType'
import { QuizStatus } from 'Interfaces/reducerTypes/IeltsPlusQuizSchema'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchQuizList(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(quizApis.fetchQuizList, action.payload)
    if (res.success) {
      const cmsData = yield call(
        quizApis.fetchIeltsQuizListCms,
        res.data
          .filter((quizItem: any) => quizItem)
          .map((quizItems: any) => quizItems.quizId)
      )
      const quizListData = res.data
        .filter((quizItems: any) =>
          cmsData.ieltsQuizs?.find(
            (quizData: any) => quizData.id == quizItems.quizId
          )
        )
        .map((quizItems: any) => {
          const quizCmsData = cmsData.ieltsQuizs?.find(
            (quizData: any) => quizData.id == quizItems.quizId
          )
          return {
            ...quizItems,
            ...quizCmsData,
            title: quizCmsData?.Title,
            icon: quizCmsData.Icon.url,
          }
        })
      yield put(quizActions.fetchQuizListSuccess(quizListData))
      const newQuizList = quizListData.filter(
        (quizItems: any) => quizItems.status != QuizStatus.FINISHED
      )
      const completeQuizList = quizListData.filter(
        (quizItems: any) => quizItems.status == QuizStatus.FINISHED
      )
      yield put(quizActions.updateNewQuizList(newQuizList))
      yield put(quizActions.updateCompleteQuizList(completeQuizList))
    } else {
      yield put(quizActions.updateError(res.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(
      quizActions.updateError('Something went wrong. Please try after sometime')
    )
  }
}

function* fetchQuizQuestion(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      quizApis.quizUserStatusReq,
      action.payload,
      action.quizBundleId,
      action.batchId
    )
    if (res.success) {
      let latestAttempt =
        res.data.length > 0 &&
        res.data.reduce((acc: any, cur: any) => {
          if (!acc || cur.attemptId > acc.attemptId) return cur
          return acc
        }, null)
      if (action.isRetake) {
        latestAttempt = { ...latestAttempt, status: QuizStatus.NOT_ATTEMPTED }
      }
      const cmsQuizQuestionData = yield call(
        quizApis.fetchQuizDetailCms,
        action.payload
      )

      const quizQuestionData = cmsQuizQuestionData?.ieltsQuizs[0]?.question
        ?.filter((data: any) => data['ielts_question'])
        .map((data: any) => {
          const ieltsQuestion = data['ielts_question']
          return {
            id: ieltsQuestion.id,
            questionType: ieltsQuestion.QuestionType,
            explanationOfAnswers: ieltsQuestion.ExplanationOfAnswers,
            optionList: ieltsQuestion.Option,
            questionTitle: ieltsQuestion.QuestionDescription,
          }
        })
      yield put(
        quizActions.updateQuizTitle(cmsQuizQuestionData?.ieltsQuizs[0]['Title'])
      )
      if (latestAttempt.status == QuizStatus.NOT_ATTEMPTED || action.isRetake) {
        yield put(
          quizActions.startQuizTest(
            action.payload,
            action.quizBundleId,
            action.batchId
          )
        )
      } else {
        yield put(quizActions.updateQuizStatus(latestAttempt.status))
        yield put(quizActions.updateAttemptId(latestAttempt.attemptId))
      }
      if (latestAttempt.startTime)
        yield put(quizActions.updateQuizTestDate(latestAttempt.startTime))
      if (latestAttempt.endTime)
        yield put(quizActions.updateQuizEndTestDate(latestAttempt.endTime))

      if (
        latestAttempt &&
        latestAttempt.submittedQuestionsList &&
        latestAttempt.status != QuizStatus.NOT_ATTEMPTED
      )
        yield put(
          quizActions.updateQuizSubmittedAns(
            latestAttempt.submittedQuestionsList
          )
        )
      action.callback && action.callback()
      yield put(quizActions.updateQuizStatus(latestAttempt.status))
      yield put(quizActions.fetchQuizQuestionSuccess(quizQuestionData))
    }
  } catch (e: any) {
    console.log(e)
    yield put(
      quizActions.updateError('Something went wrong. Please try after sometime')
    )
  }
}

function* postStartQuiz(action: AnyAction): Generator<any, any, any> {
  try {
    let res
    if (action.quizBundleId && action.batchId) {
      res = yield call(
        quizApis.rewardQuizStartAttemptReq,
        action.payload,
        action.quizBundleId,
        action.batchId
      )
    } else {
      res = yield call(quizApis.quizStartAttemptReq, action.payload)
    }
    if (res.success) {
      yield put(quizActions.updateQuizStatus(QuizStatus.ATTEMPTED))
      yield put(quizActions.updateAttemptId(res.data))
    }
  } catch (e: any) {
    yield put(
      quizActions.updateError('Something went wrong. Please try after sometime')
    )
  }
}

function* postEndQuiz(action: AnyAction): Generator<any, any, any> {
  try {
    let res
    if (action.payload.batchId) {
      res = yield call(quizApis.rewardQuizEndAttemptReq, action.payload)
    } else {
      res = yield call(quizApis.quizEndAttemptReq, action.payload)
    }
    if (res.success) {
      yield put(quizActions.updateQuizStatus(QuizStatus.FINISHED))
      yield put(quizActions.updateQuizSubmitSuccessfully(true))
    }
  } catch (e: any) {
    yield put(
      quizActions.updateError('Something went wrong. Please try after sometime')
    )
  }
}

function* postAnsSubmit(action: AnyAction): Generator<any, any, any> {
  try {
    if (action.payload.quizBundleId) {
      yield call(quizApis.rewardQuizQueAnsSubmit, action.payload)
    } else {
      yield call(quizApis.quizQueAnsSubmit, action.payload)
    }
  } catch (e: any) {
    yield put(
      quizActions.updateError('Something went wrong. Please try after sometime')
    )
  }
}

function* fetchQuizModalType(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(quizApis.fetchQuizModalType, action.payload)
    if (res.success) {
      yield put(quizActions.fetchQuizModalTypeSuccess(res.data))
    } else {
      yield put(
        quizActions.updateError(
          'Something went wrong. Please try after sometime'
        )
      )
    }
  } catch (e: any) {
    yield put(
      quizActions.updateError('Something went wrong. Please try after sometime')
    )
  }
}

function* fetchBundleQuizList(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(quizApis.fetchBundleQuizList, action.payload)
    if (res.success) {
      yield put(quizActions.fetchQuizBundleListSuccess(res.data))
      action.callback && action.callback(res.data)
    } else {
      yield put(quizActions.updateError(res.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(
      quizActions.updateError('Something went wrong. Please try after sometime')
    )
  }
}
function* quizSaga() {
  yield takeLatest(QuizType.FETCH_QUIZ_LIST, fetchQuizList)
  yield takeLatest(QuizType.FETCH_QUIZ_QUESTION, fetchQuizQuestion)
  yield takeLatest(QuizType.START_QUIZ_TEST, postStartQuiz)
  yield takeLatest(QuizType.SUBMIT_QUESTION_ANS, postAnsSubmit)
  yield takeLatest(QuizType.END_QUIZ_TEST, postEndQuiz)
  yield takeLatest(QuizType.FETCH_QUIZ_MODAL_TYPE, fetchQuizModalType)
  yield takeLatest(
    QuizType.FETCH_QUIZ_BUNDLE_TYPE_REQUESTED,
    fetchBundleQuizList
  )
}

export default quizSaga
