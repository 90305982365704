import { ReactNode } from "react"

export enum CountryCode {
  INDIA = "INDIA",
  BANGLADESH = "BANGLADESH",
  UAE = "UAE",
  QATAR = "QATAR",
  SAUDI = "SAUDI",
  KUWAIT = "KUWAIT",
  BAHRAIN = "BAHRAIN",
  OMAN = "OMAN",
  ALGERIA = "ALGERIA",
  EGYPT = "EGYPT",
  IRAN = "IRAN",
  IRAQ = "IRAQ",
  ISRAEL = "ISRAEL",
  JORDAN = "JORDAN",
  LEBANON = "LEBANON",
  LIBYA = "LIBYA",
  MOROCCO = "MOROCCO",
  SYRIA = "SYRIA",
  TUNISIA = "TUNISIA",
  PALESTINE = "PALESTINE",
  YEMEN = "YEMEN",
}

interface CountryDataSchema {
  code: string
  flag: string
}

const {
  INDIA,
  BANGLADESH,
  UAE,
  QATAR,
  SAUDI,
  KUWAIT,
  BAHRAIN,
  OMAN,
  ALGERIA,
  EGYPT,
  IRAN,
  IRAQ,
  ISRAEL,
  JORDAN,
  LEBANON,
  LIBYA,
  MOROCCO,
  SYRIA,
  TUNISIA,
  PALESTINE,
  YEMEN,
} = CountryCode

export const countryData: Record<CountryCode, CountryDataSchema> = {
  [INDIA]: {
    code: "91",
    flag: "https://leapassets.s3.ap-south-1.amazonaws.com/workshop/1658603976706-india.png",
  },
  [BANGLADESH]: {
    code: "880",
    flag: "https://leapassets.s3.ap-south-1.amazonaws.com/workshop/1658603952340-bangladesh.png",
  },
  [UAE]: {
    code: "971",
    flag: "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/flags/UAE.png",
  },
  [QATAR]: {
    code: "974",
    flag: "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/flags/QA.png",
  },
  [SAUDI]: {
    code: "966",
    flag: "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/flags/SA.png",
  },
  [KUWAIT]: {
    code: "965",
    flag: "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/flags/KW.png",
  },
  [OMAN]: {
    code: "968",
    flag: "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/flags/OM.png",
  },
  [BAHRAIN]: {
    code: "973",
    flag: "https://leap-public.s3.ap-south-1.amazonaws.com/web/assets/flags/BH.png",
  },
  [ALGERIA]: {
    code: "213",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/africa_flag/Algeria_roCYuPGcb.svg",
  },
  [EGYPT]: {
    code: "20",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/africa_flag/Egypt_3eyykyjr3.svg",
  },
  [IRAN]: {
    code: "98",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/asia_flag/Iran_48h_Z655b.svg?updatedAt=1699525262737",
  },
  [IRAQ]: {
    code: "964",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/asia_flag/Iraq_NpssNb7PE.svg?updatedAt=1699525267602",
  },
  [ISRAEL]: {
    code: "972",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/asia_flag/Israel_U7sL68D_0.svg?updatedAt=1699525272447",
  },
  [JORDAN]: {
    code: "962",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/asia_flag/Jordan_OEPvB2wQl.svg?updatedAt=1699525266100",
  },
  [LEBANON]: {
    code: "961",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/asia_flag/Lebanon_5XoLErrrX.svg?updatedAt=1699525267444",
  },
  [LIBYA]: {
    code: "218",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/africa_flag/Libya_HxdvU-nDWA.svg?updatedAt=1699525238291",
  },
  [MOROCCO]: {
    code: "212",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/africa_flag/Marocco_rMX7GJ1yp.svg?updatedAt=1699525242189",
  },
  [SYRIA]: {
    code: "963",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/asia_flag/Syria_GRpds7uxo_.svg?updatedAt=1699525257097",
  },
  [TUNISIA]: {
    code: "216",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/africa_flag/Tunis_ijCgxwodF.svg?updatedAt=1699525252697",
  },
  [PALESTINE]: {
    code: "970",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/asia_flag/Palestine_Eij7JLyEP.svg?updatedAt=1699525266833",
  },
  [YEMEN]: {
    code: "967",
    flag: "https://ik.imagekit.io/onsnhxjshmp/LeapScholar/country_flag/asia_flag/Yemen_RBe2dBjK3a.svg?updatedAt=1699525275206",
  },
}

export const countriesAndPhNoLength: Record<
  CountryCode,
  number | Array<number>
> = {
  [INDIA]: 10,
  [BANGLADESH]: 10,
  [SAUDI]: 9,
  [QATAR]: 8,
  [KUWAIT]: 8,
  [BAHRAIN]: 8,
  [OMAN]: 8,
  [UAE]: 9,
  [ALGERIA]: 9,
  // @todo - verify valid phone number length below
  [EGYPT]: 9,
  [IRAN]: 9,
  [IRAQ]: 9,
  [ISRAEL]: 9,
  [JORDAN]: 9,
  [LEBANON]: 9,
  [LIBYA]: 9,
  [MOROCCO]: 9,
  [SYRIA]: 9,
  [TUNISIA]: 9,
  [PALESTINE]: 9,
  [YEMEN]: 9,
}
export const validatePhoneNumberOnCountry = (
  number: string | undefined,
  country: CountryCode | undefined,
): boolean => {
  if (country && number?.length === countriesAndPhNoLength[country]) {
    return true
  } else {
    if (number?.length !== 10) return false
  }
  return false
}

export const countries: Array<{ label: string; value: string }> = [
  { label: "India", value: INDIA },
  { label: "Bangladesh", value: BANGLADESH },
  { label: "UAE", value: UAE },
  { label: "Bahrain", value: BAHRAIN },
  { label: "Kuwait", value: KUWAIT },
  { label: "Oman", value: OMAN },
  { label: "Qatar", value: QATAR },
  { label: "Saudi Arabia", value: SAUDI },
]

export const CountryCodeMapping = Object.keys(countryData).reduce<
  Record<string, string>
>(
  (acc, country) => ({
    ...acc,
    [countryData[country as CountryCode].code]: country,
  }),
  {},
)
const MIDDLE_EAST_COUNTRIES_IN_FORM: CountryCode[] = [
  CountryCode.INDIA,
  CountryCode.SAUDI,
  CountryCode.QATAR,
  CountryCode.UAE,
  CountryCode.BAHRAIN,
  CountryCode.KUWAIT,
  CountryCode.OMAN,
]
export const countryOptions: { label: ReactNode; value: string }[] =
  Object.keys(countryData)
    .filter((item1) =>
      MIDDLE_EAST_COUNTRIES_IN_FORM.find((item2) => item2 === item1),
    )
    .map((item) => ({
      label: (
        <p className="flex items-center gap-x-2" key={item}>
          <img
            src={countryData[item as CountryCode].flag as string}
            alt={`${item} flag`}
            width={28}
            height={20}
          />
          <span className="inline-block font-bold">
            +{countryData[item as CountryCode].code}
          </span>
        </p>
      ),
      value: item,
    }))

export enum Currency {
  INR = "INR",
  BDT = "BDT",
  SAR = "SAR",
  QAR = "QAR",
  AED = "AED",
}
//@ts-ignore // only added required countries
export const countryAndCurrency: Record<CountryCode, Currency> = {
  [INDIA]: Currency.INR,
  [SAUDI]: Currency.SAR,
  [QATAR]: Currency.QAR,
  [UAE]: Currency.AED,
  [BAHRAIN]: Currency.AED,
  [KUWAIT]: Currency.AED,
  [OMAN]: Currency.AED,
}
//@ts-ignore // only added required countries

export const countryAndCurrencySymbol: Record<CountryCode, Currency | string> =
  {
    [INDIA]: "₹",
    [BANGLADESH]: Currency.BDT,
    [SAUDI]: Currency.SAR,
    [QATAR]: Currency.QAR,
    [UAE]: Currency.AED,
    [BAHRAIN]: Currency.AED,
    [KUWAIT]: Currency.AED,
    [OMAN]: Currency.AED,
  }
//@ts-ignore // only added required countries
export const countriesAndReferalAmount: Record<CountryCode, number> = {
  [INDIA]: 1000,
  [SAUDI]: 50,
  [QATAR]: 50,
  [UAE]: 50,
  [BAHRAIN]: 50,
  [KUWAIT]: 50,
  [OMAN]: 50,
}

export enum CountryList {
  BH = "BAHRAIN",
  OM = "OMAN",
  QA = "QATAR",
  KW = "KUWAIT",
  IN = "INDIA",
  BD = "BANGLADESH",
  AE = "UAE",
  SA = "SAUDI",
}
