export enum IeltsTestStatus {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum DateUploadScreens {
  LOCKED_REWARDS = 'LOCKED_REWARDS',
  LOGIN_FORM = 'LOGIN_FORM',
  UNLOCKED_REWARDS = 'UNLOCKED_REWARDS',
}

export interface IeltsTestReducer {
  showTestForm: boolean
  status: IeltsTestStatus | ''
  showIeltsSubmissionSection: boolean
  submissionTime: string
  showSubmittedDocsModal: false
  screen: DateUploadScreens | ''
  rewardsArray: Array<RewardsIeltsDateUpload> | null
  userStatus: boolean
  showIeltsTestBanner: boolean
}

export interface RewardsIeltsDateUpload {
  key: string
  image: string
  text: string
  subText: string
  exclusive: boolean
  webLink: string
  appLink: string
}

export enum RewardsApi {
  PRE = 'pre',
  POST = 'post',
}
