import api from "service/api"

const fetchScratchCardStatus = (subscriptionType: any) =>
  api.get(`/api/scholarRoute?path=subscription/details/${subscriptionType}`)

const updateScratchCardStatus = () =>
  api.post(`/api/scholarRoute?path=subscription/scratch-card`)

const updateTipsTricksStatus = () =>
  api.post(`/api/scholarRoute?path=subscription/tips-tricks`)

export const DemoRewardsApi = {
  fetchScratchCardStatus,
  updateScratchCardStatus,
  updateTipsTricksStatus,
}
