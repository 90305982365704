import api from "service/api"

const fetchCertificateData = async (payload: string | number) => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=v2/workshop/enroll/certificate/${payload}`,
    )
    if (res.data.success) {
      return { success: true, data: res.data.data }
    } else {
      return { success: false, data: res.data.message }
    }
  } catch (err: any) {
    return { success: false, data: "Something went wrong" }
  }
}

export const certificateApi = {
  fetchCertificateData,
}
