const fetchCounsellingStage = () => ({
  type: 'COUNSELLING_STAGE_FETCH_REQUESTED',
})

const fetchCounsellingStageSuccess = (payload: string) => ({
  type: 'COUNSELLING_STAGE_FETCH_SUCCESS',
  payload,
})

const fetchCounsellingStageFail = (message: string) => ({
  type: 'COUNSELLING_STAGE_FETCH_FAIL',
  payload: message,
})

const fetchPostPaymentData = () => ({
  type: 'POST_PAYMENT_DATA_FETCH_REQUESTED',
})

const fetchPostPaymentDataSuccess = (payload: any) => ({
  type: 'POST_PAYMENT_DATA_FETCH_SUCCESS',
  payload,
})

const fetchPostPaymentDataFail = (message: string) => ({
  type: 'POST_PAYMENT_DATA_FETCH_FAIL',
  payload: message,
})

const fetchScholarshipBookmarks = () => ({
  type: 'FECTH_BOOKMARK_DATA_FETCH_REQUESTED',
})

const fetchScholarshipBookmarksSuccess = (payload: any) => ({
  type: 'FETCH_BOOKMARK_DATA_FETCH_SUCCESS',
  payload,
})

const fetchScholarshipBookmarksFail = (message: string) => ({
  type: 'FETCH_BOOMARK_FETCH_FAIL',
  payload: message,
})

export const counsellingActions = {
  fetchCounsellingStage,
  fetchCounsellingStageFail,
  fetchCounsellingStageSuccess,
  fetchPostPaymentData,
  fetchPostPaymentDataSuccess,
  fetchPostPaymentDataFail,
  fetchScholarshipBookmarks,
  fetchScholarshipBookmarksSuccess,
  fetchScholarshipBookmarksFail,
}
