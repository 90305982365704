import api from "service/api"

const fetchSpotCounsellingFromData = async (): Promise<void> => {
  try {
    const res = await api.get(`/api/scholarRoute?path=student/spot-counseling`)
    if (res.data.success) {
      return res.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    return err.message || "Something went wrong"
  }
}

export const tofuOnbordingApis = {
  fetchSpotCounsellingFromData,
}
