import { ProductType } from "components/Ielts/UpcomingIeltsBatches/ViewAllBatches/DataMapper/interface"
import { NextPageContext } from "next"

export interface BatchDetailsPayload {
  batchId: string
  ctx?: NextPageContext
}
export const batchDetailActionTypes = {
  TOGGLE_SHOW_NOT_ABLE_TO_BUY_MODAL: "TOGGLE_SHOW_NOT_ABLE_TO_BUY_MODAL",
  TOOGLE_SHOW_DISQAULIFIED_USERS_MODAL: "TOOGLE_SHOW_DISQAULIFIED_USERS_MODAL",
  FETCH_PRODUCT_BATCH_DETAILS_REQUESTED_CLIENT_SIDE:
    "FETCH_PRODUCT_BATCH_DETAILS_REQUESTED_CLIENT_SIDE",
  TOGGLE_FAIL_TO_FETCH_BATCH: "TOGGLE_FAIL_TO_FETCH_BATCH",
}
const fetchBatchDetails = (payload: BatchDetailsPayload) => ({
  type: "FETCH_BATCH_DETAILS_REQUESTED",
  payload,
})

const fetchBatchDetailsClientSide = (payload: string) => ({
  type: "FETCH_BATCH_DETAILS_REQUESTED_CLIENT_SIDE",
  payload,
})

const fetchBatchDetailsSuccess = (payload: any) => ({
  type: "FETCH_BATCH_DETAILS_SUCCESS",
  payload,
})

const fetchBatchDetailsFail = (message: string) => ({
  type: "FETCH_BATCH_DETAILS_FAIL",
  payload: message,
})

const fetchBatchTeacherSuccess = (payload: any) => ({
  type: "FETCH_BATCH_TEACHER_SUCCESS",
  payload,
})

const toggleIeltsPlusLoader = (payload: boolean) => ({
  type: "TOGGLE_IELTS_PLUS_LOADER",
  payload,
})

const toggleIeltsPlusForm = (payload: boolean) => ({
  type: "TOGGLE_IELTS_PLUS_FORM",
  payload,
})

const togglePromoCode = (payload: boolean) => ({
  type: "TOGGLE_PROMO_CODE",
  payload,
})

const toggleTriedToBuyBeforeLogin = (payload: boolean) => ({
  type: "TOGGLE_TRIED_TO_BUY_BEFORE_LOGIN",
  payload,
})
const toogleShowNotAbleToBuyModal = (payload: boolean) => ({
  type: batchDetailActionTypes.TOGGLE_SHOW_NOT_ABLE_TO_BUY_MODAL,
  payload,
})
const toggleShowDisqaulifiedUsersModal = (payload: boolean) => ({
  type: batchDetailActionTypes.TOOGLE_SHOW_DISQAULIFIED_USERS_MODAL,
  payload,
})

const fetchProductBatchDetailsClientSide = (payload: BatchDetailsPayload) => ({
  type: batchDetailActionTypes.FETCH_PRODUCT_BATCH_DETAILS_REQUESTED_CLIENT_SIDE,
  payload,
})

const toggleFailToFetchBatchData = (payload: boolean) => ({
  type: batchDetailActionTypes.TOGGLE_FAIL_TO_FETCH_BATCH,
  payload,
})
export const batchDetailsActions = {
  fetchBatchDetails,
  fetchBatchDetailsSuccess,
  fetchBatchDetailsFail,
  fetchBatchTeacherSuccess,
  toggleIeltsPlusLoader,
  toggleIeltsPlusForm,
  fetchBatchDetailsClientSide,
  togglePromoCode,
  toggleTriedToBuyBeforeLogin,
  toogleShowNotAbleToBuyModal,
  toggleShowDisqaulifiedUsersModal,
  fetchProductBatchDetailsClientSide,
  toggleFailToFetchBatchData,
}
