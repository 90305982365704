export const AiEvaluationActionTypes = {
  GET_SMART_TEST_PRICE: 'GET_SMART_TEST_PRICE',
  GET_SMART_TEST_PRICE_SUCCESS: 'GET_SMART_TEST_PRICE_SUCCESS',
  GET_SMART_TESTS_PROGRESS_STATUS: 'GET_SMART_TESTS_PROGRESS_STATUS',
  GET_SMART_TESTS_PROGRESS_STATUS_SUCCESS:
    'GET_SMART_TESTS_PROGRESS_STATUS_SUCCESS',
}
const getAiEvalutionToken = (isRedirection = false) => ({
  type: 'GET_AI_EVALUTION_TOKEN',
  payload: isRedirection,
})

const getAiEvalutionTokenSuccess = (payload: any) => ({
  type: 'GET_AI_EVALUTION_TOKEN_SUCCESS',
  payload,
})

const getAiEvalutionTokenFail = (message: string) => ({
  type: 'GET_AI_EVALUTION_TOKEN_FAIL',
  payload: message,
})

const aiEvalutionAlreadyPaid = () => ({
  type: 'AI_EVALUTION_ALREADY_PAID',
})

const aiEvalutionAlreadyPaidSuccess = (payload: any) => ({
  type: 'AI_EVALUTION_ALREADY_PAID_SUCCESS',
  payload,
})

const aiEvalutionAlreadyPaidFail = (message: string) => ({
  type: 'AI_EVALUTION_ALREADY_PAID_FAIL',
  payload: message,
})

const saveForm = (payload: any) => ({
  type: 'AI_EVALUATION_FORM_SUBMIT',
  payload,
})

const purchaseType = (payload: string | null) => ({
  type: 'AI_EVALUATION_PURCHASE_TYPE',
  payload,
})

const toggleShowForm = (payload: boolean) => ({
  type: 'TOGGLE_AI_EVALUATION_SHOW_FORM',
  payload,
})

const toggleShowLoader = (payload: boolean) => ({
  type: 'TOGGLE_AI_EVALUATION_SHOW_LOADER',
  payload,
})

const updateError = (payload: string) => ({
  type: 'UPDATE_ERROR',
  payload,
})

const openIelts8tab = (payload: string) => ({
  type: 'OPEN_IELTS8_TAB',
  payload,
})

const getSmartTestPrice = () => ({
  type: AiEvaluationActionTypes.GET_SMART_TEST_PRICE,
})

const getSmartTestPriceSucess = (payload: number) => ({
  type: AiEvaluationActionTypes.GET_SMART_TEST_PRICE_SUCCESS,
  payload,
})

const getSmartTestsProgressStatus = () => ({
  type: AiEvaluationActionTypes.GET_SMART_TESTS_PROGRESS_STATUS,
})
const getSmartTestsProgressStatusSuccess = (payload: Record<string, any>) => ({
  type: AiEvaluationActionTypes.GET_SMART_TESTS_PROGRESS_STATUS_SUCCESS,
  payload,
})

export const aiEvalutionActions = {
  getAiEvalutionToken,
  getAiEvalutionTokenSuccess,
  getAiEvalutionTokenFail,
  aiEvalutionAlreadyPaid,
  aiEvalutionAlreadyPaidSuccess,
  aiEvalutionAlreadyPaidFail,
  purchaseType,
  toggleShowForm,
  toggleShowLoader,
  saveForm,
  updateError,
  openIelts8tab,
  getSmartTestPrice,
  getSmartTestPriceSucess,
  getSmartTestsProgressStatus,
  getSmartTestsProgressStatusSuccess,
}
