import { counsellingFormActionsTypes } from "components/Profile/CounsellingFormExperiment/redux/CounsellingFormActions";
import { AnyAction } from "redux";

const initialState: any = {
  profileData: {},
  loading: true,
  error: "",
  eligibility: {},
  saving: false,
  ieltsNudgeData: {},
  ieltsNudgeError: null,
  postFetchEligibilityAction: null,
};

// create your reducer
const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "PROFILE_FETCH_SUCCESS":
      return {
        ...state,
        profileData: action.payload,
        loading: false,
      };
    case "PROFILE_FETCH_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "SAVE_PROFILE_SUCCESS":
      return {
        ...state,
        profileData: action.payload,
      };
    case "SAVE_PROFILE_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    case "ELIGIBILITY_FETCH_SUCCESS":
      return {
        ...state,
        eligibility: action.payload,
        saving: false,
      };
    case "ELIGIBILITY_FETCH_FAIL":
      return {
        ...state,
        error: action.payload,
        saving: false,
      };
    case "UPDATE_SAVE_CTA_STATUS":
      return {
        ...state,
        saving: action.payload,
      };
    case "FETCH_IELTS_NUDGE_DATA_SUCCESS":
      return {
        ...state,
        ieltsNudgeData: action.payload,
        loading: false,
        ieltsNudgeError: null,
        saving: false,
      };
    case "FETCH_IELTS_NUDGE_DATA_FAIL":
      return {
        ...state,
        loading: false,
        ieltsNudgeError: action.payload,
        saving: false,
      };
    case "SAVE_IELTS_NUDGE_DATA_SUCCESS":
      return {
        ...state,
        // ieltsNudgeData: action.payload,
        ieltsNudgeError: null,
      };
    case "SAVE_IELTS_NUDGE_DATA_FAIL":
      return {
        ...state,
        ieltsNudgeError: action.payload,
      };
    case counsellingFormActionsTypes.UPDATE_POST_FETCH_ELIGIBILITY_ACTION:
      return {
        ...state,
        postFetchEligibilityAction: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
