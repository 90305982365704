import { AnyAction } from "redux"
import { HYDRATE } from "next-redux-wrapper"
import {
  BatchDetailsSchema,
  BatchSchema,
} from "Interfaces/reducerTypes/BatchDetailsSchema"
import { TeacherSchema } from "Interfaces/reducerTypes/TeacherSchema"
import { batchDetailActionTypes } from "components/Ielts/BatchDetails/redux/BatchDetailsAction"

const initialState: BatchDetailsSchema = {
  batchData: {} as BatchSchema,
  teacherData: {} as TeacherSchema,
  error: "",
  showLoader: false,
  showForm: false,
  showPromo: false,
  triedToBuyBeforeLogin: null,
  showNotAbleToBuyModal: false,
  showDisqaulifiedUsersModal: false,
  failToFetchBatchData: false,
}

const reducer = (
  state: BatchDetailsSchema = initialState,
  action: AnyAction,
) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        batchData: action.payload.batchDetails.batchData,
        teacherData: action.payload.batchDetails.teacherData,
        error: action.payload.batchDetails.error,
      }
    case "FETCH_BATCH_DETAILS_SUCCESS":
      return {
        ...state,
        batchData: action.payload,
      }
    case "FETCH_BATCH_DETAILS_FAIL":
      return {
        ...state,
        error: action.payload,
      }
    case "FETCH_BATCH_TEACHER_SUCCESS":
      return {
        ...state,
        teacherData: action.payload,
      }
    case "FETCH_BATCH_TEACHER_FAIL":
      return {
        ...state,
        error: action.payload,
      }
    case "TOGGLE_IELTS_PLUS_LOADER":
      return {
        ...state,
        showLoader: action.payload,
      }
    case "TOGGLE_IELTS_PLUS_FORM":
      return {
        ...state,
        showForm: action.payload,
      }
    case "TOGGLE_PROMO_CODE":
      return {
        ...state,
        showPromo: action.payload,
      }
    case "TOGGLE_TRIED_TO_BUY_BEFORE_LOGIN":
      return {
        ...state,
        triedToBuyBeforeLogin: action.payload,
      }
    case batchDetailActionTypes.TOGGLE_SHOW_NOT_ABLE_TO_BUY_MODAL:
      return {
        ...state,
        showNotAbleToBuyModal: action.payload,
      }
    case batchDetailActionTypes.TOOGLE_SHOW_DISQAULIFIED_USERS_MODAL:
      return {
        ...state,
        showDisqaulifiedUsersModal: action.payload,
      }
    case batchDetailActionTypes.TOGGLE_FAIL_TO_FETCH_BATCH:
      return {
        ...state,
        failToFetchBatchData: action.payload,
      }
    default:
      return state
  }
}

export default reducer
