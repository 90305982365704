import { call, put, takeLatest } from "redux-saga/effects";
import { AnyAction } from "redux";
import { chatActionsConstants } from "./NeedHelpActions";
import { NeedHelpApis } from "components/NeedHelpV1/redux/NeedHelpApis";

function* zohoRecord(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(NeedHelpApis.zohoRecord, action.payload);
    if (res?.success) {
      yield put({ type: chatActionsConstants.QUERY_RAISE_API_SUCCESS });
    } else {
      throw Error("Something Went Wrong While Raising Query!!");
    }
  } catch (e: any) {
    yield put({ type: chatActionsConstants.QUERY_RAISE_API_ERROR });
  }
}

function* NeedHelpSaga() {
  yield takeLatest(chatActionsConstants.QUERY_RAISE_API, zohoRecord);
}

export default NeedHelpSaga;
