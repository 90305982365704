import { speakingClubApi } from "components/Profile/IeltsProfile/SpeakingClub/redux/SpeakingClubApi"
import { AnyAction } from "redux"
import { call, put, takeLatest } from "redux-saga/effects"

import {
  speakingClubActions,
  speakingClubType,
} from "components/Profile/IeltsProfile/SpeakingClub/redux/SpeakingClubActions"
import {
  eventName,
  WEeventDataAttributeNames,
} from "DataMapper/EventTracker/EventData"
import { eventTracker } from "eventTracker"
import moment from "moment"

const {
  CANCEL_SPEAKING_CLUB_BATCH,
  RESCHEDULE_SPEAKING_CLUB_BATCH,
  SPEAKING_CLUB_SLOT_FETCH_REQUESTED,
  SPEAKING_WORKSHOP_POST_REQUESTED,
  UPCOMING_SPEAKING_CLUB_REQUEST,
  FETCH_SPEAKING_CLUB_CREDITS,
  FETCH_SPEAKING_BUNDLE_PRICE_REQ,
} = speakingClubType

const { cancelSlot, rescheduleSlot } = speakingClubApi

function* fetchData(): Generator<any, any, any> {
  try {
    const speakingClubDate = yield call(
      speakingClubApi.fetchSlotAvailableDateData,
    )
    yield put(
      speakingClubActions.fetchSpeakingClubSlotSuccess(speakingClubDate),
    )
  } catch (e: any) {
    yield put(speakingClubActions.fetchSpeakingClubSlotFail(e.message))
  }
}

function* fetchUpcomingSpeakingClubData(): Generator<any, any, any> {
  try {
    const speakingClubDate = yield call(
      speakingClubApi.fetchUpcomingSpeakingClasses,
    )
    yield put(
      speakingClubActions.fetchUpcomingSpeakingClubSuccess(speakingClubDate),
    )
  } catch (e: any) {
    yield put(speakingClubActions.fetchUpcomingSpeakingClubFail(e.message))
  }
}

function* enrollSpeakingClub(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(speakingClubApi.postSpeakingWorkShop, action.payload)
    if (res.success) {
      yield put(speakingClubActions.postSpeakingWorkShopSuccess(res.payload))
    } else {
      yield put(speakingClubActions.postSpeakingWorkShopFail(res.payload))
    }
  } catch (e: any) {
    console.error(e)
    yield put(speakingClubActions.postSpeakingWorkShopFail(e.message))
  }
}

function* fetchSpeakingClubCredits(): Generator<any, any, any> {
  try {
    const res = yield call(speakingClubApi.fetchSpeakingClubCredits)
    if (res.data.success) {
      yield put(
        speakingClubActions.fetchSpeakingClubCreditsSuccess(res.data.data),
      )
    } else {
      yield put(
        speakingClubActions.fetchSpeakingClubCreditsFail(res.data.message),
      )
    }
  } catch (e: any) {
    console.error(e)
    yield put(speakingClubActions.fetchSpeakingClubCreditsFail(e.message))
  }
}

function* fetchSpeakingPriceBundle(): Generator<any, any, any> {
  try {
    const res = yield call(speakingClubApi.fetchSpeakingClubPriceBundle)
    if (res.success) {
      yield put(speakingClubActions.fetchSpeakingPriceBundleSuccess(res.data))
    } else {
      yield put(speakingClubActions.fetchSpeakingPriceBundleFail(res.data))
    }
  } catch (e: any) {
    console.error(e)
    yield put(
      speakingClubActions.fetchSpeakingPriceBundleFail("Something went wrong!"),
    )
  }
}

function* cancelSpeakingClubBatch(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(cancelSlot, action.payload.batchId)
    if (res.success) {
      yield put(speakingClubActions.toggleSpeakingClubCancelledModal(true))
      yield put(speakingClubActions.toogleSpeakingClubRescheduleModal(false))
      yield put(speakingClubActions.toggleIsRescheduling(false))
      yield put(speakingClubActions.fetchUpcomingSpeakingClubSuccess(null))
      yield call(
        eventTracker,
        eventName.CANCEL_SPEAKING_SLOT,
        {
          [WEeventDataAttributeNames.CATEGORY]: "",
          [WEeventDataAttributeNames.ACTION]: "",
          [WEeventDataAttributeNames.LABEL]: "",
          [WEeventDataAttributeNames.BATCH_START_DATE]: moment(
            action.payload.startDate,
          ).format("DD MMM YYYY"),
          [WEeventDataAttributeNames.BATCH_START_TIME]:
            action.payload.startTime,
          [WEeventDataAttributeNames.BATCH_ID]: action.payload.batchData?.id,
          [WEeventDataAttributeNames.TEACHER_NAME]:
            action.payload.teacherData?.name,
        },
        false,
      )
    } else {
      yield put(speakingClubActions.updateError(res.message))
    }
  } catch (e: any) {
    yield put(speakingClubActions.updateError("Something went wrong!"))
  }
}

function* rescheduleSpeakingClubBatch(
  action: AnyAction,
): Generator<any, any, any> {
  try {
    const res = yield call(rescheduleSlot, action.payload)
    if (res.success) {
      yield put(speakingClubActions.postSpeakingWorkShopSuccess(true))
      yield put(speakingClubActions.toogleSpeakingClubRescheduleModal(false))
    } else {
      yield put(speakingClubActions.updateError(res.message))
    }
  } catch (e: any) {
    yield put(speakingClubActions.updateError("Something went wrong!"))
  }
}

function* speakingClubSaga() {
  yield takeLatest(SPEAKING_CLUB_SLOT_FETCH_REQUESTED, fetchData)
  yield takeLatest(SPEAKING_WORKSHOP_POST_REQUESTED, enrollSpeakingClub)
  yield takeLatest(
    UPCOMING_SPEAKING_CLUB_REQUEST,
    fetchUpcomingSpeakingClubData,
  )
  yield takeLatest(FETCH_SPEAKING_CLUB_CREDITS, fetchSpeakingClubCredits)
  yield takeLatest(FETCH_SPEAKING_BUNDLE_PRICE_REQ, fetchSpeakingPriceBundle)
  yield takeLatest(CANCEL_SPEAKING_CLUB_BATCH, cancelSpeakingClubBatch)
  yield takeLatest(RESCHEDULE_SPEAKING_CLUB_BATCH, rescheduleSpeakingClubBatch)
}

export default speakingClubSaga
