import { AnyAction } from "redux";
import OverviewConstants from "../constants/overview-constants";

const initialState: any = {
  isLoading: false,
  isPaidCounselling: true,
  isPaidIelts: false,
  overviewData: {},
  error: "",
  isError: false,
  isOpenBookCounsellingModal: false,
  isOpenSpeakToCounsellorModal: false,
  isOpenGetLsPlusModal: false,
  isCallScheduled: false,
  counsellingFeeData: {},
  postPaymentData: {},
  offerData: null,
  isAllTaskOpen: false,
  isOnboardingDone: false,
  onboardStepsCompleted: 0,
  playList: {
    data: [],
  },
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case OverviewConstants.FETCH_OVERVIEW_DATA:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case OverviewConstants.FETCH_OVERVIEW_DATA_SUCCESS:
      return {
        ...state,
        overviewData: action.payload,
        isLoading: false,
        isError: false,
      };
    case OverviewConstants.FETCH_OVERVIEW_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isError: true,
      };

    case OverviewConstants.TOGGLE_BOOK_COUNSELLING_MODAL:
      return {
        ...state,
        isOpenBookCounsellingModal: action.payload,
      };
    case OverviewConstants.TOGGLE_SPEAK_TO_COUNSELLOR_MODAL:
      return {
        ...state,
        isOpenSpeakToCounsellorModal: action.payload,
      };
    case OverviewConstants.TOGGLE_GET_LS_PLUS_MODAL:
      return {
        ...state,
        isOpenGetLsPlusModal: action.payload,
      };
    case OverviewConstants.FETCH_COUNSELLING_FEE_DATA:
      return {
        ...state,
      };
    case OverviewConstants.FETCH_COUNSELLING_FEE_DATA_SUCCESS:
      return {
        ...state,
        counsellingFeeData: action.payload.data,
      };
    case OverviewConstants.FETCH_COUNSELLING_FEE_DATA_ERROR:
      return {
        ...state,
        counsellingFeeData: {},
        error: action.payload,
      };
    case OverviewConstants.FETCH_POST_PAYMENT_DATA_SUCCESS:
      return {
        ...state,
        postPaymentData: action.payload,
      };

    case OverviewConstants.FETCH_OFFER_SUCCESS:
      return {
        ...state,
        offerData: action.payload,
      };

    case OverviewConstants.FETCH_OFFER_ERROR:
      return {
        ...state,
        offerData: null,
      };

    case OverviewConstants.TOGGLE_ALL_TASK_VIEW:
      return {
        ...state,
        isAllTaskOpen: action.payload,
      };

    case OverviewConstants.FETCH_PLAYLIST: {
      return {
        ...state,
        playList: {
          ...state.playList,
        },
      };
    }

    case OverviewConstants.FETCH_PLAYLIST_SUCCESS: {
      return {
        ...state,
        playList: {
          data: action.payload,
        },
      };
    }

    case OverviewConstants.FETCH_PLAYLIST_FAIL: {
      return {
        ...state,
        playList: {
          ...state.playList,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;
