const fetchStories = () => ({
  type: "FETCH_STORIES",
});

const fetchStoriesSuccess = (payload: any) => ({
  type: "FETCH_STORIES_SUCCESS",
  payload,
});

const updateCategory = (payload: any) => ({
  type: "UPDATE_ACTIVE_CATEGORY",
  payload,
});

const updateStory = (payload: any) => ({
  type: "UPDATE_ACTIVE_STORY",
  payload,
});

const toggleShowStory = (payload: boolean) => ({
  type: "TOGGLE_SHOW_STORY",
  payload,
});

const updateStorySeen = (payload: string) => ({
  type: "UPDATE_STORY_SEEN",
  payload,
})

export const storiesActions = {
  fetchStories,
  fetchStoriesSuccess,
  updateCategory,
  updateStory,
  toggleShowStory,
  updateStorySeen
}