import api from "service/api"

// Before Login
const getBeginnerMasterClassData = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=v2/workshop/BEGINNER_MASTER_CLASS/0/12`,
    )
    return res.data
  } catch (err) {
    console.log(err)
  }
}

// Before Login
const getClassicMasterClassData = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=v2/workshop/MASTER_CLASS/0/20`,
    )
    return res.data
  } catch (err) {
    console.log(err)
  }
}

const fetchtMasterclassAttendStatus = () =>
  api.get(`/api/scholarRoute?path=ielts-elite/mc/status`)

export const masterclassTypeApi = {
  getBeginnerMasterClassData,
  getClassicMasterClassData,
  fetchtMasterclassAttendStatus,
}
