import { SemDataSchema, SemFormSchema } from "Interfaces/reducerTypes/SemSchema";

export const actionConstant = {
  SEM_PAGE_DETAIL_REQUEST: "SEM_PAGE_DETAIL_REQUEST",
  SEM_PAGE_DETAIL_SUCCESS: "SEM_PAGE_DETAIL_SUCCESS",
  UPDATE_ERROR: "UPDATE_ERROR",
  SEM_SAVE_FORM_REQUEST: "SEM_SAVE_FORM_REQUEST",
  SEM_SAVE_FORM_SUCCESS:  "SEM_SAVE_FORM_SUCCESS",
  TOGGEL_MODAL: "TOGGEL_MODAL"
};

const semPageDetailRequest = () => ({
  type: actionConstant.SEM_PAGE_DETAIL_REQUEST,
});

const semPageDetailSuccess = (payload: null | Array<SemDataSchema>) => ({
  type: actionConstant.SEM_PAGE_DETAIL_SUCCESS,
  payload,
});

const updateError = (payload: string) => ({
  type: actionConstant.UPDATE_ERROR,
  payload,
});

const semSaveFormRequest = (payload: SemFormSchema, path: string) => ({
  type: actionConstant.SEM_SAVE_FORM_REQUEST,
  payload,
  path
})

const semSaveFormSuccess = () => ({
  type: actionConstant.SEM_SAVE_FORM_SUCCESS
})

const toggelModal = (payload: boolean) => ({
  type: actionConstant.TOGGEL_MODAL,
  payload
})

export const semPageAction = {
  semPageDetailRequest,
  semPageDetailSuccess,
  updateError,
  semSaveFormRequest,
  semSaveFormSuccess,
  toggelModal
};
