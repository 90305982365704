import { call, put, takeLatest } from 'redux-saga/effects'
import { documentDetailsActions } from '../actions/document-details-actions'
import { documentDetailsApi } from '../apis/document-details-apis'
import DocumentDetailsConstants from '../constants/document-details-constants'

function* fetchDocumentsDetailsData(): Generator<any, any, any> {
  try {
    const res = yield call(documentDetailsApi.fetchDocumentDetailsRequest)
    if (res.data.success) {
      yield put(
        documentDetailsActions.fetchDocumentDetailsDataSuccess(res.data.data)
      )
    } else {
      yield put(
        documentDetailsActions.fetchDocumentDetailsDataError(res.data.message)
      )
    }
  } catch (e: any) {
    console.error(e)
    yield put(documentDetailsActions.fetchDocumentDetailsDataError(e.message))
  }
}

function* documentsDetailsSaga() {
  yield takeLatest(
    DocumentDetailsConstants.FETCH_DOCUMENT_DETAILS_DATA,
    fetchDocumentsDetailsData
  )
}

export default documentsDetailsSaga
