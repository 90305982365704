import React, {Component, ComponentType} from "react";
import { isMobile } from "react-device-detect";
import debounce from "lodash/debounce";

export default function withWindowDimensions<P extends object>(WrappedComponent: ComponentType<P>) {
  return class extends Component<P> {
    state = { width: 0, height: 0 };

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener("resize", debounce(this.updateWindowDimensions, 800));
    }

    componentWillUnmount() {
      window.removeEventListener("resize", debounce(this.updateWindowDimensions, 800));
    }

    updateWindowDimensions = () => {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          windowWidth={this.state.width}
          windowHeight={this.state.height}
          isMobileSized={isMobile}
        />
      );
    }
  };
}
