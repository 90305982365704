import { AnyAction } from "redux";
import { FinancialCounsellingSchema } from "Interfaces/reducerTypes/FinancialCounsellingSchema";

const initialState: FinancialCounsellingSchema = {
  financialCounsellingData: null,
  financialCounsellingPageData: null,
  canAttend: false,
  isJoined: false,
  error: "",
  loading: true,
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "FINANCIAL_COUNSELLING_FETCH_SUCCEEDED":
      return {
        ...state,
        financialCounsellingData: action.payload,
        loading: false,
      };
    case "FINANCIAL_COUNSELLING_FETCH_FAILED":
      return {
        ...state,
        error: action.message,
      };
    case "UPDATE_JOIN_STATUS":
      return {
        ...state,
        isJoined: action.payload,
      };
    case "UPDATE_CAN_ATTEND_STATUS":
      return {
        ...state,
        canAttend: action.payload,
      };

    // new api
    case "FINANCIAL_COUNSELLING_PAGE_FETCH_REQUESTED":
      return {
        ...state,
        loading: true,
      };

    case "FINANCIAL_COUNSELLING_PAGE_FETCH_SUCCEEDED":
      return {
        ...state,
        financialCounsellingPageData: action.payload,
        loading: false,
      };

    case "FINANCIAL_COUNSELLING_PAGE_FETCH_FAILED":
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    default:
      return state;
  }
};

export default reducer;
