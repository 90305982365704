const fetchRecordings = (payload: string) => ({
  type: "RECORDINGS_FETCH_REQUESTED",
  payload,
})

const setRecordingsLoading = (payload: boolean) => ({
  type: "SET_RECORDINGS_LOADING",
  payload,
})

const fetchRecordingsSuccess = (payload: any) => ({
  type: "RECORDINGS_FETCH_SUCCESS",
  payload,
})

const fetchRecordingsFail = (payload: any) => ({
  type: "RECORDINGS_FETCH_FAIL",
  payload,
})

const fetchVideo = (payload: any) => ({
  type: "VIDEO_FETCH_REQUESTED",
  payload,
})

const fetchVideoSuccess = (payload: any) => ({
  type: "VIDEO_FETCH_SUCCESS",
  payload,
})

const fetchVideoFail = (payload: any) => ({
  type: "VIDEO_FETCH_FAIL",
  payload,
})

const updateRecordings = (payload: any) => ({
  type: "UPDATE_RECORDINGS",
  payload,
})

const fetchSelfPrepRecordings = () => ({
  type: "SELF_PREP_RECORDINGS_FETCH_REQUESTED",
})
const fetchGrammarRecordings = () => ({
  type: "GRAMMAR_RECORDINGS_FETCH_REQUESTED",
})

const updateSelfPrepRecordings = (payload: any) => ({
  type: "SELF_PREP_UPDATE_RECORDINGS",
  payload,
})

export const classRecordingsActions = {
  fetchRecordings,
  fetchRecordingsSuccess,
  fetchRecordingsFail,
  fetchVideo,
  fetchVideoSuccess,
  fetchVideoFail,
  updateRecordings,
  fetchSelfPrepRecordings,
  updateSelfPrepRecordings,
  fetchGrammarRecordings,
  setRecordingsLoading,
}
