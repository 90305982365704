export const initWebEngage = () => {
  var webEngageScript = document.getElementById("_webengage_script_tag");
  if (webEngageScript) return;
  var script = document.createElement("script");
  script.id = "_webengage_script_tag";
  script.type = "text/javascript";
  script.innerHTML = `var webengage;
  !(function (w, e, b, n, g) {
    function o(e, t) {
      e[t[t.length - 1]] = function () {
        r.__queue.push([t.join("."), arguments]);
      };
    }
    var i,
      s,
      r = w[b],
      z = " ",
      l = "init options track screen onReady".split(z),
      a = "feedback survey notification".split(z),
      c = "options render clear abort".split(z),
      p = "Open Close Submit Complete View Click".split(z),
      u = "identify login logout setAttribute".split(z);
    if (!r || !r.__v) {
      for (
        w[b] = r = { __queue: [], __v: "6.0", user: {} }, i = 0;
        i < l.length;
        i++
      )
        o(r, [l[i]]);
      for (i = 0; i < a.length; i++) {
        for (r[a[i]] = {}, s = 0; s < c.length; s++) o(r[a[i]], [a[i], c[s]]);
        for (s = 0; s < p.length; s++) o(r[a[i]], [a[i], "on" + p[s]]);
      }
      for (i = 0; i < u.length; i++) o(r.user, ["user", u[i]]);
      setTimeout(function () {
        var f = e.createElement("script"),
          d = e.getElementById("_webengage_script_tag");
        (f.type = "text/javascript"),
          (f.async = !0),
          (f.src =
            ("https:" == e.location.protocol
              ? "https://ssl.widgets.webengage.com"
              : "http://cdn.widgets.webengage.com") +
            "/js/webengage-min-v-6.0.js"),
          d.parentNode.insertBefore(f, d);
      });
    }
  })(window, document, "webengage");
  window.webengage=webengage;
  `;
  document.body.appendChild(script);
};

export const webInitialization = (webId) => {
  if (window && typeof window === "object" && window.webengage) {
    window.webengage.init(webId);
  }
};

export const webEventTracker = (eventType, eventData) => {
  if (window && typeof window === "object" && window.webengage) {
    window.webengage.track(eventType, eventData);
  }
};

export const webUserTracker = (userAttribute, userData) => {
  if (window && typeof window === "object" && window.webengage) {
    window.webengage.user.setAttribute(userAttribute, userData);
  }
};

export const webUserLogin = (userId) => {
  if (window && typeof window === "object" && window.webengage) {
    window.webengage.user.login(userId);
  }
};

export const webUserLogOut = () => {
  if (window && typeof window === "object" && window.webengage) {
    window.webengage.user.logout();
  }
};

export const webEngageGetForever = () => {
  return new Promise((resolve, reject) => {
    try {
      let webEngageState = window.webengage?.state;
      let countDown = 10;
      const webCounter = setInterval(() => {
        if (webEngageState?.getForever() || countDown < 0) {
          clearInterval(webCounter);
          if (webEngageState?.getForever()) {
            resolve(webEngageState.getForever());
          } else {
            reject(false);
          }
        } else {
          webEngageState = window.webengage?.state;
          countDown -= 1;
        }
      }, 200);
    } catch (err) {
      reject(false);
    }
  });
};
