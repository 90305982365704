import { AnyAction } from "redux";
import VisaAssistanceConstants from "../constants/visa-assistance-constants";

const initialState: any = {
  visaAssistanceData: {},
  error: "",
  isLoading: false,
  isError: false,
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case VisaAssistanceConstants.FETCH_VISA_ASSISTANCE_DATA:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case VisaAssistanceConstants.FETCH_VISA_ASSISTANCE_DATA_SUCCESS:
      return {
        ...state,
        visaAssistanceData: action.payload,
        isLoading: false,
        isError: false,
      };
    case VisaAssistanceConstants.FETCH_VISA_ASSISTANCE_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};

export default reducer;
