import api from "service/api"

const fetchUserDetails = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=student/basic`)
    if (res.data.success) {
      return res.data.data
    } else {
      throw new Error(res.data.message)
    }
  } catch (err: any) {
    console.log(err)
    throw new Error("Something went wrong. Please try after some time")
  }
}

export const referAndEarnApi = {
  fetchUserDetails,
}
