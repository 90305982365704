import gql from "graphql-tag";

const FETCH_SEM_PAGE_QUERY = gql`
  query {
    semPages {
        target_intent
        template_type
        landing_page_name
        landing_page_link
        headline
        sub_heading
        bullet_points
    }
  }
`;

export default FETCH_SEM_PAGE_QUERY;
