import { combineReducers } from "redux"

import IeltsPlusReducer from "components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusReducer"
import FinancialCounsellingReducer from "components/Profile/CounsellingProfile/FinancialCounselling/redux/FinancialCounsellingReducer"
import CounsellingFormReducer from "components/Profile/CounsellingFormExperiment/redux/CounsellingFormReducer"
import SpeakingClubReducer from "components/Profile/IeltsProfile/SpeakingClub/redux/SpeakingClubReducer"
import ClassRecordingsReducer from "components/Profile/IeltsProfile/ClassRecordings/redux/ClassRecordingsReducer"
import NoticeBoardReducer from "components/Profile/IeltsProfile/NoticeBoard/redux/NoticeBoardReducer"
import StudyMaterialReducer from "components/Profile/IeltsProfile/StudyMaterial/redux/StudyMaterialReducer"
import MyClassReducer from "components/Profile/IeltsProfile/MyClasses/redux/MyClassReducer"
import UpcomingClass from "components/Profile/IeltsProfile/UpcomingClass/redux/upcomingReducer"
import ReferAndEarnReducer from "components/Profile/ReferAndEarn/redux/ReferAndEarnReducer"
import CounsellingReducer from "components/Profile/CounsellingProfile/redux/CounsellingReducer"
import EssayReducer from "components/Profile/IeltsProfile/EssayEvaluation/redux/EssayReducer"
import FacultyReducer from "components/Ielts/Faculty/redux/FacultyReducer"
import BatchDetailsReducer from "components/Ielts/BatchDetails/redux/BatchDetailsReducer"
import UpcomingIeltsBatchesReducer from "components/Ielts/UpcomingIeltsBatches/redux/UpcomingIeltsReducer"
import UpdateProfileReducer from "components/Profile/UpdateProfileFormsNew/redux/UpdateProfileReducer"
import IeltsRegistrationFormReducer from "components/Profile/IeltsRegistrationForm/redux/IeltsRegistrationFormReducer"
import MasterclassRegistrationReducer from "components/Profile/MasterclassRegistrationForm/redux/MasterclassRegistrationFormReducer"
import IeltsAttendanceReducer from "components/Profile/IeltsAttendanceScreen/redux/IeltsAttendanceReducer"
import ExtraClassReducer from "components/Profile/IeltsProfile/ExtraClass/redux/ExtraClassReducer"
import IeltsNps from "components/Profile/IeltsProfile/Nps/redux/NpsReducer"
import SpotCounselling from "components/SpotCounselling/redux/SpotCounsellingReducer"
import SpotCouncellingCalender from "components/SpotCounselling/Calender/redux/calenderReducer"
import PromoCodeReducer from "components/Ielts/PromoCode/redux/PromoCodeReducer"
import AuthReducer from "components/decorator/redux/AuthReducer"
import MockTestReducer from "components/Profile/IeltsProfile/MockTest/redux/MockTestReducer"
import FastTrackRegistrationForm from "components/Profile/FastTrackRegistrationForm/redux/FastTrackFormReducer"
import FastTrackReducer from "components/Profile/IeltsProfile/FastTrack/redux/FastTrackReducer"
import FastTrackVideo from "components/Profile/FastTrackVideo/redux/FastTrackVideoReducer"
import IntermediateScreenReducer from "components/Profile/IntermediateScreen/redux/IntermediateScreenReducer"
import OverviewReducer from "components/Profile/CounsellingProfile/redux/reducers/overview-reducers"
import CounsellingNoticeBoardReducer from "components/Profile/CounsellingProfile/redux/reducers/notice-board-reducers"
import UpcomingAppointmentsReducer from "components/Profile/CounsellingProfile/redux/reducers/upcoming-appointments-reducers"
import DocumentDetailsReducer from "components/Profile/CounsellingProfile/redux/reducers/document-details-reducers"
import VisaAssistanceReducer from "components/Profile/CounsellingProfile/redux/reducers/visa-assistance-reducers"
import RegistrationFormReducer from "components/Profile/RegistrationForm/redux/RegistrationFormReducer"
import MyUniversitiesReducer from "components/Profile/CounsellingProfile/redux/reducers/my-universities-reducers"
import MasterclassReducer from "components/Profile/IeltsProfile/Masterclass/redux/MasterclassReducer"
import IeltsTestReducer from "components/Profile/IeltsProfile/IeltsTest/redux/IeltsTestReducer"
import OnboardingReducer from "components/Onboarding/redux/OnboardingReducer"
import CertificateReducer from "components/Profile/IeltsProfile/Certificates/redux/CertificateReducer"
import DocumentUploadReducer from "components/DocumentsUpload/redux/DocumentUploadReducer"
import ReviewReducer from "components/Ielts/Reviews/redux/ReviewsReducer"
import MeetCounsellerReducer from "components/MeetCounsellerVirtualOffice/redux/MeetCounsellerReducer"
import StoriesReducer from "components/Profile/IeltsProfile/Stories/redux/StoriesReducer"
import IeltsScreenReducer from "components/Profile/StudentDashbord/IeltsScreenController/redux/IeltsScreenControllerReducer"
import RequestCbReducer from "components/Ielts/BatchDetails/RequestCallBackForm/redux/RequestCallBackFormSlice"
import AiEvalutionReducer from "components/Ielts/AiEvaluation/redux/AiEvalutionReducer"
import masterclassReplaysReducer from "components/Profile/IeltsProfile/Masterclass/MasterclassReplays/redux/MasterclassReplaysReducer"
import semPageReducer from "components/Ielts/SemLanding/redux/SemLandingReducer"
import IeltsQuizReducer from "components/Profile/IeltsProfile/QuizSection/redux/QuizReducer"
import masterClassTypeReducer from "components/Ielts/IeltsMasterclassType/redux/MasterClassTypeReducer"
import foundationReducer from "components/Profile/IeltsProfile/FoundationClass/redux/FoundationSlice"
import joinMasterClassReducer from "components/Profile/IeltsProfile/JoinMasterClass/redux/joinMasterClassReducer"
import MockTestPriceReducer from "components/Profile/IeltsProfile/MockTest/MockTestLandingPage/redux/MockTestPriceSlice"
import batchScheduleReducer from "components/Profile/IeltsProfile/IeltsPlus/SchedulePage/redux/schedulePageSlice"
import NeedHelpReducer from "components/NeedHelpV1/redux/NeedHelpReducer"
import selfPrepSlice from "components/Ielts/SelfPrepPrePayment/redux/selfPrepSlice"
// ToFu
import tofuOnbordingReducer from "components/TofuOnbordingForms/redux/Reducer"
import LsPaymentReducer from "components/Payment/redux/PaymentReducer"
import orientationSlice from "components/Profile/IeltsProfile/Orientation/redux/OrientationSlice"
import practiseAppSlice from "components/Profile/AndroidApp/redux/PractiseAppSlice"
import WordOfTheDaySlice from "components/Profile/AndroidApp/PractiseTab/WordOfTheDay/redux/WordOfTheDaySlice"

// ls community
import CommunityReducer from "components/CounsellingCommunity/redux/CommunityReducer"
import TestPrepSlice from "components/Profile/IeltsProfile/TestPrep/redux/TestPrepSlice"
import IeltsEliteSlice from "components/Ielts/IeltsEliteDemo/redux/IeltsEliteSlice"
import filtersSlice from "components/Ielts/IeltsDemo/DemoCalendar/Filters/redux/FiltersSlice"
import ieltsDemoSlice from "components/Ielts/IeltsDemo/redux/IeltsDemoSlice"
import PaymentBundlesSlice from "components/Ielts/GenericBundles/redux/PaymentBundleSlices"
import DemoRewardsSlice from "components/Profile/StudentDashbord/IeltsSection/IeltsDemoRewards/redux/DemoRewardsSlice"
import IeltsReferalSlice, {
  ieltsReferalStateName,
} from "components/Ielts/Referal/redux/IeltsReferalSlice"
import getSupportSlice from "components/UserDashboard/GetSupport/getSupportSlice"

export const rootReducer = combineReducers({
  ieltsPlus: IeltsPlusReducer,
  financialCounselling: FinancialCounsellingReducer,
  speakingClub: SpeakingClubReducer,
  classRecordings: ClassRecordingsReducer,
  noticeBoard: NoticeBoardReducer,
  studyMaterial: StudyMaterialReducer,
  myClass: MyClassReducer,
  upcomingClass: UpcomingClass,
  referAndEarn: ReferAndEarnReducer,
  counselling: CounsellingReducer,
  counsellingForm: CounsellingFormReducer,
  essayEvaluation: EssayReducer,
  ieltsFaculty: FacultyReducer,
  batchDetails: BatchDetailsReducer,
  upcomingIeltsBatches: UpcomingIeltsBatchesReducer,
  updateProfile: UpdateProfileReducer,
  ieltsRegistrationForm: IeltsRegistrationFormReducer,
  masterclassRegistrationForm: MasterclassRegistrationReducer,
  ieltsAttendance: IeltsAttendanceReducer,
  extraClass: ExtraClassReducer,
  ieltsNps: IeltsNps,
  spotCounselling: SpotCounselling,
  calender: SpotCouncellingCalender,
  promoCode: PromoCodeReducer,
  overviewReducer: OverviewReducer,
  counsellingNoticeBoardReducer: CounsellingNoticeBoardReducer,
  upcomingAppointments: UpcomingAppointmentsReducer,
  documentDetails: DocumentDetailsReducer,
  visaAssistance: VisaAssistanceReducer,
  auth: AuthReducer,
  mockTest: MockTestReducer,
  fastTrackRegistrationForm: FastTrackRegistrationForm,
  fastTrack: FastTrackReducer,
  fastTrackVideo: FastTrackVideo,
  intermediateScreen: IntermediateScreenReducer,
  registrationForms: RegistrationFormReducer,
  myUniversities: MyUniversitiesReducer,
  masterclass: MasterclassReducer,
  ieltsTest: IeltsTestReducer,
  onboardingReducer: OnboardingReducer,
  ieltsCertificate: CertificateReducer,
  documentUpload: DocumentUploadReducer,
  review: ReviewReducer,
  meetCounseller: MeetCounsellerReducer,
  stories: StoriesReducer,
  ieltsScreenController: IeltsScreenReducer,
  aiEvalution: AiEvalutionReducer,
  masterClassReplays: masterclassReplaysReducer,
  lsPayment: LsPaymentReducer,
  ieltsQuiz: IeltsQuizReducer,
  semDetails: semPageReducer,
  masterClassType: masterClassTypeReducer,
  foundationClass: foundationReducer,
  joinMasterClass: joinMasterClassReducer,
  RequestCbPremium: RequestCbReducer,
  tofuOnbording: tofuOnbordingReducer,
  mockTestPrice: MockTestPriceReducer,
  lsCommunity: CommunityReducer,
  batchShedule: batchScheduleReducer,
  orientation: orientationSlice,
  practiseTab: practiseAppSlice,
  NeedHelp: NeedHelpReducer,
  WordOfTheDay: WordOfTheDaySlice,
  TestPrep: TestPrepSlice,
  IeltsElite: IeltsEliteSlice,
  Filters: filtersSlice,
  IeltsDemo: ieltsDemoSlice,
  PaymentBundles: PaymentBundlesSlice,
  DemoRewards: DemoRewardsSlice,
  selfPrep: selfPrepSlice,
  [ieltsReferalStateName]: IeltsReferalSlice,
  getSupportSlice: getSupportSlice,
})
