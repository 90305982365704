import { androidApis } from 'components/Profile/AndroidApp/redux/PractiseApi'
import {
  fetchPracticeTabData,
  fetchUpdateVideoCompletionAction,
  setPractiseData,
  setPractiseError,
  setUpdateVideoCompletionError,
  setUpdateVideoCompletionLoading,
} from 'components/Profile/AndroidApp/redux/PractiseAppSlice'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchPracticeTabSaga(): Generator<any, any, any> {
  try {
    const res = yield call(androidApis.fetchPracticeTabApi)
    if (res.success) {
      yield put(setPractiseData(res.data))
    } else {
      yield put(setPractiseError('failed'))
    }
  } catch (e) {
    yield put(setPractiseError('Something went wrong!'))
  }
}

function* fetchUpdateVideoCompletionSaga(
  action: AnyAction
): Generator<any, any, any> {
  try {
    setUpdateVideoCompletionLoading(true)
    const res = yield call(
      androidApis.fetchUpdateVideoCompletion,
      action.payload
    )
    if (res.success) {
      setUpdateVideoCompletionLoading(false)
      // setUpdateVideoCompletionSuccess(true);
    } else {
      setUpdateVideoCompletionError(
        'Something went wrong please try again later'
      )
      setUpdateVideoCompletionLoading(false)
    }
  } catch (err) {
    setUpdateVideoCompletionError('Something went wrong please try again later')
    setUpdateVideoCompletionLoading(false)
  }
}

export default function* practiseAppSaga() {
  yield takeLatest(fetchPracticeTabData, fetchPracticeTabSaga)
  yield takeLatest(
    fetchUpdateVideoCompletionAction,
    fetchUpdateVideoCompletionSaga
  )
}
