const toggleView = (payload: boolean) => ({
  type: "TOGGLE_INTERMEDIATE_SCREEN_VIEW",
  payload,
})

const updateUserInfo = (payload: Record<string, any>) => ({
  type: "UPDATE_USER_INFO_REQUESTED",
  payload,
});

export const intermediateScreenActions = {
  toggleView,
  updateUserInfo
}