import { createSlice } from "@reduxjs/toolkit"

const getSupportSlice = createSlice({
  name: "getSupport",
  initialState: {
    isGetSupportModalOpen: false,
  },
  reducers: {
    setIsGetSupportModalOpen: (state, action) => {
      state.isGetSupportModalOpen = action.payload
    },
  },
})

export const { setIsGetSupportModalOpen } = getSupportSlice.actions

export default getSupportSlice.reducer
