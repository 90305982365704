export const joinMasterClassType = {
  FETCH_REGISTERED_MEETING_DATA: "FETCH_REGISTERED_MEETING_DATA",
  FETCH_REGISTERED_MEETING_DATA_SUCCESS:
    "FETCH_REGISTERED_MEETING_DATA_SUCCESS",
  FETCH_REGISTERED_MEETING_DATA_FAIL: "FETCH_REGISTERED_MEETING_DATA_FAIL",
  CHANGE_MEETING_STATUS: "CHANGE_MEETING_STATUS",
  FETCH_IELTS_DEMO_DATA: "FETCH_IELTS_DEMO_DATA",
  SET_IELTS_DEMO_DATA: "SET_IELTS_DEMO_DATA",
};

const fetchRegisteredMeetingData = (payload: any) => ({
  type: joinMasterClassType.FETCH_REGISTERED_MEETING_DATA,
  payload,
});

const fetchRegisteredMeetingDataSuccess = (payload: any) => ({
  type: joinMasterClassType.FETCH_REGISTERED_MEETING_DATA_SUCCESS,
  payload,
});

const fetchRegisteredMeetingDatafail = (payload: any) => ({
  type: joinMasterClassType.FETCH_REGISTERED_MEETING_DATA_FAIL,
  payload,
});

const changeMeetingStatus = (payload: any) => ({
  type: joinMasterClassType.CHANGE_MEETING_STATUS,
  payload,
});

const fetchIeltsDemoData = (payload: any) => ({
  type: joinMasterClassType.FETCH_IELTS_DEMO_DATA,
  payload,
});

const setIeltsDemoData = (payload: any) => ({
  type: joinMasterClassType.SET_IELTS_DEMO_DATA,
  payload,
});

export const joinMasterClassActions = {
  fetchRegisteredMeetingData,
  fetchRegisteredMeetingDataSuccess,
  fetchRegisteredMeetingDatafail,
  changeMeetingStatus,
  fetchIeltsDemoData,
  setIeltsDemoData,
};
