import { AnyAction } from "redux";
import { fastTrackFormActionType } from "components/Profile/FastTrackRegistrationForm/redux/FastTrackFormActions";

const initialState: any = {
  ctaText: "Next",
  isModalOpen: false,
  error: "",
  showFastTrackLoader: false,
  showFastTrackForm: false,
};

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case fastTrackFormActionType.TOGGLE_FAST_TRACK_FORM_CTA_TEXT:
      return {
        ...state,
        ctaText: action.payload,
      };
    case fastTrackFormActionType.TOGGLE_FAST_TRACK_MODAL:
      return {
        ...state,
        isModalOpen: action.payload,
      };
    case fastTrackFormActionType.FAST_TRACK_FORM_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case "TOGGLE_FAST_TRACK_LOADER":
      return {
        ...state,
        showFastTrackLoader: action.payload,
      };
    case "TOGGLE_FAST_TRACK_FORM":
      return {
        ...state,
        showFastTrackForm: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
