import { AnyAction } from "redux";
import { MyClassSchema } from "Interfaces/reducerTypes/MyClassSchema";
import { myClassActionType } from "components/Profile/IeltsProfile/MyClasses/redux/MyClassActions";

const initialState: MyClassSchema | any = {
  ieltsData: null,
  masterClassData: [],
  loading: true,
  showHasJoinedModal: false,
  showHasJoinedMasterClassModal: false,
  showConfirmModal: false,
  errorMessage: "",
  modalText: {
    batchId: 1,
    calendarText: "",
    clockText: "",
  },
  upcomingClass: {},
  masterclassSubscriptionStatus: null,
  fetchingMasterclassSubscriptionStatus: true,
};

// create your reducer
const reducer = (state: MyClassSchema = initialState, action: AnyAction) => {
  switch (action.type) {
    case myClassActionType.ENROLL_TO_IELTS_PLUS_SUCCESS:
      return {
        ...state,
        showHasJoinedModal: action.payload,
      };
    case myClassActionType.ENROLL_TO_IELTS_PLUS_FAILED:
      return {
        ...state,
        errorMessage: action.message,
      };
    case myClassActionType.UPDATE_MODAL_TEXT:
      return {
        ...state,
        modalText: action.payload,
      };
    case myClassActionType.TOGGLE_CONFIRM_MODAL:
      return {
        ...state,
        showConfirmModal: action.payload,
      };
    case myClassActionType.MASTER_FETCH_SUCCESS:
      return {
        ...state,
        masterClassData: action.payload,
      };
    case myClassActionType.MASTER_FETCH_FAIL:
      return {
        ...state,
        errorMessage: action.message,
      };
    case myClassActionType.SET_IELTS_MODAL:
      return {
        ...state,
        showHasJoinedModal: action.payload,
      };
    case myClassActionType.SET_MASTER_CLASS_MODAL:
      return {
        ...state,
        showHasJoinedMasterClassModal: action.payload,
      };
    case myClassActionType.ENROLL_TO_MASTER_CLASS_SUCCESS:
      return {
        ...state,
        showHasJoinedMasterClassModal: action.payload,
      };
    case "FETCH_MASTERCLASS_SUBSCRIPTION_STATUS_SUCCESS":
      return {
        ...state,
        masterclassSubscriptionStatus: action.payload,
        fetchingMasterclassSubscriptionStatus: false,
      };
    case "FETCH_MASTERCLASS_SUBSCRIPTION_STATUS_FAIL":
      return {
        ...state,
        error: action.payload,
        fetchingMasterclassSubscriptionStatus: false,
      }
    default:
      return state;
  }
};

export default reducer;
