import {
  fastTrackAction,
  fastTrackActionType,
} from 'components/Profile/IeltsProfile/FastTrack/redux/FastTrackAction'
import { fastTrackApi } from 'components/Profile/IeltsProfile/FastTrack/redux/FatTrackApi'
import { call, put, takeLatest } from 'redux-saga/effects'

function* fetchFastTrackStatus(): Generator<any, any, any> {
  try {
    const res = yield call(fastTrackApi.fetchFastTrackApi)
    if (res.success) {
      yield put(fastTrackAction.fetchFastTrackSuccess(res.data))
    } else {
      yield put(fastTrackAction.fetchFastTrackFail(res.data))
    }
  } catch (e: any) {
    console.error(e)
    yield put(fastTrackAction.fetchFastTrackFail('Something went wrong'))
    throw new Error(e.message)
  }
}

function* fetchFastTrackCard(): Generator<any, any, any> {
  try {
    const res = yield call(fastTrackApi.fetchFastTrackCardDetail)
    if (res.success) {
      yield put(fastTrackAction.fetchFastTrackCardSuccess(res.data))
    } else {
      yield put(fastTrackAction.fetchFastTrackCardFail(res.data))
    }
  } catch (e: any) {
    console.error(e)
    yield put(fastTrackAction.fetchFastTrackCardFail('Something went wrong'))
    throw new Error(e.message)
  }
}

function* fetchCategoryList(): Generator<any, any, any> {
  try {
    const res = yield call(fastTrackApi.fetchFastTrackCategoryList)
    if (res.success) {
      yield put(fastTrackAction.fetchFastTrackCardSuccess(res.data))
    } else {
      yield put(fastTrackAction.fetchFastTrackCardFail(res.data))
    }
  } catch (e: any) {
    console.error(e)
    yield put(fastTrackAction.fetchFastTrackCardFail('Something went wrong'))
    throw new Error(e.message)
  }
}

function* fastTrackSaga() {
  yield takeLatest(
    fastTrackActionType.FETCH_FAST_TRACK_STATUS_REQ,
    fetchFastTrackStatus
  )
  yield takeLatest(
    fastTrackActionType.FETCH_FAST_TRACK_CARD_REQ,
    fetchFastTrackCard
  )
  yield takeLatest(
    fastTrackActionType.FETCH_FAST_TRACK_VIDEO_CATEGORY,
    fetchCategoryList
  )
}

export default fastTrackSaga
