import { AnyAction } from "redux";
import { chatActionsConstants } from "./NeedHelpActions";

const initialState = {
  responseSuccess: false,
  responseError: false,
  responseLoading: false,
};

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case chatActionsConstants.QUERY_RAISE_API:
      return {
        ...state,
        responseLoading: true,
        responseSuccess: false,
        responseError: false,
      };

    case chatActionsConstants.QUERY_RAISE_API_SUCCESS:
      return {
        ...state,
        responseError: false,
        responseLoading: false,
        responseSuccess: true,
      };

    case chatActionsConstants.QUERY_RAISE_API_ERROR:
      return {
        ...state,
        responseError: true,
        responseLoading: false,
        responseSuccess: false,
      };
    case chatActionsConstants.RESET_QUERY_STATE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
