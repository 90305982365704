import { AnyAction } from "redux";
import { fastTrackActionVideoType } from "components/Profile/FastTrackVideo/redux/FastTrackVideoAction";
import { FastTrackVideoSchema } from "Interfaces/reducerTypes/FastTrackSchema";

const initialState: FastTrackVideoSchema = {
  fastTrackVideoSeries: null,
  loading: false,
  error: "",
  redirect: false,
  modal: false,
  videoPreviewEndModal: false,
  nextVideoToPlayAfterPayment: null,
};

const reducer = (
  state: FastTrackVideoSchema = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case fastTrackActionVideoType.FETCH_FAST_TRACK_VIDEO_REQ:
      return {
        ...state,
        loading: true,
      };
    case fastTrackActionVideoType.FETCH_FAST_TRACK_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        fastTrackVideoSeries: action.payload,
      };
    case fastTrackActionVideoType.FETCH_FAST_TRACK_VIDEO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case fastTrackActionVideoType.FAST_TRACK_WATCH_VIDEO_SUCCESS:
      return {
        ...state,
        redirect: true,
      };
    case fastTrackActionVideoType.FAST_TRACK_WATCH_VIDEO_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case fastTrackActionVideoType.FAST_TRACK_MODAL:
      return {
        ...state,
        modal: action.payload,
      };
    case fastTrackActionVideoType.VIDEO_PREVIEW_END_MODAL:
      return {
        ...state,
        videoPreviewEndModal: action.payload,
      };
    case fastTrackActionVideoType.UPDATE_NEXT_VIDEO_TO_PLAY_AFTER_PAYMENT:
      return {
        ...state,
        nextVideoToPlayAfterPayment: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
