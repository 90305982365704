import { IeltsScreenData } from "DataMapper/IeltsProfile/StudentDashboard"
import { IeltsStage } from "Interfaces/reducerTypes/IeltsScreenController"

const setIeltsView = (payload: IeltsScreenData) => ({
  type: "SET_IELTS_SCREEN",
  payload,
})

const setIeltsScreenLoading = (payload: boolean) => ({
  type: "IELTS_SCREEN_LOADING",
  payload,
})
const fetchUserStates = () => ({
  type: "FETCH_USER_STATES",
})

const setUserStates = (payload: any) => ({
  type: "SET_USER_STATES",
  payload,
})

const fetchDemoDetails = (payload: any) => ({
  type: "FETCH_DEMO_DETAILS",
  payload,
})

const setDemoDetails = (payload: any) => ({
  type: "SET_DEMO_DETAILS",
  payload,
})

const fetchUserIeltsDetailsReq = () => ({
  type: "FETCH_USER_IELTS_DETAILS_REQUEST",
})

const fetchUserIeltsDetailsSuccess = (payload: any) => ({
  type: "FETCH_USER_IELTS_DETAILS_SUCCESS",
  payload,
})

const fetchUserI2CEligibility = () => ({
  type: "FETCH_USER_I2C_ELIGIBILITY_REQUEST",
})

const fetchUserI2CEligibilitySuccess = (payload: any) => ({
  type: "FETCH_USER_I2C_ELIGIBILITY_SUCCESS",
  payload,
})

const fetchUserPaymentInfo = (payload: string) => ({
  type: "FETCH_USER_PAYMENT_INFO",
  payload,
})
const fetchUserPaymentInfoSuccess = (payload: any) => ({
  type: "FETCH_USER_PAYMENT_INFO_SUCCESS",
  payload,
})

const fetchIeltsEliteState = () => ({
  type: "FETCH_USER_IELTS_ELITE_STAGE",
})
const fetchIeltsEliteStateSuccess = (payload: IeltsStage) => ({
  type: "FETCH_USER_IELTS_ELITE_STAGE_SUCCESS",
  payload,
})

export const ieltsScreenControllerActions = {
  setIeltsView,
  setIeltsScreenLoading,
  fetchUserStates,
  setUserStates,
  fetchDemoDetails,
  setDemoDetails,
  fetchUserIeltsDetailsReq,
  fetchUserIeltsDetailsSuccess,
  fetchUserPaymentInfo,
  fetchUserPaymentInfoSuccess,
  fetchIeltsEliteState,
  fetchIeltsEliteStateSuccess,
  fetchUserI2CEligibility,
  fetchUserI2CEligibilitySuccess,
}
