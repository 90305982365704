import { NpsQuestionConfigDto } from 'Interfaces/reducerTypes/IeltsNpsSchema'

export const npsQuestions = {
  firstQuestion: {
    id: 'Q1',
    question: 'Sorry about that, What can we do better?',
    image: `${process.env.CDN_URL}/assets/images/nps/sad_emoji.png`,
  },
  secondQuestion: {
    id: 'Q2',
    question: 'Thank you! What can we improve to delight you further?',
    image: `${process.env.CDN_URL}/assets/images/nps/happy_emoji.png`,
  },
  thirdQuestion: {
    id: 'Q3',
    question: 'We are glad! What do you like about LeapScholar?',
    image: `${process.env.CDN_URL}/assets/images/nps/delight_emoji.png`,
  },
}

export enum NpsUserType {
  PROMOTERS = 'PROMOTERS',
  PASSIVE = 'PASSIVE',
  DETRACTORS = 'DETRACTORS',
}
export const ratingColors = {
  backgroundColor: [
    '#FF7675',
    '#FF7675',
    '#F99377',
    '#F69F6B',
    '#F3AB5F',
    '#F0B952',
    '#E0C176',
    '#D1D7B9',
    '#96C7C6',
    '#73B0B1',
  ],
  borderColor: [
    '#FD4B6D',
    '#FC5B55',
    '#F7724D',
    '#F88037',
    '#FD8D17',
    '#E4990E',
    '#DAAE42',
    '#BCC792',
    '#79B2B1',
    '#388E8E',
  ],
}

export const getNpsUserType = (rating: number): NpsUserType => {
  if (rating <= 6) return NpsUserType.DETRACTORS
  if (rating <= 8) return NpsUserType.PASSIVE
  return NpsUserType.PROMOTERS
}
export const npsColorDefinition = (rating: number) => {
  const npsUserType = getNpsUserType(rating)
  switch (npsUserType) {
    case NpsUserType.DETRACTORS:
      return '#FF7675'
    case NpsUserType.PASSIVE:
      return '#FABE2C'
    case NpsUserType.PROMOTERS:
    default:
      return '#73CE36'
  }
}

export const NpsRatingArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const fastTrackNpsQuestion =
  'How likely are you to recommend LeapScholar’s FastTrack course to your friends who are preparing for IELTS?'

export const ieltsNpsQuestion =
  "How likely are you to recommend LeapScholar's IELTS courses to your friends who are preparing for IELTS?"

export const getQuestionType = (
  rating: number,
  questionObject: Record<string, NpsQuestionConfigDto>,
): string | null => {
  return (
    Object.keys(questionObject).find((questionType) => {
      return questionObject[questionType].applicableRatings.includes(rating)
    }) || null
  )
}
