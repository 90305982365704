import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  fastTrackActions,
  fastTrackFormActionType,
} from "components/Profile/FastTrackRegistrationForm/redux/FastTrackFormActions";
import { getFastTrackFee } from "lib/Razorpay/getCounsellingFee";
import { fireTagEvent, logEvent } from "ga";
import { eventCategory, eventName } from "DataMapper/EventTracker/EventData";
import { fastTrackRegistrationFormApi } from "components/Profile/FastTrackRegistrationForm/redux/FastTrackFormApi";
import { fastTrackApi } from "components/Profile/IeltsProfile/FastTrack/redux/FatTrackApi";
import { IeltsToCounsellingEligibility } from "components/TofuOnbordingForms/SpotCounsellingProgressiveForm/IeltsToCounselling/IeltsToCounsellingApi";

function* saveData(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(fastTrackActions.toggleCtaText("Saving"));
    yield call(fireTagEvent, "fast_track_initiate_checkout");
    const res = yield call(
      fastTrackRegistrationFormApi.saveForm,
      {...action.payload.values,formId: "I2C_form" }
    );
    const fastTrackStatus = yield call(fastTrackApi.fetchFastTrackApi);
    if (res.success) {
      yield put(fastTrackActions.toggleFastTrackForm(false));
      IeltsToCounsellingEligibility(action.payload.values);
      yield put(fastTrackActions.toggleCtaText("Next"));
      const paymentReceived = yield call(
        getFastTrackFee,
        fastTrackStatus.data.offerPrice
      );
      if (paymentReceived) {
        logEvent(eventCategory.FAST_TRACK, eventName.PAID_FOR_FAST_TRACK, "");
        yield call(fireTagEvent, "fast_track_paid", {
          value: "249",
          currency: fastTrackStatus?.currency || "INR",
          content_id: "fastrack",
          content_type: "FastTrack",
        });
        yield put(fastTrackActions.toggleFastTrackFormModal(true));
      }
    } else {
      yield put(fastTrackActions.updateError(res.message));
    }
  } catch (e:any) {
    yield put(
      fastTrackActions.updateError(
        "Something went wrong. Please try after sometime."
      )
    );
    console.error(e);
  }
}

function* enrollToFastTrack(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(fastTrackActions.toggleCtaText("Saving"));
    const res = yield call(
      fastTrackRegistrationFormApi.enrollToFastTrack,
      action.payload
    );
    if (res.success) {
      yield put(fastTrackActions.toggleCtaText("Next"));
      yield put(fastTrackActions.toggleFastTrackFormModal(true));
    } else {
      yield put(fastTrackActions.updateError(res.message));
      yield put(fastTrackActions.toggleCtaText("Next"));
    }
  } catch (e:any) {
    yield put(
      fastTrackActions.updateError(
        "Something went wrong. Please try after sometime."
      )
    );
    console.error(e);
  }
}

function* fastTrackSaga() {
  yield takeLatest(fastTrackFormActionType.SAVE_FAST_TRACK_FORM, saveData);
  yield takeLatest(
    fastTrackFormActionType.ENROLL_TO_FAST_TRACK_FORM,
    enrollToFastTrack
  );
}

export default fastTrackSaga;
