import { npsActions } from 'components/Profile/IeltsProfile/Nps/redux/NpsActions'
import { npsApis } from 'components/Profile/IeltsProfile/Nps/redux/NpsApis'
import { getQuestionType } from 'DataMapper/IeltsProfile/npsData'
import { AnyAction } from 'redux'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { trackIeltsForm } from 'scripts/segment'
import { ieltsPageName } from 'utils/events-utils'
import { State } from 'Interfaces/storeSchema'
import { NpsEligibilityDto } from 'Interfaces/reducerTypes/IeltsNpsSchema'

function* fetchNpsStatus(): Generator<any, any, any> {
  try {
    const res = yield call(npsApis.fetchNpsStatus)
    if (res.success) {
      const npsData: NpsEligibilityDto = res.data
      yield put(npsActions.fetchNpsStatusSuccess(res.data))
      yield put(npsActions.updateNpsStatus(res.data?.isEligible || false))
      if (npsData && npsData.rating && npsData.rating > 0) {
        yield put(npsActions.updateSubmitRating(npsData.rating))
        const questionType = getQuestionType(npsData.rating, npsData.questions)
        if (questionType)
          yield put(
            npsActions.updateQuestion({
              ...npsData.questions[questionType],
              id: questionType,
            }),
          )
        yield put(npsActions.updateNpsId(`${npsData?.npsId}`))
        yield put(npsActions.updateQuestionType('suggestionForm'))
      }
    } else {
      yield put(npsActions.updateError(res.message))
    }
  } catch (e: any) {
    yield put(
      npsActions.updateError('Something went wrong. Please try after sometime'),
    )
  }
}

function* fetchFastTrackNpsStatus(): Generator<any, any, any> {
  try {
    const res = yield call(npsApis.fetchFastTrackNpsStatus)
    if (res.success) {
      yield put(npsActions.fetchNpsStatusSuccess(res.data))
    } else {
      yield put(npsActions.updateError(res.message))
    }
  } catch (e: any) {
    yield put(
      npsActions.updateError('Something went wrong. Please try after sometime'),
    )
  }
}

function* submitRatings(action: AnyAction): Generator<any, any, any> {
  yield put(npsActions.updateQuestionType('loading'))
  try {
    const res = yield call(npsApis.submitRatings, action.payload)

    if (res.success) {
      let pageName = ''

      if (action.payload.pathName) {
        if (action.payload.pathName == 'ielts?section=plus') {
          pageName = ieltsPageName.IELTS_PLUS
        } else if (action.payload.pathName == 'ielts?section=fastTrack') {
          pageName = ieltsPageName.IELTS_FAST_TRACK
        }
      }
      trackIeltsForm('Form Filled', {
        pageName: pageName || ieltsPageName.IELTS_My_Classes,
        widgetName: 'NPS',
        widgetFormat: 'Form',
        contentName: 'NPS Question',
        contentFormat: 'Rating Scale',
        rating: action.payload.ratings,
      })
      if (action.payload?.isSkipped) {
        yield put(npsActions.updateNpsStatus(false))
      } else {
        yield put(npsActions.updateSubmitRating(action.payload.ratings))
        const npsData: NpsEligibilityDto | null = yield select(
          (state: State) => state.ieltsNps.npsData,
        )
        if (npsData) {
          const questionType = getQuestionType(
            action.payload.ratings,
            npsData.questions,
          )
          if (questionType)
            yield put(
              npsActions.updateQuestion({
                ...npsData.questions[questionType],
                id: questionType,
              }),
            )
        }
        yield put(npsActions.updateNpsId(res.data))
        yield put(npsActions.updateQuestionType('suggestionForm'))
      }
    } else {
      yield put(npsActions.updateQuestionType('rating'))
      yield put(npsActions.updateError(res.message))
    }
  } catch (e: any) {
    yield put(npsActions.updateQuestionType('rating'))
    yield put(
      npsActions.updateError('Something went wrong. Please try after sometime'),
    )
  }
}

function* submitForm(action: AnyAction): Generator<any, any, any> {
  yield put(npsActions.updateQuestionType('loading'))
  try {
    const res = yield call(npsApis.submitForm, action.payload)
    if (res.success) {
      if (action.payload?.isSkipped) {
        yield put(npsActions.updateNpsStatus(false))
      } else {
        yield put(npsActions.updateQuestionType('thankYou'))
      }
    } else {
      yield put(npsActions.updateQuestionType('suggestionForm'))
      yield put(npsActions.updateError(res.message))
    }
  } catch (e: any) {
    yield put(npsActions.updateQuestionType('suggestionForm'))
    yield put(
      npsActions.updateError('Something went wrong. Please try after sometime'),
    )
  }
}

function* npsSaga() {
  yield takeLatest('FETCH_NPS_STATUS', fetchNpsStatus)
  yield takeLatest('SUBMIT_NPS_RATINGS', submitRatings)
  yield takeLatest('SUBMIT_NPS_FORM', submitForm)
  yield takeLatest('FETCH_FAST_TRACK_NPS_STATUS', fetchFastTrackNpsStatus)
}

export default npsSaga
