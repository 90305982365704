const nonVisibleTags = ["counselling_stage", "ielts_stage"]
import { sendCustomGaEvent } from "ga"
export const getTags = (tags: any[]) => {
  let arr: any = []
  arr = tags
    .filter(
      (item: any) => item.visible && !nonVisibleTags.includes(item.tag?.type),
    )
    .map((item: any) => item.tag?.Name)
  return arr
}

export const segmentEvents = {
  OTP_ERROR: "OTP Error",
}

export const handleFormSubmissionGaEventForm = (
  userPayload: Record<string, any>,
  eventPayload: Record<string, any>,
) => {
  const userProps = {
    preferred_country: userPayload.preferredCountry,
    preferred_intake: userPayload.preferredIntake,
    preferred_program: userPayload.preferredProgram,
    budget: userPayload.budget,
    admit_status: userPayload.admitStatus,
    highest_level_education: userPayload.highestLevelEducation,
    grades: userPayload.grades,
    grade_metric: userPayload.gradeMetric,
    ielts_status: userPayload.ieltsStatus,
    ielts_score: userPayload.ieltsScore,
    toefl_score: userPayload.toeflScore,
    pay_of_expenses: userPayload.payOfExpenses,
    highest_education_passout_year: userPayload.highestEducationPassoutYear,
    ielts_user_type: userPayload.ieltsUserType,
    how_leap_help: userPayload.howLeapHelp,
    passport_status: userPayload.passportStatus,
    lead_stage: userPayload.stage,
  }
  const eventProps = {
    form_type: eventPayload.formType,
    form_id: eventPayload.formId,
  }
  sendCustomGaEvent("Form Filled", eventProps, userProps)
}

export const handleQualifiedForProductGaEvent = (
  userPayload: Record<string, any>,
  eventPayload: Record<string, any>,
) => {
  const userProps = {
    lead_stage: userPayload.leadStage,
  }
  const eventProps = {
    event_category: eventPayload.eventCategory,
    product_category: eventPayload.productCategory,
    qualification_type: eventPayload.qualificationType,
    reason_for_disqualification: eventPayload.reasonForDisqualification,
  }
  sendCustomGaEvent("Qualified for Product", eventProps, userProps)
}
