import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  ReferalData,
  ReferalState,
} from "Interfaces/reducerTypes/referalSchema"

export const ieltsReferalStateName = "ieltsReferal"

const initialState: ReferalState = {
  error: "",
}

export const postReferalData = createAction<{
  data: ReferalData
  callBack: () => void
}>(`${ieltsReferalStateName}/postReferalData`)

const IeltsReferalSlice = createSlice({
  name: ieltsReferalStateName,
  initialState,
  reducers: {
    setReferalError(state, action: PayloadAction<string>) {
      return {
        ...state,
        error: action.payload,
      }
    },
  },
})
export const { setReferalError } = IeltsReferalSlice.actions

export default IeltsReferalSlice.reducer
