import { UpcomingType } from 'components/Ielts/UpcomingIeltsBatches/redux/UpcomingIeltsActions'
import { WorkshopSchema } from 'Interfaces/reducerTypes/IeltsPlusSchema'
import { IeltsBatchesSchema } from 'Interfaces/reducerTypes/UpcomingIeltsBatches'
import { HYDRATE } from 'next-redux-wrapper'
import { AnyAction } from 'redux'
import { sortBatches } from 'utils/ielts_batch_sort_utils'
import { isPremiumBatch } from 'utils/ielts_batch_utils'

const initialState: IeltsBatchesSchema = {
  upcomingIeltsBatchesData: [],
  foundationBatch: [],
  premiumBatchData: [],
  plusBatchData: [],
  ieltsSelectBatches: [],
  ieltsEliteBatches: [],
  loading: false,
  errorMessage: '',
  nudgeOffer: {},
  isUpcomingIeltsBatchModalOpen: false,
  nextBatchPriceAndTiming: null,
  allIeltsBatches: null,
  batchesBannerData: null,
  isOfferModalOpen: false,
}

const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      const upcomingIeltsBatchesData =
        action.payload.upcomingIeltsBatches.upcomingIeltsBatchesData
      const filteredPremiumBatchData = upcomingIeltsBatchesData.filter(
        (data: WorkshopSchema) => isPremiumBatch(data),
      )
      const ieltsSelectBatches = upcomingIeltsBatchesData
      const ieltsEliteBatches = upcomingIeltsBatchesData

      return {
        ...state,
        upcomingIeltsBatchesData:
          action.payload.upcomingIeltsBatches.upcomingIeltsBatchesData,
        nudgeOffer: action.payload.upcomingIeltsBatches.nudgeOffer,
        premiumBatchData: filteredPremiumBatchData,
        ieltsSelectBatches,
        ieltsEliteBatches,
      }
    case UpcomingType.UPCOMING_IELTS_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      }
    case UpcomingType.UPCOMING_IELTS_FETCH_SUCCESS:
      const newBatchList = sortBatches(action.payload)
      const premiumBatchData = newBatchList.filter((item) =>
        isPremiumBatch(item),
      )
      const selectBatch = newBatchList
      const eliteBatch = newBatchList
      return {
        ...state,
        loading: false,
        upcomingIeltsBatchesData: newBatchList,
        premiumBatchData: premiumBatchData,
        ieltsSelectBatches: selectBatch,
        ieltsEliteBatches: eliteBatch,
      }
    case UpcomingType.IELTS_FOUNDATION_BATCH_FETCH_REQUESTED:
      return {
        ...state,
        loading: true,
      }

    case UpcomingType.IELTS_FOUNDATION_BATCH_FETCH_SUCCESS:
      const newFoundationBatchList = sortBatches(action.payload)
      return {
        ...state,
        loading: false,
        foundationBatch: newFoundationBatchList,
      }
    case UpcomingType.IELTS_PREMIUM_BATCH_FETCH_SUCCESS:
      const newPremiumBatchList = sortBatches(action.payload)
      return {
        ...state,
        loading: false,
        premiumBatchData: newPremiumBatchList,
      }
    case UpcomingType.IELTS_PLUS_BATCH_FETCH_SUCCESS:
      const newPlusList = sortBatches(action.payload)
      return {
        ...state,
        loading: false,
        plusBatchData: newPlusList,
      }
    case UpcomingType.NUDGE_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        nudgeOffer: action.payload,
      }
    case UpcomingType.UPCOMING_IELTS_FETCH_FAIL:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      }
    case UpcomingType.TOGGLE_UPCOMING_IELTS_BATCHES_MODAL:
      return {
        ...state,
        isUpcomingIeltsBatchModalOpen: action.payload,
      }
    case UpcomingType.FETCH_GET_UPCOMING_BATCH_TIME_AND_PRICE_SUCCESS:
      return {
        ...state,
        nextBatchPriceAndTiming: action.payload,
      }
    case UpcomingType.SET_ALL_IELTS_BATCHES:
      return {
        ...state,
        allIeltsBatches: action.payload,
      }
    case UpcomingType.SET_BATCHES_BANNER_DATA:
      return {
        ...state,
        batchesBannerData: action.payload,
      }
    case UpcomingType.TOGGLE_OFFER_MODAL:
      return {
        ...state,
        isOfferModalOpen: action.payload,
      }
    default:
      return state
  }
}

export default reducer
