import { InfoAlert } from '@leap/ui/Alert'
import { useOnlineStatus } from 'hooks/useOnlineStatus'
import { FunctionComponent } from 'react'

const InternetCheckAlertBox: FunctionComponent = () => {
  const isOnline = useOnlineStatus()
  return (
    <InfoAlert
      type="danger"
      icon="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABHNCSVQICAgIfAhkiAAAAjFJREFUWEfNmP1RwkAQxUkFWgJUIFRg6MAOhAqECowVaAeGDuzAWIFYgdiBVhDfw5AJyX3s3uYPb4bJGPbe/rJ32ceZTf7ZyCw8dV3fYP4tPnmjU+G6y7LsJVU3GQgwz0i68iQuAbVOgUoCAswGyR4jCbeAetJCqYEAc4kkn/jwGhrf+HIGKF7FIwWIT30nzPAAoEIYewxTAaE606Y6mhys0kE6QQtUQphvlWbwrVtJJ4iBUJ0coq9S4V7cAlB7yVwNEGEI5Rpvzc1rz/cVgJajAUWqs0YyLuUEcVwa9ifXWCKuikGJKoRE7xCau8SQ5EwDsbUn6R6hCzNQ5KknCiCytNX0gUUrBCA2walXQF4hShwQPgtVKQgEmAKT74MCOiBKBZulF0hqEcolI1DQUkJA0epQPQEoWCUnkMYiEoEI5bQUH1CJCSKLMAA5LWUAhOqw37DviIYBiPoDS3EBhSxiAGkEGlhKv8vmyKgyUCMQH/DMUvpAXosYqTG6ZM6q1ALFLCKwoaTmGtqTrUYXKGgRkR1+OvbwWJQyWks5AkksIiWLcs7xlJJJLSIivmu+F/Uuj9afpYxQnfYtMf7MJeeWQOo3q/uEI7z2Xbk9gXy/8KRboPUkjQd62whEuHYX0uyOuAPuFc19XqcGrS9WqISAZTMa8g+m7ghEM62MVRoD6gci81MfWuEPntktS2eBIsyGx6lup+bacw+wYlcWdcXcD8TyRFsAhntR988GRaLk0F8Ph/r+6c8wkQAAAABJRU5ErkJggg=="
      text="Please check your internet connection."
      isOpen={!isOnline}
    />
  )
}
export default InternetCheckAlertBox
