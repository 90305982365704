import axios, { AxiosRequestConfig } from "axios"
import { getRedirectLoginPath } from "utils/networkUtils"

// Add a request interceptor
const api = axios.create()
api.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (
      (error.response?.status === 403 || error.response?.status === 401) &&
      typeof window !== "undefined"
    ) {
      api
        .get("/api/logout")
        .then((res) => {
          const loginRedirect = getRedirectLoginPath()
          window.open(loginRedirect, "_self")
        })
        .catch((err) => Promise.reject(err))
    } else return Promise.reject(error)
  },
)

export type ApiRequestConfig = AxiosRequestConfig

export default api
