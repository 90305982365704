import { AnyAction } from "redux";
import cloneDeep from "lodash/cloneDeep";
import {
  FacultySchema,
  TeacherSchema,
} from "Interfaces/reducerTypes/TeacherSchema";
import { HYDRATE } from "next-redux-wrapper";

const initialState: FacultySchema = {
  facultyData: [],
  error: "",
};

const reducer = (state: FacultySchema = initialState, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        facultyData: action.payload.ieltsFaculty.facultyData,
      };
    case "FETCH_FACULTIES_SUCCESS":
      const visibleOnHomePage = action.payload.filter(
        (data: TeacherSchema) => data.visibleOnHomePage
      );
      const dataInreqFormat = visibleOnHomePage.reduce((a: any, c: any) => {
        const newObj = cloneDeep(c);
        newObj.headerText = `${newObj.yearsOfExperience}+ years of experience`;
        newObj.bodyText = newObj.description;
        newObj.footerText = newObj.shortDescription;
        newObj.image = newObj.mobileImage;
        a.push(newObj);
        return a;
      }, []);
      return {
        ...state,
        facultyData: dataInreqFormat,
      };
    case "FETCH_FACULTIES_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
