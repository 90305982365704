export default {
    FETCH_USER_DATA_FOR_PAYMENT: "FETCH_USER_DATA_FOR_PAYMENT",
    FETCH_USER_DATA_FOR_PAYMENT_SUCCESS: "FETCH_USER_DATA_FOR_PAYMENT_SUCCESS",
    FETCH_USER_DATA_FOR_PAYMENT_ERROR: "FETCH_USER_DATA_FOR_PAYMENT_ERROR",

    FETCH_PAYMENT_DATA: "FETCH_PAYMENT_DATA",
    FETCH_PAYMENT_DATA_SUCCESS: "FETCH_PAYMENT_DATA_SUCCESS",
    FETCH_PAYMENT_DATA_ERROR: "FETCH_PAYMENT_DATA_ERROR",

    VALIDATE_PROMO_CODE: "VALIDATE_PROMO_CODE",
    VALIDATE_PROMO_CODE_SUCCESS: "VALIDATE_PROMO_CODE_SUCCESS",
    VALIDATE_PROMO_CODE_ERROR: "VALIDATE_PROMO_CODE_ERROR",

    // APPLY_PROMO_CODE:"APPLY_PROMO_CODE",

    INITIALIZE_PAYMENT: "INITIALIZE_PAYMENT",
    INITIALIZE_PAYMENT_SUCCESS: "INITIALIZE_PAYMENT_SUCCESS",
    INITIALIZE_PAYMENT_ERROR: "INITIALIZE_PAYMENT_ERROR",
  };
  