import {
  extraClassActions,
  ExtraClassType,
} from "components/Profile/IeltsProfile/ExtraClass/redux/ExtraClassActions"
import { extraClassApi } from "components/Profile/IeltsProfile/ExtraClass/redux/ExtraClassApi"
import { upcomingClassActions } from "components/Profile/IeltsProfile/UpcomingClass/redux/UpcomingClassActions"
import { batchType } from "DataMapper/Common/WorkshopData"
import { WorkshopSchema } from "Interfaces/reducerTypes/IeltsPlusSchema"
import { State } from "Interfaces/storeSchema"
import { navRoutes } from "page_routes"
import { AnyAction } from "redux"
import { call, put, select, takeLatest } from "redux-saga/effects"
import { masterclassActions } from "../../Masterclass/redux/MasterclassActions"

const getUpcomingClass = (state: State) =>
  state.upcomingClass.upcomingExtraClass

const getPostAction = (state: State) => state.extraClass.postAction

function* notifyUserForExtraClass(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(extraClassApi.notifyUser, action.payload)
    if (res.success) {
      if (action.payload.workshopType === batchType.ORIENTATION_WORKSHOP) {
        yield put(extraClassActions.toggleHasJoinedOrientation(true))
      } else {
        const upcomingExtraClass: Array<WorkshopSchema> = yield select(
          getUpcomingClass,
        )
        yield put(
          upcomingClassActions.fetchUpcomingextraClassSuccess(
            upcomingExtraClass.map((data) =>
              data.batchId == action.payload.batchId
                ? { ...data, isEnrolled: true }
                : data,
            ),
          ),
        )
        const postAction = yield select(getPostAction)
        if (postAction) {
          postAction()
        } else {
          yield put(extraClassActions.notifyUserForExtraClassSuccess(res.data))
        }
        yield put(masterclassActions.fetchMasterclassData())
        yield put(masterclassActions.fetchBeginnerMasterclass())
      }
    } else {
      yield put(extraClassActions.notifyUserForExtraClassFail(res.data))
      window.open(`${navRoutes.IELTS_STUDENT_DASHBOARD}`, "_self")
    }
  } catch (e: any) {
    console.error(e)
    yield put(extraClassActions.notifyUserForExtraClassFail(e.message))
    throw new Error(e.message)
  }
}

function* fetchEnrolledExtraClass(): Generator<any, any, any> {
  try {
    const res = yield call(extraClassApi.fetchEnrolledExtraClass)
    if (res.success) {
      yield put(extraClassActions.fetchEnrolledExtraClassSuccess(res.data))
    } else {
      yield put(extraClassActions.fetchEnrolledExtraClassFail(res.data))
    }
  } catch (e: any) {
    console.error(e)
    yield put(extraClassActions.fetchEnrolledExtraClassFail(e.message))
    throw new Error(e.message)
  }
}

function* noticeBoardSaga() {
  yield takeLatest(
    ExtraClassType.NOTIFY_USER_FOR_EXTRA_CLASS,
    notifyUserForExtraClass,
  )
  yield takeLatest(
    ExtraClassType.FETCH_ENROLLED_EXTRA_CLASS_REQ,
    fetchEnrolledExtraClass,
  )
}

export default noticeBoardSaga
