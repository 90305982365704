import api from "service/api"
import { NotifyUserForExtraClassPayload } from "components/Profile/IeltsProfile/ExtraClass/redux/ExtraClassActions"
import { batchType } from "DataMapper/Common/WorkshopData"

const notifyUser = async (payload: NotifyUserForExtraClassPayload) => {
  try {
    let res
    if (payload.workshopType == batchType.ORIENTATION_WORKSHOP) {
      res = await api.post("/api/scholarRoute?path=api/enrollment/enroll", {
        batchId: payload.batchId,
        workshopType: payload.workshopType,
      })
    } else
      res = await api.post(
        `/api/scholarRoute?path=workshop/enroll/${payload.batchId}/${payload.workshopType}`,
      )
    if (res.data.success) {
      return { success: true, data: res.data.data }
    } else {
      return { success: false, data: res.data.message }
    }
  } catch (err: any) {
    return { success: false, data: "Something went wrong" }
  }
}

const fetchEnrolledExtraClass = async () => {
  try {
    const res = await api.get(
      `/api/scholarRoute?path=workshop/enroll/my/all_extra_classes`,
    )
    if (res.data.success) {
      return { success: true, data: res.data.data }
    } else {
      return { success: false, data: res.data.message }
    }
  } catch (err: any) {
    return { success: false, data: "Something went wrong" }
  }
}

export const extraClassApi = {
  notifyUser,
  fetchEnrolledExtraClass,
}
