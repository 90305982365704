import { AnyAction } from "redux";

const initialState: any = {
  studyMaterialData: null,
  error: "",
};

// create your reducer
const reducer = (state: any = initialState, action: AnyAction) => {
  switch (action.type) {
    case "STUDY_MATERIAL_FETCH_SUCCESS":
      return {
        ...state,
        studyMaterialData: action.payload,
      };
    case "STUDY_MATERIAL_FETCH_FAIL":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
