import { ieltsScreenApi } from "components/Profile/StudentDashbord/IeltsScreenController/redux/ieltsSceenApi"
import { AnyAction } from "redux"
import { call, put, takeLatest } from "redux-saga/effects"
import { ieltsScreenControllerActions } from "./IeltsScreenControllerActions"

function* fetchIeltsUserStates(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsScreenApi.fetchIeltsUserStates)
    if (res?.data) {
      yield put(ieltsScreenControllerActions.setUserStates(res.data))
    } else {
      yield put(ieltsScreenControllerActions.setUserStates(null))
    }
  } catch (e: any) {
    console.error(e)
    yield put(ieltsScreenControllerActions.setUserStates(null))
  }
}
function* fetchDemoBookingDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(ieltsScreenApi.fetchDemoDetails, action.payload)
    if (res.data.success) {
      yield put(
        ieltsScreenControllerActions.setDemoDetails(res.data.data[0] ?? null),
      )
    } else {
      yield put(ieltsScreenControllerActions.setDemoDetails(null))
    }
  } catch (e: any) {
    console.error(e)
    yield put(ieltsScreenControllerActions.setDemoDetails(null))
  }
}

function* fetchUserIeltsDetailsSaga(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsScreenApi.fetchIeltsUserDetails)
    if (res.data.success) {
      yield put(
        ieltsScreenControllerActions.fetchUserIeltsDetailsSuccess(
          res.data.data,
        ),
      )
    }
  } catch (e) {}
}

function* fetchUserPaymentInfoSaga(
  action: AnyAction,
): Generator<any, any, any> {
  try {
    const res = yield call(ieltsScreenApi.fetchIeltsPaymentInfo, action.payload)
    if (res.data.success) {
      yield put(
        ieltsScreenControllerActions.fetchUserPaymentInfoSuccess(res.data.data),
      )
    }
  } catch (e) {}
}

function* fetchIeltsStageSaga(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsScreenApi.fetchIeltsEliteState)
    if (res.data.success) {
      yield put(
        ieltsScreenControllerActions.fetchIeltsEliteStateSuccess(
          res.data.data.userStage,
        ),
      )
    }
  } catch (e) {
    console.log("err", e)
  }
}

function* fetchI2CEligibilitySaga(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsScreenApi.fetchI2CEligibility)
    if (res.data.success) {
      yield put(
        ieltsScreenControllerActions.fetchUserI2CEligibilitySuccess(
          res.data.data,
        ),
      )
    }
  } catch (e) {
    console.log("err", e)
  }
}

function* ieltsScreenSaga() {
  yield takeLatest("FETCH_USER_STATES", fetchIeltsUserStates)
  yield takeLatest("FETCH_DEMO_DETAILS", fetchDemoBookingDetails)
  yield takeLatest(
    "FETCH_USER_IELTS_DETAILS_REQUEST",
    fetchUserIeltsDetailsSaga,
  )
  yield takeLatest("FETCH_USER_PAYMENT_INFO", fetchUserPaymentInfoSaga)
  yield takeLatest("FETCH_USER_IELTS_ELITE_STAGE", fetchIeltsStageSaga)
  yield takeLatest(
    "FETCH_USER_I2C_ELIGIBILITY_REQUEST",
    fetchI2CEligibilitySaga,
  )
}

export default ieltsScreenSaga
