import { communityAction } from 'components/CounsellingCommunity/redux/CommunityAction'
import { communityApi } from 'components/CounsellingCommunity/redux/CommunityApis'
import CommunityConstants from 'components/CounsellingCommunity/redux/CommunityConstants'
import { call, put, takeLatest } from 'redux-saga/effects'

function* getUserDateAndToken(): Generator<any, any, any> {
  try {
    const res = yield call(communityApi.fetchUserDataAndToken)
    if (res) {
      yield put(communityAction.fetchUserGetSocialTokenSuccess(res))
    } else {
      yield put(
        communityAction.fetchUserGetSocialTokenFail(
          'Something went wrong. redirecting you to profile page'
        )
      )
    }
  } catch (e) {
    yield put(
      communityAction.fetchUserGetSocialTokenFail(
        'Something went wrong. redirecting you to profile page'
      )
    )
  }
}

function* CommunitySaga() {
  yield takeLatest(
    CommunityConstants.FETCH_USER_GETSOCIAL_TOKEN,
    getUserDateAndToken
  )
}

export default CommunitySaga
