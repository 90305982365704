import { toast } from 'react-toastify'
import { call, put, takeLatest } from 'redux-saga/effects'
import { MockTestPriceApi } from './MockTestPriceApi'
import {
  fetchMockTestAutoLogin,
  fetchMockTestStatus,
  fetchTcyMockTestBannerDetailsAction,
  setAutoLoginUrl,
  setCtaText,
  setMockTestPrice,
  setReserveSeat,
  setReserveSeatErrorMessage,
  setReserveSeatLoading,
  setTcyMocktestStatus,
  setTycBannerData,
} from './MockTestPriceSlice'
export interface ResponseGenerator {
  config?: any
  data?: any
  headers?: any
  request?: any
  status?: number
  statusText?: string
  success?: boolean
}
function* fetchMockTestAutoLoginSaga(): Generator<any, any, any> {
  try {
    const res = yield call(MockTestPriceApi.fetchMockTestAutoLogin)
    if (res.success) {
      setAutoLoginUrl(res.data?.autologinUrl)
      res.data?.autologinUrl
        ? window.open(res.data?.autologinUrl)
        : toast.error('Something went wrong. Please try after some time')
    } else {
      yield put(setReserveSeatErrorMessage('something went wrong'))
    }
  } catch (error) {
    yield put(setReserveSeatErrorMessage('something went wrong'))
  }
}
function* getMockTestStatusSaga() {
  try {
    yield put(setCtaText(`Loading`))
    yield put(setReserveSeatLoading(true))
    const res: ResponseGenerator = yield call(
      MockTestPriceApi.getMockTestStatus,
    )
    if (res.success) {
      yield put(setReserveSeatLoading(false))
      yield put(setTcyMocktestStatus(res.data))
      if (res.data?.alreadyPaid) {
        yield put(setCtaText('Go to Test'))
        yield put(setReserveSeat(true))
      } else {
        yield put(setCtaText(`Buy Now ₹ ${res.data?.offerPrice}/-`))
        yield put(setMockTestPrice(res.data?.offerPrice))
        yield put(setReserveSeat(false))
      }
    } else {
      yield put(setCtaText(`Buy Now`))
      yield put(setReserveSeatLoading(false))
    }
  } catch (error) {
    yield put(setCtaText(`Buy Now`))
    yield put(setReserveSeatLoading(false))
    yield put(setReserveSeatErrorMessage('something went wrong'))
  }
}

function* getTycBannerDataSaga(): Generator<any, any, any> {
  try {
    yield put(setReserveSeatLoading(true))
    const res: ResponseGenerator = yield call(
      MockTestPriceApi.getTcyBannerStatus,
    )
    if (res.success) {
      yield put(setTycBannerData(res.data))
    } else {
      yield put(setReserveSeatErrorMessage(res.data))
    }
  } catch (error) {
    yield put(setReserveSeatErrorMessage('something went wrong'))
  } finally {
    yield put(setReserveSeatLoading(false))
  }
}
function* MockTestPriceSaga() {
  yield takeLatest(fetchMockTestAutoLogin, fetchMockTestAutoLoginSaga)
  yield takeLatest(fetchMockTestStatus, getMockTestStatusSaga)
  yield takeLatest(fetchTcyMockTestBannerDetailsAction, getTycBannerDataSaga)
}
export default MockTestPriceSaga
