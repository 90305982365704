import api from "service/api"

const fetchUpcomingAppointmentsRequest = async () => {
  try {
    const res = await api.get(`/api/scholarRoute?path=profile/post/events`)
    return res
  } catch (err: any) {
    throw new Error("Something went wrong. Please refresh the page")
  }
}

export const upcomingAppointmentsApi = {
  fetchUpcomingAppointmentsRequest,
}
