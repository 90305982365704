import { put, takeLatest, call } from "@redux-saga/core/effects";
import { registrationFormAction } from "components/Profile/RegistrationForm/redux/RegistrationFormActions";
import { registrationFormApi } from "components/Profile/RegistrationForm/redux/RegistrationFormApi";

function* fetchInitialFormValues(): Generator<any, any, any> {
  try {
    const res = yield call(registrationFormApi.fetchInitialFormValues);
    if (res.success) {
      yield put(registrationFormAction.fetchInitialFormValuesSuccess(res.data));
    } else {
      yield put(registrationFormAction.updateError(res.message));
    }
  } catch (err:any) {
    yield put(registrationFormAction.updateError("Something went wrong. Please try after sometime"));
  }
}

function* registrationFormSaga() {
  yield takeLatest("FETCH_INITIAL_FORM_VALUES_REQUESTED", fetchInitialFormValues);
}

export default registrationFormSaga;