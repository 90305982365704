import gql from "graphql-tag";

export const FETCH_IELTS_QUIZ = gql`
  query ($slugArray: [String]) {
    ieltsQuizs(where: { id: $slugArray }) {
      id
      Title
      Icon {
        url
      }
      question {
        ielts_question {
          id
          QuestionDescription
          Instruction
          ExplanationOfAnswers
          Marks
          NegativeMarking
          QuestionType
          Option {
            id
            option_description
            is_correct
          }
        }
      }
    }
  }
`;

export const FETCH_QUIZ_DETAILS = gql`
  query ($slugId: String) {
    ieltsQuizs(where: { id: $slugId }) {
      id
      Title
      question {
        ielts_question {
          id
          QuestionDescription
          Instruction
          ExplanationOfAnswers
          Marks
          NegativeMarking
          QuestionType
          Option {
            id
            option_description
            is_correct
          }
        }
      }
    }
  }
`;
