import { initRazorpayPaymentRequest } from "lib/Razorpay/razorpay";
import { initDefaultPaymentGateway } from "lib/Razorpay/paymentGateWay";
import { PaymentType } from "Interfaces/common/PaymentSchema";
import { priceBundleName } from "DataMapper/Common/WorkshopData";
export const getCounsellingFee = async () => {
  const paymentInfo = {
    paymentType: "COUNSELLING_FEE",
    formDescription: "Get LeapScholar Plus access",
  };
  const _p = await initRazorpayPaymentRequest(paymentInfo);
  console.log("razorpay module result = ", _p);
  if (_p != undefined) {
    return _p;
  }
};

export const getIeltsFee = async (paymentType: string | null) => {
  let newPaymentType;
  if (paymentType === "IELTS_PLUS_PREMIUM") {
    newPaymentType = PaymentType.IELTS_PREMIUM_FEE;
  } else if (paymentType === priceBundleName.IELTS_PLUS_PREMIUM_2) {
    newPaymentType = PaymentType.IELTS_PREMIUM_2_FEE;
  } else if (paymentType === priceBundleName.IELTS_PLUS_PREMIUM_3) {
    newPaymentType = PaymentType.IELTS_PREMIUM_3_FEE;
  } else if (paymentType === priceBundleName.IELTS_PLUS_PART_2) {
    newPaymentType = PaymentType.IELTS_PLUS_PART_2_FEE;
  } else {
    newPaymentType = "IELTS_FEE";
  }
  const paymentInfo = {
    paymentType: newPaymentType,
    formDescription: "Get IELTS Plus access",
    amount: 99,
  };
  const _p = await initDefaultPaymentGateway(paymentInfo);
  console.log("razorpay module result = ", _p);
  if (_p != undefined) {
    return _p;
  }
};

export const getFastTrackFee = async (amount: number, currency?: string) => {
  const paymentInfo = {
    paymentType: "FAST_TRACK_FEE",
    formDescription: "Get Fast Track access",
    currency,
    amount,
  };
  const _p = await initDefaultPaymentGateway(paymentInfo);
  console.log("razorpay module result = ", _p);
  if (_p != undefined) {
    return _p;
  }
};

export const getSpeakingClubFee = async (amount: number, currency?: string) => {
  const paymentInfo = {
    paymentType: "SPEAKING_CLUB_FEE",
    formDescription: "Get Speaking Club slots access",
    currency,
    amount,
  };
  const speakingClubPayment = await initDefaultPaymentGateway(paymentInfo);
  if (speakingClubPayment != undefined) {
    return speakingClubPayment;
  }
};

export const getMockTestFee = async (
  amount: number,
  currency?: string,
  promoCode?: { name: string; discount: number }
) => {
  const paymentInfo: any = {
    paymentType: PaymentType.TCY_MOCK_TEST_FEE,
    formDescription: "Get Mock Tests slots access",
    currency,
    amount,
  };
  if (promoCode) {
    paymentInfo["promoCode"] = promoCode;
  }
  const mockTestsPayment = await initDefaultPaymentGateway(paymentInfo);
  if (mockTestsPayment != undefined) {
    return mockTestsPayment;
  }
};
