import { getCookie } from "utils/cookie-utiils"
import { isMobile } from "react-device-detect"
import { navRoutes } from "page_routes"

export const getFlowType = (url: string) => {
  const queryStrings = url.split("?")[1]
  if (queryStrings) {
    const flowType = queryStrings.split("&")[0]
    if (flowType) {
      return flowType.split("=")[1]
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export const checkIeltsRoute = (): boolean => {
  if (window.location.pathname.match("/ielts-preparation-online")) {
    return true
  } else {
    return false
  }
}

export function youtubeParser(url: string) {
  try {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(regExp)
    return match && match[7].length == 11 ? match[7] : ""
  } catch (e) {
    return ""
  }
}

export const getIeltsMockTestUrl = (reqUrl: string) => {
  const mockTestBasePath = process.env.MOCK_TEST_URL
  const token = getCookie(null, "token")
  const userId = getCookie(null, "user_id")
  const source = isMobile ? "Mweb" : "WEB"
  return `${mockTestBasePath}/redirectPageV2?token=${token}&userId=${userId}&requrl=${reqUrl}&source=${source}`
}

export const getRedirectUrl = (data: {
  reqUrl: string
  token: string
  source?: string
  userId?: string
  stage?: string
}) => {
  let redirectPage = navRoutes.REDIRECT_PAGE + "?"
  if (data.reqUrl) {
    redirectPage += `requrl=${data.reqUrl}`
  }
  if (data.token) {
    redirectPage += `&token=${data.token}`
  }
  if (data.source) {
    redirectPage += `&source=${data.source}`
  }
  if (data.userId) {
    redirectPage += `&userId=${data.userId}`
  }
  if (data.stage) {
    redirectPage += `&stage=${data.stage}`
  }
  return redirectPage
}
