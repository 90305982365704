import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ReactNode } from "react"
export interface TcyBannerDto {
  showBanner: boolean
  userEmail?: string
  token?: string
  autologinUrl?: string // for android app URL, for web weblink
}
export interface MockTestPriceInitState {
  status: boolean | null
  isLoading: boolean
  error: string
  reservedTheSeat: null | boolean
  mockTestPrice: null | number
  ctaText: string | ReactNode
  autoLoginUrl: null | string
  tcyMocktestStatus: any
  tcyBannerDetails: null | TcyBannerDto
}

const initialState: MockTestPriceInitState = {
  status: null,
  isLoading: false,
  error: "",
  reservedTheSeat: null,
  mockTestPrice: null,
  ctaText: "Buy Now",
  autoLoginUrl: null,
  tcyMocktestStatus: null,
  tcyBannerDetails: null,
}
const MockTestPrice = createSlice({
  name: "mockTestPrice",
  initialState,
  reducers: {
    setReserveSeat(state, action: PayloadAction<boolean>) {
      return { ...state, reservedTheSeat: action.payload }
    },
    setReserveSeatErrorMessage(state, action: PayloadAction<string>) {
      return { ...state, error: action.payload }
    },
    setReserveSeatLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload }
    },
    setMockTestPrice(state, action: PayloadAction<number>) {
      return { ...state, mockTestPrice: action.payload }
    },
    setCtaText(state, action: PayloadAction<string | ReactNode>) {
      return { ...state, ctaText: action.payload }
    },
    setAutoLoginUrl(state, action: PayloadAction<string>) {
      return { ...state, autoLoginUrl: action.payload }
    },
    setTcyMocktestStatus(state, action: PayloadAction<any>) {
      return { ...state, tcyMocktestStatus: action.payload }
    },
    setTycBannerData(state, action: PayloadAction<null | TcyBannerDto>) {
      return { ...state, tcyBannerDetails: action.payload }
    },
  },
})
export const fetchMockTestAutoLogin = createAction(
  "mockTestPrice/fetchMockTestAutoLogin",
)

export const fetchMockTestStatus = createAction(
  "mockTestPrice/fetchMockTestStatus",
)
export const fetchTcyMockTestBannerDetailsAction = createAction(
  "mockTestPrice/fetchTcyMockTestBannerDetails",
)

export const {
  setReserveSeat,
  setReserveSeatErrorMessage,
  setReserveSeatLoading,
  setCtaText,
  setMockTestPrice,
  setAutoLoginUrl,
  setTcyMocktestStatus,
  setTycBannerData,
} = MockTestPrice.actions
export default MockTestPrice.reducer
