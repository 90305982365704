import { AnyAction } from "redux"
import { call, put, select, takeLatest } from "redux-saga/effects"
import { classRecordingsApi } from "components/Profile/IeltsProfile/ClassRecordings/redux/ClassRecordingsApi"
import { classRecordingsActions } from "components/Profile/IeltsProfile/ClassRecordings/redux/ClassRecordingsActions"
import { getClassRecordingPayload } from "components/Profile/IeltsProfile/ClassRecordings/classRecordingUtil"

function* fetchData(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(classRecordingsActions.setRecordingsLoading(true))
    const res = yield call(classRecordingsApi.fetchRecordings, action.payload)
    let modifiedRes = null
    if (res) {
      if (!res.playList) {
        res.playList = []
      }
      modifiedRes = {
        ...res,
        playList: [
          {
            id: res.id,
            title: res.title,
            url: res.url,
            imageUrl: res.imageUrl,
            createdAt: res.createdAt,
            batchClassTopic: res?.batchClassTopic,
          },
          ...res.playList,
        ],
      }
    }
    yield put(classRecordingsActions.fetchRecordingsSuccess(modifiedRes))
    yield put(classRecordingsActions.setRecordingsLoading(false))
  } catch (e: any) {
    console.error(e)
    yield put(classRecordingsActions.fetchRecordingsFail(e.message))
    yield put(classRecordingsActions.setRecordingsLoading(false))
  }
}

function* fetchVideo(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(classRecordingsApi.fetchVideo, action.payload)
    let modifiedRes = null
    if (res) {
      if (!res.playList) {
        res.playList = []
      }
      modifiedRes = {
        ...res,
        playList: [
          {
            id: res.id,
            title: res.title,
            url: res.url,
            imageUrl: res.imageUrl,
            createdAt: res.createdAt,
            batchClassTopic: res?.batchClassTopic,
          },
          ...res.playList,
        ],
      }
    }
    yield put(classRecordingsActions.fetchVideoSuccess(modifiedRes))
  } catch (e: any) {
    console.error(e)
    yield put(classRecordingsActions.fetchVideoFail(e.message))
  }
}

function* fetchSelfPrepDataSaga(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(classRecordingsActions.setRecordingsLoading(true))
    const res = yield call(classRecordingsApi.fetchSelfPrepRecordings)
    yield put(
      classRecordingsActions.fetchRecordingsSuccess(
        getClassRecordingPayload(res?.playList || null),
      ),
    )
    yield put(classRecordingsActions.setRecordingsLoading(false))
  } catch (e: any) {
    console.error(e)
    yield put(classRecordingsActions.setRecordingsLoading(false))
    yield put(classRecordingsActions.fetchRecordingsFail(e.message))
  }
}
function* fetchGrammarDataSaga(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(classRecordingsActions.setRecordingsLoading(true))
    const res = yield call(classRecordingsApi.fetchGrammarRecordings)
    yield put(
      classRecordingsActions.fetchRecordingsSuccess(
        getClassRecordingPayload(res?.playList || null),
      ),
    )
  } catch (e: any) {
    console.error(e)
    yield put(classRecordingsActions.fetchRecordingsFail(e.message))
  } finally {
    yield put(classRecordingsActions.setRecordingsLoading(false))
  }
}

function* fetchSelfPrepVideoSaga(action: AnyAction): Generator<any, any, any> {
  try {
    yield put(classRecordingsActions.setRecordingsLoading(true))
    const classRecordings = yield select(
      (state) => state.classRecordings.recordingData,
    )
    let modifiedRes = null
    const currentRecording = classRecordings.playList.find(
      (recording: any) => recording.id == action.payload,
    )
    if (classRecordings && currentRecording) {
      if (!classRecordings.playList) {
        classRecordings.playList = []
      }
      modifiedRes = {
        ...currentRecording,
        playList: classRecordings.playList,
      }
    }
    yield put(classRecordingsActions.fetchVideoSuccess(modifiedRes))
  } catch (e: any) {
    console.log(e)
    yield put(classRecordingsActions.fetchVideoFail(e.message))
  } finally {
    yield put(classRecordingsActions.setRecordingsLoading(false))
  }
}

function* recordingsSaga() {
  yield takeLatest("RECORDINGS_FETCH_REQUESTED", fetchData)
  yield takeLatest("VIDEO_FETCH_REQUESTED", fetchVideo)
  yield takeLatest(
    "SELF_PREP_RECORDINGS_FETCH_REQUESTED",
    fetchSelfPrepDataSaga,
  )
  yield takeLatest("GRAMMAR_RECORDINGS_FETCH_REQUESTED", fetchGrammarDataSaga)
  yield takeLatest("SELF_PREP_UPDATE_RECORDINGS", fetchSelfPrepVideoSaga)
}

export default recordingsSaga
