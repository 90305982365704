import { call, put, takeLatest } from "@redux-saga/core/effects";
import { AnyAction } from "redux";
import { mockTestActions } from "./MockTestActions";
import { mockTestApi } from "./MockTestApi";

function* fetchMockTest(action: AnyAction): Generator<any, any, any> {
  try {
    let res = null;
    if (action.payload) {
      res = yield call(mockTestApi.fetchMockTest, action.payload);
    } else {
      res = yield call(mockTestApi.fetchFreeMockTest);
    }
    if (res.success) {
      yield put(mockTestActions.fetchMockTestSuccess(res.data));
    } else {
      yield put(mockTestActions.updateError(res.message));
    }
  } catch (e:any) {
    yield put(
      mockTestActions.updateError(
        "Something went wrong. Please try after sometime"
      )
    );
  }
}

function* fetchFreeMockTest(): Generator<any, any, any> {
  try {
    const res = yield call(mockTestApi.fetchSampleMockTest);
    if (res.success) {
      yield put(mockTestActions.fetchMockTestSuccess(res.data));
    } else {
      yield put(mockTestActions.updateError(res.message));
    }
  } catch (e:any) {
    yield put(
      mockTestActions.updateError(
        "Something went wrong. Please try after sometime"
      )
    );
  }
}

function* fetchEssayStatus(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(mockTestApi.fetchEssayStatus, action.payload);
    if (res.success) {
    } else {
      yield put(mockTestActions.updateError(res.message));
    }
  } catch (e:any) {
    yield put(
      mockTestActions.updateError(
        "Something went wrong. Please try after sometime"
      )
    );
  }
}

function* uploadWritingMockTest(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(mockTestApi.uploadWritingMockTest, action.payload);
    if (res.success) {
      yield put(mockTestActions.toggleUploadMockTestStatus(true));
    } else {
      yield put(mockTestActions.updateError(res.message));
    }
  } catch (e:any) {
    yield put(
      mockTestActions.updateError(
        "Something went wrong. Please try after sometime"
      )
    );
  }
}

function* fetchEssayList(): Generator<any, any, any> {
  try {
    const res = yield call(mockTestApi.fetchEssayList);
    if (res.success) {
      yield put(mockTestActions.fetchEssayListSuccess(res.data));
    } else {
      yield put(mockTestActions.updateError(res.message));
    }
  } catch (e:any) {
    yield put(
      mockTestActions.updateError(
        "Something went wrong. Please try after sometime"
      )
    );
  }
}

function* mockTestSaga() {
  yield takeLatest("FETCH_MOCK_TEST_REQUESTED", fetchMockTest);
  yield takeLatest("FETCH_ESSAY_STATUS_REQUESTED", fetchEssayStatus);
  yield takeLatest("UPLOAD_WRITING_MOCK_TEST", uploadWritingMockTest);
  yield takeLatest("FETCH_ESSAY_LIST_REQUESTED", fetchEssayList);
  yield takeLatest("FETCH_SAMPLE_MOCK_TEST_REQUESTED", fetchFreeMockTest);
}

export default mockTestSaga;
