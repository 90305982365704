import { fireTagEvent, logEvent } from "ga";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { semPageAction, actionConstant } from "./SemLandingAction";
import { semPageApi } from "./SemLandingApi";
import sha256 from "crypto-js/sha256";
import { navRoutes } from "page_routes";
import { trackIeltsForm } from "scripts/segment";

function* semDetailsHandler(): Generator<any, any, any> {
  try {
    const res = yield call(semPageApi.fetchSemPageDetails);
    const semDetails = res?.data?.semPages.map((data: any) => {
      return {
        ...data,
        bullet_points: JSON.parse(data.bullet_points),
      };
    });
    yield put(semPageAction.semPageDetailSuccess(semDetails));
  } catch (e: any) {
    yield put(
      semPageAction.updateError(
        "Something went wrong. Please try after sometime"
      )
    );
  }
}

function* saveSemDataHandler(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(semPageApi.saveSemForm, action.payload);
    if (res.success) {
      yield put(semPageAction.semSaveFormSuccess());
      if (res.data !== "ALREADY_REGISTERED") {
        trackIeltsForm("Form Filled SEM", {
          formType: "Masterclass SEM",
          pageName: action.path,
        });
        yield put(semPageAction.toggelModal(true));
      } else {
        try {
          window.open(
           navRoutes.POSTLOGIN_COUNSELLING,
            "_self"
          );
        } catch (_) {
          return;
        }
      }
      yield call(fireTagEvent, "masterclass_registered", {
        hashed_email: sha256(action.payload.emailId).toString(),
        user_email: action.payload.emailId,
        hashed_phone: sha256(action.payload?.phoneNumber || "").toString(),
      });
      logEvent("MasterClass Registered", "NA", "NA");
    }
  } catch (e) {
    console.error(e);
    yield put(
      semPageAction.updateError(
        "Something went wrong. Please try after sometime"
      )
    );
  }
}

function* semPageSaga() {
  yield takeLatest(actionConstant.SEM_PAGE_DETAIL_REQUEST, semDetailsHandler);
  yield takeLatest(actionConstant.SEM_SAVE_FORM_REQUEST, saveSemDataHandler);
}

export default semPageSaga;
