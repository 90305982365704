import { AnyAction } from "redux";
import { actionConstants } from "./UpdateProfileActions";
const initialState: any = {
  // all form data state
  personalData: {},
  addressData: {},
  passportData: {},
  backgroundData: {},
  academicData: {},
  emergencyContactData: {},
  examScoreData: {},
  educationData: [],
  refereeData: [],
  workExpData: [],
  // all option data state
  countryList: [],
  languageList: [],
  cityList: [],
  stateList: [],
  // process states
  loading: false,
  fetchError: {},
  saveError: {},
  // error and success toast are managed in saga
};

// create your reducer
const reducer = (state: any = initialState, action: AnyAction) => {
  // console.log("reducer action --->", action,state);
  switch (action.type) {
    // ---FETCH_PERSONAL_DATA START--- verified
    case "FETCH_PERSONAL_DATA":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_PERSONAL_DATA_SUCCESS":
      return {
        ...state,
        personalData: action.payload,
        loading: false,
      };
    case "FETCH_PERSONAL_DATA_FAIL":
      return {
        ...state,
        loading: false,
        fetchError: {
          personalData: true
        },
      };
    // ---FETCH_PERSONAL_DATA END---

    // ---SAVE_PERSONAL_DATA START--- verified
    case "SAVE_PERSONAL_DATA":
      return {
        ...state,
        saving: true,
      };
    case "SAVE_PERSONAL_DATA_SUCCESS":
      return {
        ...state,
        saving: false,
        saveError: {
          personalData: false
        },
      };
    case "SAVE_PERSONAL_DATA_FAIL":
      return {
        ...state,
        saveError: {
          personalData: true
        },
        saving: false,
      };
    // ---SAVE_PERSONAL_DATA END---

    // ---FETCH_ADDRESS_DATA START--- verified
    case "FETCH_ADDRESS_DATA":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_ADDRESS_DATA_SUCCESS":
      return {
        ...state,
        addressData: action.payload,
        loading: false,
      };
    case "FETCH_ADDRESS_DATA_FAIL":
      return {
        ...state,
        loading: false,
        fetchError: {
          addressData : true
        },
      };
    // ---FETCH_ADDRESS_DATA END---

    // ---SAVE_ADDRESS_DATA START--- verified
    case "SAVE_ADDRESS_DATA":
      return {
        ...state,
        saving: true,
      };
    case "SAVE_ADDRESS_DATA_SUCCESS":
      return {
        ...state,
        saving: false,
        saveError: {
          addressData : false
        },
      };
    case "SAVE_ADDRESS_DATA_FAIL":
      return {
        ...state,
        saving: false,
        saveError: {
          addressData : true
        },
      };
    // ---SAVE_ADDRESS_DATA END---

    // ---FETCH_PASSPORT_DATA START--- verified
    case "FETCH_PASSPORT_DATA":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_PASSPORT_DATA_SUCCESS":
      return {
        ...state,
        passportData: action.payload,
        loading: false,
      };
    case "FETCH_PASSPORT_DATA_FAIL":
      return {
        ...state,
        loading: false,
        fetchError: {
          passportData : true
        },
      };
    // ---FETCH_PASSPORT_DATA END---

    // ---SAVE_PASSPORT_DATA START---  verified
    case "SAVE_PASSPORT_DATA ":
      return {
        ...state,
        saving: true,
      };
    case "SAVE_PASSPORT_DATA_SUCCESS":
      return {
        ...state,
        saving: false,
        saveError: {
          passportData : false
        },
      };
    case "SAVE_PASSPORT_DATA_FAIL":
      return {
        ...state,
        saving: false,
        saveError: {
          passportData : true
        },
      };
    // ---SAVE_PASSPORT_DATA END---

    // ---FETCH_BACKGROUND_DATA START--- verified
    case "FETCH_BACKGROUND_DATA ":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_BACKGROUND_DATA_SUCCESS":
      return {
        ...state,
        backgroundData: action.payload,
        loading: false,
      };
    case "FETCH_BACKGROUND_DATA_FAIL":
      return {
        ...state,
        loading: false,
        fetchError: {
          backgroundData : true
        },
      };
    // ---FETCH_BACKGROUND_DATA END---

    // ---SAVE_BACKGROUND_DATA START---  verified
    case "SAVE_BACKGROUND_DATA ":
      return {
        ...state,
        saving: true,
      };
    case "SAVE_BACKGROUND_DATA_SUCCESS":
      return {
        ...state,
        saving: false,
        saveError: {
          backgroundData: false
        },
      };
    case "SAVE_BACKGROUND_DATA_FAIL":
      return {
        ...state,
        saving: false,
        saveError: {
          backgroundData: true
        },
      };
    // ---SAVE_BACKGROUND_DATA END---

    // ---GET_COUNTRY_LIST START---
    case "GET_COUNTRY_LIST ":
      return {
        ...state,
        loading: true,
      };
    case "GET_COUNTRY_LIST_SUCCESS":
      return {
        ...state,
        countryList: action.payload,
        loading: false,
      };
    case "GET_COUNTRY_LIST_FAIL":
      return {
        ...state,
        fetchError: {
          countryData : true
        },
        loading: false,
      };
    // ---GET_COUNTRY_LIST END---

    // ---GET_LANGUAGE_LIST START---
    case "GET_LANGUAGE_LIST ":
      return {
        ...state,
        loading: true,
      };
    case "GET_LANGUAGE_LIST_SUCCESS":
      return {
        ...state,
        languageList: action.payload,
        loading: false,
      };
    case "GET_LANGUAGE_LIST_FAIL":
      return {
        ...state,
        fetchError: {
          languageData : true
        },
        loading: false,
      };
    // ---GET_LANGUAGE_LIST END---

    // ---FETCH_ACADEMIC_DATA START---
    case actionConstants.FETCH_ACADEMIC_DATA:
      return {
        ...state,
        loading: true,
      };
    case actionConstants.FETCH_ACADEMIC_DATA_SUCCESS:
      return {
        ...state,
        loading:false,
        academicData: action.payload,
      };
    case actionConstants.FETCH_ACADEMIC_DATA_FAIL:
      return {
        ...state,
        loading:false,
        fetchError: {
          academicData : true
        },
      };
    // ---FETCH_ACADEMIC_DATA END---

    // ---SAVE_ACADEMIC_DATA START---
    case actionConstants.SAVE_ACADEMIC_DATA:
      return {
        ...state,
        saving: true,
      };
    case actionConstants.SAVE_ACADEMIC_DATA_SUCCESS:
      return {
        ...state,
        saving: false,
        saveError :{
          academicData : false
        },
      };
    case actionConstants.SAVE_ACADEMIC_DATA_FAIL:
      return {
        ...state,
        saving: false,
        saveError :{
          academicData : true
        },
      };
    // ---SAVE_ACADEMIC_DATA END---

    // ---SAVE_ACADEMIC_DATA START---
    case actionConstants.FETCH_EMERGENCY_CONTACT_DATA:
      return {
        ...state,
        loading: true,
      };
    case actionConstants.FETCH_EMERGENCY_CONTACT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        emergencyContactData: action.payload,
      };
    case actionConstants.FETCH_EMERGENCY_CONTACT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        fetchError: {
          emergencyContactData: true
        },
      };
    // ---SAVE_ACADEMIC_DATA END---

    // ---FETCH_EMERGENCY_CONTACT_DATA START---
    case actionConstants.SAVE_EMERGENCY_CONTACT_DATA:
      return {
        ...state,
        saving: true,
      };
    case actionConstants.SAVE_EMERGENCY_CONTACT_DATA_SUCCESS:
      return {
        ...state,
        saving: false,
        saveError :{
          emergencyContactData : false
        },
      };
    case actionConstants.SAVE_EMERGENCY_CONTACT_DATA_FAIL:
      return {
        ...state,
        saving: false,
        saveError :{
          emergencyContactData : true
        },
      };
    // ---FETCH_EMERGENCY_CONTACT_DATA END---

    // ---GET_STATE_LIST START---
    case actionConstants.GET_STATE_LIST:
      return {
        ...state,
        loading:true,
      };
    case actionConstants.GET_STATE_LIST_SUCCESS:
      return {
        ...state,
        stateList: action.payload,
        loading: false,
      };
    case actionConstants.GET_STATE_LIST_FAIL:
      return {
        ...state,
        loading:false,
        fetchError: {
          stateData : true
        },
      };
    // ---GET_STATE_LIST END---

    // ---GET_CITY_LIST START---
    case actionConstants.GET_CITY_LIST:
      return {
        ...state,
        loading:true,
      };
    case actionConstants.GET_CITY_LIST_SUCCESS:
      return {
        ...state,
        cityList: action.payload,
        loading: false,
      };
    case actionConstants.GET_CITY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        fetchError: {
          cityData : true
        },
      };
    // ---GET_CITY_LIST END---

    // ---FETCH_EXAM_SCORE_DATA START---
    case actionConstants.FETCH_EXAM_SCORE_DATA:
      return {
        ...state,
        loading:true,
      };
    case actionConstants.FETCH_EXAM_SCORE_DATA_SUCCESS:
      return {
        ...state,
        examScoreData: action.payload,
        loading:false
      };
    case actionConstants.FETCH_EXAM_SCORE_DATA_FAIL:
      return {
        ...state,
        loading:false,
        fetchError: {
          examScoreData : true
        },
      };
    // ---FETCH_EXAM_SCORE_DATA END---

    // ---SAVE_EXAM_SCORE START---
    case actionConstants.SAVE_EXAM_SCORE:
      return {
        ...state,
        saving:true,
      }
    case actionConstants.SAVE_EXAM_SCORE_SUCCESS:
      return {
        ...state,
        saving: false,
        saveError: {
          examScoreData : false
        },
      };
    case actionConstants.SAVE_EXAM_SCORE_FAIL:
      return {
        ...state,
        saving: false,
        saveError: {
          examScoreData : true
        }, 
      };
    // ---SAVE_EXAM_SCORE END---

    // ---FETCH_EDUCATION_DATA START---
    case "FETCH_EDUCATION_DATA":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_EDUCATION_DATA_SUCCESS":
      return {
        ...state,
        educationData: action.payload,
        loading: false,
      };
    case "FETCH_EDUCATION_DATA_FAIL":
      return {
        ...state,
        loading: false,
        fetchError: {
          educationData : true
        },
      };
    // ---FETCH_EDUCATION_DATA END---

    // ---SAVE_EDUCATION_DATA START---
    case "SAVE_EDUCATION_DATA":
      return {
        ...state,
        saving:true
      };
    case "SAVE_EDUCATION_DATA_SUCCESS":
      return {
        ...state,
        saving: false,
        saveError : {
          educationData : false
        }
      };
    case "SAVE_EDUCATION_DATA_FAIL":
      return {
        ...state,
        saving: false,
        saveError : {
          educationData : false
        }
      };
    // ---SAVE_EDUCATION_DATA END---

    // ---FETCH_REFEREE_DATA START---
    case "FETCH_REFEREE_DATA":
      return {
        ...state,
        loading: false,
      };
    case "FETCH_REFEREE_DATA_SUCCESS":
      return {
        ...state,
        refereeData: action.payload,
        loading: false,
      };
    case "FETCH_REFEREE_DATA_FAIL":
      return {
        ...state,
        loading: false,
        fetchError: {
          refereeData: true
        },
      };
    // ---FETCH_REFEREE_DATA END---

    // ---SAVE_REFEREE_DATA START---
    case "SAVE_REFEREE_DATA":
      return {
        ...state,
        saving: true
      }
    case "SAVE_REFEREE_DATA_SUCCESS":
      return {
        ...state,
        saving: false,
        saveError : {
          refereeData: false
        }
      };
    case "SAVE_REFEREE_DATA_FAIL":
      return {
        ...state,
        saving: false,
        saveError : {
          refereeData: true
        }
      };
    // ---SAVE_REFEREE_DATA END---

    // ---FETCH_WORK_EXP_DATA START---
    case actionConstants.FETCH_WORK_EXP_DATA :
      return {
        ...state,
        loading: true
      }
    case actionConstants.FETCH_WORK_EXP_DATA_SUCCESS:
      return {
        ...state,
        loading:false,
        workExpData: action.payload,
      };
    case actionConstants.FETCH_WORK_EXP_DATA_FAIL:
      return {
        ...state,
        loading:false,
        fetchError: {
          workExpData: true
        },
      };
    // ---FETCH_WORK_EXP_DATA END---

    // ---SAVE_WORK_EXP START---
    case actionConstants.SAVE_WORK_EXP:
      return {
        ...state,
        saving: true,
      }
    case actionConstants.SAVE_WORK_EXP_SUCCESS:
      return {
        ...state,
        saving: false,
        saveError : {
          workExpData : false
        }
      };
    case actionConstants.SAVE_WORK_EXP_FAIL:
      return {
        ...state,
        saving: false,
        saveError : {
          workExpData : true
        },
      };
    // ---SAVE_WORK_EXP END---

    // ---FETCH_BACKGROUND_DATA START---
    // case actionConstants.DELETE_WORK_EXP_SUCCESS:
    //   return {
    //     ...state,
    //     deleting: false,
    //   };
    // case actionConstants.DELETE_WORK_EXP_FAIL:
    //   return {
    //     ...state,
    //     deleteError: action.payload,
    //     deleting: false,
    //   };
    // ---FETCH_BACKGROUND_DATA END---
    case "RESET_FETCH_ERROR":
      return {
        ...state,
        fetchError: "",
      }
      case "RESET_SAVE_ERROR":
      return {
        ...state,
        saveError: "",
      }
      case "RESET_ERROR":
      return {
        ...state,
        saveError : {},
        fetchError : {}
      }

    default:
      return state;
  }
};

export default reducer;
