import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";

export const wordOfTheDayStateName = "WordOfTheDay";

export type wordOfTheDayResponseDto = null | {
  totalLikes: number;
  userLikeStatus: boolean;
  wordOfTheDayLink: string;
  wordOfTheDayId: number;
  title: string;
};
export interface WordOfTheDayReducerSchema {
  isLoading: boolean;
  error: string;
  wordOfTheDayResponse: wordOfTheDayResponseDto;
  wordLikeCount: null | number;
  userLikeStatus: null | boolean;
}

const initialState: WordOfTheDayReducerSchema = {
  isLoading: false,
  error: "",
  wordOfTheDayResponse: null,
  wordLikeCount: null,
  userLikeStatus: null,
};

export const fetchGetWordOfTheDay = createAction(
  `${wordOfTheDayStateName}/fetchWordOfTheDay`
);
export const fetchPostWordLike = createAction<number>(
  `${wordOfTheDayStateName}/fetchPostWordLike`
);

const WordOfTheDaySlice = createSlice({
  name: wordOfTheDayStateName,
  initialState,
  reducers: {
    setWordOfTheDayLoading(state, action: PayloadAction<boolean>) {
      return { ...state, isLoading: action.payload };
    },
    setWordOfTheDayError(state, action: PayloadAction<string>) {
      return { ...state, error: action.payload };
    },
    setWordOfTheDayData(state, action: PayloadAction<any>) {
      return { ...state, wordOfTheDayResponse: action.payload };
    },
    setWordLikeCount(state, action: PayloadAction<number>) {
      return { ...state, wordLikeCount: action.payload };
    },
    setUserLikeStatus(state, action: PayloadAction<boolean>) {
      return { ...state, userLikeStatus: action.payload };
    },
  },
});

export const {
  setWordOfTheDayError,
  setWordOfTheDayLoading,
  setWordOfTheDayData,
  setWordLikeCount,
  setUserLikeStatus,
} = WordOfTheDaySlice.actions;

export default WordOfTheDaySlice.reducer;
