export const ExtraClassType = {
  NOTIFY_USER_FOR_EXTRA_CLASS: "NOTIFY_USER_FOR_EXTRA_CLASS",
  NOTIFY_USER_POST_ACTION: "NOTIFY_USER_POST_ACTION",
  NOTIFY_USER_FOR_EXTRA_CLASS_SUCCESS: "NOTIFY_USER_FOR_EXTRA_CLASS_SUCCESS",
  NOTIFY_ORIENTATION_CLASS_SUCCESS: "NOTIFY_ORIENTATION_CLASS_SUCCESS",
  NOTIFY_USER_FOR_EXTRA_CLASS_FAIL: "NOTIFY_USER_FOR_EXTRA_CLASS_FAIL",
  TOGGLE_HAS_JOINED_MODAL: "TOGGLE_HAS_JOINED_MODAL",
  FETCH_ENROLLED_EXTRA_CLASS_REQ: "FETCH_ENROLLED_EXTRA_CLASS_REQ",
  FETCH_ENROLLED_EXTRA_CLASS_SUCCESS: "FETCH_ENROLLED_EXTRA_CLASS_SUCCESS",
  FETCH_ENROLLED_EXTRA_CLASS_FAIL: "FETCH_ENROLLED_EXTRA_CLASS_FAIL",
};

export interface NotifyUserForExtraClassPayload {
  batchId: number | string;
  workshopType: string;
}

const notifyUserForExtraClassReq = (
  payload: NotifyUserForExtraClassPayload
) => ({
  type: ExtraClassType.NOTIFY_USER_FOR_EXTRA_CLASS,
  payload,
});

const notifyUserForExtraClassSuccess = (payload: boolean) => ({
  type: ExtraClassType.NOTIFY_USER_FOR_EXTRA_CLASS_SUCCESS,
  payload,
});

const notifyUserForExtraClassFail = (payload: string) => ({
  type: ExtraClassType.NOTIFY_USER_FOR_EXTRA_CLASS_FAIL,
  payload,
});

const toggleHasJoinedModal = (payload: boolean) => ({
  type: ExtraClassType.TOGGLE_HAS_JOINED_MODAL,
  payload,
});

const toggleHasJoinedOrientation = (payload: boolean) => ({
  type: ExtraClassType.NOTIFY_ORIENTATION_CLASS_SUCCESS,
  payload,
});

const fetchEnrolledExtraClass = () => ({
  type: ExtraClassType.FETCH_ENROLLED_EXTRA_CLASS_REQ,
});

const fetchEnrolledExtraClassSuccess = (payload: any) => ({
  type: ExtraClassType.FETCH_ENROLLED_EXTRA_CLASS_SUCCESS,
  payload,
});
const fetchEnrolledExtraClassFail = (payload: string) => ({
  type: ExtraClassType.FETCH_ENROLLED_EXTRA_CLASS_FAIL,
  payload,
});

const notifyUserPostAction = (payload: Function | null) => ({
  type: ExtraClassType.NOTIFY_USER_POST_ACTION,
  payload,
});

export const extraClassActions = {
  notifyUserForExtraClassReq,
  notifyUserForExtraClassSuccess,
  notifyUserForExtraClassFail,
  toggleHasJoinedModal,
  fetchEnrolledExtraClass,
  fetchEnrolledExtraClassSuccess,
  fetchEnrolledExtraClassFail,
  notifyUserPostAction,
  toggleHasJoinedOrientation,
};
