import api from "service/api"

const updateUserInfo = async (values: Record<string, any>) => {
  try {
    const newValues = {
      name: values.name,
      email: values.email,
    }
    const res = await api.post(
      `/api/scholarRoute?path=user/profile/update`,
      newValues,
    )
    return res.data
  } catch (err: any) {
    console.log(err)
  }
}

export const intermediateScreenApi = {
  updateUserInfo,
}
