export const essayType = {
  ESSAY_FETCH_REQ: "ESSAY_FETCH_REQ",
  ESSAY_FETCH_SUCCESS: "ESSAY_FETCH_ACCEPT",
  ESSAY_FETCH_FAIL: "ESSAY_FETCH_FAIL",
  UPLOAD_ESSAY_REQ: "UPLOAD_ESSAY_REQ",
  UPLOAD_ESSAY_SUCCESS: "UPLOAD_ESSAY_SUCCESS",
  UPLOAD_ESSAY_FAIL: "UPLOAD_ESSAY_FAIL",
  CLEAR_ERROR_MSG: "CLEAR_ERROR_MSG",
  REPLACE_ESSAY_REQ: "REPLACE_ESSAY_REQ",
  REPLACE_ESSAY_SUCCESS: "REPLACE_ESSAY_SUCCESS",
  REPLACE_ESSAY_FAIL: "REPLACE_ESSAY_FAIL",
};

const fetchEssayReq = (payload: number) => ({
  type: essayType.ESSAY_FETCH_REQ,
  payload,
});

const fetchEssaySuccess = (payload: any) => ({
  type: essayType.ESSAY_FETCH_SUCCESS,
  payload,
});

const fetchEssayFail = (payload: string) => ({
  type: essayType.ESSAY_FETCH_FAIL,
  payload,
});

const uploadEssayReq = (payload: any) => ({
  type: essayType.UPLOAD_ESSAY_REQ,
  payload,
});

const uploadEssaySuccess = (payload: any) => ({
  type: essayType.UPLOAD_ESSAY_SUCCESS,
  payload,
});

const uploadEssayFail = (payload: string) => ({
  type: essayType.UPLOAD_ESSAY_FAIL,
  payload,
});

const replaceEssayReq = (payload: any) => ({
  type: essayType.REPLACE_ESSAY_REQ,
  payload,
});

const replaceEssaySuccess = (payload: any) => ({
  type: essayType.REPLACE_ESSAY_SUCCESS,
  payload,
});

const replaceEssayFail = (payload: string) => ({
  type: essayType.REPLACE_ESSAY_FAIL,
  payload,
});

const clearErrorMessage = () => ({
  type: essayType.CLEAR_ERROR_MSG,
});

export const essayAction = {
  fetchEssayReq,
  fetchEssaySuccess,
  fetchEssayFail,
  uploadEssayReq,
  uploadEssaySuccess,
  uploadEssayFail,
  clearErrorMessage,
  replaceEssayReq,
  replaceEssaySuccess,
  replaceEssayFail,
};
