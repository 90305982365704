export default async function autoReadSMS(
  cb: (payload: { code: string; error: string; meta?: any }) => void,
): Promise<void> {
  const signal = new AbortController()
  setTimeout(() => signal.abort(), 60 * 1000)
  try {
    if (typeof window != "undefined" && "OTPCredential" in window) {
      if (typeof navigator != "undefined" && navigator.credentials) {
        const otpCredential: any = await navigator.credentials.get({
          otp: { transport: ["sms"] },
          signal: signal.signal,
        } as any)
        if (otpCredential && otpCredential.code) {
          return cb({
            code: otpCredential.code,
            error: "",
            meta: otpCredential,
          })
        } else {
          return cb({
            code: "",
            error: "otpCredential error" + JSON.stringify(otpCredential),
          })
        }
      } else {
        return cb({ code: "", error: "navigator credentials not found" })
      }
    } else {
      return cb({ code: "", error: "OTPCredential not found" })
    }
  } catch (error: any) {
    cb({ code: "", error: "Error reading OTP: " + error.toString() })
    console.log("Error reading OTP:", error)
  }
}
