export const certificateActionType = {
  FETCH_CERTIFICATE_REQ: "FETCH_CERTIFICATE_REQ",
  FETCH_CERTIFICATE_SUCCESS: "FETCH_CERTIFICATE_SUCCESS",
  FETCH_CERTIFICATE_FAIL: "FETCH_CERTIFICATE_FAIL",
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
};

const fetchCertificateReq = (payload: string | number) => ({
  type: certificateActionType.FETCH_CERTIFICATE_REQ,
  payload,
});

const fetchCertificateSuccess = (payload: boolean) => ({
  type: certificateActionType.FETCH_CERTIFICATE_SUCCESS,
  payload,
});

const fetchCertificateFail = (payload: string) => ({
  type: certificateActionType.FETCH_CERTIFICATE_FAIL,
  payload,
});

const clearErrorMessage = () => ({
  type: certificateActionType.CLEAR_ERROR_MESSAGE,
});

export const certificateAction = {
  fetchCertificateFail,
  fetchCertificateSuccess,
  fetchCertificateReq,
  clearErrorMessage,
};
