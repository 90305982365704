export const onboardingActionConstants = {
  ADD_COUNTRY: "ADD_COUNTRY",
  DELETE_COUNTRY: "DELETE_COUNTRY",
  SET_EDIT_MODE: "SET_EDIT_MODE",
  HANDLE_STEP: "HANDLE_STEP",
  SET_COMPLETE: "SET_COMPLETE",
  TOGGLE_SKIP_MODAL: "TOGGLE_SKIP_MODAL",
  CHECK_FORM: "CHECK_FORM",
  HANDLE_TAB_SELECT: "HANDLE_TAB_SELECT",
  SET_FIRST_STEP_DATA: "SET_FIRST_STEP_DATA",
  SAVE_FIRST_STEP: "SAVE_FIRST_STEP",
  SET_CURRENT_PROGRESS: "SET_CURRENT_PROGRESS",
  SET_COUNTRY_FORM_DATA: "SET_COUNTRY_FORM_DATA",
  SET_FORM_VALIDATION: "SET_FORM_VALIDATION",
  SHOW_FIRST_STEP_ERROR: "SHOW_FIRST_STEP_ERROR",
  SUBMIT_FIRST_STEP: "SUBMIT_FIRST_STEP",
  SUBMIT_FIRST_STEP_SUCCESS: "SUBMIT_FIRST_STEP_SUCCESS",
  SUBMIT_FIRST_STEP_ERROR: "SUBMIT_FIRST_STEP_ERROR",
  FETCH_INITIAL_DATA: "FETCH_INITIAL_DATA",
  FETCH_INITIAL_DATA_SUCCESS: "FETCH_INITIAL_DATA_SUCCESS",
  FETCH_INITIAL_DATA_ERROR: "FETCH_INITIAL_DATA_ERROR",
  SET_HIGHEST_EDUCATION: "SET_HIGHEST_EDUCATION",
  VALIDATE_EDUCATION_FORM: "VALIDATE_EDUCATION_FORM",
  CHECK_EDUCATION_FORM: "CHECK_EDUCATION_FORM",
  SET_EDUCATION_FORM_DATA: "SET_EDUCATION_FORM_DATA",
  SUBMIT_EDUCATION_DETAILS: "SUBMIT_EDUCATION_DETAILS",
  SUBMIT_EDUCATION_DETAILS_SUCCESS: "SUBMIT_EDUCATION_DETAILS_SUCCESS",
  SUBMIT_EDUCATION_DETAILS_ERROR: "SUBMIT_EDUCATION_DETAILS_ERROR",
  SUBMIT_PREPARATION_STAGE: "SUBMIT_PREPARATION_STAGE",
  SUBMIT_PREPARATION_STAGE_SUCCESS: "SUBMIT_PREPARATION_STAGE_SUCCESS",
  SUBMIT_PREPARATION_STAGE_ERROR: "SUBMIT_PREPARATION_STAGE_ERROR",
  CHECK_EXAM_FORM: "CHECK_EXAM_FORM",
  TOGGLE_SUCCESS_MODAL: "TOGGLE_SUCCESS_MODAL",
  SUBMIT_EXAM_FORM_DATA: "SUBMIT_EXAM_FORM_DATA",
  SUBMIT_EXAM_FORM_DATA_SUCCESS: "SUBMIT_EXAM_FORM_DATA_SUCCESS",
  SUBMIT_EXAM_FORM_DATA_ERROR: "SUBMIT_EXAM_FORM_DATA_ERROR",
  SET_PREPARATION_STAGE: "SET_PREPARATION_STAGE",
  SUBMIT_COLLEGE_FORMS: "SUBMIT_COLLEGE_FORMS",
  SUBMIT_COLLEGE_FORMS_SUCCESS: "SUBMIT_COLLEGE_FORMS_SUCCESS",
  SUBMIT_COLLEGE_FORMS_ERROR: "SUBMIT_COLLEGE_FORMS_ERROR",
  FETCH_CITY_FROM_COUNTRY: "FETCH_CITY_FROM_COUNTRY",
  FETCH_CITY_FROM_COUNTRY_SUCCESS: "FETCH_CITY_FROM_COUNTRY_SUCCESS",
  FETCH_CITY_FROM_COUNTRY_ERROR: "FETCH_CITY_FROM_COUNTRY_ERROR",
  FETCH_PREFERRED_PROGRAMS: "FETCH_PREFERRED_PROGRAMS",
  FETCH_PREFERRED_PROGRAMS_SUCCESS: "FETCH_PREFERRED_PROGRAMS_SUCCESS",
  FETCH_PREFERRED_PROGRAMS_ERROR: "FETCH_PREFERRED_PROGRAMS_ERROR",
  GET_PREFERRED_LOCATION_LISTS: "GET_PREFERRED_LOCATION_LISTS",
  GET_PREFERRED_LOCATION_LISTS_SUCCESS: "GET_PREFERRED_LOCATION_LISTS_SUCCESS",
  GET_PREFERRED_LOCATION_LISTS_ERROR: "GET_PREFERRED_LOCATION_LISTS_ERROR",
  DELETE_COUNTRY_FROM_FORM_DATA: "DELETE_COUNTRY_FROM_FORM_DATA",
  DELETE_COUNTRY_FROM_FORM_DATA_SUCCESS:
    "DELETE_COUNTRY_FROM_FORM_DATA_SUCCESS",
  DELETE_COUNTRY_FROM_FORM_DATA_ERROR: "DELETE_COUNTRY_FROM_FORM_DATA_ERROR",
  SET_SUBMITTING_COUNTRY_FORM: "SET_SUBMITTING_COUNTRY_FORM",
  GET_PREFERRED_COUNTRY_ID_LISTS_SUCCESS:
    "GET_PREFERRED_COUNTRY_ID_LISTS_SUCCESS",
  GET_PREFERRED_COUNTRY_ID_LISTS: "GET_PREFERRED_COUNTRY_ID_LISTS",
  GET_PREFERRED_COUNTRY_ID_LISTS_ERROR: "GET_PREFERRED_COUNTRY_ID_LISTS_ERROR",
  GET_PREFERRED_PROGRAM_COURSE_LISTS_SUCCESS:
    "GET_PREFERRED_PROGRAM_COURSE_LISTS_SUCCESS",
  GET_PREFERRED_PROGRAM_COURSE_LISTS: "GET_PREFERRED_PROGRAM_COURSE_LISTS",
  GET_PREFERRED_PROGRAM_COURSE_LISTS_ERROR:
    "GET_PREFERRED_PROGRAM_COURSE_LISTS_ERROR",
  HANDLE_NEXT_CLICKED: "HANDLE_NEXT_CLICKED",
  IELTS_REQUIRED: "IELTS_REQUIRED",
  IELTS_STATUS: "IELTS_STATUS",
};

const addCountry = (payload: any) => ({
  type: onboardingActionConstants.ADD_COUNTRY,
  payload,
});

const deleteCountry = (payload: any) => ({
  type: onboardingActionConstants.DELETE_COUNTRY,
  payload,
});

const setEditMode = (payload: any) => ({
  type: onboardingActionConstants.SET_EDIT_MODE,
  payload,
});

const handleStep = (payload: any) => ({
  type: onboardingActionConstants.HANDLE_STEP,
  payload,
});

const setComplete = (payload: any) => ({
  type: onboardingActionConstants.SET_COMPLETE,
  payload,
});

const toggleSkipModal = (payload: any) => ({
  type: onboardingActionConstants.TOGGLE_SKIP_MODAL,
  payload,
});

const checkForm = (payload: boolean) => ({
  type: onboardingActionConstants.CHECK_FORM,
  payload,
});

const handleTabSelect = (payload: any) => ({
  type: onboardingActionConstants.HANDLE_TAB_SELECT,
  payload,
});

const setFirstStepData = (payload: any) => ({
  type: onboardingActionConstants.SET_FIRST_STEP_DATA,
  payload,
});

const saveFirstStep = () => ({
  type: onboardingActionConstants.SAVE_FIRST_STEP,
});

const setCurrentProgress = (payload: any) => ({
  type: onboardingActionConstants.SET_CURRENT_PROGRESS,
  payload,
});

const setCountryFormData = (payload: any) => ({
  type: onboardingActionConstants.SET_COUNTRY_FORM_DATA,
  payload,
});

const setFormValidation = (payload: any) => ({
  type: onboardingActionConstants.SET_FORM_VALIDATION,
  payload,
});

const showFirstStepError = (payload: any) => ({
  type: onboardingActionConstants.SHOW_FIRST_STEP_ERROR,
  payload,
});
const handleNext = (payload: any) => ({
  type: onboardingActionConstants.HANDLE_NEXT_CLICKED,
  payload,
});

const submitFirstStep = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_FIRST_STEP,
  payload,
});
const setPreparationStage = (payload: any) => ({
  type: onboardingActionConstants.SET_PREPARATION_STAGE,
  payload,
});
const submitFirstStepSuccess = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_FIRST_STEP_SUCCESS,
  payload,
});
const submitFirstStepError = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_FIRST_STEP_ERROR,
  payload,
});

const fetchInitialData = () => ({
  type: onboardingActionConstants.FETCH_INITIAL_DATA,
});

const fetchInitialDataSuccess = (payload: any) => ({
  type: onboardingActionConstants.FETCH_INITIAL_DATA_SUCCESS,
  payload,
});
const fetchInitialDataError = (payload: any) => ({
  type: onboardingActionConstants.FETCH_INITIAL_DATA_ERROR,
  payload,
});

const setHighestEducation = (payload: any) => ({
  type: onboardingActionConstants.SET_HIGHEST_EDUCATION,
  payload,
});

const validateEducationForm = (payload: boolean) => ({
  type: onboardingActionConstants.VALIDATE_EDUCATION_FORM,
  payload,
});

const checkEducationForm = (payload: boolean) => ({
  type: onboardingActionConstants.CHECK_EDUCATION_FORM,
  payload,
});

const setEducationFormData = (payload: boolean) => ({
  type: onboardingActionConstants.SET_EDUCATION_FORM_DATA,
  payload,
});

const submitEducationDetails = (payload: boolean) => ({
  type: onboardingActionConstants.SUBMIT_EDUCATION_DETAILS,
  payload,
});

const submitEducationDetailsSuccess = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_EDUCATION_DETAILS_SUCCESS,
  payload,
});

const submitEducationDetailsError = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_EDUCATION_DETAILS_ERROR,
  payload,
});
const submitCollegeForms = (payload: boolean) => ({
  type: onboardingActionConstants.SUBMIT_COLLEGE_FORMS,
  payload,
});

const submitCollegeFormsSuccess = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_COLLEGE_FORMS_SUCCESS,
  payload,
});

const submitCollegeFormsError = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_COLLEGE_FORMS_ERROR,
  payload,
});
const submitPreparationStage = (payload: boolean) => ({
  type: onboardingActionConstants.SUBMIT_PREPARATION_STAGE,
  payload,
});

const submitPreparationStageSuccess = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_PREPARATION_STAGE_SUCCESS,
  payload,
});

const submitPreparationStageError = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_PREPARATION_STAGE_ERROR,
  payload,
});

const checkExamForm = (payload: any) => ({
  type: onboardingActionConstants.CHECK_EXAM_FORM,
  payload,
});

const toggleSuccessModal = (payload: any) => ({
  type: onboardingActionConstants.TOGGLE_SUCCESS_MODAL,
  payload,
});

const submitExamFormData = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_EXAM_FORM_DATA,
  payload,
});

const submitExamFormDataSuccess = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_EXAM_FORM_DATA_SUCCESS,
  payload,
});
const submitExamFormDataError = (payload: any) => ({
  type: onboardingActionConstants.SUBMIT_EXAM_FORM_DATA_ERROR,
  payload,
});

const fetchCityFromCountry = (payload: any) => ({
  type: onboardingActionConstants.FETCH_CITY_FROM_COUNTRY,
  payload,
});

const fetchCityFromCountrySuccess = (payload: any) => ({
  type: onboardingActionConstants.FETCH_CITY_FROM_COUNTRY_SUCCESS,
  payload,
});
const fetchCityFromCountryError = (payload: any) => ({
  type: onboardingActionConstants.FETCH_CITY_FROM_COUNTRY_ERROR,
  payload,
});
const fetchPreferredPrograms = (payload: any) => ({
  type: onboardingActionConstants.FETCH_PREFERRED_PROGRAMS,
  payload,
});

const fetchPreferredProgramsSuccess = (payload: any) => ({
  type: onboardingActionConstants.FETCH_PREFERRED_PROGRAMS_SUCCESS,
  payload,
});
const fetchPreferredProgramsError = (payload: any) => ({
  type: onboardingActionConstants.FETCH_PREFERRED_PROGRAMS_ERROR,
  payload,
});
const getPreferredLocationLists = (payload: any) => ({
  type: onboardingActionConstants.GET_PREFERRED_LOCATION_LISTS,
  payload,
});

const getPreferredLocationListsSuccess = (payload: any) => ({
  type: onboardingActionConstants.GET_PREFERRED_LOCATION_LISTS_SUCCESS,
  payload,
});
const getPreferredLocationListsError = (payload: any) => ({
  type: onboardingActionConstants.GET_PREFERRED_LOCATION_LISTS_ERROR,
  payload,
});
const getPreferredProgramCourseLists = (payload: any) => ({
  type: onboardingActionConstants.GET_PREFERRED_PROGRAM_COURSE_LISTS,
  payload,
});

const getPreferredProgramCourseListsSuccess = (payload: any) => ({
  type: onboardingActionConstants.GET_PREFERRED_PROGRAM_COURSE_LISTS_SUCCESS,
  payload,
});
const getPreferredProgramCourseListsError = (payload: any) => ({
  type: onboardingActionConstants.GET_PREFERRED_PROGRAM_COURSE_LISTS_ERROR,
  payload,
});
const getPreferredCountryIdLists = (payload: any) => ({
  type: onboardingActionConstants.GET_PREFERRED_COUNTRY_ID_LISTS,
  payload,
});

const getPreferredCountryIdListsSuccess = (payload: any) => ({
  type: onboardingActionConstants.GET_PREFERRED_COUNTRY_ID_LISTS_SUCCESS,
  payload,
});
const getPreferredCountryIdListsError = (payload: any) => ({
  type: onboardingActionConstants.GET_PREFERRED_COUNTRY_ID_LISTS_ERROR,
  payload,
});
const deleteCountryFromFormData = (payload: any) => ({
  type: onboardingActionConstants.DELETE_COUNTRY_FROM_FORM_DATA,
  payload,
});

const deleteCountryFromFormDataSuccess = (payload: any) => ({
  type: onboardingActionConstants.DELETE_COUNTRY_FROM_FORM_DATA_SUCCESS,
  payload,
});
const deleteCountryFromFormDataError = (payload: any) => ({
  type: onboardingActionConstants.DELETE_COUNTRY_FROM_FORM_DATA_ERROR,
  payload,
});

const setSubmittingCountryForm = (payload: boolean) => ({
  type: onboardingActionConstants.SET_SUBMITTING_COUNTRY_FORM,
  payload,
});

const setIeltsRequired = (payload: boolean) => ({
  type: onboardingActionConstants.IELTS_REQUIRED,
  payload,
});

const setIeltsStatus = (payload: boolean) => ({
  type: onboardingActionConstants.IELTS_STATUS,
  payload,
});

export const onboardingActions = {
  addCountry,
  deleteCountry,
  setEditMode,
  handleStep,
  setComplete,
  toggleSkipModal,
  checkForm,
  handleTabSelect,
  setFirstStepData,
  saveFirstStep,
  setCurrentProgress,
  setCountryFormData,
  setFormValidation,
  showFirstStepError,
  submitFirstStep,
  submitFirstStepSuccess,
  submitFirstStepError,
  submitPreparationStage,
  submitPreparationStageSuccess,
  submitPreparationStageError,
  fetchInitialData,
  fetchInitialDataSuccess,
  fetchInitialDataError,
  setHighestEducation,
  validateEducationForm,
  checkEducationForm,
  setEducationFormData,
  submitEducationDetails,
  submitEducationDetailsSuccess,
  submitEducationDetailsError,
  checkExamForm,
  toggleSuccessModal,
  submitExamFormData,
  submitExamFormDataSuccess,
  submitExamFormDataError,
  setPreparationStage,
  submitCollegeFormsError,
  submitCollegeForms,
  submitCollegeFormsSuccess,
  fetchCityFromCountry,
  fetchCityFromCountrySuccess,
  fetchCityFromCountryError,
  fetchPreferredPrograms,
  fetchPreferredProgramsSuccess,
  fetchPreferredProgramsError,
  deleteCountryFromFormData,
  deleteCountryFromFormDataSuccess,
  deleteCountryFromFormDataError,
  setSubmittingCountryForm,
  getPreferredLocationLists,
  getPreferredLocationListsSuccess,
  getPreferredLocationListsError,
  getPreferredProgramCourseLists,
  getPreferredProgramCourseListsSuccess,
  getPreferredProgramCourseListsError,
  getPreferredCountryIdLists,
  getPreferredCountryIdListsSuccess,
  getPreferredCountryIdListsError,
  handleNext,
  setIeltsRequired,
  setIeltsStatus,
};
