const fetchReviews = () => ({
  type: "FETCH_IELTS_REVIEWS",
});

const fetchReviewsSuccess = (payload: any) => ({
  type: "FETCH_IELTS_REVIEWS_SUCCESS",
  payload,
});

const updateError = (message: string) => ({
  type: "UPDATE_IELTS_REVIEWS_ERROR",
  payload: message,
})

export const reviewActions = {
  fetchReviews,
  fetchReviewsSuccess,
  updateError,
}