export const fastTrackFormActionType = {
  SAVE_FAST_TRACK_FORM: "SAVE_FAST_TRACK_FORM",
  ENROLL_TO_FAST_TRACK_FORM: "ENROLL_TO_FAST_TRACK_FORM",
  TOGGLE_FAST_TRACK_MODAL: "TOGGLE_FAST_TRACK_MODAL",
  FAST_TRACK_FORM_ERROR: "FAST_TRACK_FORM_ERROR",
  TOGGLE_FAST_TRACK_FORM_CTA_TEXT: "TOGGLE_FAST_TRACK_FORM_CTA_TEXT",
};

const saveForm = (payload: any) => ({
  type: fastTrackFormActionType.SAVE_FAST_TRACK_FORM,
  payload,
});

const toggleCtaText = (payload: string) => ({
  type: fastTrackFormActionType.TOGGLE_FAST_TRACK_FORM_CTA_TEXT,
  payload,
});

const toggleFastTrackFormModal = (payload: boolean) => ({
  type: fastTrackFormActionType.TOGGLE_FAST_TRACK_MODAL,
  payload,
});

const enrollToFastTrack = (payload: any) => ({
  type: fastTrackFormActionType.ENROLL_TO_FAST_TRACK_FORM,
  payload,
});

const updateError = (message: string) => ({
  type: fastTrackFormActionType.FAST_TRACK_FORM_ERROR,
  payload: message,
});

const toggleFastTrackLoader = (payload: boolean) => ({
  type: "TOGGLE_FAST_TRACK_LOADER",
  payload,
});

const toggleFastTrackForm = (payload: boolean) => ({
  type: "TOGGLE_FAST_TRACK_FORM",
  payload,
});

export const fastTrackActions = {
  saveForm,
  toggleCtaText,
  toggleFastTrackFormModal,
  enrollToFastTrack,
  updateError,
  toggleFastTrackLoader,
  toggleFastTrackForm,
};
