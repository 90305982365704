import { meetCounsellerActions } from 'components/MeetCounsellerVirtualOffice/redux/MeetCounsellerActions'
import { meetCounsellerApi } from 'components/MeetCounsellerVirtualOffice/redux/MeetCounsellerApi'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'

function* getExpertsData(): Generator<any, any, any> {
  try {
    const res = yield call(meetCounsellerApi.getExpertData)
    if (res.success) {
      yield put(meetCounsellerActions.fetchExpertsSuccess(res.data))
    } else {
      yield put(meetCounsellerActions.fetchExpertsFail(res.message))
    }
  } catch (e: any) {
    yield put(
      meetCounsellerActions.fetchExpertsFail(
        'Something went wrong. Please try after sometime.'
      )
    )
    console.error(e)
  }
}

function* fetchMeetCounsellerSlot(): Generator<any, any, any> {
  try {
    const slots = yield call(meetCounsellerApi.fetchMeetCounsellerSlot)
    yield put(meetCounsellerActions.fetchMeetCounsellerSlotSuccess(slots))
  } catch (e: any) {
    yield put(meetCounsellerActions.fetchMeetCounsellerSlotFail(e.message))
  }
}

function* fetchTypedMeetCounsellerSlot(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const slots = yield call(
      meetCounsellerApi.fetchTypedMeetCounsellerSlot,
      action.payload
    )

    yield put(meetCounsellerActions.fetchTypedMeetCounsellerSlotSuccess(slots))
  } catch (e: any) {
    yield put(meetCounsellerActions.fetchMeetCounsellerSlotFail(e.message))
  }
}

function* fetchVisaCounsellerSlot(action: AnyAction): Generator<any, any, any> {
  try {
    const slots = yield call(
      meetCounsellerApi.fetchVisaCounsellerSlot,
      action.payload
    )

    yield put(meetCounsellerActions.fetchVisaCounsellerSlotSuccess(slots))
  } catch (e: any) {
    yield put(meetCounsellerActions.fetchVisaCounsellerSlotFail(e.message))
  }
}

function* confirmMeetCounsellerSlot(
  action: AnyAction
): Generator<any, any, any> {
  try {
    const res = yield call(
      meetCounsellerApi.confirmMeetCounsellerSlot,
      action.payload
    )

    if (res) {
      yield put(meetCounsellerActions.confirmMeetCounsellerSlotSuccess(res))
    } else {
      yield put(meetCounsellerActions.confirmMeetCounsellerSlotFail(res))
    }
  } catch (e: any) {
    yield put(meetCounsellerActions.confirmMeetCounsellerSlotFail(e.message))
  }
}

function* confirmRescheduleSlot(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      meetCounsellerApi.confirmRescheduleSlot,
      action.payload
    )
    if (res.success) {
      yield put(meetCounsellerActions.confirmRescheduleSlotSuccess(res))
    } else {
      yield put(meetCounsellerActions.confirmRescheduleSlotFail(res))
    }
  } catch (e: any) {
    yield put(meetCounsellerActions.confirmRescheduleSlotFail(e.message))
  }
}

function* fetchMeetProfile(): Generator<any, any, any> {
  try {
    const res = yield call(meetCounsellerApi.fetchMeetProfile)
    if (res.success) {
      yield put(meetCounsellerActions.fetchMeetProfileSuccess(res.data))
    } else {
      yield put(meetCounsellerActions.fetchMeetProfileFail(res.message))
    }
  } catch (e: any) {
    yield put(
      meetCounsellerActions.fetchMeetProfileFail(
        'Something went wrong. Please try after sometime.'
      )
    )
    console.error(e)
  }
}

function* submitMeetProfile(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(meetCounsellerApi.submitMeetProfile, action.payload)

    if (res?.data?.success) {
      yield put(meetCounsellerActions.submitMeetProfileSuccess(res.data.data))
    } else {
      yield put(meetCounsellerActions.submitMeetProfileFail(res))
    }
  } catch (e: any) {
    yield put(meetCounsellerActions.submitMeetProfileFail(e.message))
  }
}

function* meetCounsellerSaga() {
  yield takeLatest('FETCH_EXPERTS_DATA', getExpertsData)
  yield takeLatest('FETCH_MEET_COUNSELLER_SLOT', fetchMeetCounsellerSlot)
  yield takeLatest('CONFIRM_MEET_COUNSELLER_SLOT', confirmMeetCounsellerSlot)
  yield takeLatest('CONFIRM_RESCHEDULE_SLOT', confirmRescheduleSlot)
  yield takeLatest('FETCH_MEET_PROFILE', fetchMeetProfile)
  yield takeLatest('SUBMIT_MEET_PROFILE', submitMeetProfile)
  yield takeLatest(
    'FETCH_TYPED_MEET_COUNSELLER_SLOT',
    fetchTypedMeetCounsellerSlot
  )
  yield takeLatest('FETCH_VISA_COUNSELLER_SLOT', fetchVisaCounsellerSlot)
}

export default meetCounsellerSaga
