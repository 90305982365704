import Script from "next/script"
import { memo } from "react"
const TRACKING_ID = process.env.GA_TRACKING_CODE!
type Props = {
  userId: string
}
const GoogleAnalytics = ({ userId }: Props) => {
  if (!TRACKING_ID) {
    return null
  }
  let gtagConfig = `'${TRACKING_ID}', {`
  if (userId) {
    gtagConfig += `'user_id': '${userId}',`
  }
  gtagConfig += "}"
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${TRACKING_ID}`}
      ></Script>
      <Script
        strategy="afterInteractive"
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', ${gtagConfig});
          `,
        }}
      />
    </>
  )
}
export default memo(GoogleAnalytics)
