import api from "service/api"

const fetchAiEvalutionToken = async () => {
  try {
    const res = await api.get(
      "/api/scholarRoute?path=aievaluation/ielts8/token",
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err) {
    console.log(err)
  }
}

const aiEvalutionAlreadyPaid = async () => {
  try {
    const res = await api.get(
      "/api/scholarRoute?path=aievaluation/ielts8/fee/alreadyPaid",
    )
    if (res.data.success) {
      return res.data.data
    } else {
      return false
    }
  } catch (err) {
    console.log(err)
  }
}

const saveForm = async (values: Record<string, any>) => {
  try {
    const res = await api.post(
      `/api/scholarRoute?path=workshop/ielts-class`,
      values,
    )
    return res.data
  } catch (err) {
    console.log(err)
  }
}

const fetchGetSmartTestFee = async () => {
  try {
    const res = await api.get("/api/scholarRoute?path=fee/smart-test/status")
    if (res.data.success) {
      return res.data
    } else {
      return false
    }
  } catch (err) {
    console.log(err)
  }
}

const fetchGetSmartTestProgressStatus = async () => {
  try {
    const res = await api.get(
      "/api/scholarRoute?path=aievaluation/ielts8/free/status",
    )
    if (res.data.success) {
      return res.data
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

export const aiEvalutionApi = {
  fetchAiEvalutionToken,
  aiEvalutionAlreadyPaid,
  saveForm,
  fetchGetSmartTestFee,
  fetchGetSmartTestProgressStatus,
}
