import { ComponentType, useEffect, useState } from 'react'

type WindowDimentions = {
  width: number | undefined
  height: number | undefined
}

const withWindowDimensions =
  <P extends object>(WrappedComponent: ComponentType<P>) =>
  (props: any) => {
    const [windowDimensions, setWindowDimensions] = useState<WindowDimentions>({
      width: 380,
      height: 640,
    })

    useEffect(() => {
      function handleResize(): void {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }
      handleResize()
      window.addEventListener('resize', handleResize)
      return (): void => window.removeEventListener('resize', handleResize)
    }, [])
    return (
      <WrappedComponent
        {...(props as P)}
        windowWidth={windowDimensions.width}
        windowHeight={windowDimensions.height}
        isMobileSized={(windowDimensions.width as number) < 700}
        isTabSized={
          (windowDimensions.width as number) < 1000 &&
          (windowDimensions.width as number) > 700
        }
      />
    )
  }
export default withWindowDimensions
