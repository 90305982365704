import { createWrapper, MakeStore } from 'next-redux-wrapper'
import { applyMiddleware, createStore, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware, { Task } from 'redux-saga'
import { rootReducer } from 'redux/rootReducer'
import rootSaga from 'redux/rootSaga'

export interface SagaStore extends Store {
  sagaTask?: Task
}

const sagaMiddleware = createSagaMiddleware()

export const makeStore: MakeStore<any> = () => {
  // 1: Create the middleware
  // 2: Add an extra parameter for applying middleware:
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
  )

  // 3: Run your sagas on server
  ;(store as SagaStore).sagaTask = sagaMiddleware.run(rootSaga)

  // 4: now return the store:
  return store
}

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
)

// export an assembled wrapper
export const wrapper = createWrapper<Store>(makeStore, { debug: false })
