import { updateProfileActions } from 'components/Profile/UpdateProfileFormsNew/redux/UpdateProfileActions'
import { updateProfileApis } from 'components/Profile/UpdateProfileFormsNew/redux/UpdateProfileApis'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import { createOptionsObject } from 'utils/common-utils'
import { actionConstants } from './UpdateProfileActions'

function* fetchPersonalData(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchPersonalData)
    if (res.data.success) {
      yield put(updateProfileActions.fetchPersonalDataSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.fetchPersonalDataFail(res.data.message))
    }
  } catch (e: any) {
    yield put(updateProfileActions.fetchPersonalDataFail(e.message))
  }
}

function* savePersonalData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.savePersonalData, action.payload)
    console.log('lion', res.data)
    if (res.data.success) {
      yield put(updateProfileActions.savePersonalDataSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.savePersonalDataFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.savePersonalDataFail(e.message))
  }
}

function* fetchAddressData(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchAddressData)
    if (res.data.success) {
      yield put(updateProfileActions.fetchAddressDataSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.fetchAddressDataFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.fetchAddressDataFail(e.message))
  }
}

function* saveAddressData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.saveAddressData, action.payload)
    if (res.data.success) {
      yield put(updateProfileActions.saveAddressDataSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.saveAddressDataFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.saveAddressDataFail(e.message))
  }
}

function* fetchPassportData(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchPassportData)
    if (res.data.success) {
      yield put(updateProfileActions.fetchPassportDataSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.fetchPassportDataFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.fetchPassportDataFail(e.message))
  }
}

function* savePassportData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.savePassportData, action.payload)
    if (res.data.success) {
      yield put(updateProfileActions.savePassportDataSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.savePassportDataFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.savePassportDataFail(e.message))
  }
}

function* fetchBackgroundData(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchBackgroundData)
    if (res.data.success) {
      yield put(updateProfileActions.fetchBackgroundDataSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.fetchBackgroundDataFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.fetchBackgroundDataFail(e.message))
  }
}

function* saveBackgroundData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.saveBackgroundData, action.payload)
    if (res.data.success) {
      yield put(updateProfileActions.saveBackgroundDataSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.saveBackgroundDataFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.saveBackgroundDataFail(e.message))
  }
}

/********* Academic details - START ********/
function* fetchAcademicDetails(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchAcademicData)
    if (res.data.success) {
      yield put(updateProfileActions.fetchAcademicDetailsSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.fetchAcademicDetailsFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.fetchAcademicDetailsFail(e.message))
  }
}
function* saveAcademicDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.saveAcademicData, action.payload)
    if (res.data.success) {
      yield put(updateProfileActions.saveAcademicDetailsSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.saveAcademicDetailsFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.saveAcademicDetailsFail(e.message))
  }
}

/********* Academic details - END ********/

/********* Emergency contact details - START ********/
function* fetchEmergencyContact(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchEmergencyContactData)
    if (res.data.success) {
      yield put(
        updateProfileActions.fetchEmergencyContactDetailsSuccess(res.data.data)
      )
    } else {
      yield put(
        updateProfileActions.fetchEmergencyContactDetailsFail(res.data.message)
      )
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.fetchEmergencyContactDetailsFail(e.message))
  }
}
function* saveEmergencyContact(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      updateProfileApis.saveEmergencyContactData,
      action.payload
    )
    if (res.data.success) {
      yield put(
        updateProfileActions.saveEmergencyContactDetailsSuccess(res.data.data)
      )
    } else {
      yield put(
        updateProfileActions.saveEmergencyContactDetailsFail(res.data.message)
      )
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.saveEmergencyContactDetailsFail(e.message))
  }
}

/********* Emergency contact details - END ********/

/********* Exam Score details - START ********/
function* fetchExamScores(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchExamScore)
    if (res.data.success) {
      yield put(
        updateProfileActions.fetchExamScoreDetailsSuccess(res.data.data)
      )
    } else {
      yield put(
        updateProfileActions.fetchExamScoreDetailsFail(res.data.message)
      )
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.fetchExamScoreDetailsFail(e.message))
  }
}
function* saveExamScores(action: AnyAction): any {
  try {
    const res = yield call(updateProfileApis.saveExamScore, action.payload)
    if (res.data.success) {
      yield put(updateProfileActions.saveExamScoreDetailsSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.saveExamScoreDetailsFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.saveExamScoreDetailsFail(e.message))
  }
}

/********* Exam Score details - END ********/

function* fetchEducationData(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchEducationData)
    if (res.data.success) {
      console.log('yash', res.data)
      yield put(updateProfileActions.fetchEducationDataSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.fetchEducationDataFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.fetchEducationDataFail(e.message))
  }
}

function* saveEducationData(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.saveEducationData, action.payload)
    console.log('lion', res.data)
    if (res.data.success) {
      yield put(updateProfileActions.saveEducationDataSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.saveEducationDataFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.saveEducationDataFail(e.message))
  }
}

function* fetchRefereeData(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchRefereeData)
    if (res.data.success) {
      console.log('yash', res.data)
      const formattedReferee = {
        refereeOne: res.data.data[0],
        refereeTwo: res.data.data[1],
        refereeThree: res.data.data[2],
      }
      yield put(updateProfileActions.fetchRefereeDataSuccess(formattedReferee))
    } else {
      yield put(updateProfileActions.fetchRefereeDataFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.fetchRefereeDataFail(e.message))
  }
}

function* saveRefereeData(action: AnyAction): Generator<any, any, any> {
  const { refereeOne, refereeTwo, refereeThree } = action.payload
  const obj = [refereeOne, refereeTwo, refereeThree] as any
  try {
    const res = yield call(updateProfileApis.saveRefereeData, obj)
    if (res.data.success) {
      yield put(updateProfileActions.saveRefereeDataSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.saveRefereeDataFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.saveRefereeDataFail(e.message))
  }
}
/********* Work Experience - START ********/
function* fetchWorkExpDetails(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchWorkExp)
    if (res.data.success) {
      yield put(updateProfileActions.fetchWorkExpDetailsSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.fetchWorkExpDetailsFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.fetchWorkExpDetailsFail(e.message))
  }
}
function* saveWorkExpDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.saveWorkExp, action.payload)
    if (res.data.success) {
      yield put(updateProfileActions.saveWorkExpDetailsSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.saveWorkExpDetailsFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.saveWorkExpDetailsFail(e.message))
  }
}
function* deletwWorkExpDetails(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.deleteWorkExp, action.payload)
    if (res.data.success) {
      yield put(updateProfileActions.deleteWorkExpSuccess(res.data.data))
    } else {
      yield put(updateProfileActions.deleteWorkExpFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.deleteWorkExpFail(e.message))
  }
}
/********* Work Experience - END ********/

function* getCountryList(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.getCountryList)
    if (res.data.success) {
      const list = createOptionsObject(res.data.data)
      yield put(updateProfileActions.getCountryListSuccess(list))
    } else {
      yield put(updateProfileActions.getCountryListFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.getCountryListFail(e.message))
  }
}

function* getLanguageList(): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.getLanguageList)
    if (res.data.success) {
      const list = createOptionsObject(res.data.data)
      yield put(updateProfileActions.getLanguageListSuccess(list))
    } else {
      yield put(updateProfileActions.getLanguageListFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.getLanguageListFail(e.message))
  }
}

function* getStateList(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchStateList, action.payload)
    if (res.data.success) {
      const states = createOptionsObject(res.data.data)
      yield put(updateProfileActions.getStateListSuccess(states))
    } else {
      yield put(updateProfileActions.getStateListFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.getStateListFail(e.message))
  }
}

function* getCityList(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(updateProfileApis.fetchCityList, action.payload)
    if (res.data.success) {
      const cities = createOptionsObject(res.data.data)
      yield put(updateProfileActions.getCityListSuccess(cities))
    } else {
      yield put(updateProfileActions.getCityListFail(res.data.message))
    }
  } catch (e: any) {
    console.log(e)
    yield put(updateProfileActions.getCityListFail(e.message))
  }
}

function* updateProfileSaga() {
  yield takeLatest('FETCH_PERSONAL_DATA', fetchPersonalData)
  yield takeLatest('SAVE_PERSONAL_DATA', savePersonalData)

  yield takeLatest('FETCH_ADDRESS_DATA', fetchAddressData)
  yield takeLatest('SAVE_ADDRESS_DATA', saveAddressData)

  yield takeLatest('FETCH_PASSPORT_DATA', fetchPassportData)
  yield takeLatest('SAVE_PASSPORT_DATA', savePassportData)

  yield takeLatest('FETCH_BACKGROUND_DATA', fetchBackgroundData)
  yield takeLatest('SAVE_BACKGROUND_DATA', saveBackgroundData)

  yield takeLatest('FETCH_EDUCATION_DATA', fetchEducationData)
  yield takeLatest('SAVE_EDUCATION_DATA', saveEducationData)

  yield takeLatest('FETCH_REFEREE_DATA', fetchRefereeData)
  yield takeLatest('SAVE_REFEREE_DATA', saveRefereeData)

  yield takeLatest('GET_COUNTRY_LIST', getCountryList)
  yield takeLatest('GET_LANGUAGE_LIST', getLanguageList)
  //ub - start
  yield takeLatest(actionConstants.FETCH_ACADEMIC_DATA, fetchAcademicDetails)
  yield takeLatest(actionConstants.SAVE_ACADEMIC_DATA, saveAcademicDetails)
  yield takeLatest(
    actionConstants.FETCH_EMERGENCY_CONTACT_DATA,
    fetchEmergencyContact
  )
  yield takeLatest(
    actionConstants.SAVE_EMERGENCY_CONTACT_DATA,
    saveEmergencyContact
  )
  yield takeLatest(actionConstants.GET_STATE_LIST, getStateList)
  yield takeLatest(actionConstants.GET_CITY_LIST, getCityList)
  yield takeLatest(actionConstants.FETCH_EXAM_SCORE_DATA, fetchExamScores)
  yield takeLatest(actionConstants.SAVE_EXAM_SCORE, saveExamScores)
  //ub - end

  //ub -start
  yield takeLatest(actionConstants.FETCH_WORK_EXP_DATA, fetchWorkExpDetails)
  yield takeLatest(actionConstants.SAVE_WORK_EXP, saveWorkExpDetails)
  yield takeLatest(actionConstants.DELETE_WORK_EXP, deletwWorkExpDetails)
  //ub -end
}

export default updateProfileSaga
