import LineBreak from "components/common/LineBreak"
import { singleTab } from "components/Ielts/UpcomingIeltsBatches/TabList"
import { ReactNode } from "react"

export interface ValuePropType {
  id: number
  icon: string
  titleText?: string
  text: string
  isDynamicText?: boolean
  type?: string
  altText: string
}

export const valuePropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/live.svg`,
    altText: "Live Classes",
    titleText: "Daily",
    text: "Live Classes",
    isDynamicText: false,
    type: "duration",
  },
  {
    id: 2,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/check.svg`,
    altText: "Mock test",
    text: "Mock Tests",
    isDynamicText: true,
    type: "tests",
  },
  {
    id: 3,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/note.svg`,
    altText: "Evaluation",
    text: "Writing Evaluation",
    isDynamicText: true,
    type: "writing",
  },
  {
    id: 4,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/chat.svg`,
    altText: "Speaking",
    text: "Speaking Evaluation",
    isDynamicText: true,
    type: "speaking",
  },
  {
    id: 5,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/idea.svg`,
    altText: "Material",
    text: "Study Materials",
    isDynamicText: false,
    type: "material",
  },
  {
    id: 6,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/certificate.svg`,
    altText: "Certificate",
    text: "Completion Certificate",
    isDynamicText: false,
    type: "certificate",
  },
  {
    id: 7,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/abc.svg`,
    altText: "Daily Grammar classes",
    text: "Daily Grammar classes",
    isDynamicText: false,
    type: "daily_grammar_classes",
  },
  {
    id: 8,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/video.svg`,
    altText: "Class recordings",
    text: "Class recordings",
    isDynamicText: false,
    type: "Class recordings",
  },
]

export const MasterclassValuePropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${process.env.CDN_URL}/assets/icons/ielts/live_classes.png`,
    altText: "Live Classes",
    text: "Live Classes",
    isDynamicText: false,
    type: "duration",
  },
  {
    id: 2,
    icon: `${process.env.CDN_URL}/assets/icons/ielts/mock_test.svg`,
    altText: "Mock test",
    text: "Mock Tests",
    isDynamicText: false,
    type: "tests",
  },
  {
    id: 3,
    icon: `${process.env.CDN_URL}/assets/icons/ielts/writing_evaluation.svg`,
    altText: "Evaluation",
    text: "Writing Evaluation",
    isDynamicText: false,
    type: "writing",
  },
  {
    id: 4,
    icon: `${process.env.CDN_URL}/assets/icons/ielts/speaking_evaluation.svg`,
    altText: "Speaking",
    text: "Speaking Evaluation",
    isDynamicText: false,
    type: "speaking",
  },
  {
    id: 5,
    icon: `${process.env.CDN_URL}/assets/icons/ielts/study_material.svg`,
    altText: "Material",
    text: "Study Materials",
    isDynamicText: false,
    type: "material",
  },
  {
    id: 6,
    icon: `${process.env.CDN_URL}/assets/icons/ielts/certificate.svg`,
    altText: "Certificate",
    text: "Completion Certificate",
    isDynamicText: false,
    type: "certificate",
  },
]

export const ieltsPlusValuePropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/live.svg`,
    altText: "20+ hours",
    titleText: "Daily",
    text: "Live Classes",
    isDynamicText: false,
    type: "duration",
  },
  {
    id: 2,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/check_list.svg`,
    titleText: "Five",
    altText: "Mock test",
    text: "Mock Tests",
    isDynamicText: false,
    type: "tests",
  },
  {
    id: 3,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/write.svg`,
    titleText: "Three",
    altText: "Evaluation",
    text: "Writing Evaluation",
    isDynamicText: false,
    type: "writing",
  },
  {
    id: 4,
    icon: `${process.env.CDN_URL}/assets/images/ielts/value_props/text.svg`,
    titleText: "Three",
    altText: "Speaking",
    text: "Speaking Evaluation",
    isDynamicText: false,
    type: "speaking",
  },
]

export const ieltsPremiumValuePropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    altText: "Small Batches",
    titleText: "Small Batches",
    text: "",
    isDynamicText: false,
    type: "duration",
  },
  {
    id: 2,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Exclusive Content",
    altText: "Exclusive Content",
    text: "",
    isDynamicText: false,
    type: "tests",
  },
  {
    id: 3,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Exclusive",
    altText: "Evaluation",
    text: "Mock Tests",
    isDynamicText: false,
    type: "writing",
  },
  {
    id: 4,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Extra Questions",
    altText: "Speaking",
    text: "for Practice",
    isDynamicText: false,
    type: "speaking",
  },
]

export const ieltsFoundationValuePropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    altText: "Designed",
    titleText: "Designed For",
    text: "IELTS Beginners",
    isDynamicText: false,
  },
  {
    id: 2,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    altText: "Skill-Building",
    titleText: "Skill-Building",
    text: "Course",
    isDynamicText: false,
  },
  {
    id: 3,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Upskill self to learn IELTS",
    altText: "Speaking",
    text: "syllabus after 2 weeks",
    isDynamicText: false,
  },
  {
    id: 4,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Specially Curated",
    altText: "Evaluation",
    text: "Practice Tests",
    isDynamicText: false,
  },
]

export const ieltsPlusPropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    altText: "Daily",
    titleText: "Daily",
    text: "Live Classes",
    isDynamicText: false,
  },
  {
    id: 2,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    altText: "Skill-Building",
    titleText: "Free Speaking",
    text: "Evaluation",
    isDynamicText: false,
  },
  {
    id: 3,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Study",
    altText: "Study",
    text: "materials",
    isDynamicText: false,
  },
  {
    id: 4,
    icon: `${process.env.CDN_URL}/assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Mock",
    altText: "Mock",
    text: "Tests",
    isDynamicText: false,
  },
]
export type bannerValuePropType = {
  imgUrl: string
  boldText?: string
  defaultText: string
}

export const plusBannerValuePropsData: bannerValuePropType[] = [
  {
    imgUrl: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/view_all/plus1.svg`,
    boldText: "LIVE",
    defaultText: "classes with experts",
  },
  {
    imgUrl: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/view_all/plus2.svg`,
    boldText: "Lifetime",
    defaultText: "access to recordings",
  },
  {
    imgUrl: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/view_all/plus3.svg`,
    boldText: "Mock tests",
    defaultText: "and study materials",
  },
]
export const premiumBannerValuePropsData: bannerValuePropType[] = [
  {
    imgUrl: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/view_all/premium1.svg`,
    defaultText: "Personalised Attention",
  },
  {
    imgUrl: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/view_all/premium2.svg`,
    defaultText: "Speaking & Writing evaluations",
  },
  {
    imgUrl: `${process.env.CDN_URL}/assets/images/ielts/value_props/ielts/view_all/premium3.svg`,
    defaultText: "Reading & Writing mock tests",
  },
]

export enum ietlsPlusFilterData {
  START_SOON = -1,
  ALL = -1,
  IELTS_PLUS_PART_2 = -2,
}

export const ieltsPlusFilterData = [
  { id: ietlsPlusFilterData.START_SOON, text: "Starts soon" },
  { id: 2, text: "All" },
  { id: 3, text: "Evening" },
  { id: 4, text: "Morning" },
  { id: 5, text: "Afternoon" },
]

export enum viewAllFilterType {
  ALL = "All_weeks",
  IELTS_PLUS_PART_2 = "IELTSPlusPart2",
}

export const ieltsViewAllTabsData: singleTab[] = [
  { id: 1, text: "IELTS Elite", active: true },
  { id: 2, text: "IELTS Select", active: true },
]

export const IeltsAppValueProps: string[] = [
  "Exclusive Live Masterclasses",
  "IELTS Band Predictor Tests",
  "Free Speaking Practice on App",
]
export type McBannerData = { title: string; points: ReactNode[] }
export const tofuDisqMcData: McBannerData = {
  title: "We have unlocked a free  IELTS Masterclass for you. What you get:",
  points: [
    <span key="1">
      <span className="font-bold">Exclusive IELTS</span> session where you learn
      about tips and tricks to get{" "}
      <span className="font-bold">8+ band for FREE</span>
    </span>,
    <span key="2">
      Finding the perfect <span className="font-bold">preparation method </span>
      for you with our <span className="font-bold"> band predictor</span> and
      diagnostic tests
    </span>,
    <span key="3">
      Live interaction with{" "}
      <span className="font-bold">India's top IELTS Trainers </span> and mentors
      to get your doubts cleared
    </span>,
  ],
}

export const tofuDisqMcDataMweb: McBannerData = {
  title: "Unlocked : Free IELTS Masterclass",
  points: [
    <span key="1">Get your doubts cleared about IELTS</span>,
    <span key="2">
      Finding the perfect <span className="font-bold">preparation </span>{" "}
      <LineBreak device="mobile" /> method for you
    </span>,
    <span key="3">
      <span className="font-bold"> Live interaction </span>
      with IELTS <LineBreak device="mobile" /> Trainers
    </span>,
  ],
}

export const mcBannerDataWeb: McBannerData = {
  title: "IELTS Masterclass",
  points: tofuDisqMcData.points,
}

export const mcBannerDataMweb: McBannerData = {
  title: "IELTS Masterclass",
  points: [
    <span key="1">
      <span className="font-bold">Free exclusive </span> IELTS session
    </span>,
    <span key="2">
      <span className="font-bold"> Live interaction </span>
      with IELTS Trainers
    </span>,
    <span key="3">
      Get your doubts cleared and <LineBreak device="mobile" /> learn{" "}
      <span className="font-bold"> tips & tricks</span>
    </span>,
  ],
}
