import { getUtmData } from "utils/utm_utils"

export const logPageView = (path?: string | undefined): void => {
  const page = path || window.location.pathname
  gtag("event", "page_view", {
    page,
  })
}

export const logEvent = (
  category: string,
  action: string,
  label: any,
): void => {
  if (
    typeof window !== "undefined" &&
    typeof (window as any).gtag === "function"
  ) {
    gtag("event", action, {
      category: category,
      action: action,
      label: label,
    })
  }
}

export const sendPageViewGAEvent = (
  eventProps: Record<string, any>,
  userProps?: Record<string, any>,
) => {
  const utmDetails = getUtmData(null)
  const url = window.location.href
  const isMobile = window.innerWidth < 768
  const allEventDetails = {
    ...eventProps,
    ...utmDetails,
    page_url: url,
    platform: isMobile ? "MWEB" : "WEB",
  }
  if (
    typeof window !== "undefined" &&
    typeof (window as any).gtag === "function"
  ) {
    if (userProps) {
      gtag("set", "user_properties", userProps)
    }
    gtag("event", "page_view", allEventDetails)
  }
}
export function sendCustomGaEvent(
  eventName: string,
  eventProps: Record<string, any>,
  userProps?: Record<string, any>,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const utmDetails = getUtmData(null)
    const url = window.location.href
    const isMobile = window.innerWidth < 768
    const allEventDetails = {
      ...eventProps,
      ...utmDetails,
      page_url: url,
      platform: isMobile ? "MWEB" : "WEB",
    }

    if (
      typeof window !== "undefined" &&
      typeof (window as any).gtag === "function"
    ) {
      if (userProps) {
        gtag("set", "user_properties", userProps)
      }
      gtag("event", eventName, allEventDetails)
      resolve() // Resolve the Promise when the event is sent.
    } else {
      reject(new Error("Google Analytics not available."))
    }
  })
}

export const sendCustomGaEventWithoutProperties = (
  eventName: string,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const isMobile = window.innerWidth < 768

    if (
      typeof window !== "undefined" &&
      typeof (window as any).gtag === "function"
    ) {
      gtag("event", eventName, {
        platform: isMobile ? "MWEB" : "WEB",
      })

      resolve() // Resolve the Promise when the event is sent.
    } else {
      reject(new Error("Google Analytics not available."))
    }
  })
}

export const fireTagEvent = async (
  eventName: string,
  eventData?: { [key: string]: string },
): Promise<void> => {
  if ((window as any).dataLayer) {
    await (window as any).dataLayer.push({
      event: eventName,
      eventData: eventData,
    })
  }
}

export const fireCountryTagEvent = async (
  eventName: string,
  country?: string,
): Promise<void> => {
  if ((window as any).dataLayer) {
    await (window as any).dataLayer.push({ event: eventName, country: country })
  }
}

export const fireOptimizeEvent = async () => {
  if ((window as any).dataLayer) {
    await (window as any).dataLayer.push({ event: "optimize.activate" })
  }
}
